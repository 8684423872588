import { useEffect, useRef, useContext } from "react";
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Context } from '../../pages/home.page';
import { useTranslation } from "react-i18next";
//import es from './es.js';

interface IProps {
    inputData: any
    setValue: Function
}

export default function RichTextEditor(props:IProps) {
    // add auto focus to text editor
    const editorRef = useRef<Editor | null>(null);

    const loggedInUserData = useContext(Context).user;

    const { t } = useTranslation();

    const editorLabels = {
        // Generic
        'generic.add': t('Add'),
        'generic.cancel': t('Cancel'),
        // BlockType
        'components.controls.blocktype.h3': t('Heading'),
        'components.controls.blocktype.h5': t('Subheading'),    
        'components.controls.blocktype.normal': t('Normal text'),
        // Inline
        'components.controls.inline.bold': t('Bold'),
        'components.controls.inline.italic': t('Italic'),
        'components.controls.inline.underline': t('Underline'),
        // Link
        'components.controls.link.linkTitle': t('Link Title'),
        'components.controls.link.linkTarget': t('Link Target'),
        //'components.controls.link.linkTargetOption': t('Link Target Options'),
        'components.controls.link.link': t('Link'),
        'components.controls.link.unlink': t('Unlink'),
        // List
        'components.controls.list.list': t('List'),
        'components.controls.list.unordered': t('Bullet'),
    };    

    useEffect(() => {
       editorRef?.current?.focusEditor();
    }, [])

    function onChange(editorState : any) {
        props.setValue(editorState)
    }

    console.log(loggedInUserData.settings.language)

    return(
        <Editor
            ref={editorRef}
            editorState={props.inputData}
            placeholder={t("Add text...")}
            wrapperClassName="text-editor-wrapper-class"
            editorClassName="text-editor-editor-class"
            toolbarClassName="text-editor-toolbar-class"
            localization={{ locale: loggedInUserData.settings.language || 'en', translations: editorLabels }}
            onEditorStateChange={onChange}
            stripPastedStyles={true}
            //autoCapitalize="on" // TODO: low
            toolbar={{
                options: ['blockType', 'inline', 'list', 'link'],
                blockType: {
                    options: ['Normal', 'H5', 'H3'],
                    className: "tex-editor-blockType"
                },
                inline: {
                    options: ['bold', 'italic', 'underline'],
                    className: 'text-editor-inline'
                },
                list: {
                    options: ['unordered'],
                    className: 'text-editor-options'
                },
                link: {
                    // inDropdown: false,
                    // className: undefined,
                    // component: undefined,
                    // popupClassName: undefined,
                    // dropdownClassName: undefined,
                    // showOpenOptionOnHover: true,
                    defaultTargetOption: "_blank",
                    options: ['link'],
                    // link: { icon: mdiLink, className: undefined },
                    // unlink: { icon: mdiCheckUnderlineCircleOutline, className: undefined },
                    // linkCallback: undefined
                  },
            }}
        />
    );
}