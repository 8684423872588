import React, { useEffect, useState } from 'react';
import { Spinner, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import instance from '../api/api';
import { IUserDetails } from '../interfaces/authentication';
import { IStory } from '../interfaces/story';
import default_avatar from "../assets/images/default-avatar.jpg"
import { DisplayStory } from '../components/story/blocks/display-story.component';
import axios from "axios"
import config from "../config/config"
import simirity_logo from "../assets/images/simirity-logo.png";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { getStoryDate } from '../_helper/getStoryDate';
//import IFrameDisplay from '../components/_helper/iframe-display.component';

const date = new Date();

const PublicStory: React.FunctionComponent<{}> = props => {

    const [ story, setStory ] = useState<IStory | null>(null);
    const [ error, setError ] = useState<boolean>(false);
    const [ loading, setLoading ] = useState<boolean>(true);

    //const [ banner, setBanner ] = useState<any>([]);
    const { t } = useTranslation(); 

    const params:any = useParams();

    useEffect(() => {
        getPublicStory();
        //fetchBanner();
    }, []);

    async function getPublicStory() {
        try {
            const response = await instance.get('/publishedstories/public/' + params.id);
            setStory(response.data)
            setLoading(false);

        }
        catch(err) {
            setLoading(false);
            setError(true);
        }
    }
/*
    async function fetchBanner() {        
        axios.get(`${config.contentServerURL}/api/banners`).then(resp => { // Strapi v4 query format
            setBanner(resp.data.data);
        }, error => {
            console.log("Failed to get banner", error)
        });
    }
*/
    function getImage() {
        const featured = story?.featured as IUserDetails;
        return featured.avatar ? featured.avatar : default_avatar
    }

    return (
        <div className="public-story-page">
            <div className='header'>
                <div className='page-title'>
                    <a href="https://www.simirity.com" rel="noreferrer" target="_blank"><img src={simirity_logo} alt="Logo" className="logo" /></a>
                </div>
            </div>
            {loading ?
                <div className='loader-container'>
                    <Spinner animation="border" variant="#fff"/>
                </div>
                : error ?
                <div className='error-container'>
                    <p>{t("This story does not exist or not yet public. Please try again later.")}</p>
                </div>
                : story !== null ?
                <div className="story-container">
                    <div className="story-details">
                        <div className="main-info">
                            <p className="name title">
                                {(story.featured as IUserDetails).nickname ? (story.featured as IUserDetails).nickname : `${(story.featured as IUserDetails).firstname}`}
                            </p>
                            {story.date && story.updatedAt.length && (
                                <p className="date">
                                    {getStoryDate(story.date.startDate)} {story.date.endDate && <span> - {getStoryDate(story.date.endDate)}</span>}
                                </p>
                            )}
                            <div className="user-avatar">
                                <img className="user-picture" src={getImage()} alt="user" />
                            </div>
                            {(story.author as IUserDetails)._id !== (story.featured as IUserDetails)._id ? <p className="author">Author: {`${(story.author as IUserDetails).firstname} ${(story.author as IUserDetails).lastname}`}</p> : null}
                            {story.coAuthors.length ? (
                                <p className="co-author">
                                    Co-author(s):
                                    {(story.coAuthors as IUserDetails[]).map((coauthor, index) => {
                                        return <span>{` ${coauthor.firstname} ${coauthor.lastname}${index === story.coAuthors.length - 1 ? "" : ","}`}</span>
                                    })}
                                </p>
                            ) : null}
                        </div>
                        <div className="separator"></div>
                        <div className="story-body">
                            <p className="title">{story.title}</p>
                            <DisplayStory story={story} showInteractions={false} />
                        </div>
                    </div>
                </div>            
            : null}
            <div className="banner-container content">
                <p className="title">Stronger Family App</p>
                <p className="subtitle">For families that want something other<br/>than Whatsapp, Facebook or Google Photos</p>
                <div className="iframe-empty-page">
                    <iframe src={`https://player.vimeo.com/video/849109482`} width="460" height="232" allow="autoplay; fullscreen; picture-in-picture"></iframe>
                </div>
                <a href="https://www.simirity.com" rel="noreferrer" target="_blank" className="link-button"><Button className="default-button">{t("Learn more")}</Button></a>
            </div>
            {/*banner && banner[0] ? 
                <div className="banner-container">
                    <a href="https://www.simirity.com" rel="noreferrer" target="_blank"><img src={`${config.contentServerURL}${banner[0].banner.url}`} alt="simirity-banner" /></a>
                </div>
            : null */}
            <footer className="footer">
                <Container fluid className="px-0">
                    <Row className="footer-links">
                        <ul>
                            <li>
                                <a className="link" href="https://www.simirity.com/our-story/">
                                    {t("our story")}
                                </a>
                            </li>
                                                      
                            <li>
                                <a className="link" href="https://www.simirity.com/contact-us/">
                                    {t("contact us")}
                                </a>
                            </li>

                            <li>
                                <a className="link" href="https://www.simirity.com/terms-conditions/">
                                    {t("terms & conditions")}
                                </a>
                            </li>
                        </ul>
                    </Row>
                    <Row className="rights">
                        <Col xs={12}>
                            <p>
                                Copyright {date.getFullYear()} Simirity Ltd. <br /> All rights
                                reserved.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </div>
    );
}

export default PublicStory;
