import React, { FormEvent } from "react"
import { Button, Modal, Spinner } from "react-bootstrap"

// icons
import Icon from "@mdi/react"
import { mdiCheckCircle, mdiCloseCircle } from "@mdi/js"

import { useTranslation } from "react-i18next";

interface IProps {
    show: boolean
    action?: string
    handleClose: () => void
    modalTitle: string
    modalBody: () => JSX.Element
    reject: () => void
    confirm: (evt: FormEvent<EventTarget>) => Promise<void>
    loading?:boolean
}

function ConfirmModal(props: IProps) {
    
    const modalClass = props.action === "delete" ? "delete-group-modal" : "confirm-modal";
    
    const { t } = useTranslation();
    
    return (
        <Modal dialogClassName={modalClass} show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t(props.modalTitle)}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {props.modalBody()}
                {props.action === "delete" ?
                    <div className="button-group">
                        <Button className="default-button proceed" onClick={props.confirm}>
                            {props.loading ? <Spinner animation="border" variant="#fff" /> : t("Confirm")}
                        </Button>
                        <Button className="link-button cancel" onClick={props.reject}>
                            {t("Cancel")}
                        </Button>
                    </div>
                    :              
                    <div className="actions">
                        <span onClick={props.confirm}>
                            <Icon className="accept" size={1.4} path={mdiCheckCircle} />
                        </span>
                        <span onClick={props.reject}>
                            <Icon className="refuse" size={1.4} path={mdiCloseCircle} />
                        </span>
                    </div>
                }
            </Modal.Body>
        </Modal>
    )
}

export default ConfirmModal
