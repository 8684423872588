import React, { useContext, useEffect, useState } from 'react';
import { IUserDetails } from '../../interfaces/authentication';
import Switch from "react-switch";
import UniqueUserListInput from '../_helper/unique-selects/unique-user-list-input.component';
import { Context } from '../../pages/home.page';
import { IOption, IRelation } from '../../interfaces/family';
import UniqueSimpleSelect from '../_helper/unique-simple-select.component';
import countries from "countries-list";
import TooltipHelp from '../notifications/tooltiphelp.component';
import DatePicker from "react-datepicker";
import UniqueListInput from '../_helper/unique-list-input.component';
import config from '../../config/config';
import Icon from '@mdi/react';
import { mdiMenuDown } from '@mdi/js';
import { IVisualFilters } from './story-visuals.component';
import { useTranslation } from "react-i18next";

interface IProps {
    filters:IVisualFilters
    setFilters:Function
    setShowFilters:Function
    queryString:string
    count: Number
}

export default function StoryVisualFilters(props:IProps) {

    const [ showFeaturedTooltip, setShowFeaturedTooltip ] = useState<boolean>(false);
    const [ showStoryVisualTT, setShowStoryVisualTT ] = useState<boolean>(false);

    const [ userOptions, setUserOptions ] = useState<IUserDetails[]>([]);

    const userData = useContext(Context).user;

    const { t } = useTranslation();    

    //Setup options
    useEffect(() => {
        if(userData._id.length) {
            setUserOptions([...userData.relations.map((relation:IRelation) => relation.userid), userData])
        }
    }, [])

    function updateUserFilterField(key:string, value:IUserDetails) {
        const listType = (props.filters[key] as IUserDetails[]).map(x => x._id);
        if(listType.includes(value._id)) {
            props.setFilters({...props.filters, [key]: props.filters[key].filter((x:IUserDetails) => x._id !== value._id)})
        }
        else {
            props.setFilters({...props.filters, [key]:[...props.filters[key], value]})
        }
    }

    function updateIOptionField(key:string, value:IOption) {
        const optionType = (props.filters[key] as IOption[]).map(x => x._id);
        if(optionType.includes(value._id)) {
            props.setFilters({...props.filters, [key]: props.filters[key].filter((x:IUserDetails) => x._id !== value._id)})
        }
        else {
            props.setFilters({...props.filters, [key]:[...props.filters[key], value]})
        }
    }


    function handleDateInput(date: Date, dateAccuracy: string) {

        let newDate: Date = new Date()
        switch (dateAccuracy) {
            case "year":
                newDate = new Date(props.filters.date.date.setFullYear(date.getFullYear()));
                props.setFilters({ ...props.filters, date: { date: newDate, year: newDate.getFullYear(), month: null, day: null }})
                break
            case "month":
                // the date is the first day of the selected month
                newDate = new Date(props.filters.date.date.setMonth(date.getMonth()));
                props.setFilters({ ...props.filters, date: {...props.filters.date, date: newDate, month: newDate.getMonth(), day: null }})
                break
            case "day":
                newDate = new Date(props.filters.date.date.setDate(date.getDate()));
                props.setFilters({ ...props.filters, date: {...props.filters.date, date: newDate, day: newDate.getDate() }})
                break
        }
    }

    function showStories() {
        props.setShowFilters(false);
    }

    function setFilterField(key:string, value:string) {
        props.setFilters({...props.filters, [key]: value})
    }

    function setupCountryOptions() {
        const countryOptions = Object.entries(countries.countries).map(([key, value]) => {
            return(
                {key:key, value:value.name}
            );
        }).sort((a, b) => a.value.localeCompare(b.value))

        return countryOptions
    }

    return(
        <div className='story-filters'>
            <p className='filter-group-title'>{t("People")}</p>
            <div className="people">
                <div className="with-tooltip">
                    <UniqueUserListInput placeholder={t("Featuring")} data={props.filters.participants} options={userOptions} handleAction={(value:IUserDetails) => updateUserFilterField("participants", value)}/>
                    <p onClick={() => setShowFeaturedTooltip(true)} className="tooltip-text">
                        {t("What's this?")}
                    </p>
                </div>
                <div className="with-tooltip">
                    <UniqueUserListInput placeholder={t("Stories Visuals")} data={props.filters.featured} options={userOptions} handleAction={(value:IUserDetails) => updateUserFilterField("featured", value)}/>
                    <p onClick={() => setShowStoryVisualTT(true)} className="tooltip-text">
                        {t("What's this?")}
                    </p>
                </div>
            </div>
            <p className='filter-group-title'>{t("Story")}</p>
            <div className="story">
                <div>
                    <div className="dates-input-container">
                        <div className="date-container">
                            <label className="top-label">{t("Year")}</label>
                            <Icon className="calendar-icon" size={1} path={mdiMenuDown} />
                            <DatePicker 
                                selected={props.filters.date.year !== null ? props.filters.date.date : null} 
                                onChange={(date) => (date instanceof Date ? handleDateInput(date, "year") : null)} 
                                showYearPicker 
                                dateFormat="yyyy" 
                            />
                        </div>
                        <div className={`date-container ${!(props.filters.date.year !== null) ? "disabled" : ""}`}>
                            <label className="top-label">{t("Mth")}</label>
                            <Icon className="calendar-icon" size={1} path={mdiMenuDown} />
                            <DatePicker
                                disabled={!(props.filters.date.year !== null)}
                                selected={props.filters.date.month !== null ? props.filters.date.date : null} 
                                onChange={(date) => (date instanceof Date ? handleDateInput(date, "month") : null)}
                                dateFormat="MMM"
                                showMonthYearPicker
                            />
                        </div>
                        <div className={`date-container ${!(props.filters.date.month !== null) ? "disabled" : ""}`}>
                            <label className="top-label">{t("Day")}</label>
                            <Icon className="calendar-icon" size={1} path={mdiMenuDown} />
                            <DatePicker
                                disabled={!(props.filters.date.month !== null)}
                                selected={props.filters.date.day !== null ? props.filters.date.date : null} 
                                onChange={(date) => (date instanceof Date ? handleDateInput(date, "day") : null)}
                                dateFormat="dd"
                                dropdownMode="select"
                            />
                        </div>
                    </div>
                </div>         
                <UniqueListInput placeholder={t("Tag(s)")} icon={true} data={props.filters.storyTags} options={config.storyTags} handleAction={(value:IOption) => updateIOptionField("storyTags", value)}/>
                <UniqueSimpleSelect placeholder={t("Country")} selected={(countries.countries as any)[props.filters.country] ? (countries.countries as any)[props.filters.country].name : props.filters.country} options={setupCountryOptions()} handleAction={(value:string) => setFilterField("country", value)}/>
                <TooltipHelp contextKey={'story_filters_visuals_featuring'}
                    show={showFeaturedTooltip}
                    clear={() => setShowFeaturedTooltip(false)}
                />
                <TooltipHelp contextKey={'story_filters_visuals_stories_visuals'}
                    show={showStoryVisualTT}
                    clear={() => setShowStoryVisualTT(false)}
                />
                <div className="toggels">
                    <div className="switch-container">
                        <span>{t("My Favourites")}</span>
                        <div className="status">
                            <p className="switch-status">{props.filters.favorites ? t("On") : t("Off")}</p>
                            <Switch 
                                className={`date-container ${props.filters.favorites ? "active-gold-switch" : ""}`}
                                offColor="#17243d"
                                offHandleColor="#8B919E"
                                onColor="#a38138"
                                onHandleColor="#a38138"
                                handleDiameter={20}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={14}
                                width={32}
                                checked={props.filters.favorites}
                                onChange={(checked: any) => setFilterField('favorites', checked)} 
                                />
                            </div>
                    </div>
                    <button className="default-button" onClick={() => showStories()}>{t("Show {{var}} visuals", {"var": props.count})}</button>
                </div>
            </div>
        </div>
    )
}