import instance from "../../api/api";
import config from "../../config/config";
import { IUserDetails } from "../../interfaces/authentication";
import { blockTypes } from "../../interfaces/story";
import { ESubscriptionStatus } from "../../interfaces/subscription";
import { IdefBlock } from "../story/blocks/block-picker.component";

export async function getAccesForBlock(userData:IUserDetails, defaultBlocks:IdefBlock[]) {
    try {
        const subscription = userData.subscription;

        const disabledBlocks:string[] = [];

        defaultBlocks.forEach(async (block:IdefBlock, index:number) => {
            if(subscription && subscription.status !== ESubscriptionStatus.INACTIVE) {
                const limit = config.freeUserLimits[block.type];
                if(limit !== undefined) {
                    if(subscription.allUploadInPeriod >= subscription.subscription_type.upload_limit) {
                        disabledBlocks.push(block.type)
                    }
                }
            }
            else {
                const limit = config.freeUserLimits[block.type]
                if(limit !== undefined) {
                    // console.log('limit', limit)
                    if(limit > 0) {
                        const used = await instance.get("/stories/block/count", { params: { type:block.type }})
                        if(limit <= used.data.count) {
                            disabledBlocks.push(block.type)
                        }
                        else {
                            disabledBlocks.filter(x => x !== block.type)
                        }
                    }
                    else {
                        disabledBlocks.push(block.type)
                    }
                }
            } 
        });

        return disabledBlocks;
    } catch(err) {
        return [];
    }
}

export async function getSpecificAccess(blockType:blockTypes) {

    try {
        const used = await instance.get("/stories/block/count", { params: { type:blockType }})
        return used.data.count;
    }
    catch(err) {
        return 0;
    }
}