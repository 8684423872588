import React from "react";
import happy_emoji from "../../../assets/images/notification/emoji-happiness.svg";
import { useTranslation } from "react-i18next";

interface IProps {
} 

export default function SubscriptionFamilyVipWidget(props:IProps) {

    const { t } = useTranslation();

    return(
        <div className="subscription-referral-widget">
            <div className="top-container">
                <p className="">{t("VIP ACCESS")}</p>
                <p className="">{t("1-YEAR 'FAMILY' SUBSCRIPTION")}*</p>
            </div>
            <div className="emoji-conatiner">
                <img className="emoji" alt="simle-emoji" style={{width: "80px", marginTop: "-45px"}} src={happy_emoji}/>
            </div>
            <div className="info-text-container">
                <p>Register to access</p>
                <p className="gray-text">* {t("Up to 10 family members can use your Subscription and together upload up to 50GB in media files")}</p>
            </div>
        </div>
    )
}