import React, { useEffect, useState, useContext }  from "react";
import { Tabs, Tab } from "react-bootstrap";
import instance from "../../api/api";
import { IUserDetails } from "../../interfaces/authentication";
import { fileTypes, IMedia } from "../../interfaces/story";
import { ESubscriptionStatus } from "../../interfaces/subscription";
import ProfileVisualImages from "./profile-visual-images.component";
import { Context } from "../../pages/home.page";
import { useTranslation } from "react-i18next";
import Access from "../_helper/access-component";

interface IProps {
    user: IUserDetails
    administrator: boolean
    myProfile: boolean
}

enum localMenu {
    ABOUT_ME = "1",
    MY_UPLOADS = "2",
}

interface ISort {
    field:string
    type:string
}

export default function ProfileVisuals(props:IProps) {

    const [ key, setKey ] = useState<localMenu>(localMenu.ABOUT_ME);
    const [ media, setMedia] = useState<IMedia[]>([]);
    const [ hasMore, setHasMore ] = useState<boolean>(false);
    const [ page, setPage ] = useState<number>(0);
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ fav, setFav] = useState<boolean>(false);
    const [ totalCount, setTotalCount ] = useState<number>(0);

    const [ sortOption, setSortOption ] = useState<ISort>({field:'createdAt', type:'desc'})

    const userData = useContext(Context).user;

    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            setMedia([]);
        }
    }, [])

    useEffect(() => {
        if(props.user && props.user._id && props.user._id.length && !loading && userData.subscription && userData.subscription.status !== ESubscriptionStatus.INACTIVE) {
            loadVisuals();
        }
    }, [ sortOption, fav, props.user._id, page, key, userData.subscription ])


    async function loadVisuals() {
        setLoading(true);
        let queryString = "";
        let sortString = "";

        if(props.myProfile && key === localMenu.MY_UPLOADS) {
            queryString = `/media?uploader=${props.user._id}&pageNum=${page}`
        }
        else {
            queryString = `/media?participants=${props.user._id}&pageNum=${page}`
        }

        if(fav) {
            queryString += "&favorites=true"
        }

        if(sortOption.field.length) {
            sortString = "sort=" + sortOption.field + "," + sortOption.type;
        }

        const response = await instance.get(queryString + "&" + sortString, {
            params: {
                fileType: [fileTypes.VIDEO, fileTypes.IMAGE]
            }
        });
        
        setMedia((prev) => { return [...prev, ...response.data.result]});
        setHasMore(response.data.hasNextPage);
        setTotalCount(response.data.totalRecords)
        setLoading(false);
    }

    async function loadMore() {
        setPage(prev => prev+1);
    };

    function setFavourite() {
        setMedia([]);
        setPage(0);
        setFav(!fav);
    };

    function _setSortOption(option:any) {
        setMedia([]);
        setPage(0);
        setSortOption(option)
    }

    return(
        <div className="profile-visuals-container">
            {(userData.subscription && userData.subscription.status !== ESubscriptionStatus.INACTIVE) ?
            <div>
                {props.myProfile ? 
                    <Tabs className="family-tabs" activeKey={key} onSelect={(k) => {setKey((k as localMenu)); setMedia([]); setPage(0); }}>
                        <Tab eventKey={localMenu.ABOUT_ME} title={t("About Me")}>
                            <ProfileVisualImages setSortOption={_setSortOption} totalCount={totalCount} fav={fav} setFav={setFavourite} visuals={media} loadMore={loadMore} hasMore={hasMore} loading={loading}/>
                        </Tab>
                        <Tab eventKey={localMenu.MY_UPLOADS} title={t("My Uploads")}>
                        <ProfileVisualImages setSortOption={_setSortOption} totalCount={totalCount} fav={fav} setFav={setFavourite} visuals={media} loadMore={loadMore} hasMore={hasMore} loading={loading}/>
                        </Tab>
                    </Tabs>
                    :
                    <ProfileVisualImages setSortOption={_setSortOption} totalCount={totalCount} fav={fav} setFav={setFavourite} visuals={media} loadMore={loadMore} hasMore={hasMore} loading={loading}/>
                }
            </div>
            : <Access pageName={"my_profile-visuals"} />}
        </div>
    )
}