import { useCallback, useState, Dispatch, useContext } from "react";
import { INotification, NotificationType } from "../../interfaces/notification";
import Notification, { notificationImages } from "../../components/notifications/notification.component";
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../../store/actionCreators";
import { Form } from 'react-bootstrap';
import { Context } from '../../pages/home.page';
import countries from "countries-list";
import { useHistory } from "react-router"
import instance from "../../api/api";
import config from "../../config/config";

interface IProps {
}

export default function ShareYourTips(props: IProps) {

    const history = useHistory();    
    const dispatch: Dispatch<any> = useDispatch();
    const setPageName = useCallback((currentPage: string) => dispatch(setCurrentPage(currentPage)), [dispatch]);
    setPageName('Inspiration');    

    const loggedInUserData = useContext(Context).user;    

    const [ data, setData ] = useState<string>('');
    const [ name, setName ] = useState<string>('');
    const [ stateCounty, setStateCounty ] = useState<string>('');
    const [ country, setCountry ] = useState<string>('');
    const [ tos, setTos ] = useState<boolean>(false);    

    //notifications
    const [ notification, setNotification ] = useState<INotification>({icon:"", title:"", text:"", success:NotificationType.problem});    

    async function send(){
       
        try {

            await instance.post('/email', {data: data, type: "Shared Tip", param1: country, param2: stateCounty, param3: name});

            setNotification({
                icon:notificationImages.happiness, title:"Success", text:"Tip sent successfully", success:NotificationType.success
            })

            setData("");            
    
            setTimeout(() => {
                resetNotification();
                setTos(false);
            }, config.modalCloseTimeout)

        }
        catch(error) {
            setNotification({
                icon:notificationImages.crying, title:"Failed", text:"Tip not sent", success:NotificationType.problem
            })

            console.log('Failed to send message, Error ' + error)
        }

    }

    function resetNotification() {
        setNotification({icon:"", title:"", text:"", success:NotificationType.problem});
    }    

    return(
        <div className="s-form-container px-3">
            <p className="title">Share Your Tips</p>
            <p className="subtitle">Help others get the most from Simirity</p>
            <div className="inputs">
                <Form.Group className="group charlimit">
                    <textarea placeholder="Tip" rows={3} value={data} onChange={(evt) => evt.target.value.length <= 200 ? setData(evt.target.value) : false} />
                    <label className="top-label">Tip</label>
                    <p className="sub-subtitle right">{data.length} / 200</p>
                </Form.Group>          
                <Form.Group className="group">
                    <input placeholder="Name or nickname" value={name} onChange={(evt) => evt.target.value.length <= 300 ? setName(evt.target.value) : false} type="text"></input>
                    <label className="top-label">Name or nickname</label>
                </Form.Group>
                <Form.Group className="group country">
                    <Form.Label className="top-label">Country</Form.Label>
                    <Form.Select aria-label="Default select example" onChange={(evt: React.FormEvent<HTMLSelectElement>) => setCountry((evt.target as HTMLSelectElement).value)} value={country}>
                        <option disabled value=""></option>
                        {Object.values(countries.countries).sort((a, b) => a.name.localeCompare(b.name)).map(country => {
                            return(
                                <option key={country.name} value={country.name}>{country.name}</option>
                            );
                        })}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="group state">
                    <input type="text" className="" placeholder="State / County" onChange={(evt) => setStateCounty(evt.target.value)} />
                    <Form.Label className="top-label">State / County</Form.Label>
                </Form.Group>
                <Form.Group className="group checkbox">
                    <div onClick={(evt) => setTos(prev => !prev)}>
                        <Form.Check required className="checkbox-input" type="checkbox" label="I allow the above information to be shared publically on www.simirity.com" checked={tos} />
                    </div>
                </Form.Group>                                 
            </div>                  
          
            {notification.title.length ?
                <Notification data={notification} close={() => resetNotification()}/>
            : null}
            <button className="default-button" disabled={((data.length > 0 && tos) ? false : true)} onClick={() => send()}>Send</button>
            <button className="light-button" onClick={() => history.goBack()}>Cancel</button>              
        </div>
        )
}