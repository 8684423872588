import moment from "moment"
import { IStoryDateMember } from "../interfaces/story"
import { dateFormat } from "./enum/enum"


export function getStoryDate(dateData: IStoryDateMember) {
    if(dateData.year && dateData.month && dateData.day) {
        return moment(dateData.date).format(dateFormat.SHORT) 
    }
    else if(dateData.year && dateData.month){
        return moment(dateData.date).format(dateFormat.YEAR_MONTH)   
    } 
    else {
        return moment(dateData.date).format(dateFormat.ONLY_YEAR)
    }
}