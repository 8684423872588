import React, { useContext, useEffect, useState } from 'react';
import { IGroup, IUserDetails } from '../../interfaces/authentication';
import Switch from "react-switch";
import UniqueUserListInput from '../_helper/unique-selects/unique-user-list-input.component';
import { Context } from '../../pages/home.page';
import { IOption, IRelation } from '../../interfaces/family';
import UniqueSimpleSelect from '../_helper/unique-simple-select.component';
import countries from "countries-list";
import TooltipHelp from '../notifications/tooltiphelp.component';
import DatePicker from "react-datepicker";
import instance from '../../api/api';
import UniqueListInput from '../_helper/unique-list-input.component';
import config from '../../config/config';
import Icon from '@mdi/react';
import { IDateInputs } from './story-info.component';
import { mdiMenuDown } from '@mdi/js';
import { useTranslation } from "react-i18next";

interface IProps {
    queryString:string
    filters:IFilters
    setFilters:Function
    setFiltersActive: Function
    setShowFilters: Function
}

export interface IFilters {
    [key: string]: any
    storiesRelatingTo:IUserDetails[]
    featured:IUserDetails[]
    participants:IUserDetails[]
    storyAuthorCoAuthor:IUserDetails[]
    date:IDateInputs
    storyTags:IOption[]
    country:string
    readTime:string
    lifeLessons:IOption[]
    accessGroups:IOption[]

    favorites:boolean
    readLaterStories:boolean
    unreadStories:boolean
}

export interface IRefactoredQueryObject {
    [key: string]: any
    storiesRelatingTo?:string[]    
    featured?:string[]
    participants?:string[]
    storyAuthorCoAuthor?:string[]

    date?:IDateInputs
    storyTags?:IOption[]
    country?:string
    readTime?:string
    lifeLessons?:IOption[][]
    accessGroups?:IOption[]

    favorites?:boolean
    readLaterStories?:boolean
    unreadStories?:boolean
}

export const readTimeOptions = [
    {key:"< 5 mins", value:"< 5 mins", searchValue:"5"}, 
    {key:"< 15 mins", value:"< 15 mins", searchValue:"15"}, 
    {key:"< 30 mins", value:"< 30 mins", searchValue:"30"}, 
    {key:"< 60 mins", value:"< 60 mins", searchValue:"60"}, 
    {key:"> 60 mins", value:"> 60 mins", searchValue:"70"}
]

export default function StoryFilters(props:IProps) {

    //Filters
    const [ userOptions, setUserOptions ] = useState<IUserDetails[]>([]);
    const [ userGroupOptions, setUserGroupOptions ] = useState<IOption[]>([]);

    //Tooltips
    const [ showLifeLessonTooltip, setShowLifeLessonTooltip ] = useState<boolean>(false);
    const [ showAccessGroupTooltip, setShowAccessGroupTooltip ] = useState<boolean>(false);
    const [ showFeaturedTooltip, setShowFeaturedTooltip ] = useState<boolean>(false);
    const [ showStoriesRelatingToTooltip, setShowStoriesRelatingToTooltip ] = useState<boolean>(false);    

    //Count
    const [ storyCount, setStoryCount ] = useState<number>(0);

    const userData = useContext(Context).user;

    const { t } = useTranslation();    

    useEffect(() => {
        getStoryCount();
    }, [props.queryString])

    async function getStoryCount() {
        const newStories = await instance.get(`/publishedstories?${props.queryString}&pageNum=0`);
        setStoryCount(newStories.data.totalRecords);
    }

    //Setup options
    useEffect(() => {
        if(userData._id.length) {
            setUserOptions([...userData.relations.map((relation:IRelation) => relation.userid), userData])
            setUserGroupOptions(userData.groups.map((x:IGroup) => {return {_id:x._id, text:x.name, img:''}}));
        }
    }, [])

    //Checks
    function updateUserFilterField(key:string, value:IUserDetails) {
        const listType = (props.filters[key] as IUserDetails[]).map(x => x._id);
        if(listType.includes(value._id)) {
            props.setFilters({...props.filters, [key]: props.filters[key].filter((x:IUserDetails) => x._id !== value._id)})
        }
        else {
            props.setFilters({...props.filters, [key]:[...props.filters[key], value]})
        }
    }

    function updateIOptionField(key:string, value:IOption) {
        const optionType = (props.filters[key] as IOption[]).map(x => x._id);
        if(optionType.includes(value._id)) {
            props.setFilters({...props.filters, [key]: props.filters[key].filter((x:IUserDetails) => x._id !== value._id)})
        }
        else {
            props.setFilters({...props.filters, [key]:[...props.filters[key], value]})
        }
    }

    function setFilterField(key:string, value:string) {
        props.setFilters({...props.filters, [key]: value})
    }

    function showStories() {
        props.setShowFilters(false);
    }

    function setupCountryOptions() {
        const countryOptions = Object.entries(countries.countries).map(([key, value]) => {
            return(
                {key:key, value:value.name}
            );
        }).sort((a, b) => a.value.localeCompare(b.value))

        return countryOptions
    }

    function handleDateInput(date: Date, dateAccuracy: string) {

        let newDate: Date = new Date()
        switch (dateAccuracy) {
            case "year":
                newDate = new Date(props.filters.date.date.setFullYear(date.getFullYear()));
                props.setFilters({ ...props.filters, date: { date: newDate, year: newDate.getFullYear(), month: null, day: null }})
                break
            case "month":
                // the date is the first day of the selected month
                newDate = new Date(props.filters.date.date.setMonth(date.getMonth()));
                props.setFilters({ ...props.filters, date: {...props.filters.date, date: newDate, month: newDate.getMonth(), day: null }})
                break
            case "day":
                newDate = new Date(props.filters.date.date.setDate(date.getDate()));
                props.setFilters({ ...props.filters, date: {...props.filters.date, date: newDate, day: newDate.getDate() }})
                break
        }
    }



    return(
        <div className='story-filters'>
            <p className='filter-group-title'>{t("People")}</p>
                <div className="people">
                    <div className="with-tooltip">
                        <UniqueUserListInput placeholder={t("Stories Relating To")} data={props.filters.storiesRelatingTo} options={userOptions} handleAction={(value:IUserDetails) => updateUserFilterField("storiesRelatingTo", value)}/>
                        <p onClick={() => setShowStoriesRelatingToTooltip(true)} className="tooltip-text">
                            {t("What's this?")}
                        </p>
                    </div>                    
                    <div className="with-tooltip">
                        <UniqueUserListInput placeholder={t("Featured Profile")} data={props.filters.featured} options={userOptions} handleAction={(value:IUserDetails) => updateUserFilterField("featured", value)}/>
                        <p onClick={() => setShowFeaturedTooltip(true)} className="tooltip-text">
                            {t("What's this?")}
                        </p>
                    </div>
                    <UniqueUserListInput placeholder={t("Story Participants")} data={props.filters.participants} options={userOptions} handleAction={(value:IUserDetails) => updateUserFilterField("participants", value)}/>
                    <UniqueUserListInput placeholder={t("Story Author / Co-Author")} data={props.filters.storyAuthorCoAuthor} options={userOptions} handleAction={(value:IUserDetails) => updateUserFilterField("storyAuthorCoAuthor", value)}/>
                </div>
                <p className='filter-group-title'>{t("Story")}</p>
                <div className="story">
                    <div>
                        <div className="dates-input-container">
                            <div className="date-container">
                                <label className="top-label">{t("Year")}</label>
                                <Icon className="calendar-icon" size={1} path={mdiMenuDown} />
                                <DatePicker 
                                    selected={props.filters.date.year !== null ? props.filters.date.date : null} 
                                    onChange={(date) => (date instanceof Date ? handleDateInput(date, "year") : null)} 
                                    showYearPicker 
                                    dateFormat="yyyy" 
                                />
                            </div>
                            <div className={`date-container ${!(props.filters.date.year !== null) ? "disabled" : ""}`}>
                                <label className="top-label">{t("Mth")}</label>
                                <Icon className="calendar-icon" size={1} path={mdiMenuDown} />
                                <DatePicker
                                    disabled={!(props.filters.date.year !== null)}
                                    selected={props.filters.date.month !== null ? props.filters.date.date : null} 
                                    onChange={(date) => (date instanceof Date ? handleDateInput(date, "month") : null)}
                                    dateFormat="MMM"
                                    showMonthYearPicker
                                />
                            </div>
                            <div className={`date-container ${!(props.filters.date.month !== null) ? "disabled" : ""}`}>
                                <label className="top-label">{t("Day")}</label>
                                <Icon className="calendar-icon" size={1} path={mdiMenuDown} />
                                <DatePicker
                                    disabled={!(props.filters.date.month !== null)}
                                    selected={props.filters.date.day !== null ? props.filters.date.date : null} 
                                    onChange={(date) => (date instanceof Date ? handleDateInput(date, "day") : null)}
                                    dateFormat="dd"
                                    dropdownMode="select"
                                />
                            </div>
                        </div>
                    </div>
                    <UniqueListInput placeholder={t("Story Tags")} icon={true} data={props.filters.storyTags} options={config.storyTags} handleAction={(value:IOption) => updateIOptionField("storyTags", value)}/>
                    <UniqueSimpleSelect placeholder={t("Country")} selected={(countries.countries as any)[props.filters.country] ? (countries.countries as any)[props.filters.country].name : props.filters.country} options={setupCountryOptions()} handleAction={(value:string) => setFilterField("country", value)}/>

                    <UniqueSimpleSelect placeholder={t("Read Time")} selected={props.filters.readTime} options={readTimeOptions} handleAction={(value:string) => setFilterField("readTime", value)}/>
                    {/*<div className="with-tooltip">
                        <UniqueListInput placeholder='Life Lesson' icon={true} data={props.filters.lifeLessons} options={config.lifelessonTags} handleAction={(value:IOption) => updateIOptionField("lifeLessons", value)}/>
                        <p onClick={() => setShowLifeLessonTooltip(true)} className="tooltip-text">
                            What's this?
                        </p>
                    </div>*/}
                    <div className="with-tooltip">
                        <UniqueListInput icon={true} data={props.filters.accessGroups} placeholder={t("Access Group")} options={userGroupOptions} handleAction={(value:IOption) => updateIOptionField("accessGroups", value)}/>
                        <p onClick={() => setShowAccessGroupTooltip(true)} className="tooltip-text">
                            {t("What's this?")}
                        </p>
                    </div>
                </div>
            <p className='filter-group-title'>{t("Toggles")}</p>
            <div className="toggels">
                <div className="switch-container">
                    <span>{t("My Favourites")}</span>
                    <div className="status">
                        <p className="switch-status">{props.filters.favorites ? t("On") : t("Off")}</p>
                        <Switch 
                            className={`${props.filters.favorites ? "active-gold-switch" : ""}`}
                            offColor="#17243d"
                            offHandleColor="#8B919E"
                            onColor="#a38138"
                            onHandleColor="#a38138"
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={14}
                            width={32}
                            checked={props.filters.favorites}
                            onChange={(checked: any) => setFilterField('favorites', checked)} 
                            />
                        </div>
                </div>
                <div className="switch-container">
                    <span>{t("Read Later")}</span>
                    <div className="status">
                        <p className="switch-status">{props.filters.readLaterStories ? t("On") : t("Off")}</p>
                        <Switch 
                            className={`${props.filters.readLaterStories ? "active-gold-switch" : ""}`}
                            offColor="#17243d"
                            offHandleColor="#8B919E"
                            onColor="#a38138"
                            onHandleColor="#a38138"
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={14}
                            width={32}
                            onChange={(checked : any) => setFilterField('readLaterStories', checked)} 
                            checked={props.filters.readLaterStories} />
                    </div>
                </div>
                <div className="switch-container">
                    <span>{t("Unread stories only")}</span>
                    <div className="status">
                        <p className="switch-status">{props.filters.unreadStories ? t("On") : t("Off")}</p>
                        <Switch 
                            className={`${props.filters.unreadStories ? "active-gold-switch" : ""}`} 
                            offColor="#17243d"
                            offHandleColor="#8B919E"
                            onColor="#a38138"
                            onHandleColor="#a38138"
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={14}
                            width={32}
                            onChange={(checked : any) => setFilterField('unreadStories', checked)} 
                            checked={props.filters.unreadStories} />
                    </div>
                </div>
            </div>
            <button className="default-button" onClick={() => showStories()}>{t("Show {{var}} Stories", {"var": storyCount})}</button>
            <TooltipHelp contextKey={'story_filters_life_lesson'} show={showLifeLessonTooltip} clear={() => setShowLifeLessonTooltip(false)} />
            <TooltipHelp contextKey={'story_filters_featured'} show={showFeaturedTooltip} clear={() => setShowFeaturedTooltip(false)} />
            <TooltipHelp contextKey={'story_filters_stories_relating_to'} show={showStoriesRelatingToTooltip} clear={() => setShowStoriesRelatingToTooltip(false)} />            
            <TooltipHelp contextKey={'story_filters_access_group'} show={showAccessGroupTooltip} clear={() => setShowAccessGroupTooltip(false)} />
        </div>
    )
}