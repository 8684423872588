import React, { useState, useEffect, useContext } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import Switch from 'react-switch';
import instance from '../../api/api';
import Icon from '@mdi/react';
import { mdiInformation } from '@mdi/js';
import { IExplanation, IInvitation, IOption } from '../../interfaces/family';
import AdultForm from './new-profile-forms/adult.component';
import ChildForm from './new-profile-forms/child.component';
import DeceasedForm from './new-profile-forms/deceased.component';
import { IControlledUser, IGroup, IUserDetails } from '../../interfaces/authentication';
import { profileTypes } from '../../_helper/enum/enum';
import RelationshipPicker from './relationship-picker.component';
import { INotification, NotificationType } from '../../interfaces/notification';
import Notification, { notificationImages } from '../notifications/notification.component';
import { Context } from '../../pages/home.page';
import config from '../../config/config';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import UniqueListInput from '../_helper/unique-list-input.component';
import TooltipHelp from '../notifications/tooltiphelp.component';

/**
 * Local interface for input properties.
 */
interface IProps {
    handleClose: (addMore: boolean) => void
    handleUpdateConnectionsFromProfile: () => void    
    familymembers: IOption[]
    updateData?: () => void
    email: string
    groups: IGroup[]
    forcedProfileType: profileTypes | null
}

interface ILocation {
    country: string
    state: string
}

interface IFormError {
    relationTo: boolean,
    relationFrom: boolean,
    email: boolean,
    firstName: boolean,
    lastName: boolean,
    diedOn: boolean,
    birthdate: boolean,
    tos: boolean
}

/**
 *
 * @param props :
 * @returns void
 */
function CreateNewProfile(props: IProps) {

    const [profileType, setProfileType] = useState<string>(profileTypes.ADULT);
    const [notification, setNotification] = useState<INotification>({ icon: "", title: "", text: "", success: NotificationType.problem });

    //Adult
    const [email, setEmail] = useState<string>('');
    const [explanation, setExplanation] = useState<IExplanation>({ reasons: [], message: "", examples: [] });

    //Child
    const [firstname, setFirstname] = useState<string>('');
    const [lastname, setLastname] = useState<string>('');
    const [birthdate, setBirthdate] = useState<null | Date>(null);
    const [location, setLocation] = useState<ILocation>({ country: '', state: '' });

    const [administrators, setAdministrators] = useState<IUserDetails[]>([]);
    //const [connectToIndividuals, setConnectToIndividuals] = useState<IOption[]>([]);

    //Groups
    //const [connectToGroups, setConnectToGroups] = useState<IGroup[]>([]);

    //Deceased
    const [diedOn, setDiedOn] = useState<null | Date>(null);

    //Relation information
    const [relationTo, setRelationTo] = useState<string>("-");
    const [relationFrom, setRelationFrom] = useState<string>("-");
    //const [connectToFamily, setConnectToFamily] = useState<boolean>(false);
    //const [showAllFamMember, setShowAllFamMember] = useState(false)
    //const maxLengthOfMembersLess = 180
    const [tos, setTos] = useState<boolean>(false);
    const [formError, setFormError] = useState<IFormError>(
        {
            relationTo: false,
            relationFrom: false,
            email: false,
            firstName: false,
            lastName: false,
            diedOn: false,
            birthdate: false,
            tos: false
        }
    );
    const [updatedFormData, setUpdatedFormData] = useState(false);

    const [clicked, setClicked] = useState<boolean>(false);

    const [ selectedFamily, setSelectedFamily ] = useState<IOption[]>([]);    

    //hanlde Action type
    const [addmore, setAddmore] = useState(false);

    // loader
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [ showTooltip, setShowTooltip ] = useState<boolean>(false);

    const loggedInUserData = useContext(Context).user;
    const updateUserData = useContext(Context).setUserDetails;

    const { t } = useTranslation();

    useEffect(() => {
        props.forcedProfileType !== null ? setProfileType(props.forcedProfileType) : setProfileType(profileTypes.ADULT);
    }, [props.forcedProfileType])    

    useEffect(() => {
        setEmail(props.email);
    }, [props.email])
/*
    useEffect(() => {
        if(props.familymembers.length) {
            setConnectToFamily(true);
        }

    }, [props.familymembers])
*/    
    function resetNotification() {
        setNotification({ icon: "", title: "", text: "", success: NotificationType.problem });
    }

    function switchProfileType(type:string) {
        setProfileType(type);
        setFormError({
                relationTo: false,
                relationFrom: false,
                email: false,
                firstName: false,
                lastName: false,
                diedOn: false,
                birthdate: false,
                tos: false
            });
        setTos(false);
    }

    async function sendInvitation(evt: React.FormEvent) {
        evt.preventDefault();
                
        if (!checkDataAvalability()) {
            setIsLoading(true);
            setClicked(true);            

            if (profileType === profileTypes.ADULT) {

                let invitationData: IInvitation = {
                    inviter: localStorage.userid,
                    email: email,
                    explanation: explanation,
                    relationType: relationTo,
                    suggestions: [],
                }                           

                //Add all family to suggestions
//                if (connectToFamily) {
                    invitationData.suggestions = selectedFamily.map(member => member._id);
//                }
/*
                //Add suggestions by group
                if (connectToGroups.length) {
                    connectToGroups.forEach((group: IGroup) => {
                        invitationData.suggestions = [...invitationData.suggestions, ...group.members.map(x => x._id)]
                    })
                }

                if (connectToIndividuals.length) {
                    invitationData.suggestions = [...invitationData.suggestions, ...connectToIndividuals.map(member => member._id)];
                }
*/
                //Remove duplicates
                const uniques = new Set(invitationData.suggestions)
                invitationData.suggestions = Array.from(uniques.values())

                try {

                    await instance.post('/invitation', invitationData);
                    if (props.updateData) {
                        props.updateData()
                    }
                    setNotification({
                        icon: notificationImages.happiness, title: t("Success"), text: t("Invitation sent"), success: NotificationType.success
                    })

                    // update Context
                    let responseUserUpdated = await instance.get('/user/' + localStorage.userid );
                    updateUserData(responseUserUpdated.data);
                    props.handleUpdateConnectionsFromProfile();

                    setTimeout(() => {
                        //setClicked(false); // todo: low: clearTimeout in useEffect unmount
                        props.handleClose(addmore);
                    }, config.modalCloseTimeout)
                }
                catch (error) {
                    setNotification({
                        icon: notificationImages.crying, title: t("Failed"), text: t("Invitation not sent"), success: NotificationType.problem
                    })

                    console.log('Failed to create new Invitation, Error ' + error)
                }
            }
            else {
                const newUser: IControlledUser = {
                    profileType: profileType,
                    firstname: firstname,
                    lastname: lastname,
                    email: email,
                    country: location.country,
                    state: location.state,
                    birthdate: moment(birthdate?.toLocaleString()).parseZone().toISOString(),
                    diedon: moment(diedOn?.toLocaleString()).parseZone().toISOString(),
                    administrators: [localStorage.userid, ...administrators.map(x => x._id)],
                    featuredBy: [localStorage.userid, ...administrators.map(x => x._id)]
                }

                try {

                    const res = await instance.post('/auth/registration/controlled', { userDetails: newUser, supervisor: { from: localStorage.userid, relationTypeTo: relationTo, relationTypeFrom: relationFrom } });
                    updateUserData({...loggedInUserData, relations:[...loggedInUserData.relations, res.data]});

                    setNotification({
                        icon: notificationImages.happiness, title: t("Success"), text: t("User created"), success: NotificationType.success
                    })

                    setTimeout(() => {
                        //setClicked(false); // todo: low: clearTimeout in useEffect unmount
                        props.handleClose(addmore)
                    }, 2000)
                }
                catch (error) {
                    setNotification({
                        icon: notificationImages.crying, title: t("Failed"), text: t("Failed to create new user"), success: NotificationType.problem
                    });

                    console.log('Failed to create new user, Error ' + error)
                }
            }
            setIsLoading(false)
        } else {
            setTimeout(() => {
                document.getElementsByClassName("input-error")[0].scrollIntoView({behavior: "smooth"});
            }, 150);
        }
    }

    function validateData() {

        // needed by DOM
        setFormError(prevState => {
            let temp: IFormError = prevState;
            if (!tos) {
                temp.tos = true;
            }
            else {
                temp.tos = false;
            }
            if(relationTo === "") {
                temp.relationTo = true;
            }
            else {
                temp.relationTo = false;
            }
            if (profileType === profileTypes.ADULT) {
                if (!email.length) {
                    temp.email = true;
                }
                else {
                    temp.email = false;
                }
            } else if (profileType === profileTypes.CHILD) {
                (!firstname.length) ? temp.firstName = true : temp.firstName = false;
                (!lastname.length) ? temp.lastName = true : temp.lastName = false;
                (!email.length) ? temp.email = true : temp.email = false;
                (!birthdate) ? temp.birthdate = true : temp.birthdate = false;
                (!relationFrom) ? temp.relationFrom = true : temp.relationFrom = false;
            } else {
                (!firstname.length) ? temp.firstName = true : temp.firstName = false;
                (!lastname.length) ? temp.lastName = true : temp.lastName = false;
                (!diedOn) ? temp.diedOn = true : temp.diedOn = false;
                (!birthdate) ? temp.birthdate = true : temp.birthdate = false;
                (!relationFrom) ? temp.relationFrom = true : temp.relationFrom = false;
            }
            return temp;
        })
 
        // needed by DOM        
        setUpdatedFormData(!updatedFormData);
    }

    function checkDataAvalability() {
        validateData();      
        if(!tos) {
            return true
        }
        if (relationTo === "") {
            return true
        }
        if (profileType === profileTypes.ADULT) {
            if (!email.length) {
                return true
            }
        }
        else if (profileType === profileTypes.CHILD) {           
            if (!firstname.length || !lastname.length || !birthdate || !email.length || relationFrom === "") {                    
                return true
            }
        }
        else {
            if (!firstname.length || !lastname.length || !birthdate || !diedOn || relationFrom === "") {
                return true
            }
        }
        return false;
    }
/*
    function updateIndividuals(selectedData: IOption) {
        if (connectToIndividuals.filter((data: IOption) => data._id === selectedData._id).length) {
            const temp = connectToIndividuals.filter((data: IOption) => data._id !== selectedData._id);
            setConnectToIndividuals(temp);
        }
        else {
            setConnectToIndividuals((prev: IOption[]) => { return [...prev, selectedData] })
        }
    }
*/
    function displayProfileType(type: string) {
        if (type === profileTypes.ADULT) {
            return t("Living adult (18+ years old)")
        } else if (type === profileTypes.CHILD) {
            return t("Child (<18 years old)")
        } else {
            return t(type)
        }
    }
/*
    function groupChange(id: string) {
        const selectedGroup = props.groups.find(x => x._id === id);
        if (selectedGroup) {
            setConnectToGroups((prev: IGroup[]) => [...prev, selectedGroup]);
        }
    }

    function removeGroup(id: string) {
        let temp = connectToGroups.filter(x => x._id !== id);
        setConnectToGroups(temp);
    }
*/
    function pickRelation(relation:string) {
        //relation === "friend" ? setConnectToFamily(false) : setConnectToFamily(true);
        setRelationTo(relation);
    }

    function updateSelectedFamily(selectedData:IOption) {
        if(selectedFamily.filter((data:IOption) => data._id === selectedData._id).length) {
            const temp = selectedFamily.filter((data:IOption) => data._id !== selectedData._id);
            setSelectedFamily(temp);
        }
        else {
            setSelectedFamily((prev:IOption[]) => { return [...prev, selectedData]})
        }
    }

    return (
        <Form onSubmit={sendInvitation} className="create-new-profile">
            <Form.Group className="group">
                <Form.Label className="subtitle">{t("Profile type")}</Form.Label>
                <Form.Select aria-label="" disabled={props.forcedProfileType!==null ? true : false} onChange={(evt) => switchProfileType((evt.target as HTMLInputElement).value)} value={profileType}>
                    {Object.values(profileTypes).map(type => {
                        return (
                            type !== profileTypes.INFO &&
                                <option key={type} value={type} hidden={props.forcedProfileType === null && type === profileTypes.DECEASED ? true : false}>{displayProfileType(type)}</option>
                        );
                    })}
                </Form.Select>
            </Form.Group>
            {profileType === profileTypes.ADULT ? <p className="title">{t("Invite New User")}</p> : profileType === profileTypes.DECEASED ? <p className="title">{t("Create Deceased Profile")}</p> : <p className="title">{t("Create Child Profile")}</p>}
            {profileType === profileTypes.ADULT ? <AdultForm email={email} formError={formError} setEmail={setEmail} explanation={explanation} setExplanation={setExplanation} setFormError={setFormError} /> : null}
            {profileType === profileTypes.CHILD ?
                <ChildForm
                    firstname={firstname}
                    setFirstname={setFirstname}
                    lastname={lastname}
                    setLastname={setLastname}
                    email={email}
                    setEmail={setEmail}
                    birthdate={birthdate}
                    setBirthdate={setBirthdate}
                    location={location}
                    setLocation={setLocation}
                    administrators={administrators}
                    setAdministrators={setAdministrators}
                    formError={formError}
                    setFormError={setFormError}
                /> : null}
            {profileType === profileTypes.DECEASED ?
                <DeceasedForm
                    firstname={firstname}
                    setFirstname={setFirstname}
                    lastname={lastname}
                    setLastname={setLastname}
                    birthdate={birthdate}
                    setBirthdate={setBirthdate}
                    diedOn={diedOn}
                    setDiedOn={setDiedOn}
                    location={location}
                    setLocation={setLocation}
                    administrators={administrators}
                    setAdministrators={setAdministrators}
                    formError={formError}
                    setFormError={setFormError}
                /> : null}
            <div className="add-to-family">
            {(profileType === "NON-EXISTENT PROFILE TYPE") ?
                    <div>
                        <p className="title">{t("Add to your family")}</p>
                        <p className="subtitle">{props.familymembers.length ? "1. " : " "}{t("Relationship")}</p>
                        <p className="top-text">{t("They are your...")}</p>
                        <RelationshipPicker relationError={formError.relationTo} handleAction={pickRelation} relation={relationTo} />
                        <p className="top-text">{t("You are their...")}</p>
                        <RelationshipPicker relationError={formError.relationFrom} handleAction={setRelationFrom} relation={relationFrom} />
                    </div> : null}
                {props.familymembers.length ?
                    <div>
                        <p className="subtitle">{t("connect to family")}</p>
                        <p className="top-text">{t("Who do you think they'd like to connect with?")}</p>
                        {/*<div className={`weekly-update switch-container ${connectToFamily ? "active-gold-switch" : ""}`}>
                        <span>{t("Connect to all my family")}</span>
                        <Switch className="switch"
                            offColor="#17243d"
                            offHandleColor="#8B919E"
                            onColor="#006238"
                            onHandleColor="#006238"
                            disabled={!props.familymembers.length}
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={14}
                            width={32}
                            onChange={(checked: boolean) => setConnectToFamily(checked)}
                            checked={connectToFamily} />
                        <p className="switch-status">{connectToFamily ? "On" : "Off"}</p>
                    </div>*/}                
                </div>                
                : null}
                {//connectToFamily && 
                props.familymembers.length ?
                <div className="family-member-list-to-connect">
                    <div className="group">                    
                        <UniqueListInput iconRef={''} icon={false} placeholder={t("Suggest people")} handleAction={updateSelectedFamily} data={selectedFamily} options={props.familymembers}/>
                    </div>
                    <p className="tooltip-text" onClick={() => setShowTooltip(true) }>{t("What's this?")}</p>
                    <TooltipHelp contextKey={'profile_family_suggest_family'} show={showTooltip} clear={() => setShowTooltip(false)} />
                    {/*<p className="connect-info">{t("Users will be notified about their new family member")}</p>*/}
                </div>
                : <></> }
            </div>
            <Form.Group className="checkbox information">
                {(profileType !== profileTypes.ADULT && profileType !== profileTypes.DECEASED) ? <div className="create-information">
                    <div className="icon">
                        <Icon size={1} path={mdiInformation} />
                    </div>
                    <p><span>{t("Login information")}</span> {t("will only be sent to your email address")}</p>
                </div> : null}
                <div className="checkbox-input" onClick={() => setTos(prev => !prev)} >
                    <Form.Check className={"checkbox-input" + (formError.tos ? " input-error" : "")} type="checkbox" checked={tos} />
                </div>
                {profileType === profileTypes.ADULT ? 
                    <Form.Label>
                        {t("I agree to")} <Link className="underlined-link-button" to="/dashboard/terms">{t("Terms of Service")}</Link> {t("relating to invitations and the use of third party emails")}
                    </Form.Label> : profileType === profileTypes.CHILD ? 
                    <Form.Label>
                        {t("I agree to")} <Link className="underlined-link-button" to="/dashboard/terms">{t("Terms of Service")}</Link> {t("relating to the protection of minors, I accept my role as their parent/guardian and I understand that by providing the login details to a minor I am giving my consent to use the Service. I understand that children under the age of 13 are legally not permitted to use the Service.")}
                    </Form.Label> :
                    <Form.Label>
                        {t("I agree to")} <Link className="underlined-link-button" to="/dashboard/terms">{t("Terms of Service")}</Link> {t("relating to the creation of a third party profile")}
                    </Form.Label>}
            </Form.Group>
            {notification.title.length ?
                <Notification data={notification} close={() => resetNotification()} />
                : null}
            <div className="actions">
                <button type='submit' className="default-button finish" disabled={clicked} onClick={(evt) => setAddmore(false)}>
                    {isLoading ? <Spinner animation="border" variant="#fff" /> : t("Finish")}
                </button>
                <button type='submit' className="link-button add" disabled={clicked} onClick={(evt) => setAddmore(true)}>{t("Finish & Add more")}</button>
            </div>
        </Form>
    );
};

export default CreateNewProfile;
