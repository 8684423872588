import { IOption } from "../interfaces/family"
import { blockTypes } from "../interfaces/story";

interface IObjectKeys {
    [key: string]: string[];
}
  
interface IRelationTypes extends IObjectKeys {
    "most popular": string[];
    "elders": string[];
    "in-laws": string[];
    "step family": string[];
}

interface IMediaQualiltyTypes {
    image: IOption[]
    video: IOption[]
    audio: IOption[]        
}

interface ILimits {
    [key: string]: any;
}

interface IConfig {
    defaults:any
    relations:IRelationTypes
    storyTags:IOption[]
    lifelessonTags:IOption[]
    mediaQuality:IMediaQualiltyTypes
    languages:IOption[]
    linkAlbumProviders:any
    embedProviders:any
    contentServerURL:string
    modalCloseTimeout:number
    freeUserLimits:ILimits
    translateableNotifications:any
}

/**
 * Default config file, this should not contain sensitive information.
 */
const config:IConfig = {
    defaults: {
        namespace: "Application", //TODO generic, have to be removed later.
    },
    relations: {
        "most popular": [ "husband", "wife", "partner", "sister", "brother", "mother", "father", "aunt", "uncle", "cousin", "niece", "nephew", "daughter", "son", "grandchild", "great grandchild", "second cousin", "family relative", "friend", "-"],
        "elders": ["grandmother", "grandfather", "great grandmother", "great grandfather", "great great grandmother", "great great grandfather", "ancestor"],
        "in-laws": ["sister in law", "brother in law", "mother in law", "father in law", "daughter in law", "son in law"],
        "step family": ["step mother", "step father", "step brother", "step sister", "step daughter", "step son"],
    },
    /* add story tag here when adding new inspiration category */
    storyTags: [
        { _id: "AUTHENTIC_EVERYDAY", text: `#Authentic-Everyday`, img: "" },
        { _id: "BUCKET_LIST", text: `#Bucket-List`, img: "" },
        { _id: "EDUCATION_AND_WORK", text: `#Education-&-Work`, img: "" },
        { _id: "ENTERTAINMENT_AND_ARTS", text: `#Entertainment-&-Arts`, img: "" },
        { _id: "FAMILY_CULTURE", text: `#Family-Culture`, img: "" },
        { _id: "FAMILY_FOOD", text: `#Family-Food`, img: "" },
        { _id: "HEALTH_AND_GENES", text: `#Health-&-Genes`, img: "" },
        { _id: "INTERESTS_AND_SOCIAL_LIFE", text: `#Interests-&-Social-Life`, img: "" },
        { _id: "LIFE_HACKS", text: `#Life-Hacks`, img: "" },
        { _id: "LIFE_INFLUENCERS", text: `#Life-Influencers`, img: "" },
        { _id: "PLACES_AND_JOURNEYS", text: `#Places-&-Journeys`, img: "" },
        { _id: "PRACTICAL_TIPS", text: `#Practical-Tips`, img: "" },
        { _id: "SIGNIFICANT_TIMES", text: `#Significant-Times`, img: "" },
        { _id: "THOUGHTS_ON_LIFE", text: `#Thoughts-on-Life`, img: "" },
        { _id: "TREASURED_THINGS", text: `#Treasured-Things`, img: "" },
        { _id: "USEFUL_INFO", text: `#Useful-Info`, img: "" },
        { _id: "WORLD_NEWS", text: `#World-News`, img: "" },
    ],
    lifelessonTags: [
        { _id: "ACHIEVING_OUR_POTENTIAL", text: `Achieving our Potential`, img: "" },
        { _id: "ENJOYING_LIFE", text: `Enjoying life`, img: "" },
        { _id: "GROWING_OLDER", text: `Growing Older`, img: "" },
        { _id: "HABITS_AND_ROUTINES", text: `Habits & Routines`, img: "" },
        { _id: "HARD_TIMES_AND_DISAPPOINTMENT", text: `Hard Times & Disappointment`, img: "" },
        { _id: "HEALTH_AND_FITNESS", text: `Health & Fitness`, img: "" },
        { _id: "MENTAL_WELLBEING", text: `Mental Wellbeing`, img: "" },        
        { _id: "PARENTING", text: `Parenting`, img: "" },
        { _id: "PRINCIPLES_AND_VALUES", text: `Principles & Values`, img: "" },
        { _id: "RELATIONSHIPS", text: `Relationships`, img: "" },     
    ],
    mediaQuality: {
        image: [
            { _id: "HD", text: `10_HD (good quality)`, img: "" },            
            { _id: "FULLHD", text: `20_Full HD (high quality)`, img: "" },
            { _id: "4K", text: `40_4K (best quality)`, img: "" },
            //{ _id: "ORIGINAL", text: `99_Original`, img: "" },
        ],
        video: [
            { _id: "HD", text: `10_HD (high quality)`, img: "" },
            { _id: "FULLHD", text: `20_Full HD (best quality)`, img: "" },
            //{ _id: "ORIGINAL", text: `99_Original`, img: "" },
        ],
        audio: [
            { _id: "96", text: `10_96 kbps (high quality)`, img: "" },
            { _id: "160", text: `40_160 kbps (best quality)`, img: "" },
            //{ _id: "ORIGINAL", text: `99_Original`, img: "" },
        ]
    },
    languages: [
        { _id: "en", text: `English`, img: "" },
        { _id: "hu", text: `Magyar`, img: "" },
    ],
    linkAlbumProviders: [
        { _id: "GOOGLE_PHOTOS", text: `Google Photos`, regExp: "https://photos.app.goo.gl/[a-zA-Z0-9]+" },
        { _id: "FLICKR", text: `Flickr`, regExp: "https://www.flickr.com/.*" }, 
    ],
    embedProviders: [
        { _id: "YOUTUBE_VIDEO", text: `Youtube`, regExp: '<iframe width.*src="https:\/\/www\.youtube\.com\/embed\/.*?".*<\/iframe>', regExpURL: null, regExpURL2: null },
        { _id: "YOUTUBE_VIDEO_NOCOOKIE", text: `Youtube (cookiless unless played)`, regExp: '<iframe width.*src="https:\/\/www\.youtube-nocookie\.com\/embed\/.*?".*<\/iframe>', regExpURL: 'https:\/\/youtu\.be\/.*', regExpURL2: 'https:\/\/youtube\.com\/live\/.*'},
        { _id: "SPOTIFY", text: `Spotify`, regExp: '<iframe(.*)src="https:\/\/open\.spotify\.com\/embed\/.*?".*<\/iframe>', regExpURL: 'https:\/\/open\.spotify\.com\/.*', regExpURL2: null },
        { _id: "VIMEO", text: `Vimeo`, regExp: '<iframe.src="https:\/\/player\.vimeo\.com\/video\/.*?".*<\/iframe>', regExpURL: null, regExpURL2: null },
        { _id: "FACEBOOK_VIDEO", text: `Facebook`, regExp: '<iframe.src="https:\/\/www\.facebook\.com\/plugins\/.*?".*<\/iframe>', regExpURL: null, regExpURL2: null }
    ],
    contentServerURL: "https://content.simirity.com",
    modalCloseTimeout: 3000,
    
    //TODO: move to strapi or own admin panel later and just call API
    freeUserLimits: {
        [blockTypes.IMAGE]:10,
        [blockTypes.VIDEO]:0,
        [blockTypes.AUDIO]:5,
        [blockTypes.ATTACHMENT]:0,
    }, 
    translateableNotifications: [
        // always create unique regExp
        // {  regExp: "", toBeTranslated: ""},
        // notificationTypes.PENDING_RELATION_NEW_ADMIN, title
        {  regExp: "Sent New Contact Request to (.*)", toBeTranslated: "Sent New Contact Request to {{p1}}"},
        // notificationTypes.PENDING_RELATION_DECLINED, cta
        {  regExp: "(.*) has declined your contact request at the current time. If you believe this to be in error place an additional request.", toBeTranslated: "{{p1}} has declined your contact request at the current time. If you believe this to be in error place an additional request."},
        //notificationTypes.GROUP_DELETED, cta
        {  regExp: "The group (.*) has been deleted by (.*).", toBeTranslated: "The group {{p1}} has been deleted by {{p2}}."},
        //notificationTypes.GROUP_REMOVED_USERS, cta
        {  regExp: "You are no longer in the group (.*). If you believe this to be in error, please contact (.*).", toBeTranslated: "You are no longer in the group {{p1}}. If you believe this to be in error, please contact {{p2}}."},
        // notificationTypes.RELATION_DELETED, cta
        {  regExp: "(.*) has removed the connection to your profile. You cannot access their profile or stories. If this user was your Admin or Successor please update your selection.", toBeTranslated: "{{p1}} has removed the connection to your profile. You cannot access their profile or stories. If this user was your Admin or Successor please update your selection."},
        // notificationTypes.REQUEST_ACCEPTED, cta
        {  regExp: "(.*) has accepted the request to create: (.*). You will receive a notification when it is published.", toBeTranslated: "{{p1}} has accepted the request to create: {{p2}}. You will receive a notification when it is published."},
        // notificationTypes.REQUEST_DECLINED, cta
        {  regExp: "(.*) has declined the request to create: (.*).", toBeTranslated: "{{p1}} has declined the request to create: {{p2}}."},
        // notificationTypes.STORY_NEW_RELATED, cta
        {  regExp: "(.*) has added a Related Story link between:<br>(.*)<br>and<br>(.*) (published by you).", toBeTranslated: "{{p1}} has added a Related Story link between:<br>{{p2}}<br>and<br>{{p3}} (published by you)."},
        // notificationTypes.SUBSCRIPTION_UPGRADE, title
        {  regExp: "Added you to their (.*) Subscription", toBeTranslated: "Added you to their {{p1}} Subscription"},
        // notificationTypes.SUBSCRIPTION_CANCEL, description
        {  regExp: "(.*) Subscription, ending (.*)", toBeTranslated: "{{p1}} Subscription, ending {{p2}}"},
        // notificationTypes.SUBSCRIPTION_CANCEL, cta
        {  regExp: "(.*) has cancelled their Subscription. You will continue to have access until the current Subscription Period ends on the (.*). This cancellation can be reversed at any time by (.*) prior to the end date when all users of the Subscription will be downgraded to Free Accounts.", toBeTranslated: "{{p1}} has cancelled their Subscription. You will continue to have access until the current Subscription Period ends on the {{p2}}. This cancellation can be reversed at any time by {{p3}}} prior to the end date when all users of the Subscription will be downgraded to Free Accounts."},
        // notificationTypes.SUBSCRIPTION_RESTART, title
        {  regExp: "Will not Downgrade Subscription (.*)", toBeTranslated: "Will not Downgrade Subscription {{p1}}"},        
        // notificationTypes.SUBSCRIPTION_RESTART, description
        {  regExp: "(.*) restarted their Subscription so you will not be downgraded when this Subscription period ends. Enjoy uninterrupted access to premium features.", toBeTranslated: "{{p1}} restarted their Subscription so you will not be downgraded when this Subscription period ends. Enjoy uninterrupted access to premium features."},
        // notificationTypes.SUBSCRIPTION_SHARE, title
        {  regExp: "Add (.*) (.*) to your Subscription?", toBeTranslated: "Add {{p1}} {{p2}} to your Subscription?"},
        // notificationTypes.USER_NEW_ADMIN, cta
        {  regExp: "As the Administrator for (.*), you can update their profile and manage their contact requests.", toBeTranslated: "As the Administrator for {{p1}}, you can update their profile and manage their contact requests."},
        // notificationTypes.USER_REMOVED_ADMIN, cta
        {  regExp: "You are no longer the Administrator of (.*).", toBeTranslated: "You are no longer the Administrator of {{p1}}."},
        // notificationTypes.USER_NEW_SUCCESSOR, cta
        {  regExp: "As the Successor to (.*), you will gain Administration rights upon their passing.", toBeTranslated: "As the Successor to {{p1}}, you will gain Administration rights upon their passing."},
        // notificationTypes.USER_REMOVED_SUCCESSOR, cta
        {  regExp: "You are no longer the Successor to (.*)", toBeTranslated: "You are no longer the Successor to {{p1}}"},
        // notificationTypes.USER_NEW_FEATUREDBY, cta
        {  regExp: "When creating stories, you can change the Featured Profile to (.*) so your story is connected to their profile.", toBeTranslated: "When creating stories, you can change the Featured Profile to {{p1}} so your story is connected to their profile."},
        // notificationTypes.USER_REMOVED_FEATUREDBY, cta
        {  regExp: "You can not assign stories you write to the profile of (.*).", toBeTranslated: "You can not assign stories you write to the profile of {{p1}}."},
        // notificationTypes.USER_PASSED_AWAY, cta
        {  regExp: "(.*) writes:<br><br> (.*)", toBeTranslated: "{{p1}} writes:<br><br> {{p2}}"},
        // notificationTypes.GROUP_NEW_MANAGER, cta
        {  regExp: "(.*) has made you the manager of group (.*).<br><br>", toBeTranslated: "{{p1}} has made you the manager of group {{p2}}.<br><br>"},
        // notificationTypes.GROUP_MANAGEMENT_ENDS, cta
        {  regExp: "Group (.*) cannot be edited as it has no manager. Any stories assigned only to this group will not be visible to future generations. This decision was taken by (.*).<br><br>(.*)", toBeTranslated: "Group {{p1}} cannot be edited as it has no manager. Any stories assigned only to this group will not be visible to future generations. This decision was taken by {{p2}}.<br><br>{{p3}}"},
    ]
}

export default config
