import React, { useEffect, useState } from 'react';
import IPage from '../interfaces/page';
import { useHistory } from "react-router-dom";
import { Container } from 'react-bootstrap';
import axios from 'axios';
import config from '../config/config';
import simirity_logo from "../assets/images/logo2_with_tagline_vertical.svg";
import ExampleStory from '../components/story/example-story.component';
import i18n from "../i18n";
import { useTranslation } from 'react-i18next';
import why_stories_matter from "../assets/images/WELCOME-why-stories-matter.jpg";
import { Modal } from "react-bootstrap";

interface IProps {
    sharer: string;
    review: string;
    avatar: any;
}

const Welcome: React.FunctionComponent<IPage> = (props) => {

    const history = useHistory();
    const { t } = useTranslation();

    const [ data, setData ] = useState<IProps[]>([]);

    const [showVideoModal, setShowVideoModal] = useState<boolean>(false);

    useEffect(() => {
        if(props.match.params.lang) {
            i18n.changeLanguage(props.match.params.lang);
        }
        fetchwelcomeData();
    }, [])

    async function fetchwelcomeData() {        
        axios.get(`${config.contentServerURL}/api/user-reviews?sort[0]=sortOrder:asc`).then(resp => { // Strapi v4 query format
            setData(resp.data.data);
        }, error => {
            console.log("Failed to get user reviews", error)
        });
    }    

    return(
        <div className='main-wrapper'>
            <div className='content-wrapper'>
                <div className='header row'>
                    <div className='page-title'>
                        <p>{t("Welcome")}</p>
                    </div>
                </div>             
            <Container className="welcome-container">
                <img src={simirity_logo} alt="Logo" className="logo" />
                <p className='lead'>{t("Private social media app that unites your family")}</p>            
                <p className='motto'>{t("Share life stories today, while storing")}<br />{t("cherished memories for tomorrow.")}</p>
                <div className='open-by-link'>
                    {/*<ExampleStory openByLink={true} />*/}                    
                    <a className="link-button" target="_blank" href="https://26198383.fs1.hubspotusercontent-eu1.net/hubfs/26198383/Simirity-Welcome-Guide.pdf" rel="noopener noreferrer">{t("View Welcome Guide")}</a>
                </div>                
                <div className="usertips-container">
                    <div className="usertips-content">
                        <div className="modal-iframe-custom">
                            <iframe src={`https://player.vimeo.com/video/849109482`} width="460" height="238" allow="autoplay; fullscreen; picture-in-picture"></iframe>
                        </div>
                    </div>
                </div>
                <p className='join'>{t("Join your family today")}</p>
                <button className="default-button" onClick={() => history.push(`/register${props.match.params.lang ? "/"+props.match.params.lang : ''}`, { id:props.match.params.id ? props.match.params.id : ''})}>{t("Join For Free")}</button>
            </Container>
            </div>

            {showVideoModal ?
                <Modal className="video-welcome-modal" show={showVideoModal} onHide={() => setShowVideoModal(false)}>
                    <Modal.Header>
                        <Modal.Title>{t("Why Stories Matter")}</Modal.Title>
                        <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowVideoModal(false)}></button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-iframe-custom">
                            <iframe width="100%" src="https://www.youtube.com/embed/URvHVnDTZ50" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                        </div>
                    </Modal.Body>
                </Modal> : null}
        </div>
    )
};

export default Welcome;