import moment from "moment"
import { IUserDetails } from "../../interfaces/authentication"

export function IdentifyUser(userId: string, userDetails: IUserDetails) {
    const userData = {
        // ACCOUNT RELATED
        // Account creation date
        ...(userDetails.createdAt && { ACC_CREATION: userDetails.createdAt }),
        // Days from creation date
        ...(userDetails.createdAt && { DAYS_FROM_REGISTRATION: moment().diff(userDetails.createdAt, "days")  }),
        // Profile photo added
        ACC_PROFILE: userDetails.avatar ? true : false,
        // Family connections approved
        ACC_CONNECTIONS: userDetails.relations.length,
        // Successor or Admin added yes/no
        ACC_ADMIN: userDetails.administrators.length > 0 || userDetails.successors.length > 0,

        // // SUBSCRIPTION RELATED
        // Subscription type
        SUB_TYPE: userDetails.subscription?.subscription_type.name || "",
        // Trial flag
        ...(userDetails.subscription && { SUB_TRIAL: userDetails.subscription.trial }),
        // Subscription owner or user, Free/Owner/User
        SUB_USERTYPE: userDetails.subscription ? (userDetails.subscription.owner._id === userDetails._id ? "Owner" : "User") : "Free",
        // Subscription status, Active / Cancelled
        ...(userDetails.subscription && userDetails.subscription.status !== undefined && { SUB_STATUS: userDetails.subscription.status }),
        // Subscription renewal date
        ...(userDetails.subscription && { SUB_RENEWAL: userDetails.subscription.expiration }),
        // Remained days before the renewal date
        ...(userDetails.subscription && { REMAINED_DAYS_SUB_RENEWAL: moment(userDetails.subscription.expiration).diff(moment(), "days") }),

        // CONTENT RELATED
        // Stories published
        STORIES: userDetails.stats?.publishedStoryCounter || 0,
        // Requests created
        REQUESTS: userDetails.stats?.requestsCounter || 0,
        // Voice recordings number
        AUDIO_REC: userDetails.stats?.voiceCounter || 0,
        // Narrations uploaded
        NARRATIONS: userDetails.stats?.narrationCounter || 0,
        // Photos uploaded
        PHOTOS: userDetails.stats?.photoCounter || 0,
        // Videos uploaded
        VIDEOS: userDetails.stats?.videoCounter || 0,
        // Life lessons published
        LIFE_LESSONS: userDetails.stats?.lifeLessonCounter || 0,
        USER_NUMBER: userId || 0,
    }
    if (window.userGuiding) {
        window.userGuiding.identify(userId, userData)
    }
}
