import React, { useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import UniqueListInput from "../_helper/unique-list-input.component"
import Switch from "react-switch"
import Icon from "@mdi/react"
import { mdiCloseCircle, mdiMenuDown } from "@mdi/js"
import TooltipHelp from "../notifications/tooltiphelp.component"
import { IOption } from "../../interfaces/family"
import { IUserDetails } from "../../interfaces/authentication"
import instance from "../../api/api"
import { storyFields } from "../../_helper/enum/enum"
import { IRelatedStory, IStory } from "../../interfaces/story"
import UniqueSingleSelect from "../_helper/unique-single-select.component"
import UniqueUserListInput from "../_helper/unique-selects/unique-user-list-input.component"
import config from "../../config/config"
import moment from "moment"
import GoogleMap, { ILocation } from "../_helper/google-map.component"
import UniqueListFreeInput from "../_helper/unique-selects/unique-related-story-list-free-input.component"
import { useTranslation } from "react-i18next";

export enum DateTypes {
    FIXED = "Fixed",
    PERIOD = "Period",
}

interface IProps {
    story: IStory
    userData: IUserDetails
    updateStory: Function
    validatePeriod: Function
    removedParticipants: string[]
    setRemovedParticipants : Function
    isAuthor:boolean
}

export interface IDateInputs {
    date: Date
    year: number | null
    month: number | null
    day: number | null
}

type TDateAccuracy = "year" | "month" | "day"
type TDatePeriodType = "start" | "end"

const dateStartInputInit: IDateInputs = {date: new Date(), year: new Date().getFullYear(), month: new Date().getMonth(), day: new Date().getDate() + 1 }
const dateEndInputInit: IDateInputs = { date:new Date(),  year: null, month: null, day: null }

//console.log(dateStartInputInit.month);

function StoryInfo(props: IProps) {

    // state to store the adminsitrated profiles of the user
    const [administratedUsers, setAdministratedUsers] = useState<IOption[]>([]);

    // date input (fixed or period)
    const [dateType, setDateType] = useState<string>(DateTypes.FIXED)
    const [addStoryLocation, setAddStoryLocation] = useState<boolean>(false);
    const [dateStartInput, setdateStartInput] = useState<IDateInputs>(dateStartInputInit)
    const [dateEndInput, setdateEndInput] = useState<IDateInputs>(dateEndInputInit);

    // Tooltips
    const [featuredProfileTTShow, setFeaturedProfileTTShow] = useState(false);
    const [storyParticipantsTTShow, setStoryParticipantsTTShow] = useState(false);
    const [storyTagsTTShow, setStoryTagsTTShow] = useState(false);
    const [relatedStoriesTTShow, setRelatedStoriesTTShow] = useState(false);
    const [coAuthorsTTShow, setCoAuthorsTTShow] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        if(props.story) {
            const start: IDateInputs = {
                date: new Date(props.story.date.startDate.date),
                year: props.story.date.startDate.year,
                month: props.story.date.startDate.month ? props.story.date.startDate.month  : null,
                day: props.story.date.startDate.day ? props.story.date.startDate.day : null
            }

            setdateStartInput(start);

            if(props.story.date.endDate) {
                const end: IDateInputs = {
                    date: new Date(props.story.date.endDate.date),
                    year: props.story.date.endDate.year,
                    month: props.story.date.endDate.month ? props.story.date.endDate.month : null,
                    day: props.story.date.endDate.day ? props.story.date.endDate.day : null
                }
                setDateType(DateTypes.PERIOD)
                setdateEndInput(end);
            }

            if(props.story.sLocation.lat !== 0 && props.story.sLocation.lng !== 0 ) {
                setAddStoryLocation(true);
            }
        }
    }, [props.story.date, props.story.sLocation])

    useEffect(() => {
        props.validatePeriod(dateStartInput, dateEndInput, dateType)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateStartInput, dateEndInput, dateType])

    useEffect(() => {
        const self: IOption = { _id: props.userData._id, text: `${props.userData.firstname} ${props.userData.lastname}`, img: props.userData.avatar ? props.userData.avatar : "" }

        if (props.userData._id.length) {
            getAdministratedUsers(self)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.userData])

    /**
     * Function to fetch adminisrated users
     * @param self
     */
    async function getAdministratedUsers(self: IOption) {

        try {
            const response = await instance.get(`/user/${props.userData._id}/featuredby`);

            const resOption: IOption[] = response.data.map((x: IUserDetails) => {
                return { _id: x._id, text: `${x.firstname} ${x.lastname}`, img: x.avatar ? x.avatar : "" }
            });


            if (resOption.length) {
                setAdministratedUsers([...resOption, self])
            }

        } catch (error) {
            console.log("Error while getting Administrater users: ", error)
        }
    }

    /**
     * function to form the featured id to IOption
     * @returns
     */
    function getFeaturedProfiles() {

        if ((props.story.featured as IUserDetails)._id === props.userData._id) {
            return { _id: props.userData._id, text: `${props.userData.firstname} ${props.userData.lastname}`, img: "" }
        }

        const selectedFromAdmininistrated = administratedUsers.find((administrated) => {
            return administrated._id === (props.story.featured as IUserDetails)._id
        })

        if (selectedFromAdmininistrated !== undefined) {
            return selectedFromAdmininistrated
        } else {
            return { _id: "", text: "", img: "" }
        }
    }

    /**
     * list the selected storyTags as IOption[]
     * @returns
     */
    function getStoryTags() {
        return (props.story.storyTags as string[]).map(tag => { return { _id: tag, text: t((config.storyTags.find(data => data._id === tag) as IOption).text), img: "" } })
    }

    /**
     * update participants
     * @param selectedData
     */
    function updateParticipants(selectedData: IUserDetails) {
        if (props.story.participants.map(x => (x as IUserDetails)._id).includes(selectedData._id)) {
            const temp = (props.story.participants as IUserDetails[]).filter((data: IUserDetails) => data._id !== selectedData._id)
            //Have to blaclist this user from tagging;
            props.setRemovedParticipants([...props.removedParticipants, selectedData._id]);

            props.updateStory(storyFields.PARTICIPANTS, temp)
        } else {
            props.updateStory(storyFields.PARTICIPANTS, [...props.story.participants, selectedData])
        }
    }

    /**
     * update coAuthors
     * @param selectedData
     */
    function updateCoAuthors(selectedData: IUserDetails) {
        if (props.story.coAuthors.map(x => (x as IUserDetails)._id).includes(selectedData._id)) {
            const temp = (props.story.coAuthors as IUserDetails[]).filter((data: IUserDetails) => data._id !== selectedData._id)
            props.updateStory(storyFields.COAUTHORS, temp)
        } else {
            props.updateStory(storyFields.COAUTHORS, [...props.story.coAuthors, selectedData])
        }
    }

    /**
     * update storyTags
     * @param selectedData
     */
     function updateStoryTags(selectedData: IOption) {
        if ((props.story.storyTags as string[]).includes(selectedData._id)) {
            const temp = (props.story.storyTags as string[]).filter(data => data !== selectedData._id)
            props.updateStory(storyFields.STORYTAGS, temp)
        } else {
            props.updateStory(storyFields.STORYTAGS, [...props.story.storyTags, selectedData._id])
       }
    }

    /**
     * update relatedStories
     * @param selectedData
     */
     function updateRelated(selectedData: IRelatedStory) {
        if ((props.story.related.map(x => (x as IRelatedStory)._id)).includes(selectedData._id)) {
            const temp = (props.story.related as IRelatedStory[]).filter((data: IRelatedStory) => data._id !== selectedData._id)
            props.updateStory(storyFields.RELATED, temp)
        } else {
            props.updateStory(storyFields.RELATED, [...props.story.related, selectedData])
       }
    }

    /**
     * handle the date input from the datepickers
     * @param date Date --> the selected date from the input field
     * @param dateAccuracy TDateAccuracy (day or month or year) --> the accuracy of the date input
     * @param datePeriodType TDatePeriodType (start or end) --> the start or the end date of the story (the default value is start)
     */
    function handleDateInput(date: Date, dateAccuracy: TDateAccuracy, datePeriodType: TDatePeriodType = "start") {
        // the user has to choose the start date before the end date
        if (!props.story.date.startDate && datePeriodType !== "start") {
            return
        }
        let newDate: Date = new Date(date);

        switch (dateAccuracy) {
            case "year":
                // the date is the first day of the selected year
                if (datePeriodType === "start") {
                    const newFullDate = new Date(dateStartInput.date.setFullYear(date.getFullYear()));
                    setdateStartInput({ date: newFullDate, year: newFullDate.getFullYear(), month: null, day: null })
                    setdateEndInput(dateEndInputInit)
                } else {
                    const newFullDate = new Date(dateEndInput.date.setFullYear(date.getFullYear()));
                    setdateEndInput({ date: newFullDate, year: newFullDate.getFullYear(), month: null, day: null })
                }
                break
            case "month":
                // the date is the first day of the selected month
                if (datePeriodType === "start") {
                    const newFullDate = new Date(dateStartInput.date.setMonth(date.getMonth()));
                    setdateStartInput(prev => { return { ...prev, date: newFullDate, month: newFullDate.getMonth(), day: null }})
                    setdateEndInput(prev => { return { ...prev, date: prev.date, month: null, day: null }})
                } else {
                    const newFullDate = new Date(dateEndInput.date.setMonth(date.getMonth()));
                    setdateEndInput(prev => { return { ...prev, date: newFullDate, month: newFullDate.getMonth(), day: null }})
                }
                break
            case "day":
                if(datePeriodType === "start") {
                    const newFullDate = new Date(dateStartInput.date.setDate(date.getDate()));
                    setdateStartInput(prev => { return { ...prev, date: newFullDate, day: newFullDate.getDate() }})
                }
                else {
                    const newFullDate = new Date(dateEndInput.date.setDate(date.getDate()));
                    setdateEndInput(prev => { return { ...prev, date: newFullDate, day: newFullDate.getDate() }})
                }
                break
        }

        let dateOfStory = props.story.date

        console.log("dateOfStory: ", dateOfStory)

        // set the proper data from the date array of the story
        if (dateOfStory.startDate) {
            if(datePeriodType === "start") {
                dateOfStory.startDate.date = newDate
                switch (dateAccuracy) {
                    case "year":
                        dateOfStory.startDate.year = newDate.getFullYear()
                        delete dateOfStory.startDate.month
                        delete dateOfStory.startDate.day
                        break
                    case "month":
                        dateOfStory.startDate.month = newDate.getMonth() // + 1
                        delete dateOfStory.startDate.day
                        break
                    case "day":
                        dateOfStory.startDate.day = newDate.getDate()
                        break
                }
            }
            else {

                if(!dateOfStory.endDate) {
                    dateOfStory.endDate = {
                        date: newDate,
                        year: newDate.getFullYear()
                    }
                }
                else {
                    dateOfStory.endDate.date = newDate
                }

                switch (dateAccuracy) {
                    case "year":
                        dateOfStory.endDate.year = newDate.getFullYear()
                        delete dateOfStory.endDate.month
                        delete dateOfStory.endDate.day
                        break
                    case "month":
                        dateOfStory.endDate.month = newDate.getMonth() // + 1
                        delete dateOfStory.endDate.day
                        break
                    case "day":
                        dateOfStory.endDate.day = newDate.getDate()
                        break
                }
            }
        } else {
            if (datePeriodType === "start") {
                dateOfStory = {
                    startDate: {
                        date: newDate,
                        year: parseInt(moment(newDate).format('YYYY'))
                    }
                }
            } else {
                return
            }
        }
        // update the date of the story with the new array
        props.updateStory(storyFields.DATE, dateOfStory)
    }

    async function reverseGeoCode(loc:ILocation) {
        const google = window.google;
        if(google) {
            const geocoder = new google.maps.Geocoder();

            try {
                let geoRes = await geocoder.geocode({ location: loc })
                let country = '';
                geoRes.results[0].address_components.forEach(data => {
                    if(data.types.includes('country')) {
                        country = data.short_name;
                    }
                })
                return country
            }
            catch(err) {
                console.log('Failed to add country to the story.')
            }
        }
    }

    async function updateStoryLocation(loc: ILocation) {
        const reversedLocation = await reverseGeoCode(loc);
        // If enabled and google revers fail set default to London/GB
        if(!reversedLocation) {
            props.updateStory('country', "GB");
        }
        else {
            props.updateStory('country', reversedLocation);
        }
        
        props.updateStory('sLocation', loc);
    }

    function enableStoryLocation(checked:boolean) {
        if(checked) {
            //props.updateStory('sLocation', {lat:51.5072, lng:0.1276})
            setAddStoryLocation(true);
        }
        else {
            setAddStoryLocation(false);
            props.updateStory('sLocation', {lat:0, lng:0})
        }
    }

    function getFixedMaxDay() {

        const thisMonth = new Date().getMonth();
        const thisYear = new Date().getFullYear();

        if(dateStartInput.year !== null && dateStartInput.month !== null) {
            if((thisYear !== dateStartInput.year || thisMonth !== dateStartInput.month)) {
                return new Date(dateStartInput.year, dateStartInput.month + 1, 0)
            }
            else {
                return new Date();
            }
        }
        else {
            return new Date();
        }
    }

    function getFixedMaxDayEnd() {

        const thisMonth = new Date().getMonth();
        const thisYear = new Date().getFullYear();

        if(dateEndInput.year !== null && dateEndInput.month !== null) {
            if((thisYear !== dateEndInput.year || thisMonth !== dateEndInput.month)) {
                return new Date(dateEndInput.year, dateEndInput.month + 1, 0)
            }
            else {
                return new Date();
            }
        }
        else {
            return new Date();
        }
    }

    function clearDateElement(pos:string, type:string) {
        let dateOfStory = props.story.date;
        if(pos === "start") {
            if(type === "day") {
                delete dateOfStory.startDate.day;
                delete dateOfStory.endDate?.day;
                setdateStartInput(prev => { return { ...prev, day: null }})
                setdateEndInput(prev => { return { ...prev, day: null }})

            }
    
            if(type === "month") {
                delete dateOfStory.startDate.day;
                delete dateOfStory.startDate.month;

                delete dateOfStory.endDate?.day;
                delete dateOfStory.endDate?.month;

                setdateStartInput(prev => { return { ...prev, month:null, day: null }})
                setdateEndInput(prev => { return { ...prev, month:null, day: null }})

            }
        }
        else {
            if(type === "day") {
                delete dateOfStory.endDate?.day;
                setdateEndInput(prev => { return { ...prev, day: null }})
            }
    
            if(type === "month") {
                delete dateOfStory.endDate?.day;
                delete dateOfStory.endDate?.month;
                setdateEndInput(prev => { return { ...prev, month:null, day: null }})
            }
        }

        props.updateStory(storyFields.DATE, dateOfStory)
    }

    return (
        <div className="storyinfo-container">
            <div className="basics">
                <p className="title">{t("Basics")}</p>
                <div>
                    <p className="block-title bas">{t("When did this story take place?")}</p>
                    <div className="radio-input-container">
                        <label htmlFor="date-fixed">
                            <input
                                className="radio-input"
                                type="radio"
                                id="date-fixed"
                                name="date-type"
                                onChange={(evt) => {
                                    setdateEndInput(dateEndInputInit)
                                    if (props.story.date.endDate) {
                                        const date = {
                                            startDate: props.story.date.startDate
                                        }
                                        props.updateStory(storyFields.DATE, date);
                                    }
                                    setDateType(evt.target.value)
                                }}
                                value={DateTypes.FIXED}
                                checked={dateType === DateTypes.FIXED}
                            />
                            <div></div>
                            <span>{t("Fixed")}</span>
                        </label>
                    </div>
                    {dateType === DateTypes.FIXED && (
                        <div>
                            <p className="explanation">{t("Only 'Year' is mandatory")}</p>
                            <div className="dates-input-container">
                                <div className="date-container">
                                    <label className="top-label">{t("Year")}<span style={{color:'red'}}> *</span></label>
                                    <Icon className="calendar-icon" size={1} path={mdiMenuDown} />
                                    <DatePicker
                                        selected={dateStartInput.year !== null ? dateStartInput.date : null}
                                        onChange={(date) => (date instanceof Date ? handleDateInput(date, "year") : null)}
                                        showYearPicker
                                        dateFormat="yyyy"
                                        maxDate={new Date()}

                                    />
                                </div>
                                <div className={`date-container ${!(dateStartInput.year !== null) ? "disabled" : ""}`}>
                                    <label className="top-label">{t("Mth")}</label>
                                    <Icon className="calendar-icon" size={1} path={mdiMenuDown} />
                                    <DatePicker
                                        disabled={!(dateStartInput.year !== null)}
                                        selected={dateStartInput.month !== null ? dateStartInput.date : null}
                                        onChange={(date) => (date instanceof Date ? handleDateInput(date, "month") : null)}
                                        dateFormat="MMM"
                                        showMonthYearPicker
                                        minDate={dateStartInput.year !== null ? new Date(dateStartInput.year, 0, 0) : new Date()}
                                        maxDate={dateStartInput.year !== null ? new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0) : new Date()}
                                        openToDate={dateStartInput.year !== null ? new Date(dateStartInput.year + 1, 0, 0) : new Date()}
                                        renderCustomHeader={() => <div></div>}
                                    />
                                    {dateStartInput.month !== null ? <div onClick={() => { clearDateElement("start", "month")}}>
                                        <Icon className="calendar-close-icon" size={0.8} path={mdiCloseCircle} />
                                    </div> : null}
                                </div>
                                <div className={`date-container ${!(dateStartInput.month !== null) ? "disabled" : ""}`}>
                                    <label className="top-label">{t("Day")}</label>
                                    <Icon className="calendar-icon" size={1} path={mdiMenuDown} />
                                    <DatePicker
                                        disabled={!(dateStartInput.month !== null)}
                                        selected={dateStartInput.day !== null ? dateStartInput.date : null}
                                        onChange={(date) => (date instanceof Date ? handleDateInput(date, "day") : null)}
                                        dateFormat="dd"
                                        dropdownMode="select"
                                        openToDate={(dateStartInput.month !== null && dateStartInput.year !== null) ? new Date(dateStartInput.year, dateStartInput.month, 0) : new Date()}
                                        minDate={dateStartInput.month !== null ? new Date(dateStartInput.year !== null ? dateStartInput.year : new Date().getFullYear(), dateStartInput.month, 0) : new Date()}
                                        maxDate={getFixedMaxDay()}
                                        />
                                    {dateStartInput.day !== null ? <div onClick={() => { clearDateElement("start","day") }}>
                                        <Icon className="calendar-close-icon" size={0.8} path={mdiCloseCircle} />
                                    </div> : null}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="radio-input-container">
                        <label htmlFor="date-period">
                            <input
                                type="radio"
                                className="radio-input"
                                id="date-period"
                                name="date-type"
                                onChange={(evt) => {
                                    setDateType(evt.target.value)
                                }}
                                value={DateTypes.PERIOD}
                                checked={dateType === DateTypes.PERIOD}
                            />
                            <div></div>
                            <span>{t("Period")}</span>
                        </label>
                    </div>
                    {dateType === DateTypes.PERIOD && (
                        <div className="date-period-container-margin">
                            <p className="explanation">{t("Only year is mandatory")}</p>
                            <div>
                                <p><b>From:</b></p>
                                <div className="dates-input-container">
                                <div className="date-container">
                                    <label className="top-label">{t("Year")}<span style={{color:'red'}}> *</span></label>
                                    <Icon className="calendar-icon" size={1} path={mdiMenuDown} />
                                    <DatePicker
                                        selected={dateStartInput.year !== null ? dateStartInput.date : null}
                                        onChange={(date) => (date instanceof Date ? handleDateInput(date, "year") : null)}
                                        showYearPicker
                                        dateFormat="yyyy"
                                        maxDate={new Date()}

                                    />
                                </div>
                                <div className={`date-container ${!(dateStartInput.year !== null) ? "disabled" : ""}`}>
                                    <label className="top-label">{t("Mth")}</label>
                                    <Icon className="calendar-icon" size={1} path={mdiMenuDown} />
                                    <DatePicker
                                        disabled={!(dateStartInput.year !== null)}
                                        selected={dateStartInput.month !== null ? dateStartInput.date : null}
                                        onChange={(date) => (date instanceof Date ? handleDateInput(date, "month") : null)}
                                        dateFormat="MMM"
                                        showMonthYearPicker
                                        minDate={dateStartInput.year !== null ? new Date(dateStartInput.year, 0, 0) : new Date()}
                                        maxDate={dateStartInput.year !== null ? new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0) : new Date()}
                                        openToDate={dateStartInput.year !== null ? new Date(dateStartInput.year + 1, 0, 0) : new Date()}
                                        renderCustomHeader={() => <div></div>}
                                    />
                                    {dateStartInput.month !== null ? <div onClick={() => { clearDateElement("start","month")}}>
                                        <Icon className="calendar-close-icon" size={0.8} path={mdiCloseCircle} />
                                    </div> : null}
                                </div>
                                <div className={`date-container ${!(dateStartInput.month !== null) ? "disabled" : ""}`}>
                                    <label className="top-label">{t("Day")}</label>
                                    <Icon className="calendar-icon" size={1} path={mdiMenuDown} />
                                    <DatePicker
                                        disabled={!(dateStartInput.month !== null)}
                                        selected={dateStartInput.day !== null ? dateStartInput.date : null}
                                        onChange={(date) => (date instanceof Date ? handleDateInput(date, "day") : null)}
                                        dateFormat="dd"
                                        dropdownMode="select"
                                        openToDate={(dateStartInput.month !== null && dateStartInput.year !== null) ? new Date(dateStartInput.year, dateStartInput.month +1, 0) : new Date()}
                                        minDate={dateStartInput.month !== null ? new Date(dateStartInput.year !== null ? dateStartInput.year : new Date().getFullYear(), dateStartInput.month, 0) : new Date()}
                                        maxDate={getFixedMaxDay()}
                                        />
                                    {dateStartInput.day !== null ? <div onClick={() => { clearDateElement("start","day")}}>
                                        <Icon className="calendar-close-icon" size={0.8} path={mdiCloseCircle} />
                                    </div> : null}
                                </div>
                            </div>
                                <p><b>To:</b></p>
                                <div className="dates-input-container">
                                    <div className={`date-container ${!dateStartInput.year ? "disabled" : ""}`}>
                                        <label className="top-label">{t("Year")}<span style={{color:'red'}}> *</span></label>
                                        <Icon className="calendar-icon" size={1} path={mdiMenuDown} />
                                        <DatePicker
                                            selected={dateEndInput.year !== null ? dateEndInput.date : null}
                                            onChange={(date) => (date instanceof Date ? handleDateInput(date, "year", "end") : null)}
                                            showYearPicker
                                            dateFormat="yyyy"
                                            maxDate={new Date()}

                                        />
                                    </div>
                                    <div className={`date-container ${(!(dateStartInput.month !== null) || !(dateEndInput.year !== null)) ? "disabled" : ""}`}>
                                        <label className="top-label">{t("Mth")}</label>
                                        <Icon className="calendar-icon" size={1} path={mdiMenuDown} />
                                        <DatePicker
                                            disabled={!(dateEndInput.year !== null)}
                                            selected={dateEndInput.month !== null ? dateEndInput.date : null}
                                            onChange={(date) => (date instanceof Date ? handleDateInput(date, "month", "end") : null)}
                                            dateFormat="MMM"
                                            showMonthYearPicker
                                            minDate={dateEndInput.year !== null ? new Date(dateEndInput.year, 0, 0) : new Date()}
                                            maxDate={dateEndInput.year !== null ? new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0) : new Date()}
                                            openToDate={dateEndInput.year !== null ? new Date(dateEndInput.year + 1, 0, 0) : new Date()}
                                            renderCustomHeader={() => <div></div>}
                                        />
                                        {dateEndInput.month !== null ? <div onClick={() => { clearDateElement("end","month")}}>
                                            <Icon className="calendar-close-icon" size={0.8} path={mdiCloseCircle} />
                                        </div> : null}
                                    </div>
                                    <div className={`date-container ${(!(dateStartInput.day !== null) || !(dateEndInput.month !== null)) ? "disabled" : ""}`}>
                                        <label className="top-label">{t("Day")}</label>
                                        <Icon className="calendar-icon" size={1} path={mdiMenuDown} />
                                        <DatePicker
                                            disabled={!(dateEndInput.month !== null)}
                                            selected={dateEndInput.day !== null ? dateEndInput.date : null}
                                            onChange={(date) => (date instanceof Date ? handleDateInput(date, "day", "end") : null)}
                                            dateFormat="dd"
                                            dropdownMode="select"
                                            openToDate={(dateEndInput.month !== null && dateEndInput.year !== null) ? new Date(dateEndInput.year, dateEndInput.month +1, 0) : new Date()}
                                            minDate={dateEndInput.month !== null ? new Date(dateEndInput.year !== null ? dateEndInput.year : new Date().getFullYear(), dateEndInput.month, 0) : new Date()}
                                            maxDate={getFixedMaxDayEnd()}
                                            />
                                        {dateEndInput.day !== null ? <div onClick={() => { clearDateElement("end","day")}}>
                                            <Icon className="calendar-close-icon" size={0.8} path={mdiCloseCircle} />
                                        </div> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {props.isAuthor ? <div>
                    <p className="block-title involved">{t("Roles")}</p>
                    <p className="center-explanation">{t("Everyone named has automatic story access")}</p>
                    {administratedUsers.length ? (
                        <div>
                            <UniqueSingleSelect
                                placeholder="Featured Profile"
                                handleAction={(selectedData: IOption) =>  props.userData.relations.find(x => x.userid._id === selectedData._id) ?
                                    props.updateStory(storyFields.FEATURED, props.userData.relations.find(x => x.userid._id === selectedData._id)?.userid) :
                                    props.updateStory(storyFields.FEATURED, {
                                        _id: props.userData._id,
                                        firstname: props.userData.firstname,
                                        lastname: props.userData.lastname
                                    })                                }
                                data={getFeaturedProfiles() ? getFeaturedProfiles() : { _id: "", text: "", img: "" }}
                                options={administratedUsers}
                            />
                            <p onClick={() => setFeaturedProfileTTShow(true)} className="tooltip-text">
                                {t("What's this?")}
                            </p>
                            <TooltipHelp contextKey={'story_info_featured'}
                                show={featuredProfileTTShow}
                                clear={() => setFeaturedProfileTTShow(false)}
                            />
                        </div>
                    ) : null}
                    <div>
                        <div>
                            <UniqueUserListInput
                                placeholder={t("Story Participants")}
                                handleAction={(selectedData: IUserDetails) => updateParticipants(selectedData)}
                                data={props.story.participants as IUserDetails[]}
                                options={[ props.userData, ...props.userData.relations.map(x => x.userid) ]}
                            />
                            <p onClick={() => setStoryParticipantsTTShow(true)} className="tooltip-text">
                                {t("What's this?")}
                            </p>
                            <TooltipHelp contextKey={'story_info_participants'}
                                show={storyParticipantsTTShow}
                                clear={() => setStoryParticipantsTTShow(false)}
                            />
                        </div>
                        <div>
                            <UniqueUserListInput
                                placeholder={t("Co-Author(s)")}
                                handleAction={(selectedData: IUserDetails) => updateCoAuthors(selectedData)}
                                data={props.story.coAuthors as IUserDetails[]}
                                options={props.userData.relations.map(x => x.userid)}
                            />
                            <p onClick={() => setCoAuthorsTTShow(true)} className="tooltip-text">
                                {t("What's this?")}
                            </p>
                            <TooltipHelp contextKey={'story_info_co_authors'} show={coAuthorsTTShow} clear={() => setCoAuthorsTTShow(false)} />
                        </div>
                    </div>
                </div> : null}
            </div>
            <div className="separator"></div>
            <div className="optional">
                <div className="line"></div>
                <p className="title opt">{t("Optional")}</p>
                <div className="story-tags">
                    <UniqueListInput iconRef={''}
                        icon={false}
                        placeholder={t("Add Story Tag(s)")}
                        handleAction={(selectedData: IOption) => updateStoryTags(selectedData)}
                        data={getStoryTags()}
                        options={config.storyTags} 
                        translateable={true} />
                    <p onClick={() => setStoryTagsTTShow(true)} className="tooltip-text">
                        {t("What's this?")}
                    </p>
                    <TooltipHelp contextKey={'story_info_story_tags'} show={storyTagsTTShow} clear={() => setStoryTagsTTShow(false)} />
                </div>
                {props.isAuthor ? <div className="related-stories">
                    <UniqueListFreeInput
                        placeholder={t("Add Related Stories")}
                        handleAction={(selectedData: IRelatedStory) => updateRelated(selectedData)}
                        data={props.story.related as IRelatedStory[]}
                    />
                    <p onClick={() => setRelatedStoriesTTShow(true)} className="tooltip-text">
                        {t("What's this?")}
                    </p>
                    <TooltipHelp contextKey={'story_info_related_stories'}
                        show={relatedStoriesTTShow}
                        clear={() => setRelatedStoriesTTShow(false)}
                    />
                </div> : null}
                <div className="map switch-container">
                    <div className="infos">
                        <span>{t("Story Location")}</span>
                    </div>
                    <div className="status">
                        <p className="switch-status">{addStoryLocation ? t("On") : t("Off")}</p>
                        <Switch
                            className={`${addStoryLocation ? "active-gold-switch" : ""}`}
                            offColor="#17243d"
                            offHandleColor="#8B919E"
                            onColor="#006238"
                            onHandleColor="#006238"
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={14}
                            width={32}
                            onChange={(checked) => enableStoryLocation(checked)}
                            checked={addStoryLocation}
                        />
                    </div>
                </div>
                {addStoryLocation ?
                    (props.story.sLocation.lat !== 0 && props.story.sLocation.lng !== 0) ?
                        <GoogleMap setLocation={updateStoryLocation} id="info-map" specificLocation={props.story.sLocation}/>
                        :
                    <GoogleMap setLocation={updateStoryLocation} id="info-map"/>
                : null}
                <div className="line"></div>
            </div>
        </div>
    );
}

export default StoryInfo;
