import React, {useState, useEffect, useRef, useContext } from "react";

import { mdiPause, mdiPlay, mdiVolumeHigh } from "@mdi/js";
import Icon from "@mdi/react";
import WaveSurfer from 'wavesurfer.js';
import { Context } from '../../pages/home.page';

interface IProps {
    id:string
    blob?:any
    file?:any
}

export function AudioDisplay(props: IProps) {

    const setPageDataContext = useContext(Context).setPageData;
    const pageData = useContext(Context).pageData;

    //Audio elements
    const [waveSurfer, setWaveSurfer] = useState<any>(false);
    const [play, setPlay] = useState<boolean | string>(false);
    const [pause, setPause] = useState<boolean>(false);

    const wrapperRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        let newLoaded = false;

        if(waveSurfer) {
            newLoaded = true
            setWaveSurfer(false);
            waveSurfer.destroy();
        }

        if ((wrapperRef.current && !waveSurfer) || newLoaded) {

            const wavesurfer = WaveSurfer.create({
                container: '#waveform',
                backend: 'MediaElement',
                waveColor: 'grey',
                progressColor: 'green',
                barWidth: 3,
                barGap:2,
                height: 100,
                hideScrollbar: true,
                responsive: true,
                fillParent: true,
                autoCenter: true,
            });

            if(props.blob) {
                wavesurfer.loadBlob(props.blob)
            }
            if(props.file) {
                wavesurfer.load(new Audio((props.file as string)))
            }
            setWaveSurfer(wavesurfer);

            // save the wavesurfer into the pagecontext -> we have to destroy if the audio display is in a modal and the modal is closed
            const newPageData = {...pageData, wavesurfers: [...pageData.wavesurfers, wavesurfer]}
            setPageDataContext(newPageData)

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.blob, props.file])

    useEffect(() => {
        if (waveSurfer) {
            waveSurfer.backend.on('audioprocess', function (time: any) {
                let current = parseFloat(time.toFixed(1));
                let max = parseFloat(waveSurfer.backend.getDuration().toFixed(1))
                if ((current + 0.2) >= max) {
                    setPlay(false);
                    setPause(false);
                }

                if (waveSurfer.isPlaying()) {
                    const totalTime = waveSurfer.getDuration();
                    const currentTime = waveSurfer.getCurrentTime();
                    const remainingTime = totalTime - currentTime;

                    let hours = Math.floor(remainingTime / 3600);
                    let mins = Math.floor(remainingTime / 60) - (hours * 60);
                    let secs = Math.floor(remainingTime % 60);

                    const element = document.getElementById(`time-remaining ${props.id}`);
                    if (element !== null) {
                        element.innerText = mins.toString().padStart(2, '0') + ':' + secs.toString().padStart(2, '0');
                    }
                }
            });
        }
        return () => {
            if (waveSurfer) {            
                pauseAudio();
            }
        };           
    }, [waveSurfer])


    function playAudio() {
        waveSurfer.play();
        setPlay(props.id);
        setPause(false);
    }

    function pauseAudio() {
        waveSurfer.pause();
        setPause(true);
    }

    return (
        <div className="">
            <div className="content">
                {((play === props.id) && !pause) ?
                    <button className="record-button pause" onClick={() => (play === props.id && !pause) ? pauseAudio() : playAudio()}><Icon size={1} path={mdiPause} /></button>
                    : ((play === props.id) && pause) ?
                        <button className="record-button play" onClick={() => (play === props.id && !pause) ? pauseAudio() : playAudio()}><Icon size={1} path={mdiPlay} /></button>
                        :
                        <button className="record-button play" onClick={() => (play === props.id && !pause) ? pauseAudio() : playAudio()}><Icon size={1} path={mdiVolumeHigh} /></button>
                }
                {((play === props.id) && !pause) ? <p className="audio-user"><span id={`time-remaining ${props.id}`}>0.00</span> remaining</p> : <p className="audio-user">Play record</p> }
                <div ref={wrapperRef} id="waveform"></div>
            </div>
        </div>
    )
}