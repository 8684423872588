import { useEffect, useRef, useState } from "react";
import Icon from '@mdi/react'
import { mdiMenuDown } from '@mdi/js';
import { useTranslation } from "react-i18next";

interface KeyValue {
    key:string
    value:string
}
interface IProps {
    handleAction:Function
    options:KeyValue[]
    placeholder:string
    selected:string
}

export default function UniqueSimpleSelect(props:IProps) {

    const [ showAll, setShowAll ] = useState<boolean>(false);
    const [ filterWord, setFilterWord] = useState<string>('');

    const wrapperRef = useRef<HTMLDivElement>(null);
    const upperRef = useRef<HTMLDivElement>(null);

    const { t } = useTranslation();    

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside, false);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside, false);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(props.selected !== filterWord) {
            setFilterWord(props.selected);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, [props.selected])

    function _setFilterWord(text:string) {
        if(props.selected) {
            if(text.length < filterWord.length) {
                setFilterWord(text);
                props.handleAction('');
            }
        }
        else {
            setFilterWord(text)
        }
    }

    function handleClickOutside(event:any) {
        if(wrapperRef.current && !wrapperRef?.current?.contains(event.target as Node) && !upperRef?.current?.contains(event.target as Node)) {
            setShowAll(false);
        }
    };
    
    return(
        <div className="unique-single-select-container group">
            <div ref={upperRef}>
                <input style={{cursor:'default'}} onChange={(evt) => _setFilterWord(evt.target.value)} onClick={() => setShowAll(true)} className="unique-select" type="text" value={props.selected ? t(props.selected) : filterWord} placeholder={t(props.placeholder)}></input>
                <div className="dropdown-icon" onClick={() => setShowAll(!showAll)}>
                    <Icon size={1} path={ mdiMenuDown } />
                </div>
            </div>
            {(props.selected.length || filterWord.length) ? <p className="top-label">{t(props.placeholder)}</p> : null}
            {(showAll) ? <div className="example-list" ref={wrapperRef}>
                {/* {props.options.sort((a, b) => (a.key > b.key) ? 1 : -1).map((keyValue:KeyValue) => { */}
                {props.options.filter(x => filterWord !== props.selected ? x.value.match(new RegExp("^" + filterWord, "i")) : true).map((keyValue:KeyValue) => {
                    return(
                        <p onClick={() => { props.handleAction(keyValue.key); setShowAll(false)}} key={keyValue.key}>{t(keyValue.value)}</p>
                    );
                })}
            </div> : null}
        </div>
    );
}