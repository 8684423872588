import React, { useContext, useEffect, useState } from "react"
import { Row, Col } from "react-bootstrap"
import default_avatar from "../../assets/images/default-avatar.jpg"
import Icon from '@mdi/react';
import { mdiShieldCheck, mdiAccountMultiple, mdiArrowRightTop, mdiCloseCircle, mdiHelpCircle, mdiAlertOctagram, mdiStar } from '@mdi/js';
import { IUserDetails } from "../../interfaces/authentication"
import SelectAdministrator from "../family/select-administrator.component"
import TooltipHelp from "../notifications/tooltiphelp.component"
import SelectSuccessor from "../family/select-successor.component"
import InfoField from "./info-field.component";
import instance from "../../api/api";
import { profileTypes, userFields, dateFormat } from "../../_helper/enum/enum";
import moment from "moment";
import PhotoUploader from "./photo-uploader.component";
import { IStory } from "../../interfaces/story";
import { useHistory } from "react-router-dom";
import { Context } from "../../pages/home.page";
import SelectFeatured from "../family/select-featured.component";
import { useTranslation } from "react-i18next";

interface IStoryProfileData {
    featured:number,
    participants:number,
    favourite:number,
    toBeRead:number
}

interface IProps {
    user: IUserDetails
    administrator: boolean
    relation: string
    myProfile: boolean
    updateUser: Function
    familyPage: string
}
function ProfileInfo(props: IProps) {
    //Helper states for tooltips
    const [showTooltipAdmin, setShowTooltipAdmin] = useState<boolean>(false);
    const [showTooltipSuccessors, setShowTooltipSuccessors] = useState<boolean>(false);
    const [showTooltipFeatured, setShowTooltipFeatured] = useState<boolean>(false);    

    // states for editing the profile image
    const [showPhotoUploader, setShowPhotoUploader] = useState(false);

    //profile info data
    const [ storyData, setStoryData ] = useState<IStoryProfileData>({featured:0, participants:0, favourite:0, toBeRead:0});

    const history = useHistory();

    const userData = useContext(Context).user;
    const updateUser = useContext(Context).setUserDetails;

    const { t } = useTranslation();    

    //Get story data for user
    useEffect(() => {
        if(props.user._id.length && !props.familyPage) {
            getStoryDataForUser();
        }
    }, [props.user, props.familyPage])

    async function getStoryDataForUser() {
        const storyRes = await instance.get(`/publishedstories`);
        const participants = storyRes.data.filter((story:IStory) => story.participants.map((user) => (user as IUserDetails)._id).includes(props.user._id))
        const featured = storyRes.data.filter((x:IStory) => (x.featured as IUserDetails)._id === props.user._id)
        
        let favourite = 0;
        let toBeRead = 0;

        if(!props.myProfile) {
            favourite = featured.filter((story:IStory) => userData.favorites.stories.includes(story._id)).length
            toBeRead = featured.filter((story:IStory) => !story.readBy.includes(userData._id)).length
        }

        setStoryData({ 
            featured:featured.length, 
            participants:participants.length,
            favourite:favourite,
            toBeRead:toBeRead
        });
    }

    function clearTooltipAdmin() {
        setShowTooltipAdmin(false)
    }

    function clearTooltipSuccessors() {
        setShowTooltipSuccessors(false)
    }

    function clearTooltipFeatured() {
        setShowTooltipFeatured(false)
    }    

    const notChildProfile = props.user.profileType !== profileTypes.CHILD ? true : false;

    async function removeWebsite(removedSite:string) {
        try {
            const filteredWebsites = props.user.websites.filter(site => site !== removedSite)

            const response = await instance.put(`/user/${props.user._id}`, { details: { websites: filteredWebsites } })
            if (response.status === 200) {
                // update the data on the profile info page
                props.updateUser("websites", response.data.websites)
            } else {
                // TODO: error notification (design?)
            }
        } catch (error) {
            // TODO: error notification (design?)
        }
    }

    function updatePhoto(newImage:any){
       
        props.updateUser("avatar", newImage);        

        // update Context: admin's Context
        if(props.administrator) {
            //console.log("todo-pr.inf.c")
            //updateUser(userData); // TODO: medium: how to update admin's context so admin sees new photo of the just administrated person?
        }
    }


    return (
        <div className={props.administrator ? "profile-info admin-profile-tab" : "profile-info"}>
            <Row className="top-section">     
                <div className="cover-container">
                    <img src={props.user.avatar ? props.user.avatar : default_avatar} className="user-picture-cover" alt="user-cover" />
                </div>
                <Col className="avatar">
                    <img src={props.user.avatar ? props.user.avatar : default_avatar} className="user-picture" alt="user" />
                    {(props.administrator || props.myProfile) && <PhotoUploader userid={props.user._id} show={showPhotoUploader} handleClose={() => setShowPhotoUploader(false)} setProfilePicture={(newImage) => updatePhoto(newImage)} />}
                    {props.relation.length && props.relation !== "-" ? <p className="relation">{t(props.relation)}</p> : null}
                    {props.user.profileType === profileTypes.DECEASED && <p className="life-dates">{`${moment(props.user.birthdate).format(dateFormat.SHORT)} to ${moment(props.user.diedon).format(dateFormat.SHORT)}`}</p>}
                    {(props.administrator || props.myProfile) && <button className="underlined-link-button" onClick={() => setShowPhotoUploader(true)}>{t("Change photo")}</button>}
                </Col>
                {props.administrator && <div className="admin-notification admin-access-notification">
                    <p className="access-text" ><span className="access-bold">{t("Admin Access")}</span> - {t("You can edit family on their behalf.")}</p>
                </div>}                
            </Row>
            {props.myProfile && (!props.user.administrators.length && !props.user.successors.length) && 
                <Row>
                    <Col>
                        <div className="admin-notification">
                            <p>{t("Warning! Future proof your account")} - <a href="#admin">{t("assign a Successor or Admin here")}</a></p>
                        </div>
                    </Col>
                </Row>}
            <Row className="section">
                <Col xs={12} className="info-section stories">
                    <p className="title">{t("Stories")}</p>
                    <div className="content">                    
                        <div className="info-item">
                            <p>
                                <Icon size={1} path={ mdiShieldCheck  } />
                                {t("Featured profile INFO")} 
                            </p>
                            <div className="btn-container">
                                <button onClick={() => history.push({pathname: '/dashboard/stories/2', state: { filters: { story: { featured: [props.user] } } } })}>{storyData.featured}</button>
                            </div>
                        </div>
                        <div className="info-item">
                            <p>
                                <Icon size={1} path={ mdiAccountMultiple } />
                                {t("Participant")} 
                            </p>
                            <div className="btn-container">
                                <button onClick={() => history.push({pathname: '/dashboard/stories/2', state: { filters: { story: { participants: [props.user]}} } })}>{storyData.participants}</button>
                            </div>
                        </div>
                        {!props.myProfile && <div className="info-item">
                            <p>
                                <Icon size={1} path={ mdiAlertOctagram  } />
                                {t("To be read")}
                            </p>
                            <div className="btn-container">
                                <button onClick={() => history.push({pathname: '/dashboard/stories/2', state: { filters: { story: {featured: [props.user], unreadStories:true} }} })}>{storyData.toBeRead}</button>
                            </div>
                        </div>}
                        {!props.myProfile && <div className="info-item">
                            <p>
                                <Icon size={1} path={ mdiStar } />
                                {t("My Favorites")} 
                            </p>
                            <div className="btn-container">
                                <button onClick={() => history.push({pathname: '/dashboard/stories/2', state: { filters: { story: {featured: [props.user], favorites:true} }} })}>{storyData.favourite}</button>
                            </div>
                        </div>}
                    </div>
                </Col>
            </Row>
            <Row className="section">
                <Col xs={12} className="info-section details">
                    <p className="title">{t("Details")}</p>
                    <div className="content">
                        <InfoField
                            title={t("First name")}
                            userid={props.user._id}
                            field={userFields.FIRSTNAME}
                            hasEditRights={props.administrator || (props.myProfile)}
                            displayedData={props.user.firstname}
                            updateUser={props.updateUser}
                        />
                        <InfoField
                            title={t("Middle name")}
                            userid={props.user._id}
                            field={userFields.MIDDLENAME}
                            hasEditRights={props.administrator || (props.myProfile)}
                            displayedData={props.user.middlename ? props.user.middlename : ""}
                            updateUser={props.updateUser}
                        />
                        <InfoField
                            title={t("Last Name")}
                            userid={props.user._id}
                            field={userFields.LASTNAME}
                            hasEditRights={props.administrator || (props.myProfile)}
                            displayedData={props.user.lastname}
                            updateUser={props.updateUser}
                        />
                        <InfoField
                            title={t("Nickname")}
                            userid={props.user._id}
                            field={userFields.NICKNAME}
                            hasEditRights={props.administrator || (props.myProfile)}
                            displayedData={props.user.nickname ? props.user.nickname : "" }
                            updateUser={props.updateUser}
                        />
                        <InfoField
                            title={t("Date of birth")}
                            userid={props.user._id}
                            field={userFields.BIRTHDATE}
                            hasEditRights={props.administrator || (props.myProfile && notChildProfile)}
                            displayedData={props.user.birthdate ? moment(props.user.birthdate).format(dateFormat.SHORT) : ""}
                            updateUser={props.updateUser}
                        />
                        {props.user.profileType === profileTypes.DECEASED && <InfoField
                            title={t("Date of death")}
                            userid={props.user._id}
                            field={userFields.DIEDON}
                            hasEditRights={props.administrator || (props.myProfile && notChildProfile)}
                            displayedData={props.user.diedon ? moment(props.user.diedon).format(dateFormat.SHORT) : ""}
                            updateUser={props.updateUser}
                        />}
                        {props.user.profileType !== profileTypes.DECEASED && <InfoField
                            title={t("Country")}
                            userid={props.user._id}
                            field={userFields.COUNTRY}
                            hasEditRights={props.administrator || (props.myProfile)}
                            displayedData={props.user.country}
                            updateUser={props.updateUser}
                        />}
                        {props.user.profileType !== profileTypes.DECEASED && <InfoField
                            title={t("State / County")}
                            userid={props.user._id}
                            field={userFields.STATE}
                            hasEditRights={props.administrator || (props.myProfile)}
                            displayedData={props.user.state}
                            updateUser={props.updateUser}
                        />}
                        {props.user.profileType !== profileTypes.DECEASED && <InfoField
                            title={t("Email")}
                            userid={props.user._id}
                            field={userFields.EMAIL}
                            hasEditRights={props.administrator || (props.myProfile && notChildProfile)}
                            displayedData={props.user.email}
                            updateUser={props.updateUser}
                        />}
                        {props.user.profileType !== profileTypes.DECEASED && <InfoField
                            title={t("Telephone")}
                            userid={props.user._id}
                            field={userFields.PHONE}
                            hasEditRights={props.administrator || (props.myProfile)}
                            displayedData={props.user.phone ? props.user.phone : ""}
                            updateUser={props.updateUser}
                        />}
                        {props.user.profileType === profileTypes.CHILD && (
                            <InfoField
                                title={t("Profile type")}
                                userid={props.user._id}
                                field={userFields.PROFILETYPE}
                                hasEditRights={props.administrator}
                                displayedData="Child (<18 years old)"
                                updateUser={props.updateUser}
                                birthdate={props.user.birthdate}
                            />
                        )}

                        {props.user.profileType !== profileTypes.DECEASED && <InfoField
                            title={t("Related websites")}
                            userid={props.user._id}
                            field={userFields.WEBSITES}
                            hasEditRights={props.administrator || (props.myProfile)}
                            displayedData=""
                            updateUser={props.updateUser}
                            websites={props.user.websites}
                        />}

                        <div className="related-websites">
                            {props.user.websites && props.user.websites.map((site, index) => {                                
                                return (
                                    <div key={site+index} className="website-item">
                                    <div>
                                        <Icon size={1} path={ mdiArrowRightTop  } />
                                        <a href={site.includes("http") ? site : `//${site}`} rel="noreferrer" target="_blank">{site}</a>
                                    </div>
                                    {props.administrator || props.myProfile ? 
                                        <div onClick={() => removeWebsite(site)}>
                                            <Icon size={1} path={ mdiCloseCircle  } />
                                        </div>
                                    : null}
                                </div>
                                )
                            })}
                        </div>

                    </div>
                </Col>
            </Row>
            <Row className="info-container">
                <Col xs={12} className="info-section">
                    {props.myProfile && !props.user.administrators.length && !props.user.successors.length &&
                        <div className="admin-notification">
                            <p>{t("Assign an Admin and/or Successors so future generations will have someone able to manage your profile, accept new family contacts and authorise stories to be written for you")}</p>
                        </div>}
                </Col>
            </Row>                

            <Row className="info-container section">
                <Col xs={12} className="info-section admin" id="admin">
                    {(props.administrator || (props.myProfile && notChildProfile)) ?
                        (<div>
                            <p className="title">{t("Admins")}  {(props.administrator || (props.myProfile && notChildProfile)) && <span onClick={() => setShowTooltipAdmin(true)}><Icon size={1} path={ mdiHelpCircle  } /></span>} </p>
                            <p className="subtitle">{t("People with editing access to this profile")}</p>
                            <div className="content">
                                <SelectAdministrator userid={props.user._id} administrators={props.user.administrators} relations={props.user.relations}/>
                                <TooltipHelp contextKey={'profile_info_admin'} clear={clearTooltipAdmin} show={showTooltipAdmin} />
                            </div>
                        </div>)
                        : (props.user.profileType === profileTypes.DECEASED || props.user.profileType === profileTypes.CHILD) ? (
                            <div className="admin-list">
                                <p className="title">{t("Admins")}  {(props.administrator || (props.myProfile && notChildProfile)) && <span onClick={() => setShowTooltipAdmin(true)}><Icon size={1} path={ mdiHelpCircle  } /></span>} </p>
                                <p className="subtitle">{t("People with editing access to this profile")}</p><br/>
                                {props.user.administrators.map((admin) => {
                                    return (
                                        <div className="admin-list-item">                       
                                            <a className="underlined-link-button" href={`/dashboard/profile/${admin._id}/2`}><img className="user-picture" src={admin.avatar ? admin.avatar : default_avatar} alt="" />{`${admin.firstname} ${admin.lastname}`}</a>                            
                                        </div>
                                    )
                                })}
                            </div>
                        ) : null }
                    {!props.administrator && props.user.profileType === profileTypes.DECEASED && <Col xs={12}>
                        <div className="admin-notification centered">
                            <p className="title">{t("UNVERIFIED PROFILE!")}</p>
                            <p>{t("Managed by")} {props.user.administrators.map((admin, index) => <span key={admin._id}><a href={'/dashboard/profile/' + admin._id + "/1"}>{`${admin.firstname} ${admin.lastname}`}</a>{index < props.user.administrators.length-1 ? ", " : ""} </span>)} </p>
                            <p><a href="/dashboard/terms">{t("View terms of usage")}</a></p>
                        </div>
                    </Col>}
                </Col>
            </Row>
            {props.user.profileType !== profileTypes.DECEASED && (props.administrator || (props.myProfile && notChildProfile)) ? <Row className="info-container section" >
                <Col xs={12} className="info-section successor">
                    <p className="title">{t("Successors")} <span onClick={() => setShowTooltipSuccessors(true)}><Icon size={1} path={ mdiHelpCircle  } /></span></p>                    
                    <p className="subtitle">{t("Give account access when you retire from active use.")}<br/>{t("Name Successors to manage your profile.")}</p>
                    <SelectSuccessor userid={props.user._id} successors={props.user.successors} relations={props.user.relations}/>
                    <TooltipHelp contextKey={'profile_info_successor'} clear={clearTooltipSuccessors} show={showTooltipSuccessors} />
                </Col>
            </Row> : null}
            {(props.administrator || props.myProfile) ? <Row className="info-container section" >
                <Col xs={12} className="info-section featured">
                    <p className="title">{t("Write about me")} <span onClick={() => setShowTooltipFeatured(true)}><Icon size={1} path={ mdiHelpCircle  } /></span></p>                    
                    <p className="subtitle">{t("Allow others to write stories about you.")}</p>
                    <SelectFeatured userid={props.user._id} featuredBy={props.user.featuredBy} relations={props.user.relations}/>
                    <TooltipHelp contextKey={'profile_info_featured'} clear={clearTooltipFeatured} show={showTooltipFeatured} />
                </Col>
            </Row> : null}            
        </div>
    )
}

export default ProfileInfo
