import React, { useState, useEffect, useContext } from "react";
import { Context } from "../home.page";
import GroupDetailsModal from "../../components/profile/groups/group-details-modal.component";
import { IGroup } from "../../interfaces/authentication";
import instance from "../../api/api";
import { useParams, useHistory } from "react-router"
import { Spinner } from "react-bootstrap";
import { INotification, NotificationType } from "../../interfaces/notification";
import Notification, { notificationImages } from "../../components/notifications/notification.component";
import config from "../../config/config";

interface RouteParams {
  id: string
}

const successNotification: INotification = { icon:notificationImages.happiness, title:"Success", text:"You are now member of the group", success:NotificationType.success, closeBtnOff: true }
const errorNotification: INotification = { icon:notificationImages.crying, title:"Failed", text:"Error when adding you to the group", success:NotificationType.problem, closeBtnOff: true }
const errorAlreadyMemberNotification: INotification = { icon:notificationImages.crying, title:"Failed", text:"You are already a member of the group", success:NotificationType.problem, closeBtnOff: true }

function PrivateGroup() {

  const [ groups, setGroups ] = useState<IGroup[]>([]);
  const [ detailGroup, setDetailGroup ] = useState<IGroup | null>(null);

  //Notification
  const [ showNotification, setShowNotification ] = useState<boolean>(false);
  const [ showErrorNotification, setShowErrorNotification] = useState<boolean | INotification>(false)

  //User feedback
  const [ loading, setLoading ] = useState<boolean>(false)  

  const loggedInUserData = useContext(Context).user;

  const { id } = useParams<RouteParams>();

  const history = useHistory();

  useEffect(() => {
    //getGroups();
    addLoggedInUserToGroup(id)
  }, [id])

  // TODO: what if admin opens the page? now: you're already member of the group
  // TODO: what happens when modal is closed by X? now: redirected to view assigned stories
/*
  async function getGroups() {
    const groups = [];
    const groupsResponse = await instance.get('/group', { params: { privateId: id } });

    if(groupsResponse.data && groupsResponse.data.length) {        
        groups.push(...groupsResponse.data);
        setGroups(groups);
        // TODO: do it easier
        const temp = groups.find(x => (x as IGroup).privateId === id as string);
        temp === undefined ? setDetailGroup(null) : setDetailGroup(temp);   
    }
  }
*/
  async function addLoggedInUserToGroup(privateId:string) {
    setLoading(true);

    try {
        const response = await instance.put('/group/private', { privateId: privateId });
        //props.updateGroups(response.data);
        //updateUserData({...loggedInUserData, groups:[...loggedInUserData.groups.filter(x => x._id !== response.data._id), response.data]})
        setShowNotification(true);

        setTimeout(() => {
          setDetailGroup(response.data)
        }, config.modalCloseTimeout)        
    }
    catch(error:any) {
      if(error.response.status === 400 && error.response.data === "Member already") {
        setShowErrorNotification(errorAlreadyMemberNotification)
      } else {
        setShowErrorNotification(errorNotification)
      }
      setTimeout(() => {
        history.push("/")
        //props.modalClose();
      }, config.modalCloseTimeout)
    }

    setLoading(false);    
  }

  function handleClose(){
    setDetailGroup(null)
    history.push({pathname: '/dashboard/stories/2', state: { filters: { story: { accessGroups: [{_id:(detailGroup as IGroup)._id, text:(detailGroup as IGroup).name, img:''}]}} } })
  }

  return (
    <div className="help-page-container" style={{textAlign:'center'}}>
      {!loading ?
        detailGroup !== null && <GroupDetailsModal user={loggedInUserData} administrator={false} group={detailGroup} show={true} handleClose={() => handleClose()} updateGroups={() => {}} deleteGroup={() => {}}/>
        : <div className="button-loader"><Spinner animation="border" variant="#fff" /></div>}
      {showNotification ? <Notification data={successNotification} close={() => setShowNotification(false)} /> : null}
      {showErrorNotification ? <Notification data={showErrorNotification as INotification} close={() => setShowErrorNotification(false)} /> : null}        
    </div>
  );
}

export default PrivateGroup;
