import Cookies from 'universal-cookie';
import { clearRedux } from '../store/actionCreators';

/**
 * Default logout function.
 */
export default async function logout() {

    //Clear jwt cookie
    const cookies = new Cookies();
    cookies.remove('token');
    cookies.remove('refresh_token');

    //Clear session and localstorage.
    sessionStorage.clear();
    localStorage.clear();

    //Clear redux
    clearRedux();
    window.location.replace('/login');
};
