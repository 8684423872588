import { mdiChevronDown, mdiChevronUp, mdiPlusCircleOutline, mdiMinusCircle } from "@mdi/js";
import Icon from "@mdi/react";
import { useContext, useState } from "react";
import { Accordion } from "react-bootstrap";
import { Context } from "../../pages/home.page";
import Markdown from 'markdown-to-jsx';
import { INotification, NotificationType } from "../../interfaces/notification";
import Notification, { notificationImages } from "../../components/notifications/notification.component";
import instance from "../../api/api";

interface IHelp {
    category: string
    pageName: string
}

interface IHelpQuestionData {
  data: IHelpQuestion[]
}

interface IHelpQuestion {
  id: number
  attributes: IHelpQuestionAttributes
}

interface IHelpQuestionAttributes {
  question: string
  answer: string
  vimeo_id?: string  
}

export interface IHelpCategory {
  id: number
  attributes: IHelpCategoryAttributes
}
export interface IHelpCategoryAttributes {
    category: string
    vimeo_id?: string
    page_name: string    
    created_at?: Date
    help_questions?: IHelpQuestionData
    page_description?: string
    published_at?: Date
    updated_at?: Date
}

export default function Help(props: IHelp) {

    //const helpData = useContext(Context).helpQuestions;
    const helpCategories : IHelpCategory[] = useContext(Context).helpCategories;
    const [ showHelp, setShowHelp ] = useState<boolean>(false);
    const [ showNotHelpful, setShowNotHelpful ] = useState<boolean>(false);

    const [ data, setData ] = useState<string>('');

    //notifications
    const [ notification, setNotification ] = useState<INotification>({icon:"", title:"", text:"", success:NotificationType.problem});       

    const [currentCategoryOpened, setCurrentCategoryOpened] = useState("");
    const toggleCategoryItemHandler = (idx: string) => {
        idx === currentCategoryOpened ? setCurrentCategoryOpened("") : setCurrentCategoryOpened(idx);
    };

    const category = helpCategories.find(x => (x.attributes.category.toLowerCase() === props.category.toLowerCase() && x.attributes.page_name.toLowerCase() === props.pageName.toLowerCase()));

    async function send(){
        
        try {

            await instance.post('/email', { data: data, type: "Not Helpful", param1: props.category, param2: props.pageName});

            setNotification({
                icon:notificationImages.happiness, title:"Success", text:"Message sent successfully", success:NotificationType.success
            })
            setData("");

            setTimeout(() => {
                resetNotification()
                setShowNotHelpful(!showNotHelpful)
            }, 3000)

        }
        catch(error) {
            setNotification({
                icon:notificationImages.crying, title:"Failed", text:"Message not sent", success:NotificationType.problem
            })

            console.log('Failed to send message, Error ' + error)
        }
    }

    function resetNotification() {
        setNotification({icon:"", title:"", text:"", success:NotificationType.problem});
    }     
    
    return (
        <div className="need-help">
            <div className={`need-help-header ${showHelp ? "show" : ""}`} onClick={() => setShowHelp(!showHelp)}>
                <p className="main">Need Help?</p>
                <Icon size={1} path={showHelp ? mdiChevronUp : mdiChevronDown} />
            </div>
            {showHelp ?
                <div className="help-container">
                    {category?.attributes?.vimeo_id && <div className="modal-iframe-custom">
                        <iframe src={`https://player.vimeo.com/video/${category.attributes.vimeo_id}`} width="460" height="238" allow="autoplay; fullscreen; picture-in-picture"></iframe>
                    </div>}
                    <Accordion flush>
                        {category && category.attributes.help_questions?.data && category.attributes.help_questions.data.map((item, idx) => {
                            return (
                              <Accordion.Item
                                eventKey={idx.toString()}
                                onClick={() => {
                                  toggleCategoryItemHandler(idx.toString());
                                }}
                              >
                                <div className="accordion-item-container">
                                  <div className="accordion-header">
                                    <div className="icon-container">
                                      <Icon
                                        size={1}
                                        path={
                                          currentCategoryOpened ===
                                          idx.toString()
                                            ? mdiMinusCircle
                                            : mdiPlusCircleOutline
                                        }
                                      />
                                    </div>
                                    <p
                                      className={
                                        "title " +
                                        (currentCategoryOpened ===
                                        idx.toString()
                                          ? "opened"
                                          : "")
                                      }
                                    >
                                      {item.attributes.question}
                                    </p>
                                  </div>
                                  {currentCategoryOpened === idx.toString() && (
                                    <div>
                                      <p className="answer">
                                        <Markdown>{item.attributes.answer}</Markdown>
                                      </p>
                                      {item?.attributes?.vimeo_id && <div className="modal-iframe-custom">
                                        <iframe src={`https://player.vimeo.com/video/${item.attributes.vimeo_id}`} width="460" height="238" allow="autoplay; fullscreen; picture-in-picture"></iframe>
                                      </div>}
                                    </div>
                                  )}
                                </div>
                              </Accordion.Item>
                            );
                        }
                        )}
                    </Accordion>
                    <div className={`need-help-header not-helpful-header ${showNotHelpful ? "show" : ""}`} onClick={() => setShowNotHelpful(!showNotHelpful)}>
                        <p className="main">Can we assist?</p>
                        <Icon size={1} path={showNotHelpful ? mdiChevronUp : mdiChevronDown} />
                    </div>
                    {showNotHelpful ? 
                        <div className="not-helpful-body">
                            <div className="inputs">
                                <div className="group">
                                    <textarea placeholder="Tell us how..." rows={3} value={data} onChange={(evt) => evt.target.value.length <= 900 ? setData(evt.target.value) : false} />
                                    <label className="top-label">Tell us how...</label>
                                    <p className="sub-subtitle right">{data.length} / 900</p>
                                </div>
                            </div>

                            {notification.title.length ?
                                <Notification data={notification} close={() => resetNotification()}/>
                            : null}
                            <button className="default-button" disabled={(data.length > 0 ? false : true)} onClick={() => send()}>Send</button>                        
                        </div>

                    : null }       
                </div> : null }
        </div>
    )

}