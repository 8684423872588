import React, { useEffect, useRef, useState } from "react";
import Icon from '@mdi/react'
import { mdiMenuDown } from '@mdi/js';
import { IOption } from "../../interfaces/family";

interface IProps {
    handleAction:Function
    data:IOption
    options:IOption[]
    placeholder:string
    sortById?:boolean // not used currently
    slice?: number
    viewOnly?:boolean
}

export default function UniqueSingleSelect(props:IProps) {

    const [ tempText, setTempText ] = useState<string>('');
    const [ showAll, setShowAll ] = useState<boolean>(false);
    const [ loaded, setLoaded ] = useState<boolean>(false);

    const wrapperRef = useRef<HTMLDivElement>(null);
    const upperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        //Wait if thjere is an input
        if(props.data.text.length) {
            setTempText(props.data.text);
        }
        setLoaded(true);

        document.addEventListener("mousedown", handleClickOutside, false);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside, false);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleClickOutside(event:any) {
        if(wrapperRef.current && !wrapperRef?.current?.contains(event.target as Node) && !upperRef?.current?.contains(event.target as Node)) {
            setShowAll(false);
        }
    };

    useEffect(() => {

        if(props.data.text.length > tempText.length && loaded) {
            setTempText(props.data.text);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data])

    useEffect(() => {
        if(props.data.text.length > tempText.length && loaded) {
            props.handleAction({id:'', text:'', img:''});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tempText])

    return(
        <div className="unique-single-select-container group">
            <div ref={upperRef}>
                <input className="unique-select" type="text" value={props.data.text.length ? props.data.text : tempText} placeholder={props.placeholder} onChange={(evt) => evt}></input>
                { /* TODO? onChange={(evt) => setTempText(evt.target.value)*/ }
                <div className="dropdown-icon" onClick={() => setShowAll(!showAll)}>
                    <Icon size={1} path={ mdiMenuDown } />
                </div>
            </div>
            <p className="top-label">{props.placeholder}</p>
            {((tempText.length && !props.data.text.length) || showAll) ? 
            <div className={props.viewOnly ? "example-list view-only" : "example-list"} ref={wrapperRef}>
                {props.data.text && !props.sortById ? 
                    props.options.filter((option:IOption) => !option.text.match(new RegExp(props.data.text, "i") ))
                    .sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase()) ? 1 : -1)
                    .map((value:IOption) => {   
                        return(
                            <p onClick={() => {props.handleAction(value); setShowAll(false)}} key={value._id}>{props.slice ? value.text.slice(props.slice) : value.text}</p>
                        );
                    }) 
                : !props.data.text && !props.sortById ?
                    props.options.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase()) ? 1 : -1)
                    .map((value:IOption) => {   
                        return(
                            <p onClick={() => {props.handleAction(value); setShowAll(false)}} key={value._id}>{value.text}</p>
                        );
                    })
                : 
                    props.options.sort((a, b) => (a._id.toLowerCase() > b._id.toLowerCase()) ? 1 : -1)
                    .map((value:IOption) => {   
                        return(
                            <p onClick={() => {props.handleAction(value); setShowAll(false)}} key={value._id}>{value.text}</p>
                        );
                    })                
                }
            </div> : null}
        </div>
    );
}