import React, {useEffect} from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Route, Switch, RouteComponentProps, Redirect, useHistory} from 'react-router-dom';
import routes from '../config/routes';
import { IState } from '../interfaces/redux';
import "react-datepicker/dist/react-datepicker.css";
import logout from '../_helper/_logout';
import OfflineDetect from '../components/_helper/offline-detect';
import { useTranslation } from 'react-i18next';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import PWAModal from '../components/_helper/pwa-ios-modal';

const Application: React.FunctionComponent<{}> = props => {
    const { trackPageView } = useMatomo()

    const history = useHistory()
    useEffect(() => {
        history.listen((location) => {
            const pathName = location.pathname
            trackPageView({
                documentTitle: pathName, 
            })

        })
    }, [history])

    const { t } = useTranslation();

    const isAuthenticated: boolean = useSelector(
        (state: IState) => state.isAuthenticated,
        shallowEqual
    )

    const handleInvalidUserId = () => {
        if (!localStorage.getItem("userid") && window.location.pathname.includes("/dashboard")) {
            logout()
        }
      }

    useEffect(() => {
        window.addEventListener('storage', handleInvalidUserId);

        return function cleanup() {
          window.removeEventListener('storage', handleInvalidUserId);
        }
      }, []);

    /**
     * Main router components
     * 
     * get routes interface loop trough the implemented routes and create a component for each
     * 
     */
    return (
        <div className="App">
            <PWAModal />
            <OfflineDetect />
                <Switch>
                    {routes.map((route, index) => {
                        /**
                         * key: map returns awlays need a key
                         * path: the routes path
                         * exact: exact route or not
                         * render: function to parse any props the component needs. Only render component if not private, or user loged in, else redirect
                         */
                        return(
                            <Route 
                                key={index} 
                                path={route.path} 
                                exact={route.exact} 
                                render={(props: RouteComponentProps<any>) => {
                                    if(route.private) {
                                        if(isAuthenticated) {
                                            return(<route.component {...props} {...route.props} />)
                                        }
                                        else {
                                            return <Redirect to={'/login'} />
                                        }
                                    }else {
                                        return(<route.component {...props} {...route.props} />)
                                    }
                                }}
                            />
                        )
                    })}
                    <Route path='*'>
                        <p>{t("Not found")}</p>
                    </Route>
                </Switch>
        </div>
    );
}

export default Application;
