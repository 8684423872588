import React, { Dispatch, useCallback, useContext, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { setCurrentPage } from "../../../store/actionCreators"
import Help from "../../../components/_helper/help.component"
import { Link } from "react-router-dom"
import Switch from "react-switch";
import { useTranslation } from "react-i18next"
import { Context } from "../../home.page";
import instance from "../../../api/api";
//import HubspotContactModal from "../../../components/settings/hubspot-contact.modal"
import { Row, Col } from "react-bootstrap"
//import Icon from "@mdi/react"
//import { mdiChevronRight } from "@mdi/js"

/**
 * /settings/newsletters page
 *
 */

function EmailAndNewsLetters() {

    const userDetails = useContext(Context).user;

    const [ monthlyNewsletter, setMonthlyNewsletter ] = useState<boolean>( (userDetails.settings && userDetails.settings.updates.monthly) ? true : false);

    //const [showHubspotModal, setShowHubspotModal] = useState(false)

    const dispatch: Dispatch<any> = useDispatch()
    const setPageName = useCallback((currentPage: string) => dispatch(setCurrentPage(currentPage)), [dispatch])

    const updateLoggedInUserData = useContext(Context).setUserDetails;    

    const { t } = useTranslation()

    useEffect(() => {
        setPageName(t("Email & Newsletter"))
    }, [])

    useEffect(() => {
        // run update only if anything changes       
        if (userDetails.settings && userDetails.settings.updates && (
                (userDetails.settings.updates.monthly && !monthlyNewsletter)
                || (!userDetails.settings.updates.monthly && monthlyNewsletter)
                )
            ) {
            updateUserData(monthlyNewsletter);
        }
    }, [monthlyNewsletter]);
    
    async function updateUserData(monthlyNewsletter:boolean) {

        let details = {};

        details = {
            settings: {...userDetails.settings,
                updates: {
                    ...userDetails.settings.updates,
                    monthly: monthlyNewsletter,
                }
            },
        }

        try {
            const response = await instance.put('/user/' + localStorage.userid, {details:details});
            updateLoggedInUserData(response.data);
        }
        catch(error) {
            setMonthlyNewsletter(!monthlyNewsletter)
            console.log('error', error)
        }
            
    }

    return (
        <div className="settings email-newsletters details-container">
            <div className="settings-container-newsletters">
                <div className="setting-group-row">
                    <p className="setting-group-title">{t("Email & Newsletter")}</p>
                </div>
                <Row className="setting-option-row">
                    <Col xs={11}>
                        <p className="settings-title">{t("Family Newsletter")}</p>
                        <p className="settings-description">
                            {t("Explore a wide range of family-themed topics designed to help your whole family thrive, including tips on using the Simirity App.")}
                            <Link to="/dashboard/terms">{t("Terms & Privacy")}</Link>.
                        </p>
                    </Col>
                    {(userDetails.settings && userDetails.settings.updates) ? 
                        <div className="update-container">
                                <div className="monthly-update setting-option-row notifications-row switch-container">
                                    <p className="switch-status">{monthlyNewsletter ? t("On") : t("Off")}</p>
                                    <Switch className={`switch ${monthlyNewsletter ? "active-gold-switch" : ""}`} 
                                            offColor="#17243d"
                                            offHandleColor="#8B919E"
                                            onColor="#006238"
                                            onHandleColor="#006238"
                                            handleDiameter={20}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={14}
                                            width={32}
                                            onChange={(checked) => setMonthlyNewsletter(checked)} 
                                            checked={monthlyNewsletter} />
                                </div>                 
                        </div>    
                    : null}
                </Row>
                {/*<HubspotContactModal show={showHubspotModal} close={() => setShowHubspotModal(false)} />*/}
            </div>
            <Help category={"SETTINGS"} pageName={"Email_Newsletters"}></Help>
        </div>
    )
}

export default EmailAndNewsLetters
