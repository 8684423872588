import React, { useState } from "react"
import { Container, Row, Col } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiFacebook, mdiTwitter, mdiInstagram } from '@mdi/js';
//import HubspotContactModal from "../settings/hubspot-contact.modal";

const date = new Date();

function Footer() {
    const [showHubspotModalNewsletter, setShowHubspotModalNewsletter] = useState(false)
    return (
        <footer className="footer">
            <Container fluid className="px-0">
                <Row className="footer-links">
                    <ul>
                        <li>
                            <a className="link" href="/dashboard/terms">
                                terms & privacy
                            </a>
                        </li>

                        <li>
                            <a className="link" href="/dashboard/cookies">
                                cookie policy
                            </a>
                        </li>

                        <li>
                            <a className="link" onClick={() => setShowHubspotModalNewsletter(true)}>
                                newsletter
                            </a>
                        </li>

                        <li className="footer-social-icons">
                            <div className="icon"><a href="https://twitter.com/SimirityApp" target="_blank"><Icon size={1} path={ mdiTwitter } /></a></div>
                            <div className="icon"><a href="https://www.facebook.com/SimirityApp/" target="_blank"><Icon size={1} path={ mdiFacebook } /></a></div>
                            <div><a href="https://www.instagram.com/simirityapp/" target="_blank"><Icon size={1} path={ mdiInstagram } /></a></div>
                        </li>
                    </ul>
                </Row>
                {/*<HubspotContactModal show={showHubspotModalNewsletter} close={() => setShowHubspotModalNewsletter(false)} />*/}
                <Row className="rights">
                    <Col xs={12}>
                        <p>
                            Copyright {date.getFullYear()} Simirity Ltd. <br /> All rights
                            reserved.
                        </p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;
