import React, { useEffect, useRef, useState } from "react";
import { mdiCloudUpload } from "@mdi/js";
import Icon from "@mdi/react";
import { blockTypes, IBlock, IStory } from "../../../../interfaces/story";
import { instance } from "../../../../api/api"
import { instanceUpload } from "../../../../api/upload"
import { INotification, NotificationType } from "../../../../interfaces/notification";
import Notification, { notificationImages } from "../../../notifications/notification.component";
import moment from "moment";
import ImageMetaDataEdit from "../../../_helper/image-metadata-edit.component";
import { ILocation } from "../../../_helper/google-map.component";
// @ts-ignore
import exifr from "exifr/dist/lite.esm.mjs";
import Help from "../../../_helper/help.component";
import getBlobDuration from 'get-blob-duration';
import { ProgressBar } from "react-bootstrap";
import UploadLimitReachedModal from "../../upload-limit-reached.modal";
import axios, { AxiosError } from "axios"
import VideoPlayer from "../../../_helper/video-player.component";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next"

interface IProps {
    addBlockToStory: Function
    data: IBlock | boolean
    updateStory: Function
    story: IStory
}

interface IBlobFile {
    blob:string
    file:File
    fileDuration:number
}

function renameFile(originalFile:any, newName:string) {
    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    });
}

interface IMeta {
    date: Date | boolean
    location : ILocation | boolean
}

export default function VideoBlockInput(props:IProps) {

    const [ data, setData ] = useState<IBlobFile>();

    const [ addedVideo, setAddedVideo ] = useState<IBlobFile>();
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ edit, setEdit ] = useState<boolean>(false);
    const [uploadPercentage, setUploadPercentage] = useState(0)

    //MetaData
    const [ metaData, setMetaData ] = useState<IMeta[]>([]);
    const [ metaAvalible, setMetaAvalible ] = useState<boolean>(false);
    const [ showAdditionalInfo, setShowAdditionalInfo] = useState<boolean>(false);

    //notifications
    const [ errorNotification, setErrorNotification ] = useState(false);
    const [uploadLimitReachedModal, setUploadLimitReachedModal] = useState(false)
    //filesize notification
    const [ fileSizeErrorNotifican, setFileSizeErrorNotification ] = useState<boolean>(false);

    const inputFile = useRef<HTMLInputElement | null>(null);

    const { t } = useTranslation();
    
    let errorNotificationData : INotification = {
        icon:notificationImages.crying,
        title:t("Failed"),
        text:"",
        success: NotificationType.problem
    };
    
    const errorFileSizeNotificationData : INotification = {
        icon: notificationImages.crying,
        title: t("Failed"),
        text: t("Video must be under 2GB"),
        success: NotificationType.problem
    };    

    useEffect(() => {
        
        if(props.data) {
            const block = props.data as IBlock;

            if(block.media) {
                const type = "video/mp4";
                let video = {file: new File([block.media.data], block.media.name, {type:type}), blob:block.media.data, fileDuration: block.media.duration }

                setData(video);
                setEdit(true);
                // setAddedVideo(video);
            }
        }
    }, [])

    // set isLoading to false if the uploadPercentage is 100%
    useEffect(() => {        
        if (uploadPercentage === 100) {
            setTimeout(() => {
                setIsLoading(false)
            }, 2000);
        }
    }, [uploadPercentage])
    

    function selectVideos(evt: any) {
        try {
            if (data) {
                setData(undefined)
            }
          
            let mediaFile: IBlobFile = addedVideo as IBlobFile

            setMetaData([])

            Array.from(evt.target.files).forEach((file: any) => {
                const blob = URL.createObjectURL(file)
                mediaFile = { file: file, blob: blob, fileDuration: 0 }
            })

            setAddedVideo(mediaFile)

            if (mediaFile.file.type.includes("image")) {
                exifr.parse(mediaFile.file).then((output:any) => {
                    let data: IMeta = { date: false, location: false }
                    if (!output) {
                        return
                    }
                    if (Object.keys(output).length) {
                        if (output.hasOwnProperty("DateTimeOriginal") || output.hasOwnProperty("GPSLatitude")) {
                            setMetaAvalible(true)
                            data = {
                                date: output.DateTimeOriginal ? moment(output.DateTimeOriginal, "YYYY:MM:DD HH:mm:ss").toDate() : false,
                                location: output.GPSLatitude
                                    ? {
                                          lat: output.GPSLatitude[0] + output.GPSLatitude[1] / 60 + output.GPSLatitude[2] / 3600,
                                          lng: output.GPSLongitude[0] + output.GPSLongitude[1] / 60 + output.GPSLongitude[2] / 3600,
                                      }
                                    : false,
                            }
                        }
                    }
                    setMetaData((prev) => {
                        return [data]
                    })
                })
            }

            setData(mediaFile)
        } catch (error) {
            console.log("Failed to select the video", error)
        }
    }

    async function addToStory(meta?:IMeta) {
        try {
        const formdata = new FormData();
        
        //Show error if no video selected
        if(!data) {
            errorNotificationData.text = t("Can't add Block to Story without any video");
            setErrorNotification(true)
            return;
        }

        let file = data.file;

        // get duration as it is not part of default file type
        let duration = 0
        try {
            duration = await getBlobDuration(data.blob);
        } catch (error) {
            duration = 0
        }
        const durationFinal = duration ? Math.ceil(duration) : 0;

        //Show error if size > 2 GB
        if(file.size > 21474837648) {
            setFileSizeErrorNotification(true)
            return;
        }

        setIsLoading(true);

        if(props.data) {
            const block = props.data as IBlock;

            if(block.media) {
                try {
                     await instance.delete('/upload/'+ block.media._id );
                }
                catch(error) {
                    console.log('Error while deleting old media files');
                }
            }
        }

        const fileName = file.name;
        const newFile = renameFile(file, fileName);
        formdata.append("file", newFile);
        formdata.append("duration", durationFinal.toString());
        formdata.append("story", props.story._id)
        formdata.append("type", blockTypes.VIDEO)
        formdata.append("isBlock", "true")

        let type = blockTypes.VIDEO

            const options = {
                // get the status of the file upload
                onUploadProgress: (progressEvent: any) => {
                    const { loaded, total } = progressEvent
                    let percent = Math.floor((loaded * 100) / total)
                    setUploadPercentage(percent * 0.8) // the file is uploaded, waiting for the response
                },
            }
            const response = await instanceUpload.post('/upload', formdata, options);
            setUploadPercentage(100) // the process is done
            props.addBlockToStory({data:response.data.data, multipleMedia:[], media:response.data._id ,type:type})
        }
        catch(error) {
            setIsLoading(false);
            const isAxiosError = axios.isAxiosError(error)
            if ( isAxiosError && (error as AxiosError).response?.data === "Upload Limit Reached" ) {
                setUploadLimitReachedModal(true)
            } else if (isAxiosError && (error as AxiosError).response?.data === "The file type is incorrect") {
                errorNotificationData.text = t("The file type is incorrect")
                setErrorNotification(true)
                console.log("Error while uploading video: ", error)
            } else {
                errorNotificationData.text = t("Media server is not responding, try again a few minutes later");
                setErrorNotification(true);            
                console.log('Error while uploading video: ', error)
                Sentry.captureException(error, {user: {id: localStorage.userid}, tags: { 'mediaErrMsg': 'Error while uploading video'}});                
            }
        }
    }

    return(
        <div className="video-upload">
            <div className="inner">           
                {!data ? 
                <div>
                    <div className="selected-container">
                        <p className="selected">{t("Select video")}</p>
                        <p className="sub-selected">{t("from your media library")}</p>
                    </div>          
                    <div onClick={() => inputFile.current ? inputFile.current.click() : null} className="upload-drag-and-drop">
                        <Icon size={2} path={mdiCloudUpload}/>
                        <p>{t("Choose file")}</p>
                    </div>
                    <div className="upload-container-button">
                        <input accept="video/*" className="upload-button" type="file" id="drag-and-drop" ref={inputFile} onChange={selectVideos} hidden />
                    </div>
                </div>
                :
                <div>
                    {!showAdditionalInfo ? 
                        <div className="">
                            <div className="video">
                                <p>{data.file.name} {t("selected")}</p>
                                <VideoPlayer url={data.blob} errorMessage={t("The video will be optimized after uploading")} />
                                {edit &&
                                <div className="change-video">
                                    <div onClick={() => inputFile.current ? inputFile.current.click() : null} className="upload-drag-and-drop">
                                        <Icon size={2} path={mdiCloudUpload}/>
                                        <p>{t("Change file")}</p>
                                    </div>
                                    <div className="upload-container-button">
                                        <input accept="video/*" className="upload-button" type="file" id="drag-and-drop" ref={inputFile} onChange={selectVideos} hidden />
                                    </div>
                                </div>}
                            </div>

                            </div> 
                            : 
                            <div>
                                <ImageMetaDataEdit uploadPercentage={uploadPercentage} updateStory={props.updateStory} metaData={metaData} addToStory={addToStory} isLoading={isLoading}/>
                            </div>
                        }
                </div>}
                
                {errorNotification ? <Notification data={errorNotificationData} close={() => { setData(undefined); setErrorNotification(false)}} /> : null}
                {fileSizeErrorNotifican ? <Notification data={errorFileSizeNotificationData} close={() => { setData(undefined); setFileSizeErrorNotification(false) }}/> : null}

                <button className="default-button add-button" disabled={(data && addedVideo && !isLoading ? false : true)} onClick={() => { metaAvalible ? setShowAdditionalInfo(true) : addToStory() }}>
                    {metaAvalible ? t("Next") : t("Add to story")}
                    {isLoading ? <ProgressBar className="progressbar" now={uploadPercentage} /> : <></>}
                </button>
            </div>
            <Help category={"PUBLISHING"} pageName={"Adding Videos"}></Help>
            <UploadLimitReachedModal show={uploadLimitReachedModal} setUploadLimitReachedModal={setUploadLimitReachedModal} />
        </div>
    );
}