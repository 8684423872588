import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { IGroup, IUserDetails } from '../../../interfaces/authentication';
import { IOption } from '../../../interfaces/family';
import default_avatar from "../../../assets/images/default-avatar.jpg";
import Icon from '@mdi/react'
import { mdiChevronDown, mdiChevronUp } from '@mdi/js'
import instance from '../../../api/api';
import UniqueSingleSelect from '../../_helper/unique-single-select.component';
import DeleteGroupConfirmModal from './delete-group-modal.component';
import UpdateGroup from './update-group.component';
import Notification, { notificationImages } from '../../notifications/notification.component';
import { INotification, NotificationType } from '../../../interfaces/notification';
import { useTranslation } from "react-i18next"

interface IProps {
    group:IGroup
    administrator:boolean
    show:boolean
    user: IUserDetails
    updateGroups: Function
    deleteGroup: Function
    handleClose: () => void
}

enum action {
    REASSIGN=0,
    DELETE=1
}

const feedbackNotifications = {
    error: { icon:notificationImages.happiness, title:"", text:"", success:NotificationType.success },
}

export default function GroupDetailsModal(props:IProps) {

    const [ actionType, setActionType ] = useState<action | null>(null);
    const [ newManager, setNewManager ] = useState<IOption>({_id:"", text:"", img:""});
    const [ relatedStories, setRelatedStories ] = useState<number>(0);

    //Notification feedback
    const [ feedback, ] = useState<INotification>(feedbackNotifications.error);
    const [ showFeedback, setShowFeedback ] = useState<boolean>(false);

    const history = useHistory();

    // delete confirm modal
    const [showConfirmModal, setshowConfirmModal] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        //Have to check stories assigned to the group
        if(props.group._id.length) {
            getAssignedStories();
        }
    }, []);

    async function getAssignedStories() {
        const storiesRes = await instance.get(`/publishedstories?accessGroups=${props.group._id}`);
        setRelatedStories(storiesRes.data.length);
    }

    async function updateGroupManager() {
        try {

            const groupData = {
                newManager: newManager._id
            };

            const response = await instance.put('/group', {_id:[props.group._id], query:groupData});
            props.updateGroups(response.data);

            setTimeout(() => {
                modalClose();
            }, 2000)
        }
        catch(error) {
            setShowFeedback(true)
        }
    }

    function modalClose() {
        setActionType(null);
        setNewManager({_id:"", text:"", img:""});
        props.handleClose()
    }

    return(
        <Modal className="family-group-details-modal" show={props.show} onHide={modalClose}>
            <Modal.Header closeButton >
                <Modal.Title>{t("Group details")}</Modal.Title>                
            </Modal.Header>
            <Modal.Body>
                {props.administrator ?
                <div>
                    <UpdateGroup modalClose={modalClose} updateGroups={props.updateGroups} ctaText='Update Group' group={props.group}/>
                    <div className="group-actions">
                        <div className={`view ${actionType === action.REASSIGN ? "prev" : ""}`} onClick={() => history.push({pathname: '/dashboard/stories/2', state: { filters: { story: { accessGroups: [{_id:props.group._id, text:props.group.name, img:''}]}} } })}>
                            <p className="link-button action-title">{t("View assigned stories")} ({relatedStories})</p>
                        </div>
                        <div className={`reassign ${actionType === action.REASSIGN ? "active" : ""} ${actionType === action.DELETE ? "prev" : ""}`}>
                            <div className="title-wrapper" onClick={() =>  setActionType(actionType === action.REASSIGN ? null : action.REASSIGN)}>
                                <p className="link-button action-title">{t("Reassign group")}</p>
                                <Icon className="group-action-icon" size={1} path={ actionType === action.REASSIGN ? mdiChevronUp : mdiChevronDown } />
                            </div>
                            {actionType === action.REASSIGN ? 
                            <div className="content">
                                <p className="content-title">{t("Name the new group manager")}</p>
                                <UniqueSingleSelect placeholder={t("Select one manager")} handleAction={(data:IOption) => setNewManager(data)} data={newManager} options={props.group.members.filter(x => x._id !== localStorage.userid).map((member:IUserDetails) => {return {_id:member._id, text:`${member.firstname} ${member.lastname}`, img:''}})}/>
                                <button className="default-button" disabled={!newManager._id.length} onClick={() => updateGroupManager()}>{t("Reassign group")}</button>
                                {showFeedback ? <Notification data={feedback} close={() => setShowFeedback(false)}/> : null}
                                <p className="bottom-text">{t("Admin rights will be passed from you to the assigned person")}</p>
                            </div> : null }
                        </div>
                        <div className={`delete ${actionType === action.DELETE ? "active" : ""}`}>
                        <div className="title-wrapper" onClick={() =>  setActionType(actionType === action.DELETE ? null : action.DELETE)}>
                                <p className="link-button action-title">{t("Delete Group")}</p>
                                <Icon className="group-action-icon" size={1} path={ actionType === action.DELETE ? mdiChevronUp : mdiChevronDown } />
                            </div>
                           {actionType === action.DELETE ? 
                           <div className="content">
                               <div>
                                    <p>{t("Changes to assigned stories post deletion")}</p>
                                    <ul>
                                        <li>{t("Current group members will continue to have access")}</li>
                                        <li>{t("Future family will not have access unless a new group that grows with your family is assigned")}</li>
                                    </ul>
                                    <p onClick={() => history.push({pathname: '/dashboard/stories/2', state: { filters: { story: { accessGroups: [{_id:props.group._id, text:props.group.name, img:''}]}} } })} className="link">{t("View assigned stories")} ({relatedStories})</p>
                               </div>
                                <button className="default-button" onClick={() => setshowConfirmModal(true)}>{t("Delete Group")}</button>
                            </div> : null}
                        </div>
                    </div>
                </div>
                :<div className="only-member">
                    <div className="container">
                        <p className="only-member-title">{t("Group name")}</p>
                        <p className="only-member-description">{props.group.name}</p>
                    </div>
                    <div className="container">
                        <p className="only-member-title">{t("Group goal")}</p>
                        <p className="only-member-description">{props.group.description}</p>
                    </div>
                    <div className="container" onClick={() => window.location.replace('/dashboard/profile/' + props.group.administrator._id + "/1")} >
                        <p className="only-member-title">{t("Managed by")}</p>
                        <p className="admin-name">{props.group.administrator.firstname} {props.group.administrator.lastname}</p>
                    </div>
                    <div className="container">
                        <p className="only-member-title">{t("People in the group")}</p>
                        {props.group.members.map((member:IUserDetails, index) => {
                            return(
                                <div className="member" key={index}>
                                    <img src={member.avatar ? member.avatar : default_avatar} className="user-picture" width="20" height="20" alt="avatar" />
                                    <p>{member.firstname} {member.lastname}</p>
                                </div>
                            )
                        })}
                    </div>
                    <div className="group-actions">
                    <div onClick={() => history.push({pathname: '/dashboard/stories/2', state: { filters: { story: { accessGroups: [{_id:props.group._id, text:props.group.name, img:''}]}} } })}>
                            <p className="link-button">{t("View assigned stories")} ({relatedStories})</p>
                        </div>
                    </div>
                </div>}
            </Modal.Body>
            <DeleteGroupConfirmModal show={showConfirmModal} handleClose={() => setshowConfirmModal(false)} closeDetailsModal={props.handleClose} groupid={props.group._id} deleteGroup={props.deleteGroup} />        
        </Modal>
    );
}