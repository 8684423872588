import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import instance from "../../api/api";
import { dateFormat } from "../../_helper/enum/enum";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { INotification, NotificationType } from "../../interfaces/notification";
import Notification, { notificationImages } from "../notifications/notification.component";

interface IProps {

}

export enum EPaymentType {
    //CREDIT_TRANSACTION_PAYMENT="CREDIT_TRANSACTION_PAYMENT",
    SUBSCRIPTION_FAMILY="SUBSCRIPTION_FAMILY",
    SUBSCRIPTION_FAMILYPLUS="SUBSCRIPTION_FAMILYPLUS",
    SUBSCRIPTION_UPGRADE="SUBSCRIPTION_UPGRADE",
    //MEDIA_BOOST_PAYMENT="Media Boost",
    //MENTORSHIP="Mentorship",
}

export default function AccountPurchases(props:IProps) {

    const [ purchases, setPurchases ] = useState<any[]>([]);

    const [ feedback, setFeedback ] = useState<boolean>(false);

    const successNotificationData: INotification = { 
        icon:notificationImages.happiness, 
        title:"Success", 
        text:"Now share access with your family", 
        success:NotificationType.success,
        closeBtnOff: true
    }

    //Translation
    const { t } = useTranslation();

    //history
    const location: any = useLocation();

    useEffect(() => {
        if(location.state && location.state.success) {
            setFeedback(true); //TODO: when either subscription or upgrade is bought
            window.history.replaceState({}, document.title)
        }

        getUsersPurchases();
    }, [location.state])

    async function getUsersPurchases() {
        try {
            const purchasesResponse = await instance.get("/payment");
            setPurchases(purchasesResponse.data)
        }
        catch(error) {

        }
    }

    /**
     * we have to reload the app because of the fastspring popup
     */
    function openSharePage(){
        window.location.replace(`${window.location.origin}/dashboard/account/subscription/share`);
    }

    /**
     * we have to reload the app because of the fastspring popup
    */
    function closeFeedback() {        
        window.location.reload()
    }

    function paymentTypeDisplay(type:EPaymentType){
        switch(type){
            case EPaymentType.SUBSCRIPTION_FAMILY:
                return t("Family Subscription");
            case EPaymentType.SUBSCRIPTION_FAMILYPLUS:
                return t("Family+ Subscription");
            case EPaymentType.SUBSCRIPTION_UPGRADE:
                return t("Subscription Upgrade");
            default:
                return "";
        }
    }

    return(
        <div className="account-purchases-container">
            <p className="title-text">{t("Purchase History")}</p>
            <div className="purchases">
                {purchases.map((purchase) => {
                    return(
                        <Row className="purchase">
                            <Col>
                                <p className="date">{moment(purchase.createdAt).format(dateFormat.CUSTOM)}</p>
                                <p className="type">{paymentTypeDisplay(purchase.payment_type)}</p>
                            </Col>
                            <Col>
                                <p className="price">{purchase.total_display}</p>
                            </Col>
                        </Row>
                    )
                })}
                {!purchases.length && <p className="info-text">{t("Nothing to display")}</p>}
            </div>
            <Modal className="subscription-success-modal" show={feedback} onHide={closeFeedback}>
                <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>                
                </Modal.Header>
                <Modal.Body>
                    <Notification data={successNotificationData} close={() => {}}>
                        <Button style={{marginTop:25, marginBottom:25}} onClick={openSharePage} className="default-button">Share Access</Button>
                    </Notification> 
                </Modal.Body>
            </Modal>
        </div>
    )
}