import React, { useState, useEffect } from "react"
import Switch from "react-switch"
import Icon from "@mdi/react"
import { mdiAccountGroup, mdiHelpCircle } from "@mdi/js"
import Notification, { notificationImages } from "../notifications/notification.component"
import { INotification, NotificationType } from "../../interfaces/notification"
import PublishStoryGroup from "./publish-with-groups.component"
import UniqueListInput from "../_helper/unique-list-input.component"
import TooltipHelp from "../notifications/tooltiphelp.component"
import { accessTypes, IBlock, IMedia, IStory } from "../../interfaces/story"
import { IUserDetails } from "../../interfaces/authentication"
import UniqueSimpleSelect from "../_helper/unique-simple-select.component"
import { IOption } from "../../interfaces/family"
import { profileTypes } from "../../_helper/enum/enum"
import emoji_neutral from "../../assets/images/notification/emoji-neutral.svg";
import PrivateSharingUrl from "./_helper/private-sharing-url.component"
import { useTranslation } from "react-i18next";

const privateSharingNotification: INotification = {
    icon: notificationImages.happiness,
    title: "TIP",
    text: "Enable ’Share with Private Link’ to share the story outside Simirity. You can even send joining invitations with a link to this story.",
    success: NotificationType.info,
}

enum AccessTypesLocal {
    ALL_FAMILY = "All Family",
    ALL_FAMILY_AND_FRIENDS = "All Family & Friends",
    GROUP = "Assign Group(s)",
    INDIVIDUAL = "Individual People",
    PRIVATE = "Private (my eyes only)",
}

interface IProps {
    story: IStory
    userData: IUserDetails
    updateStory: Function
    access: AccessTypesLocal
    setAccess: Function
    returnToInfo: Function
    isAuthor: boolean
    setNotificationEmails:Function
}

const AccessOptions: string[] = [AccessTypesLocal.ALL_FAMILY_AND_FRIENDS, AccessTypesLocal.GROUP, AccessTypesLocal.INDIVIDUAL, AccessTypesLocal.PRIVATE]

function PublishStory(props: IProps) {
    // Notifications
    const [showPrivateSharingNoti, setShowPrivateSharingNoti] = useState<boolean>(false);

    //tooltips
    const [showDisableCommentsTT, setShowDisableCommentsTT] = useState<boolean>(false);
    const [showaddToReviewTT, setShowaddToReviewTT] = useState<boolean>(false);
    const [showPrivateSharingTT, setShowPrivateSharingTT] = useState<boolean>(false);
    const [showIndividualTT, setShowIndividualTT] = useState<boolean>(false);
    const [showAccessTT, setShowAccessTT] = useState<boolean>(false);

    // states for choosed options
    const [reviewList, setreviewList] = useState<boolean>(false);

    //Access data
    const [ automaticAccesUsers, setAutomaticAccessUsers ] = useState<IOption[]>([]);

    const [ accessIndividuals, setAccessIndividuals] = useState<IOption[]>([]);
    const [ allIndividualOptions, setAllIndividualOptions ] = useState<IOption[]>([]);

    const { t } = useTranslation();

    const selectIndividualsTooltip = {
        title: "What's this?",
        subtitle: "Select Individuals",
        text: () => (
            <div>
                <p>Select the people to access your published story.</p>
                <p>Those displayed with a grey background have automatic story access as you gave them the role of Featured Profile, Co-author or Story Participant. To remove them, <button className="underlined-link-button" onClick={() => {props.returnToInfo(); setShowAccessTT(false);} }>remove them from their roles</button>.</p>
            </div>
        ),
    }

    const accessTooltip = {
        title: "What's this?",
        subtitle: "Access due to story role",
        text: () => (
            <div>
                <p>These people have automatic access as you gave them the role of Featured Profile, Co-author or Story Paricipant.</p>
                <p style={{fontWeight:'bold', marginBottom:'2px'}}>Remove their access?</p>
                <button className="underlined-link-button" onClick={() => {props.returnToInfo(); setShowAccessTT(false);} }>Remove them from their roles.</button></div>
        ),
    }
    
    useEffect(() => {
        //check if we have to display private sharing tooltip

        let mediaFiles:IMedia[] = [];

        props.story.blocks.forEach((block:IBlock) => {
            if(block.media) {
                mediaFiles.push(block.media);
            }

            if(block.multipleMedia) {
                mediaFiles = [...mediaFiles, ...block.multipleMedia];
            }
        })

        const hasOutside = mediaFiles.filter(media => media.tags.others);

        if(hasOutside.length || !props.userData.relations.length) {
            setShowPrivateSharingNoti(true)
        }

    }, [props.story.blocks])

    //Setup automatic access for coauthors, tagged people and other related users.
    useEffect(() => {
        updateAutomaticAccess();
        //Update individuals
        if(props.access === AccessTypesLocal.INDIVIDUAL) {
            let individuals = props.userData.relations.filter(relation => (props.story.accessIndividuals as string[]).includes(relation.userid._id)).map(relation => { return { _id:relation.userid._id, text: `${relation.userid.firstname} ${relation.userid.lastname}`, img: (relation.userid as IUserDetails).avatar ? (relation.userid as IUserDetails).avatar : ""} });
            setAccessIndividuals([...individuals]);

        }
    }, [props.userData, props.story.blocks, props.story.participants, props.story.coAuthors, props.story.featured]);


    //setup individuals
    useEffect(() => {
        if(props.userData._id.length) {
            const individualOptions = props.userData.relations.filter(relation => relation.userid.profileType !== profileTypes.DECEASED).map(relation => { return { _id:relation.userid._id, text: `${relation.userid.firstname} ${relation.userid.lastname}`, img: (relation.userid as IUserDetails).avatar ? (relation.userid as IUserDetails).avatar : ""} });
            const filtered = individualOptions.filter(x => !automaticAccesUsers.map(aut => aut._id).includes(x._id))
            setAllIndividualOptions(filtered);
        }
    }, [props.userData, automaticAccesUsers])

    function updateAutomaticAccess() {
        let usersWhoHaveAccess:IOption[] = []
        /**
         * Add participants
         */
        if(props.story.participants.length) {
            (props.story.participants as IUserDetails[]).filter(x => x.profileType !== profileTypes.DECEASED).forEach((user:IUserDetails) => {
                if(!usersWhoHaveAccess.map(x => x._id).includes(user._id)) {
                    usersWhoHaveAccess = [...usersWhoHaveAccess, {_id:(user as IUserDetails)._id, text: `${(user as IUserDetails).firstname} ${(user as IUserDetails).lastname}`, img: (user as IUserDetails).avatar ? (user as IUserDetails).avatar : "", editable:false}]
                }
            });
        }

        /**
         * Add co Authors 
         */
        if(props.story.coAuthors.length) {
            (props.story.coAuthors as IUserDetails[]).filter(x => x.profileType !== profileTypes.DECEASED).forEach((author:IUserDetails) => {
                if(!usersWhoHaveAccess.map(x => x._id).includes(author._id)) {
                    usersWhoHaveAccess = [...usersWhoHaveAccess, {_id:(author as IUserDetails)._id, text: `${(author as IUserDetails).firstname} ${(author as IUserDetails).lastname}`, img: (author as IUserDetails).avatar ? (author as IUserDetails).avatar : "", editable:false}]
                }
            })
        }

        /**
         * Add featured 
         */
         if(props.story.featured) {
            const f = (props.story.featured as IUserDetails);
            if(f.profileType !== profileTypes.DECEASED && !usersWhoHaveAccess.map(x => x._id).includes(f._id)) {
                    usersWhoHaveAccess = [...usersWhoHaveAccess, {_id:f._id, text: `${f.firstname} ${f.lastname}`, img: (f as IUserDetails).avatar ? (f as IUserDetails).avatar : "", editable:false}]
            }
        }        

        setAutomaticAccessUsers(usersWhoHaveAccess.filter(user => user._id !== (props.story.author as IUserDetails)._id));
    }

    function updateIndividuals(selectedData:IOption) {
        if(accessIndividuals.filter((data:IOption) => data._id === selectedData._id).length) {
            const temp = accessIndividuals.filter((data:IOption) => data._id !== selectedData._id);
            setAccessIndividuals(temp);
            props.updateStory("accessIndividuals", temp.map(x => x._id));
        }
        else {
            const updated = [...accessIndividuals, selectedData];
            setAccessIndividuals(updated)
            props.updateStory("accessIndividuals", updated.map(x => x._id));

        }
    }

    /**
     * Function to render the input fields by the selected story props.access
     * @returns JSX
     */
    function renderByAccess() {
        switch (props.access) {
            case AccessTypesLocal.ALL_FAMILY:
                const notFamily = props.userData.relations.filter(x => x.group === "FRIENDS");
                const tagged = notFamily.filter(relation => automaticAccesUsers.map(x => x._id).includes(relation.userid._id.toString()))
                
                return (
                    <div>
                        <p className="explanation">{t("Share story with current family & future generations.")}</p>
                        {tagged.length ? 
                            <div>
                                <div className="access-to-friends-container">
                                    <div className="group-item">
                                        <div className="icon">
                                            <Icon size={0.7} path={ mdiAccountGroup } />
                                        </div>
                                        <p>{t("Access due to Story Role")}</p>
                                    </div>
                                    <p className="name-list">{tagged.map(relation => relation.userid.firstname + ' ' + relation.userid.lastname).join(", ")}</p>
                                </div>
                                <p className="tooltip-text" onClick={() => setShowAccessTT(true)}>{t("What's this?")}</p>
                            </div>
                            : null}
                    </div>
                )
            case AccessTypesLocal.ALL_FAMILY_AND_FRIENDS:
                return (
                    <div>
                        <p className="explanation">{t("Share story with everyone, including friends & guests.")}</p>
                    </div>
                )
            case AccessTypesLocal.GROUP:
                return (
                    <div>
                        <p className="explanation">
                            {t("Share with specific group of people.")}
                            <br />
                            {t("Groups grow so new generations access old stories.")}
                        </p>
                        <PublishStoryGroup story={props.story} updateStory={props.updateStory} automaticAccesUsers={automaticAccesUsers} setShowIndividualTT={setShowAccessTT}/>
                    </div>
                )
            case AccessTypesLocal.INDIVIDUAL:
                return (
                    <div>
                        <p className="explanation">
                            {t("Share story with specific people.")}
                            <br />
                            {t("Story will")} <span>{t("not")}</span> {t("be available to future generations.")}
                        </p>
                        <UniqueListInput iconRef={''}
                            handleAction={updateIndividuals}
                            data={[ ...automaticAccesUsers, ...accessIndividuals]}
                            options={allIndividualOptions}
                            placeholder="Select individual(s)"
                            icon={false}
                        />
                        <p className="tooltip-text" onClick={() => setShowIndividualTT(true)}>{t("What's this?")}</p>
                    </div>
                )
            case AccessTypesLocal.PRIVATE:
                return (
                    <div className="private-access-container">
                        <p className="explanation">{t("A diary entry that only you will ever access.")}</p>
                        {automaticAccesUsers.filter((x : IOption) => x._id !== (props.story.author as IUserDetails)._id).length ? 
                        <div>
                            <div className={`notification-container info`}>
                                <img src={emoji_neutral} alt="emoji"/>
                                <p className="notification-title">{t("INFORMATION NOTICE")}</p>
                                <div className="notification-body">
                                    <p className="notification-text">{t("By publishing privately, people will be removed from their roles in this story.")}</p>
                                    <br/>
                                    {(props.story.participants as IUserDetails[]).filter((x : IUserDetails) => x._id !== (props.story.author as IUserDetails)._id).length ?
                                    <div>
                                        <p className="notification-text">{t("ROLE: Story Participant")}</p>
                                        <p style={{fontWeight:"bold"}}>{(props.story.participants as IUserDetails[]).filter(x => x._id !== (props.story.author as IUserDetails)._id).map((x : IUserDetails) => x.firstname + " " + x.lastname + ", ")}</p>
                                    </div> : null}
                                    {props.story.coAuthors.length ?
                                    <div>
                                        <p className="notification-text">{t("ROLE: Story Co-Author")}</p>
                                        <p style={{fontWeight:"bold"}}>{(props.story.coAuthors as IUserDetails[]).map((x : IUserDetails) => x.firstname + " " + x.lastname + ", ")}</p>
                                    </div> : null}
                                    <br/>
                                    <p className="notification-text">{t("This is necessary to remove their access.")}</p>
                                </div>
                            </div>
                        </div> : null}
                    </div>
                )                
            default:
                return (
                    <div>
                        <p>{t("Share story with current family & future generations.")}</p>
                    </div>
                )
        }
    }

    //TODO: better conversion from local types to usable types in backend
    function updateStoryAccess(data:any) {
        props.setAccess(data);
        if(data === AccessTypesLocal.PRIVATE) {
            props.updateStory('accessFamily', accessTypes.PRIVATE)
        }
        else if(data === AccessTypesLocal.GROUP) {
            props.updateStory('accessFamily', accessTypes.GROUP)
        }
        else if(data === AccessTypesLocal.INDIVIDUAL) {
            props.updateStory('accessFamily', accessTypes.INDIVIDUAL)
        }
        else if(data === AccessTypesLocal.ALL_FAMILY) {
            props.updateStory('accessFamily', accessTypes.ALL_FAMILY)
        }
        else if(data === AccessTypesLocal.ALL_FAMILY_AND_FRIENDS) {
            props.updateStory('accessFamily', accessTypes.ALL_FAMILY_AND_FRIENDS)
        }
    }

    return (
        <div>
            {props.isAuthor ? <div className="basics">
                <p className="title">{t("Story Access")}</p>
                <div className="option group padded">
                    <UniqueSimpleSelect placeholder={t("Assigned Access")} options={AccessOptions.map(x => {return {key:x, value:x}})} handleAction={(data:string) => updateStoryAccess(data)} selected={props.access}/>
                </div>
                {renderByAccess()}
            </div> : null}
            <div className="separator"></div>
            <div className="optional">
                <p className="title">{t("Optional")}</p>
                {showPrivateSharingNoti && <Notification data={privateSharingNotification} close={() => setShowPrivateSharingNoti(false)} />}
                <PrivateSharingUrl 
                        setShowToolTip={setShowPrivateSharingTT}
                        showTooltip={showPrivateSharingTT}
                        story={props.story}
                        setNotificationEmails={props.setNotificationEmails}
                        updateStory={props.updateStory}
                        handleFinish={() => console.log('finish')}
                />
                <div className="switch-container">
                    <div className="infos">
                        <span>{t("Disable Comments")}</span>
                        <span onClick={() => setShowDisableCommentsTT(true)}>
                            <Icon size={1} path={mdiHelpCircle} />
                        </span>
                    </div>
                    <div className="status">
                        <span className="switch-status">{!props.story.commentsEnabled ? t("On") : t("Off")}</span>
                        <Switch
                            className={`${!props.story.commentsEnabled ? "active-gold-switch" : ""}`}
                            offColor="#17243d"
                            offHandleColor="#8B919E"
                            onColor="#006238"
                            onHandleColor="#006238"
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={14}
                            width={32}
                            onChange={(checked) => props.updateStory('commentsEnabled', !checked)}
                            checked={!props.story.commentsEnabled}
                        />
                    </div>
                    <TooltipHelp contextKey={'story_publish_disable_comments'}
                        show={showDisableCommentsTT}
                        clear={() => setShowDisableCommentsTT(false)}
                    />
                </div>
                {/* Hidden it's a future feature. */}
                <div className="switch-container" style={{display:'none'}}>
                    <div className="infos">
                        <span>{t("Add to Review list")}</span>
                        <span onClick={() => setShowaddToReviewTT(true)}>
                            <Icon size={1} path={mdiHelpCircle} />
                        </span>
                    </div>
                    <div className="status">
                        <span className="switch-status">{false ? t("On") : t("Off")}</span>
                        <Switch
                            disabled
                            className="switch"
                            offColor="#17243d"
                            offHandleColor="#8B919E"
                            onColor="#006238"
                            onHandleColor="#006238"
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={14}
                            width={32}
                            onChange={(checked) => setreviewList(checked)}
                            checked={reviewList}
                        />
                    </div>
                    <TooltipHelp contextKey={'story_publish_add_to_review'}
                        show={showaddToReviewTT}
                        clear={() => setShowaddToReviewTT(false)}
                    />
                </div>
                {reviewList && (
                    <div>
                        <textarea name="review-list" placeholder={t("Enter reminder on what needs reviewing")} rows={5} />
                    </div>
                )}
            </div>
            <TooltipHelp
                title={selectIndividualsTooltip.title}
                subtitle={selectIndividualsTooltip.subtitle}
                text={selectIndividualsTooltip.text}
                show={showIndividualTT}
                clear={() => setShowIndividualTT(false)}
            />
            <TooltipHelp
                title={accessTooltip.title}
                subtitle={accessTooltip.subtitle}
                text={accessTooltip.text}
                show={showAccessTT}
                clear={() => setShowAccessTT(false)}
            />
            <div className="separator"></div>
        </div>
    )
}

export default PublishStory
