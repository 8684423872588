import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import default_avatar from "../../assets/images/default-avatar.jpg";
import IPage from '../../interfaces/page';
import moment from 'moment';
import i18n from "../../i18n";
import { useTranslation } from 'react-i18next';

/**
 * Local interface for user with inactive data
 */
interface IInactiveUser {
    _id:string
    avatar?:string
    firstname:string
    lastname:string
    birthdate:Date
    diedon:Date
}

/**
 * Inactive accout page for decased status users
 * @param props : unused for now
 * @returns void
 */
 const InactiveAccount: React.FunctionComponent<IPage> = props => {

    const [inactiveUser, setInactiveUser] = useState<IInactiveUser>({_id:'', firstname:'', lastname:'', birthdate:new Date(), diedon:new Date()})
    const history = useHistory();
    const { t } = useTranslation();


    useEffect(() => {
        if(props.match.params.lang) {
            i18n.changeLanguage(props.match.params.lang);
        }
        if(props.location.state) {
            const user : IInactiveUser = (props.location.state.user as IInactiveUser);
            setInactiveUser(user);
        }
        else {
            history.push('/')
        }
    }, [])

    return(
        <Container className="inactive-container">
            <p className="title">{t("Inactive Account")}</p>
            <Row>
                <Col xs={12}>
                    <p className="subtitle">{t("This account has undergone the 'deceased user' process and can not be logged into.")}</p>
                </Col>
                <Col xs={12}>
                    <img src={inactiveUser.avatar ? inactiveUser.avatar : default_avatar} className="user-picture" alt="avatar"/>
                    <p className="name">{inactiveUser.firstname} {inactiveUser.lastname}</p>
                    <p className="date">{moment(inactiveUser.birthdate).format('YYYY-MM-DD')} to {moment(inactiveUser.diedon).format('YYYY-MM-DD')}</p>
                </Col>
                <Col xs={12}>
                    <p className="middle-text">{t("If this has been done in error, and the owner of this profile is alive, proceed with reactivation this account.")}</p>
                </Col>
                <Col xs={12}>
                    <p className="tip">{t("Tip")}</p>
                    <p className="bottom-text">{t("To prevent reoccurance, visit your profile page and name a successor so only they have the authority to deactivate your account.")}</p>
                </Col>
                <div className='something-missing'>
                    <div className='container'>
                        <p>To proceed, email <a href = "mailto: info@simirity.com">info@simirity.com</a> from your Simirity account email address and our customer support team will contact you.</p>
                    </div>
                </div>
                <Link className="link-button cancel" to="/login">{t("Cancel")}</Link>
            </Row>
        </Container>
    );
};

export default InactiveAccount;