import React, { Dispatch, useCallback, useEffect } from "react"
import { Row, Col } from "react-bootstrap"
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../../../store/actionCreators";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next"

/**
 * /settings/deleteprofile page
 * 
 */

function DeleteProfile() {

    const dispatch: Dispatch<any> = useDispatch();
    const { t } = useTranslation();
    const setPageName = useCallback((currentPage: string) => dispatch(setCurrentPage(currentPage)), [dispatch]);

    useEffect(() => {
        setPageName(t("Delete Profile"));
    }, [])     

    const history = useHistory();

    return (
        <div className="settings final">
            <Row className="black">
                <Col xs={12}>
                    <p>{t("Should you wish, you can delete your profile and all data associated with it. Please consider this carefully, as this action can not be undone.")}</p>
                    <p><b>{t("Deleting your profile result in:")}</b></p>
                    <ol>
                        <li>{t("Your profile being deleted")}</li>
                        <li>{t("You will be removed from all family contact lists")}</li>
                        <li>{t("All your stories will be deleted")}</li>
                        <li>{t("You will no longer have an account or be able to login")}</li>
                        <li>{t("You will no longer be able to access your family online and their stories")}</li>
                    </ol>
                    <p><b>{t("Alternatively, consider unpublishing or deleting specific stories.")}</b></p>
                    <p>{t("To proceed, email")} <a href = "mailto: info@simirity.com">info@simirity.com</a> {t("from your Simirity account email address and our customer support team will contact you.")}</p>
                </Col>
            </Row>       
        </div>
    )
}

export default DeleteProfile
