import React, { useContext } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import logout from '../../_helper/_logout';
import default_avatar from "../../assets/images/default-avatar.jpg";
import { menu } from '../../pages/dashboard/profile.page';
import { Context } from '../../pages/home.page';
import { ESubscriptionStatus } from '../../interfaces/subscription';
import { useTranslation } from "react-i18next";
import packageJson from '../../../package.json';

const date = new Date();

/**
 * Local interface for input properties.
 */
interface IDetails {
    show:boolean,
    handleClose:Function,
}

/**
 * 
 * @param props :  interface
 * @returns void
 */
function RightSidebar(props:IDetails) {

    //Logged in user
    const userData = useContext(Context).user;
    const pageData = useContext(Context).pageData;

    const history = useHistory();

    const { t } = useTranslation();

    function navigate(route:string) {
        props.handleClose();
        history.push(route);
    }

    return(
        <div className="right-sidebar">
            <Offcanvas className="off-canvas" show={props.show} onHide={props.handleClose} backdrop={true} placement="end">
                <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="profile-details" onClick={() => { navigate('/dashboard/profile/' + localStorage.userid + "/" + menu.INFO)}}>
                        <img src={userData.avatar ? userData.avatar : default_avatar} className="user-picture" alt="Avatar" />
                        <div className="profile-text">
                            <p className="name">{userData.firstname} {userData.lastname}</p>
                            <p className="email">{userData.email}</p>
                            <p className="account-type link-button" onClick={(e) => { e.stopPropagation(); navigate('/dashboard/account/subscription/info'); }}>{userData.subscription && userData.subscription.status !== ESubscriptionStatus.INACTIVE ? userData.subscription.subscription_type.name + " " + t("Subscription") : t("Free Account")} </p>
                        </div>
                    </div>
                    <div className="sidebar-navigation-container">
                        <ul>
                            {/*<li><Link to="/dashboard/stories/1" onClick={() => props.handleClose()}>Search</Link></li>*/}
                            <li className="notification"><Link to="/dashboard/notifications" onClick={() => props.handleClose()}>{t("Notifications")}</Link>{pageData.notifications > 0 ? <span className="dot"></span> : null}</li>
                            <li><Link to={"/dashboard/profile/" + localStorage.userid + "/" + menu.INFO} onClick={() => { navigate('/dashboard/profile/' + localStorage.userid + "/" + menu.INFO)}}>{t("My Profile")}</Link></li>
                            <li><Link to="/dashboard/account/subscription/info" onClick={() => props.handleClose()}>{t("My Subscription")}</Link></li>
                            {userData.subscription && userData.subscription.status !== ESubscriptionStatus.INACTIVE && <li><Link to="/dashboard/referandsave/refer" onClick={() => props.handleClose()}>{t("Refer & Save")}</Link></li>}
                            <li><Link to="/dashboard/settings" onClick={() => props.handleClose()}>{t("Settings")}</Link></li>
                            <li><Link to="/dashboard/help" onClick={() => props.handleClose()}>{t("Help")}</Link></li>
                            <li><Link to="/dashboard/contactus" onClick={() => props.handleClose()}>{t("Contact us")}</Link></li>
                            <li><button className="logout" onClick={() => logout()}>{t("Logout")}</button></li>
                        </ul>                        
                    </div>
                    <p className="copyright">Simirity {packageJson.version}<br />{t("Copyright")} {date.getFullYear()} Simirity Ltd. <br /> {t("All rights reserved")}</p>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
};

export default RightSidebar;