import { useContext, useEffect, useState } from 'react';
import { IGroup, IUserDetails } from '../../../interfaces/authentication';
import CreateNewGroupModal from './create-new-group-modal.component';
import default_avatar from "../../../assets/images/default-avatar.jpg";
import Icon from '@mdi/react'
import { mdiAccountCircle, mdiChevronRight, mdiClose, mdiMagnify } from '@mdi/js'
import GroupDetailsModal from './group-details-modal.component';
import GroupFilters, { IGroupFilters } from './group-filters.component';
import instance from '../../../api/api';
import { Context } from '../../../pages/home.page';
import { IStory } from '../../../interfaces/story';
import IFrameDisplay from '../../_helper/iframe-display.component';
import { useTranslation } from "react-i18next";

interface IProps {
    groups:IGroup[]
    user:IUserDetails
    isAdministrator:boolean
    isMyProfile: boolean
}

export enum filterOptions {
    MY_GROUPS = "My groups",
}

export default function Groups(props:IProps) {

    const [ groups, setGroups ] = useState<IGroup[]>([]);
    // const [ selectedGroup, setselectedGroup ] = useState<IGroup>()

    //Modal states
    const [ showGroupDetails, setShowGroupDetails ] = useState<boolean>(false);
    const [ showCreateNewModal, setShowCreateNewModal ] = useState<boolean>(false);
    const [ detailGroup, setDetailGroup ] = useState<IGroup | null>(null);
    const [ groupStories, setGroupStories ] = useState<IStory[]>([]);

    const [ filters, setFilters] = useState<IGroupFilters>({text: "", option: ""});
    const [ filteredGroups, setFilteredGroups ] = useState<IGroup[]>([]);

    const loggedInUserData = useContext(Context).user;
    const updateUserData = useContext(Context).setUserDetails;

    const { t } = useTranslation();

    //Get user groups where member and administrator
    useEffect(() => {
        getGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.groups]);

    useEffect(() => {
        filterGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ filters.text, filters.option, groups ])


    async function getGroups() {
        const groups = [];

        if(props.groups.length) {
            const gorupResponse = await instance.get('/group', { params: { _id: props.groups.map(x => x._id) } });
            groups.push(...gorupResponse.data)
        }
        setGroups(groups);

        if(groups.length) {
            const storiesRes = await instance.get(`/publishedstories?accessGroups=${groups.map(x => x._id).join()}`);
            setGroupStories(storiesRes.data)
        }

    }

    function updateGroups(group:IGroup) {
        if(groups.filter(x => x._id === group._id).length) {
            const groupToUpdate = groups.find(x => x._id === group._id);
            const index = groups.findIndex(x => x._id === group._id)
            let temp = [...groups];

            if(groupToUpdate) {
                groupToUpdate.administrator = group.administrator
                groupToUpdate.description = group.description
                groupToUpdate.name = group.name
                groupToUpdate.members = group.members
                if(groupToUpdate.privateId) {
                    groupToUpdate.privateId = group.privateId
                }
                temp[index] = groupToUpdate
            }
            setGroups([...temp]);
        }
        else {
            const temp = [...groups, group]
            setGroups(temp);
        }
    }

    function deleteGroup(removedGroupId:string) {
        const newGroups = groups.filter(group => group._id !== removedGroupId)
        updateUserData({...loggedInUserData, groups:newGroups });
        setGroups(newGroups)
    }

    function enableDetailview(group:IGroup) {
        setDetailGroup(group);
        setShowGroupDetails(true);
    }

    function filterGroups() : void {

        let filteredGroups = groups.filter((group:IGroup) => group.name.match(new RegExp(filters.text, "i")) || group.description.match(new RegExp(filters.text, "i")))
        if(filters.option.length) {
            filteredGroups = filteredGroups.filter((group:IGroup) => group.administrator._id === props.user._id)
        }
        setFilteredGroups(filteredGroups);
    }

    function sortMembers(members:IUserDetails[]) : IUserDetails[] {
        const groupMemberIds = members.map(x => x._id);
        const userRelations = props.user.relations.filter(x => groupMemberIds.includes(x.userid._id)).sort((a, b) => { return new Date(b.createdAt).getTime() + new Date(a.createdAt).getTime() }).reverse().map(x => x.userid);
        return userRelations;
    }

    function checkRights () {
     
        if (detailGroup && detailGroup.administrator._id === localStorage.userid) {
            return true
        }
        /* not needed anymore since only 1 manager?
        if (detailGroup && detailGroup.administrator.administrators.find((admin:IUserDetails) => admin === localStorage.userid)) {
            return true
        }
        */
        return false
    }

    return(
        <div>
            <GroupFilters
                filters={filters}
                setFilters={setFilters}
                filteredRelationNumber={0}
            />
            <div className="active-filters">
                {filters.option !== "" ? (
                    <div className="active-filter-container">
                        <div className="content">
                            <Icon className="icon" size={1} path={ mdiAccountCircle } />
                            <p>{filters.option}</p>
                            <span onClick={() => setFilters({ ...filters, option: "" })}><Icon className="close" size={1} path={ mdiClose } /></span>
                        </div>
                    </div>
                ) : null}
                {filters.text !== "" ? (
                    <div className="active-filter-container">
                        <div className="content">
                            <Icon className="icon"  size={1} path={ mdiMagnify } />
                            <p>{filters.text}</p>
                            <span onClick={() => setFilters({ ...filters, text: "" })}><Icon className="close" size={1} path={ mdiClose } /></span>
                        </div>
                    </div>
                ) : null}
                {((filters.option || filters.text) && filteredGroups.length) ? <p className="showing-text">Showing {filteredGroups.length} group</p> : null }
            </div>
            <div className="family-groups">             
                {groups.length===0 ? <IFrameDisplay pageName={"my_profile-groups"} /> : null }                
                <p className="info-text">{t("Write stories for small groups instead of your whole family")}</p>
                {filteredGroups.map((group:IGroup) => {
                    return(
                        <div key={group._id} className="group-container" onClick={() => enableDetailview(group)}>
                            <p className="name">{group.name}</p>
                            <div className="members">
                                {sortMembers(group.members).slice(0, 4).map((member:IUserDetails) => {
                                    return(<img src={member.avatar ? member.avatar : default_avatar} className="user-picture" alt="avatar" width="20" height="20" key={member._id}/>)
                                })}
                                {group.members.length > 4  ? <p className="group-length">+{group.members.length - 4}</p> : null}
                            </div>
                            <p className="group-description">{group.description}</p>
                            <Icon size={1} path={ mdiChevronRight } />
                            <p className="manager">{t("Managed by")}: <span onClick={(e) => {e.stopPropagation(); window.location.replace('/dashboard/profile/' + group.administrator._id + "/1")}} className="m-name">{group.administrator.firstname} {group.administrator.lastname}</span></p>
                            <p className="stories">{groupStories.filter(x => (x.accessGroups as string[]).includes(group._id)).length} {t("stories")}</p>
                        </div>
                    );
                })}
                {!groups.length ? <p className="feedback">{t("You are not in any group")}</p> : null}
                {props.isMyProfile && <div className="action">
                    <p>{t("Update groups so new generations can access old stories")}</p>
                    <button className="default-button" onClick={() => setShowCreateNewModal(true)}>{t("Create new group")}</button>
                </div>}
                {props.isMyProfile && <CreateNewGroupModal updateGroups={updateGroups} show={showCreateNewModal} handleClose={() => setShowCreateNewModal(false)}/>}
                {detailGroup !== null && <GroupDetailsModal user={props.user} administrator={checkRights()} group={detailGroup} show={showGroupDetails} handleClose={() => setShowGroupDetails(false)} updateGroups={updateGroups} deleteGroup={deleteGroup}/>}
            </div>
        </div>
    )
}