/**
 * Basic enum for navigating tabs used in registration details for the 3 main pages to navigate:
 * - Optional informations: Birthdate, country etc.
 * - Connect: Family connections, pending connections
 * - Engage: Includes some helper pages to engage the user to start 
 */
export enum regDetailTabs {
    OPTIONAL_INFORMATION="1",
    CONNECT="2",
    ENGAGE="3"
}

/**
 * Basic enum for deciding the welcome engagement after registration
 * - If the user is the first in their family we use the WELCOME type
 * - If the user isn't the first we use th ENGAGE type to make sure of the app usage (new stories etc.)
 */
export enum welcomeTypes {
    EMPTY="",
    WELCOME="welcome",
    ENGAGE="engage"
}

/**
 * Profile Types
 */
export enum profileTypes  {
    ADULT="Adult",
    CHILD="Child",
    DECEASED="Deceased",
    INFO="Info"
}

/**
 * User fields
 */

export enum userFields {
    FIRSTNAME= "firstname",
    LASTNAME= "lastname",
    MIDDLENAME= "middlename",
    NICKNAME= "nickname",
    EMAIL= "email",
    PROFILETYPE= "profileType",
    COUNTRY= "country",
    STATE= "state",
    GROUPS= "groups",
    BIRTHDATE= "birthdate",
    DIEDON= "diedon",
    ADMINISTRATORS= "administrators",
    SUCCESSORS= "successors",
    SUGGESTIONS= "suggestions",
    RELATIONS= "relations",
    AVATAR= "avatar",
    WEBSITES= "websites",
    PHONE="phone"
}

export enum storyFields {
    AUTHOR= "author",
    TITLE= "title",
    PUBLISHED= "published",
    COAUTHORS= "coAuthors",
    BLOCKS= "blocks",
    DATE= "date",
    FEATURED= "featured",
    PARTICIPANTS= "participants",
    STORYTAGS= "storyTags",
    RELATED= "related",
    LOCATION= "location",
    ACCESS= "access",
    PRIVATEURL= "privateUrl",
    COMMENTS_ENABLED= "commentsEnabled"
}

/**
 * Basic enum for setting multiple locale supported date format across the application
 */
 export enum dateFormat {
    SHORT="ll",
    CUSTOM="D MMM, YYYY",
    ONLY_YEAR="YYYY",
    YEAR_MONTH="MMM, YYYY",
    MONTH_DAY="MMM, DD"
}