import React from "react"
import { IUserDetails } from "../../interfaces/authentication"
import default_avatar from "../../assets/images/default-avatar.jpg"

interface IProps {
    admins: IUserDetails[]
}

function DeceasedNonAdmin(props: IProps) {
    return (
        <div className="deceased-nonadmin">
            <div className="top-content">
                <p className="content-title">We are so very sorry to hear of your loss.</p>
                <p>Please contact one of the following people who have been authorised to complete this process.</p>
            </div>
            <div className="profiles">
                {props.admins.map((admin) => {
                    return (
                        <div className="profile-item">                       
                            <a className="underlined-link-button" href={`/dashboard/profile/${admin._id}/2`}><img className="user-picture" src={admin.avatar ? admin.avatar : default_avatar} alt="" />{`${admin.firstname} ${admin.lastname}`}</a>                            
                        </div>
                    )
                })}
                {!props.admins.length && (<p>No one has been authorised to perform this action. Please email <a href = "mailto: info@simirity.com">info@simirity.com</a> with at least two of this person’s family contacts in CC, to proceed with this update.</p>)}
            </div>
        </div>
    )
}


export default DeceasedNonAdmin
