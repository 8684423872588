import { useContext } from "react"
import { ITooltip } from "../../interfaces/notification"
import { Modal } from "react-bootstrap"
import Markdown from "markdown-to-jsx";
import DOMPurify from "dompurify";
import { Context } from "../../pages/home.page";
import { useTranslation } from 'react-i18next';

/**
 * Saved user load from cookie
 * @param props : ISavedUser interface
 * @returns void
 */
function TooltipHelp(props: ITooltip) {

    const whatSThis = useContext(Context).whatSThis;

    const { t } = useTranslation();

    return (
        <Modal className="tooltip-container" show={props.show} onHide={props.clear}>
            <Modal.Header className="tooltip-header" closeButton>
                <Modal.Title className="title">{props.contextKey ? t("What's this?") : props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="tooltip-content">
                <p className="subtitle">{(props.contextKey && whatSThis[props.contextKey]) ? DOMPurify.sanitize(whatSThis[props.contextKey].subtitle) : props.subtitle}</p>
                <div className="body">
                    {(props.contextKey && whatSThis[props.contextKey]) ? 
                        <Markdown>
                            {DOMPurify.sanitize(whatSThis[props.contextKey].content)}
                        </Markdown>
                    : props.text ? props.text() : null}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default TooltipHelp
