import React, { useState, useEffect, useContext } from "react"
import { Modal, Spinner, Tab, Tabs } from "react-bootstrap"
import { Row, Col } from "react-bootstrap"
import default_avatar from "../../assets/images/default-avatar.jpg"
import Icon from '@mdi/react';
import { mdiAccountCircle, mdiPlusCircle, mdiDotsHorizontal, mdiClose, mdiMagnify, mdiClockOutline } from '@mdi/js';
import AddToYourFamily from "../family/add-to-family.component"
import PendingInvitations from "../family/pending-invitations.component"
import { IInvitation, IRelation, IPendingRelationDB } from "../../interfaces/family"
import ProfileAction, { UserRelationStatus } from "./profile-action-modal.component"
import { IUserDetails } from "../../interfaces/authentication"
import Groups from "./groups/profile-groups.component"
import instance from "../../api/api"
import FamilyFilters, { ProfileTypes, IFilters } from "./family-filters.component"
import RelationshipPicker from "../family/relationship-picker.component"
import { profileTypes } from "../../_helper/enum/enum"
import Notification, { notificationImages } from "../notifications/notification.component"
import { INotification, NotificationType } from "../../interfaces/notification"
import { Context } from "../../pages/home.page"
import AddToTheirFamilyList from "./profile-actions/add-to-their-family.component"
import { useHistory } from "react-router-dom"
import Help from "../_helper/help.component"
import { useTranslation } from "react-i18next";
import IFrameDisplay from "../_helper/iframe-display.component";

enum menu {
    PROFILES = "1",
    GROUPS = "2",
}

interface IProps {
    user: IUserDetails
    administrator: boolean
    myProfile: boolean
    adminPending: string[]
    setAdminPending: Function
}

interface IData {
    name: string
    type: UserRelationStatus
    data: IRelation | IPendingRelationDB | IUserDetails | IInvitation
}

function ProfileFamily(props: IProps) {

    const { t } = useTranslation();
    
    const FamilyNotifications = {
        default: { icon:notificationImages.happiness, title:"", text:"", success:NotificationType.success },
        newRelationSuccess: { icon:notificationImages.happiness, title:t("Success"), text:t("You have a new relation"), success:NotificationType.success },
        newPendingSuccess: { icon:notificationImages.happiness, title:t("Success"), text:t("New pending request is created"), success:NotificationType.success },
        removedSuccess: { icon:notificationImages.like, title:t("Success"), text:t("This relation is removed from your family"), success:NotificationType.success },
        changeRelationshipSuccess: { icon:notificationImages.happiness, title:t("Success"), text:t("The relationship is modified"), success:NotificationType.success },
    }  

    // Modal actions
    const [showActionModal, setShowActionModal] = useState(false);
    const [show, setShow] = useState<boolean>(false);

    // notification
    const [ showNotification, setShowNotification ] = useState<boolean>(false);
    const [notification, setNotification] = useState<INotification>(FamilyNotifications.default)

    //Action with user
    const [selectedRelation, setSelectedRelation] = useState<IRelation | IPendingRelationDB | IInvitation>();
    const [userRelationType, setUserRelationStatus] = useState<UserRelationStatus>(UserRelationStatus.PENDING);

    // profile type filters
    const [ filters, setFilters] = useState<IFilters>({nameFilter: "", profileTypeFilter: ProfileTypes.ACTIVE });

    // relations after filtering --> displayed relations
    const [ userAllTypeRelations, setUserAllTypeRelations ] = useState<IData[]>([]);
    const [ content, setContent ] = useState<any[]>([]);
    const [ loaded, setLoaded ] = useState<boolean>(false);

    //Admin related
    const [ adminRelations, setAdminRelations ] = useState<string[]>([]);
    const [ showAddRelation, setShowAddRelation ] = useState<boolean>(false);
    const [ selectedUser, setSelectedUser] = useState<IUserDetails>();
    const [ newRelation, setNewRelation ] = useState<string>('');
    const [ showAddToTheirFamily, setShowAddToTheirFamily ] = useState<boolean>(false);

    //Logged in user
    const loggedInUserData = useContext(Context).user;
    const history = useHistory();  

    useEffect(() => {
        if(props.user._id) {
            getPendingsAndInvitations();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user])

    useEffect(() => {
        displayAllTypesRelations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ filters, userAllTypeRelations, props.adminPending ])

    async function getPendingsAndInvitations() {

        //Get admin relations to be able to suggest new connections;
        if(!props.myProfile) {
            let relationIds = loggedInUserData.relations.map((relation:IRelation) => {
                return relation.userid._id
            })
            relationIds.push(loggedInUserData._id);

            setAdminRelations(relationIds);
        }
        try {
            /**
             * Get user specific data
             */
            let suggestionsRes = (props.myProfile || props.administrator) ? await instance.get(`/user/suggestions/${props.user._id}`) : null;
            let pendingToRes = (props.myProfile || props.administrator) ? await instance.get(`/relation/pending?to=${props.user._id}`) : null;
            let pendingFromRes = (props.myProfile || props.administrator) ? await instance.get(`/relation/pending?from=${props.user._id}`) : null;
            let invitationsRes = (props.myProfile || props.administrator) ? await instance.get(`/invitation?inviter=${props.user._id}`) : null;

            /**
             * Refactor data to be able to use it normally
             */

            // We don't want to expose user _ids of people who the loggedin user hasn't had any contact with yet.
            // In these cases, _id is not returned from API, therefore, we need a temporary, component level id
            // so that we can manage i.e. user removal from screen.
            let componentLevelUserIdCounter = 1;
            
            let refactoredRelations:IData[] = [];

            let refactoredSuggestions:IData[] = suggestionsRes ? suggestionsRes.data.map((userDetails:IUserDetails) => {
                if(!userDetails._id) {
                    userDetails._id = (componentLevelUserIdCounter++).toString();
                }
                return {name:`${userDetails.firstname} ${userDetails.lastname}`, type:UserRelationStatus.SUGGESTION, data:userDetails};
            }) : []
 
            let refactoredPendingTo:IData[] = pendingToRes ? pendingToRes.data.map((relation:IPendingRelationDB) => {
                return {name:`${relation.from.firstname} ${relation.from.lastname}`, type:UserRelationStatus.PENDING, data:relation};
            }) : []

            let refactoredPendingFrom:IData[] = pendingFromRes ? pendingFromRes.data.map((relation:IPendingRelationDB) => {
                return {name:`${relation.to.firstname} ${relation.to.lastname}`, type:UserRelationStatus.SENT, data:relation};
            }) : []

            let refactoredInvitations:IData[] = invitationsRes ? invitationsRes.data.map((invitation:IInvitation) => {
                return {name:`${invitation.email}`, type:UserRelationStatus.INVITATION, data:invitation};
            }) : []

            /**
             * Refactor user relations
             */
            let userPos:number = 0;
            let userActiveRelations:IData[] = props.user.relations.map((relation:IRelation, index:number) => {
/*
                if(props.user.birthdate) {
                    if(relation.userid.birthdate) {
                        if(props.user.birthdate < relation.userid.birthdate) {
                            userPos = index;
                        }
                    }
                }
*/                
                return {name:`${relation.userid.firstname} ${relation.userid.lastname}`, type:UserRelationStatus.ACTIVE, data:relation};
            })
/*
            const refactoredUser:IData = {name:`${props.user.firstname} ${props.user.lastname}`, type:UserRelationStatus.SELF, data:props.user};

            if(userPos) {
                userActiveRelations.splice(userPos, 0, refactoredUser);
            }
            else {
                userActiveRelations.push(refactoredUser);
            }
*/
            /**
             * Insert user relations into the middle, pending to the front, and sent to the back
             */
            refactoredRelations = [...refactoredSuggestions, ...refactoredPendingTo, ...userActiveRelations, ...refactoredPendingFrom, ...refactoredInvitations];
            setUserAllTypeRelations(refactoredRelations);
            setLoaded(true);

        } catch (error) {
            // TODO: error handling
            console.log("error", error);
        }

    }

    function displayAllTypesRelations() {
        const result:any = [];
        userAllTypeRelations.forEach((relation:IData) => {
            if(!filterName(relation.name)) {
                return
            }

            switch(relation.type) {
                case UserRelationStatus.PENDING:
                    if(!props.myProfile && !props.administrator) {
                        break
                    }

                    if(filters.profileTypeFilter === ProfileTypes.INACTIVE ) {
                        break
                    }
                    const pendings = (relation.data as IPendingRelationDB)
                    result.push(
                        <PendingInvitations userid={props.user._id} myProfile={props.myProfile} profileType={props.user.profileType} administrator={props.administrator} relation={pendings} type={UserRelationStatus.PENDING} acceptRelation={acceptRelation} declineRelation={declineRelation} />
                    )
                    break
                case UserRelationStatus.SUGGESTION:
                    if(!props.myProfile && !props.administrator) {
                        break
                    }

                    if(filters.profileTypeFilter === ProfileTypes.INACTIVE ) {
                        break
                    }
                    const suggestions = (relation.data as IPendingRelationDB)
                    result.push(
                        <PendingInvitations userid={props.user._id} myProfile={props.myProfile} profileType={props.user.profileType} administrator={props.administrator} relation={suggestions} type={UserRelationStatus.SUGGESTION} acceptRelation={acceptRelation} declineRelation={declineRelation}/>
                    )
                    break
                /**
                 * Actual user relation
                 */
                case UserRelationStatus.ACTIVE:
                    const rActiveRelation = (relation.data as IRelation)
                    if(filters.profileTypeFilter === ProfileTypes.INACTIVE) {
                        if((relation.data as IRelation).userid.profileType !== profileTypes.DECEASED) {
                            break
                        }
                    }
                    if(filters.profileTypeFilter === ProfileTypes.ACTIVE) {
                        if((relation.data as IRelation).userid.profileType === profileTypes.DECEASED) {
                            break
                        }
                    }
                    result.push(displayOneRelation(rActiveRelation));

                    break
                /**
                 * User sent these invitations to other people email
                 */
                case UserRelationStatus.INVITATION:
                    if(filters.profileTypeFilter === ProfileTypes.INACTIVE ) {
                            break
                    }
                    const rInvitation = (relation.data as IInvitation)
                    if(!props.myProfile && !props.administrator) {
                        break
                    }
                    result.push(
                        <Row key={rInvitation._id} className="family-member">
                          <Col xs={10} className="member-detail">
                              <div className="img-container">
                                  <img src={default_avatar} className="user-picture" alt="" />
                              </div>
                              <div className="content">
                                  <p>{rInvitation.email}</p>
                                  <p className="invitation-info"><Icon className="icon" size={1} path={ mdiClockOutline } />{t("Invitation sent")}</p>
                              </div>
                          </Col>
                          <Col xs={2} className="menu-btn">
                              <span onClick={() => setUserAction(rInvitation, UserRelationStatus.INVITATION)}><Icon className="icon" size={1} path={ mdiDotsHorizontal } /></span>
                          </Col>
                        </Row>
                    )
                    break;
                /**
                 * User sent these to other people
                 */
                case UserRelationStatus.SENT:
                    if(filters.profileTypeFilter === ProfileTypes.INACTIVE ) {
                        break
                    }
                    const rRelation = (relation.data as IPendingRelationDB)
                    if(!props.myProfile && !props.administrator) {
                        break
                    }
                    result.push(
                        <Row key={rRelation._id} className="family-member">
                          <Col xs={10} className="member-detail">
                              <div className="img-container">
                                  {rRelation.to && <img className="user-picture" src={rRelation.to.avatar ? rRelation.to.avatar : default_avatar} alt="" />}
                              </div>
                              <div className="content">
                                  {rRelation.to && <p className="name">{`${rRelation.to.firstname} ${rRelation.to.lastname}`}</p>}
                                  <p className="invitation-info"><Icon className="icon" size={1} path={ mdiClockOutline } />{t("Pending confirmation")}</p>
                              </div>
                          </Col>
                          <Col xs={2} className="menu-btn">
                              <span onClick={() => setUserAction(rRelation, UserRelationStatus.PENDING)}><Icon className="icon" size={1} path={ mdiDotsHorizontal } /></span>
                          </Col>
                        </Row>
                    );
                    break;
                /**
                 * Self, it' Me in the list
                 */
                case UserRelationStatus.SELF:
                    if(filters.profileTypeFilter === ProfileTypes.INACTIVE ) {
                        break
                    }
                    result.push(
                        <Row  className="family-member own-profile">
                            <Col xs={12} className="member-detail">
                                <div className="img-container">
                                    <img className="user-picture" src={props.user.avatar ? props.user.avatar : default_avatar} alt="" />
                                </div>
                                <div className="content">
                                    <p className="name">{`${props.user.firstname} ${props.user.lastname}`}</p>
                                    <p className={props.myProfile ? 'unqiue-name-border' : 'unqiue-name-border-profile'}>{props.myProfile ? t("You"): t("Profile")}</p>
                                </div>
                            </Col>
                        </Row>
                    );
                    break
                default:
                    break
            }
        })
        setContent(result);
    }

    // function for the name search filtering
    function filterName(name:string): boolean {

        //Simplify data
        let fullName = name.split(" ");

        let sFirstname = fullName[0].toLowerCase();
        let sLastname = "";

        /**
         * Check for email instead of name
         */
        if(fullName.length > 1) {
            sLastname = fullName[1].toLowerCase();
        }
        let sFilter = filters.nameFilter.toLowerCase();

        return (`${sFirstname} ${sLastname}`).includes(sFilter) || (`${sLastname} ${sFirstname}`).includes(sFilter)
    }

    function setUserAction(relation:IRelation | IPendingRelationDB | IInvitation, relationType: UserRelationStatus) {
        setSelectedRelation(relation);
        setUserRelationStatus(relationType);
        setShowActionModal(true);
    }

    function acceptRelation(createdRelation: IRelation | IPendingRelationDB, removedId: string, relationStatus: UserRelationStatus) {
        // remove the pending request from the relations
        const newRelations: IData[] = userAllTypeRelations.filter(relation =>  relation.data._id !== removedId)

        // the displayed name depends on the status of the created relations
        let name: string = ""
        if (relationStatus === UserRelationStatus.SENT) {
            name = `${(createdRelation as IPendingRelationDB).to.firstname} ${(createdRelation as IPendingRelationDB).to.lastname}`
        } else {
            name = `${(createdRelation as IRelation).userid.firstname} ${(createdRelation as IRelation).userid.lastname}`
        }

        // create new data of the created relation
        const newRelation: IData = {
            name: name,
            type: relationStatus,
            data: createdRelation
        }

        if (relationStatus === UserRelationStatus.SENT) {
            // if the new relation is a pending relation, the position is before the first invitations.
            var firstInvitationPos = newRelations.findIndex(rel => rel.type === UserRelationStatus.INVITATION);
            
            if (firstInvitationPos !== -1) {
                newRelations.splice(firstInvitationPos, 0, newRelation);                
            } else {
                // If there is not invitation: the new pending relation goes onto the end of the list
                newRelations.concat(newRelation)
            }

            setNotification(FamilyNotifications.newPendingSuccess)
            setShowNotification(true)
        } else {
            // if the new relation is an active relation, it has to be on the correct position in the list (sorted by the birthdate)
            const birthdate = (newRelation.data as IRelation).userid.birthdate
            let userPos:number | null = null;
            let lastPos:number = 0;

            if(birthdate) {
                newRelations.forEach((relation:IData, index:number) => {
                    if(relation.type === UserRelationStatus.ACTIVE) {
                        lastPos++;

                        let currentBirthDate = (relation.data as IRelation).userid.birthdate;
                            if(currentBirthDate) {
                                if(birthdate < currentBirthDate) {
                                    userPos = index;
                                }
                            }
                    }
                })
            }

            if(userPos !== null) {
                newRelations.splice(userPos, 0, newRelation);
            }
            else {
                newRelations.splice(lastPos, 0, newRelation);
            }

            setNotification(FamilyNotifications.newRelationSuccess)
            setShowNotification(true)
        }

        setUserAllTypeRelations([...newRelations])
    }

    function declineRelation(id: string) {
        // remove relation by id from userAllTypeRelations
        const newRelations = userAllTypeRelations.filter(relation => relation.data._id !== id)
        setUserAllTypeRelations(newRelations)
        setNotification(FamilyNotifications.removedSuccess)
        setShowNotification(true)
        window.scrollTo(0, 0)
    }

    function updateRelationship(updatedRelation: IRelation) {
        // find the index of the modified relation
        const relationIndex = userAllTypeRelations.findIndex(rel => rel.data._id === updatedRelation._id);
        // console.log("cs-updatedRelation:", updatedRelation, relationIndex)
        // create the new, updated relation data
        const newRelationData: IData = {
            name: userAllTypeRelations[relationIndex].name,
            type: userAllTypeRelations[relationIndex].type,
            data: updatedRelation
        }
        let newRelationList = [...userAllTypeRelations]
        // modify the relation field in the list
        newRelationList[relationIndex] = newRelationData

        setUserAllTypeRelations(newRelationList)
        setNotification(FamilyNotifications.changeRelationshipSuccess)
        setShowNotification(true)
    }

    // function to display one relation
    function displayOneRelation(relation:IRelation) {
        return (
            <Row key={relation._id} className={"family-member " + relation.userid.profileType.toString().toLowerCase()}>
                <Col xs={10} className="member-detail" onClick={(adminRelations.includes(relation.userid._id) || props.myProfile) ? () => history.push('/dashboard/profile/' + relation.userid._id + "/1") : undefined} >
                    <div className="img-container">
                        {relation.userid && <img className="user-picture" src={relation.userid.avatar ? relation.userid.avatar : default_avatar} alt="" />}
                    </div>

                    <div className="content">
                        <p className="name">{`${relation.userid.firstname} ${relation.userid.lastname}`}</p>
                        <p className="relation">{relation.type === "-" ? "" : t(relation.type)}{!props.myProfile ? relation.userid._id === localStorage.userid ? <span className="unqiue-name-border with-relation">You</span> : null : null}
                            <span>{(relation.userid && relation.userid.profileType === profileTypes.DECEASED && relation.userid.birthdate && relation.userid.diedon
                                ?` (${new Date(relation.userid.birthdate).getFullYear()}-${new Date(relation.userid.diedon).getFullYear()})`
                                : "")}</span>
                        </p>
                    </div>
                </Col>
                <Col xs={2} className="menu-btn">
                    {(!adminRelations.includes(relation.userid._id) && !props.myProfile && !props.administrator && !props.adminPending.includes(relation.userid._id)) ?
                        <div className="connect" onClick={() =>{ setSelectedUser(relation.userid); setShowAddRelation(true);}}>
                            <Icon size={1.4} path={ mdiPlusCircle  } /> 
                            <p>{t("Connect")}</p>
                        </div>
                    : props.adminPending.includes(relation.userid._id) ? 
                        <div className="connect sent">
                            <Icon className="icon" size={1.5} path={ mdiClockOutline } />
                            <p>{t("Request sent")}</p>
                        </div>
                    : relation.userid._id !== localStorage.userid && (props.myProfile || props.administrator) ?
                    <span onClick={() => setUserAction(relation, relation.userid.profileType === profileTypes.DECEASED ? UserRelationStatus.DECEASED : UserRelationStatus.ACTIVE)}><Icon className="icon" size={1} path={ mdiDotsHorizontal } /></span>
                    : null}
                </Col>
            </Row>
        )
    }

    async function sendContactRequest() {

        if(!selectedUser) {
            return
        }

        try {
            const data = {
                from:loggedInUserData._id,
                to:selectedUser._id,
                relationType:newRelation,
                suggestions:[]
            }
    
            await instance.post('/relation/pending', data);

            props.setAdminPending((prev:string[]) => [...prev, selectedUser._id]);
            setShowAddRelation(false);
        }
        catch(error) {
            console.log('Failed to create new pending relation', error)
        }
    }

    return (
        <div className="family-container">
            <Tabs className="family-tabs" defaultActiveKey={menu.PROFILES}>
                <Tab eventKey={menu.PROFILES} title={t("Profiles")} className={props.administrator ? "admin-profile-tab admin-profile-subtab" : ""}>
                <div>
                    <FamilyFilters
                        filters={filters}
                        setFilters={setFilters}
                        filteredRelationNumber={content.length}
                    />
                    <div className="active-filters">
                        {filters.profileTypeFilter !== "" ? (
                            <div className="active-filter-container">
                                <div className="content">
                                    <Icon className="icon" size={1} path={ mdiAccountCircle } />
                                    <p>{t(filters.profileTypeFilter)}</p>
                                    <span onClick={() => setFilters({ ...filters, profileTypeFilter: "" })}><Icon className="close" size={1} path={ mdiClose } /></span>
                                </div>
                            </div>
                        ) : null}
                        {filters.nameFilter !== "" ? (
                            <div className="active-filter-container">
                                <div className="content">
                                    <Icon className="icon"  size={1} path={ mdiMagnify } />
                                    <p>{filters.nameFilter}</p>
                                    <span onClick={() => setFilters({ ...filters, nameFilter: "" })}><Icon className="close" size={1} path={ mdiClose } /></span>
                                </div>
                            </div>
                        ) : null}
                        {content.length ? <p className="showing-text">{t("Showing")} {content.length} {t("people")}</p> : null}
                        {!content.length && loaded ? <div>
                                <p className="showing-text">{t("Showing")} {content.length} {t("people")}</p>
                                <IFrameDisplay pageName={"my_profile-profiles"} />
                            </div> : null}                        
                    </div>
                    {showNotification ? <Notification data={notification} close={() => setShowNotification(false)} /> : null}
                    {!props.myProfile ? <p className="relation-info">{t("Relationships are relative to")} {props.user.firstname}</p> : null}
                    {props.administrator && <div className="admin-notification admin-access-notification">
                        <p className="access-text" ><span className="access-bold">{t("Admin Access")}</span> - {t("You can edit family on their behalf")}</p>
                    </div>}
                    <div className="family-relations">
                        <div className="family-members">
                            {loaded ? content : <div className="spinner-container"><Spinner animation="border" variant="#fff" /></div>}
                        </div>
                        {props.myProfile ?
                            <div className="action">
                                <button className="default-button" onClick={() => setShow(true)}>{t("Add to family list")}</button>
                                <p className="subtext">{t("Invite others, connect to Simirity users or create new profiles")}</p>
                            </div> :
                            <div className="action">
                                <p className="subtext missing">{t("Someone missing?")}</p>
                                <button className="default-button" onClick={() => setShowAddToTheirFamily(true)}>{t("Add to {{var}}'s Family List", {"var": props.user.nickname ? props.user.nickname : props.user.firstname})}</button>
                            </div>}
                        {/*(props.myProfile || props.administrator) ? <div className="switch-wrapper">
                            <Row>
                                <Col>
                                    <div className="private-profiles switch-container">
                                        <span>Private Family Profiles</span>
                                        <Switch
                                            offColor="#17243d"
                                            offHandleColor="#8B919E"
                                            onColor="#006238"
                                            onHandleColor="#006238"
                                            handleDiameter={20}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={14}
                                            width={32}
                                            onChange={(checked) => console.log("private family profiles")}
                                            checked={false}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p className="subtext">Your family members are visible only to you</p>
                                </Col>
                            </Row>
                        </div> : null*/}
                    </div>
                </div>
                <Help category={"FAMILY"} pageName={"Profiles"}></Help> 
                </Tab>
                {props.myProfile && <Tab eventKey={menu.GROUPS} title={t("Groups")}>
                    <Groups groups={props.user.groups} user={props.user} isAdministrator={props.administrator} isMyProfile={props.myProfile}/>
                    <Help category={"FAMILY"} pageName={"Groups"}></Help>
                </Tab>}
            </Tabs>

            {selectedRelation ?
                <ProfileAction
                    userid={props.user._id}
                    userRelationType={userRelationType}
                    relation={selectedRelation}
                    show={showActionModal}
                    handleClose={() => setShowActionModal(false)}
                    myProfile={props.myProfile}
                    administrator={props.administrator}
                    declineRelation={declineRelation}
                    updateRelationship={updateRelationship}/>
            : null}
            <AddToYourFamily show={show} handleUpdateConnections={() => {}} handleClose={() => { setShow(false) }} />

            <Modal className="connect-modal" show={showAddRelation} onHide={() => {setShowAddRelation(false); setNewRelation('')}}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Connect")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img className="user-picture" alt="avatar user" src={selectedUser ? selectedUser.avatar ? selectedUser.avatar : default_avatar : default_avatar}/>
                    <p className="name">{selectedUser ? selectedUser.firstname + " " + selectedUser.lastname : ''}</p>
                    <p className="relation">{t("is your")}...</p>
                    <RelationshipPicker relationError={false} relation={newRelation} handleAction={(relation:string) => setNewRelation(relation)}/>
                    <button className="default-button" disabled={newRelation === ""} onClick={() => sendContactRequest()}>{t("Send contact request")}</button>
                </Modal.Body>
            </Modal>
            
            <AddToTheirFamilyList showAddToTheirFamily={showAddToTheirFamily} setShowAddToTheirFamily={setShowAddToTheirFamily} user={props.user}/>
        </div>
    )
}

export default ProfileFamily
