import React, { useContext, useEffect, useState } from 'react';
import { Tab, Tabs, Modal, Form, Spinner } from 'react-bootstrap';
import instance from '../../api/api';
import CreateNewProfile from './create-new-profile.component';
import { IUserDetails } from '../../interfaces/authentication';
import default_avatar from "../../assets/images/default-avatar.jpg";
import ExistingProfile from './existing-profile.component';
import { IOption, IPendingRelation, IRelation } from '../../interfaces/family';
import Icon from '@mdi/react';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import { Context } from '../../pages/home.page';
import Help from '../_helper/help.component';
import Carousel from "react-multi-carousel";
import * as EmailValidator from 'email-validator';
import { INotification, NotificationType } from "../../interfaces/notification";
import Notification, { notificationImages } from "../../components/notifications/notification.component";
import { profileTypes } from '../../_helper/enum/enum';
import { useTranslation } from "react-i18next";
import config from '../../config/config';

enum menu {
    STEP1="1",
    STEP2="2",
    STEP3="3"
}

interface IProps {
    show:boolean
    handleUpdateConnections: () => void    
    handleClose: () => void
}

export const alreadyConnectedNotificationData : INotification = {
    icon:notificationImages.neutral,
    title:"Connection Info",
    text:"Already on your family contact list",
    success: NotificationType.info
}

/**
 *
 * @param props :  interface
 * @returns void
 */
function AddToYourFamily(props:IProps) {

    const userData = useContext(Context).user;

    const [ email, setEmail ] = useState<string>('');
    const [ avalibleConnections, setAvalibleConnections ] = useState<IUserDetails[]>([]);
    const [ menuStatus, setMenuStatus ] = useState<string>(menu.STEP1);
    const [ selected, setSelected ] = useState<IUserDetails | null>(null);
    const [ familymembers, setFamilyMembers ] = useState<IOption[]>([]);
    const [ suggestions, setSuggestions ] = useState<IUserDetails[]>([]);
    const [ suggestionsLoaded, setSuggestionsLoaded ] = useState<boolean>(false);

    const [ alreadyConnectedNotification, setAlreadyConnectedNotification ] = useState<boolean>(false);

    const [copied, setCopied] = useState<boolean>(false);
    const [joiningMessage, setJoiningMessage ] = useState<string>(`I’ve just started using www.simirity.com and it would be great if you joined me. \r\nIt’s a social media app where we can privately share life stories today while preserving memories for tomorrow. \r\nClick on the link below to learn more and create a free account. \r\nFind me in the app using my email address: \r\n${userData.email}`);

    //NewProfile
    const [ profileType, setProfileType ] = useState<string>('new');
    const [ forcedProfileType, setForcedProfileType ] = useState<profileTypes | null>(null);

    //notifications
    const [ notification, setNotification ] = useState<INotification>({icon:"", title:"", text:"", success:NotificationType.problem});

    const { t } = useTranslation();

    useEffect(() => {
        if(userData._id.length && props.show) {
            getSuggestions();
            // TODO: LOW: find out better way to know when both getSuggestions and filterPending finished
            const timeOutId = setTimeout(() => setSuggestionsLoaded(true), 3000);
            return () => clearTimeout(timeOutId);
        } else {
            setSuggestionsLoaded(true)
        }
        if(!props.show) {
            setSuggestions([]);
        }
    }, [userData, props.show])

    function copyHelper() {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, config.modalCloseTimeout)
    }    

    async function getSuggestions() {
        try{
            setSuggestions([]);
            const userRelationUsers = [...userData.relations.filter(x => x.group==="FAMILY").map(x => x.userid._id), userData._id];
            userData.relations.forEach(async (relation:IRelation) => {

                // don't show any family members of ‘friends’
                if(relation.group!=="FAMILY") return;

                // relation.userid._id is fine since we know these guys already
                const userResponse = await instance.get(`/user/${relation.userid._id}`);

                if(userResponse.data) {               

                    // TODO: medium: but we don't know these yet, userid._id should not be public there:
                    // don't show any ‘friends’ of the family members                 
                    const notConnected = userResponse.data.relations.filter((x:IRelation) => !userRelationUsers.includes(x.userid._id) && x.group!=="FRIENDS");

                    // filter out IUserDetails that are already among suggestions from previous forEach iteration and then merge with this iteration
                    setSuggestions(prev => {return [...prev, ...notConnected.map((x:IRelation) => x.userid).filter((x:IUserDetails) => !prev.map(x => x._id).includes(x._id))]})           
                }
            })

            // by now we have all suggestions collected from all our family members, it is time to remove the ones with which we already have pending requests
            filterPending();
        }
        catch(error) {
            console.log('Failed to get suggestions')
        }
    }

    async function filterPending() {

        const pendingRelationResFrom = await instance.get('/relation/pending?from=' + userData._id);
        const pendingRelationResTo = await instance.get('/relation/pending?to=' + userData._id);
        
        const pendingIds = [...pendingRelationResTo.data.map((x: IPendingRelation) => (x.from as IUserDetails)._id), ...pendingRelationResFrom.data.map((x: IPendingRelation) => (x.to as IUserDetails)._id)];

        setSuggestions(prev => {return [...prev.filter(x => !pendingIds.includes(x._id))]})

    }

    async function searchConnections() {
        try {

            //Email validation
            if(!EmailValidator.validate(email)) {
                setNotification({...notification, icon:notificationImages.crying, title:t("Error"), text:t("Invalid e-mail format")});
                return
            }        

            const encodedEmail = encodeURIComponent(email);
            let response = await instance.get('/user?email=' + encodedEmail);

            setAvalibleConnections(response.data);
            if(response.data.length) {
                setSelected(response.data[0])
                setProfileType('existing');
            }
            else {
                setProfileType('new');
            }
            setFamilyMembers(userData.relations.filter(x => x.group==="FAMILY").map((relation:IRelation) => { return { _id:relation.userid._id, text: `${relation.userid.firstname} ${relation.userid.lastname}`, img:'' }}));

            if(response.data.length) {
                setMenuStatus(menu.STEP3);
            }else {
                setMenuStatus(menu.STEP2);
            }

            setForcedProfileType(null);
        }
        catch(error:any) {
            console.log('error', error.response)

            if(error.response.status === 409) {
                setEmail('');
                setAlreadyConnectedNotification(true);
            }
            
        }
    }

    function handleSuggestion(data:IUserDetails){
        setSelected(data);
        setFamilyMembers(userData.relations.filter(x => x.group==="FAMILY").map((relation:IRelation) => { return { _id:relation.userid._id, text: `${relation.userid.firstname} ${relation.userid.lastname}`, img:'' }}));        
        setProfileType('existing'); 
        setMenuStatus(menu.STEP3);
    }

    function handleFinish(addMore:boolean) {
        setEmail('');
        setAvalibleConnections([]);
        setMenuStatus(menu.STEP1);
        setSelected(null);
        if(!addMore) {
            props.handleClose();
        }
    }

    function updateConnections() {
        props.handleUpdateConnections();
    }

    function resetNotification() {
        setNotification({icon:"", title:"", text:"", success:NotificationType.problem});
    }    

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 7
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 6
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 5
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 4
        }
    };

    return(

        <Modal className="add-to-family-modal" show={props.show} onHide={() => handleFinish(false)} backdrop={window.innerWidth <= 760 ? true : "static"}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <div className="add-to-family-container">
                <p className="title">{t("Add to family list")}</p>
                <Tabs activeKey={menuStatus}>
                    <Tab eventKey={menu.STEP1} title={t("STEP 1")}>
                        {userData._id && props.show ?
                            <div className='suggestion-container'>
                                <h3 className="subtitle">{t("Suggestions")}</h3>
                                {suggestions.length && suggestionsLoaded ?
                                <div>
                                    <p className="gray">{t("Click to add")}</p>
                                    <div className="suggestions">
                                        <Carousel responsive={responsive} arrows={false}>
                                            {suggestions.map((data) => {
                                                return(
                                                    <div className="suggestion noselect" onClick={() => handleSuggestion(data)}>
                                                        <img alt="img" className="user-picture" src={data.avatar ? data.avatar : default_avatar}/>
                                                        <p>{data.firstname}</p>
                                                    </div>);
                                            })}
                                        </Carousel>
                                    </div>
                                </div> : !suggestionsLoaded ?
                                <div>
                                    <p className="gray">{t("Loading...")}</p>
                                    <Spinner animation="border" variant="#fff" />
                                </div> : 
                                <div className="none">
                                    <p className="gray">{t("None available")}</p>
                                </div>}
                            </div> : null}
                        <div className="modal-content-inside">
                            <div className="subtitle-wrapper">
                                <h3 className="subtitle">{t("Connect using email")}</h3>
                                <p className="gray">{t("Enter one email address")}</p>

                            </div>
                            <div className="group">
                                <input placeholder={t("Email")} value={email} onChange={(evt) => {setEmail(evt.target.value);resetNotification()}}></input>
                                <label className="top-label">{t("Email")}</label>
                            </div>
                            <div className="btn-container">
                                <button disabled={!email.length} className="default-button button-space-below" onClick={searchConnections}>{t("NEXT STEP")} <Icon size={1} path={ mdiChevronRight } /></button>
                                {alreadyConnectedNotification ? <Notification data={alreadyConnectedNotificationData} close={() =>setAlreadyConnectedNotification(false)}/> : null}

                                <button className="link-button button-space no-bottom" onClick={() => {setMenuStatus(menu.STEP2);setForcedProfileType(profileTypes.INFO)}}>{t("Send joining message to many")}</button>                                
                                <button className="link-button button-space no-bottom" onClick={() => {setMenuStatus(menu.STEP3);setForcedProfileType(profileTypes.DECEASED)}}>{t("Create Deceased Profile")}</button>                                
                            </div>
                        </div>
                        {notification.title.length ?
                            <Notification data={notification} close={() => resetNotification()}/>
                        : null}                        
                        <Help category={"FAMILY"} pageName={"Add to Family List - Step 1"}></Help>                       
                    </Tab>
                    <Tab eventKey={menu.STEP2} title={t("STEP 2")}>
                        {forcedProfileType !== profileTypes.INFO && <div>
                            <div className="subtitle-wrapper">
                                {!avalibleConnections.length ? <p className="subtitle">{t("No profile found for this email")}</p> : null}
                            </div>
                            <div className="modal-content-inside">
                                <div className="btn-container">
                                    <div className="radio-container">
                                        <Form.Group className="group">
                                            <input hidden readOnly type="radio" id="new-profile" value="new" name="new" checked={profileType === 'new'} />
                                            <Form.Label htmlFor="new-profile" className="radio-circle"></Form.Label>
                                            <Form.Label htmlFor="new-profile" >{t("Invite User or Create New Profile")}</Form.Label>
                                        </Form.Group>
                                    </div>
                                    <button className="default-button" onClick={() => setMenuStatus(menu.STEP3)}>{t("NEXT STEP")} <Icon size={1} path={ mdiChevronRight } /></button>
                                </div>
                            </div>
                        </div>
                        }
                        {forcedProfileType === profileTypes.INFO && <div>
                            <div className="subtitle-wrapper">
                                {!avalibleConnections.length ? <p className="subtitle">{t("Joining message")}</p> : null}
                            </div>
                            <div className="modal-content-inside">
                                    <p className='note'>
                                        NOTE: For a smooth start, it's best to use the built-in tool to send individual invites to each family member. This way, they'll automatically be linked to your account when they register. 
                                    </p>
                                    <p>
                                        Prefer a quicker alternative? Share the following joining instructions to invite everyone at once. Then wait for them to find you in the app:
                                    </p>
                                    <p className='gray-container'>
                                        {joiningMessage}
                                    </p>

                                <div className="btn-container">
                                    <button className="default-button button-space" onClick={() => {navigator.clipboard.writeText(joiningMessage); copyHelper()}}>{t("COPY")}</button>
                                    {copied && <p className="copy">{t("Copied!")}</p>}                                    
                                    <button className="link-button button-space" onClick={() => setMenuStatus(menu.STEP1)}>{t("SEND INDIVIDUAL INVITES")}</button>
                                </div>
                            </div>
                        </div>}                        
                        {forcedProfileType !== profileTypes.INFO && <Help category={"FAMILY"} pageName={"Add to Family List - Step 2"}></Help>}
                    </Tab>
                    <Tab eventKey={menu.STEP3} title={t("STEP 3")}>
                        <div>
                            {(selected && profileType === 'existing') ?
                                <ExistingProfile user={selected} handleUpdateConnectionsFromProfile={updateConnections} handleClose={handleFinish} familymembers={familymembers} groups={userData.groups} />
                            :
                                <CreateNewProfile email={email} handleUpdateConnectionsFromProfile={updateConnections} handleClose={handleFinish} familymembers={familymembers} groups={userData.groups} forcedProfileType={forcedProfileType}/>
                            }
                        </div>
                        <Help category={"FAMILY"} pageName={"Add to Family List - Step 3"}></Help>                        
                    </Tab>
                </Tabs>
            </div>
        </Modal.Body>
      </Modal>
    );
};

export default AddToYourFamily;