// dot styles by position
export function getDotClassName(items: any[], activeIndex: number) {
    return items.map((x, index: number) => {
        if ((activeIndex === 1 && index - 3 === activeIndex) || (activeIndex === items.length - 2 && index + 3 === activeIndex)) {
            return "small-dot"
        }

        if (index - 5 >= activeIndex || index + 5 <= activeIndex || (activeIndex > 0 && index - 2 > activeIndex) || (activeIndex < items.length - 1 && index + 2 < activeIndex)) {
            return "hidden-dot"
        }

        if (index === activeIndex || (index <= 2 && activeIndex <= 2) || index + 1 === activeIndex || index + 2 === activeIndex) {
            return "big-dot"
        }

        if ((activeIndex <= 2 && index === 3) || index + 3 === activeIndex || index - 1 === activeIndex) {
            return "middle-dot"
        }

        return "small-dot"
    })
}
