import React, { useState } from 'react';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiExportVariant } from '@mdi/js';
import logo from "../../assets/images/logo2_with_tagline_vertical.svg"

const PWAModal = () => {
    const isPWA = window.matchMedia('(display-mode: standalone)').matches;

    //Check if user was prompted before, and if it was within 10 days
    const isPromptedBefore = () => {
        const storedDate = localStorage.getItem("prompt-date");
        //console.log("isPB", storedDate)
        if (storedDate) {
            const date = moment(storedDate);
            const today = moment(new Date());
            if (today.diff(date, 'd') >= 10) return false;
            return true;
        }
        return false;
    }

    //Check if user's device runs iOS
    const isIOS = () => {
        const ua = window.navigator.userAgent;
        const isIPad = !!ua.match(/iPad/i);
        const isIPhone = !!ua.match(/iPhone/i)
        const isIOS = isIPad || isIPhone;
        //console.log("isI", isIOS)

        return isIOS;
    }

    const [show, setShow] = useState(isIOS() && !isPromptedBefore());

    const handleButtonClick = () => {
        setShow(false);
        const promptDate = moment(new Date()).toString();
        localStorage.setItem('prompt-date', promptDate);
    }

    if (isPWA) {
        return null;
    }
    else {
        return (
            <Modal show={show} className="pwa-modal-ios">
                <div className="content">
                    <div className='desc-container'>
                        <h6 className="pwa-title">Install app for the best experience</h6>
                        <ol>
                            <li>
                                <p>Click on the share menu <Icon className="pwa-modal-icon" size={1} path={mdiExportVariant} /></p>
                            </li>
                            <li>
                                <p>Select 'Add to Homescreen' </p>
                            </li>
                        </ol>
                    </div>
                    <div className='logo-container'>
                        <img src={logo} alt="logo"/>
                    </div>
                </div>
                <button className="modal-button default-button" onClick={handleButtonClick}>OK</button>
            </Modal>
        )
    }
}

export default PWAModal;