import React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import IFrameDisplay from './iframe-display.component';
import { useTranslation } from "react-i18next";

interface IProps {
    pageName: string
}

function Access(props:IProps) {

    const history = useHistory();

    const { t } = useTranslation();

    return (
        <div className="access">
            <IFrameDisplay pageName={props.pageName} />            
            <p className="locked">{t("Page locked")}</p>
            <p className="info-text">{t("Available to subscribers only")}</p>
            <Button className="default-button" onClick={() => history.push("/dashboard/account/subscription/buy")}>{t("Subscribe to access")}</Button>
        </div>                
    )
}

export default Access;