import React, { useEffect, useCallback, Dispatch, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../../store/actionCreators";
import { useHistory, useLocation } from "react-router";
import default_avatar from "../../assets/images/default-avatar.jpg";
import instance from "../../api/api";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Context } from "../home.page";
import Help from "../../components/_helper/help.component";
import GroupDetailsModal from "../../components/profile/groups/group-details-modal.component";
import Markdown from "markdown-to-jsx";
import { IGroup, IUserDetails } from "../../interfaces/authentication";
import moment from "moment";
import { dateFormat } from "../../_helper/enum/enum";
import StoryMediaCommentModal from "../../components/story/story-media-comment-modal.component";
import { IMedia } from "../../interfaces/story";
import { useTranslation } from 'react-i18next';
import { INotification } from "../../interfaces/notification";
import config from "../../config/config";
import logo_icon from "../../assets/images/logo-icon.jpg";

export interface IFamilyUpdate {
    _id:string
    author:IUserDetails
    users:IUserDetails[]
    type:notificationTypes
    title:string
    description:string
    cta:string
    createdAt:Date
    pObjectId?:string
    pString?:string    
    readBy:string[]
}

export enum notificationTypes {
    FAMILY = "FAMILY",
    PENDING_RELATION_NEW = "PENDING_RELATION_NEW",
    PENDING_RELATION_NEW_ADMIN = "PENDING_RELATION_NEW_ADMIN",// admin    
    PENDING_RELATION_ACCEPTED = "PENDING_RELATION_ACCEPTED",
    PENDING_RELATION_DECLINED = "PENDING_RELATION_DECLINED",
    RELATION_DELETED = "RELATION_DELETED",
    GROUP_ADDED_USERS = "GROUP_ADDED_USERS",
    GROUP_REMOVED_USERS = "GROUP_REMOVED_USERS",
    GROUP_UPDATED = "GROUP_UPDATED",
    GROUP_NEW_MANAGER = "GROUP_NEW_MANAGER",
    GROUP_DELETED = "GROUP_DELETED",
    GROUP_MANAGEMENT_ENDS = "GROUP_MANAGEMENT_ENDS",
    REQUEST_NEW = "REQUEST_NEW",
    STORY_PUBLISHED = "STORY_PUBLISHED",
    STORY_COAUTHOR_INVITE = "STORY_COAUTHOR_INVITE",
    STORY_COAUTHOR_INFORM_DONE = "STORY_COAUTHOR_INFORM_DONE",
    STORY_COAUTHOR_INFORM_UPDATE = "STORY_COAUTHOR_INFORM_UPDATE",
    STORY_NEW_RELATED = "STORY_NEW_RELATED",
    STORY_UPDATED = "STORY_UPDATED",
    REQUEST_ACCEPTED = "REQUEST_ACCEPTED",
    REQUEST_DECLINED = "REQUEST_DECLINED",
    USER_NEW_ADMIN = "USER_NEW_ADMIN",
    USER_REMOVED_ADMIN = "USER_REMOVED_ADMIN",
    USER_NEW_SUCCESSOR = "USER_NEW_SUCCESSOR",
    USER_REMOVED_SUCCESSOR = "USER_REMOVED_SUCCESSOR",
    USER_NEW_FEATUREDBY = "USER_NEW_FEATUREDBY",
    USER_REMOVED_FEATUREDBY = "USER_REMOVED_FEATUREDBY",
    USER_PASSED_AWAY = "USER_PASSED_AWAY",
    USER_NEW_SUGGESTION = "USER_NEW_SUGGESTION",
    COMMENT_ON_STORY = "COMMENT_ON_STORY",
    COMMENT_ON_MEDIA = "COMMENT_ON_MEDIA",
    COMMENT_ON_REQUEST = "COMMENT_ON_REQUEST",
    COMMENT_TO_COAUTHORS = "COMMENT_TO_COAUTHORS",
    ADMIN_TEST = "ADMIN_TEST",

    //Subscription notifications
    SUBSCRIPTION_UPGRADE = "SUBSCRIPTION_UPGRADE",
    SUBSCRIPTION_CANCEL = "SUBSCRIPTION_CANCEL",
    SUBSCRIPTION_RESTART = "SUBSCRIPTION_RESTART",
    SUBSCRIPTION_CHARGE_FAILING = "SUBSCRIPTION_CHARGE_FAILING",
    SUBSCRIPTION_RENEW = "SUBSCRIPTION_RENEW",
    SUBSCRIPTION_DEACTIVATION = "SUBSCRIPTION_DEACTIVATION",
    SUBSCRIPTION_REFERRAL = "SUBSCRIPTION_REFERRAL",
    SUBSCRIPTION_TRIAL_REMINDER = "SUBSCRIPTION_TRIAL_REMINDER",
    SUBSCRIPTION_SHARE = "SUBSCRIPTION_SHARE" // not really subscription related as it is triggered after creating a relation with a subscription owner
}

enum modalTypes {
    HIDDEN="",
    GROUP_ADDED_USERS="GROUP_ADDED_USERS",
    GROUP_REMOVED_USERS="GROUP_REMOVED_USERS",
    GROUP_UPDATED="GROUP_UPDATED",
    GROUP_NEW_MANAGER="GROUP_NEW_MANAGER",
    GROUP_NEW_MANAGER_VIA_MODAL_BUTTON_CLICK="GROUP_NEW_MANAGER_VIA_MODAL_BUTTON_CLICK", // local
    GROUP_DELETED="GROUP_DELETED",
    GROUP_MANAGEMENT_ENDS="GROUP_MANAGEMENT_ENDS",
    GROUP_MANAGEMENT_ENDS_VIA_MODAL_BUTTON_CLICK="GROUP_MANAGEMENT_ENDS_VIA_MODAL_BUTTON_CLICK", // local
    PENDING_RELATION_DECLINED="PENDING_RELATION_DECLINED",
    RELATION_DELETED="RELATION_DELETED",
    STORY_NEW_RELATED = "STORY_NEW_RELATED",   
    USER_NEW_ADMIN="USER_NEW_ADMIN",
    USER_REMOVED_ADMIN="USER_REMOVED_ADMIN",
    USER_NEW_SUCCESSOR="USER_NEW_SUCCESSOR",
    USER_REMOVED_SUCCESSOR="USER_REMOVED_SUCCESSOR",
    USER_NEW_FEATUREDBY = "USER_NEW_FEATUREDBY",
    USER_REMOVED_FEATUREDBY = "USER_REMOVED_FEATUREDBY",
    USER_PASSED_AWAY="USER_PASSED_AWAY",
    COMMENT_ON_MEDIA = "COMMENT_ON_MEDIA",
    SUBSCRIPTION_CANCEL = "SUBSCRIPTION_CANCEL",
    SUBSCRIPTION_CHARGE_FAILING="SUBSCRIPTION_CHARGE_FAILING",
    SUBSCRIPTION_DEACTIVATION="SUBSCRIPTION_DEACTIVATION",
    SUBSCRIPTION_REFERRAL="SUBSCRIPTION_REFERRAL",
    SUBSCRIPTION_TRIAL_REMINDER="SUBSCRIPTION_TRIAL_REMINDER"
}

const groupModalTypes = [
    notificationTypes.GROUP_UPDATED,
    notificationTypes.GROUP_ADDED_USERS,
    notificationTypes.GROUP_NEW_MANAGER,
    notificationTypes.GROUP_MANAGEMENT_ENDS,
]

function FamilyUpdates() {

    const [ notifications, setNotifications ] = useState<IFamilyUpdate[]>([]);
    const [ pastNotifications, setPastNotifications ] = useState<IFamilyUpdate[]>([]);
    const [ loading, setLoading ] = useState(true);
    const [ modal, setModal ] = useState(modalTypes.HIDDEN);
    const [ cta, setCta ] = useState<string>("");
    const [ title, setTitle ] = useState<string>("");
    const [ ctaButtonLink, setCtaButtonLink ] = useState<string>("");

    const [ groups, setGroups ] = useState<IGroup[]>([]);
    const [ detailGroup, setDetailGroup ] = useState<IGroup | null>(null);
    const [ media, setMedia ] = useState<IMedia[]>([]);
    const [ detailMedia, setDetailMedia ] = useState<IMedia | null>(null);    
    const [ showOld, setShowOld ] = useState<boolean>(false);

    const history = useHistory()
    const { t } = useTranslation();    

    //redux
    const dispatch: Dispatch<any> = useDispatch()
    const setPageName = useCallback((currentPage: string) => dispatch(setCurrentPage(currentPage)), [dispatch])

    //Context
    const pageData = useContext(Context).pageData;
    const loggedInUserData = useContext(Context).user;
    const updatePageData = useContext(Context).setPageData;

    useEffect(() => {
        getUserNotifications();
    }, [pageData.notifications])
    

    useEffect(() => {
        setPageName(t("Notifications"));

        //Get users notifications
        getUserNotifications();

        // update the last visited notifications time of the user when he/she opens the page
        if(loggedInUserData._id){
            updatelastVisitedNotificationsTimeForUser()
        }

        return () => {
            // update the last visited notifications time of the user when he/she leaves the page
            updatelastVisitedNotificationsTimeForUser()
          }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function updatelastVisitedNotificationsTimeForUser() {
        await instance.put(`/user/${loggedInUserData._id}`, { details: { lastVisitedNotifications: new Date() } });
    }

    async function getUserNotifications() {
        try {
            const notifyRes = await instance.get('/notification?from=' + loggedInUserData.lastVisitedBefore);
            if(!notifyRes.data.length){
                await getUserPastNotifications();
                setShowOld(true)
            }
            setNotifications(notifyRes.data);
            setLoading(false);
            updatePageData({ ...pageData, notifications: 0})
        } catch(error) {
            console.log(error)
        }
    }

    async function getUserPastNotifications() {
        try {
            const notifyRes = await instance.get('/notification?to=' + loggedInUserData.lastVisitedBefore);
            setPastNotifications(notifyRes.data);
            setLoading(false);
            setShowOld(true);
        } catch(error) {
            console.log(error)
        } 
    }

    useEffect(() => {
        getGroups();
        getMedia();
    }, [notifications])

    async function getGroups() {
        const groups = [];
        const groupIds = notifications.filter(x => groupModalTypes.includes(x.type)).map((notification:IFamilyUpdate) => notification.pObjectId );

        if(!groupIds.length) return;
        const groupsResponse = await instance.get('/group', { params: { _id: groupIds } });

        if(groupsResponse.data && groupsResponse.data.length) {        
            groups.push(...groupsResponse.data);
            setGroups(groups);
        }
    }

    async function getMedia() {
        const media = [];
        const mediaIds = notifications.filter(x => x.type === notificationTypes.COMMENT_ON_MEDIA).map((notification:IFamilyUpdate) => notification.pObjectId );

        if(!mediaIds.length) return;
        const mediaResponse = await instance.get('/media', { params: { _id: mediaIds.join() }});

        if(mediaResponse.data.result && mediaResponse.data.result.length) {
            media.push(...mediaResponse.data.result); // TODO: low: what if more records than pagination = 0? response.data.hasNextPage!
            setMedia(media);     
        }
    }    

    async function getStory(storyId:string, forceInProgess?:boolean)  {
        try {
            const response = await instance.get(`/stories/${storyId}`);
            
            if (response.data.published && !forceInProgess) {
                history.push(`/dashboard/story/${storyId}`);
            } else {
                history.push('/dashboard/create', { data: response.data, subTab: "3" });
            }
        } catch (error) {
            console.log("Failed to load story for co-authoring", error);            
        }
    }

    async function openFastspringAccountPage() {
        setLoading(true)
        try {
            const res = await instance.get("/fastspring/account/authenticate") // + loggedInUserData.fastspring_account_id);
            if(res.data.accounts && res.data.accounts[0]) {
                const data = res.data.accounts[0];
                window.open(data.url + "#/payment", '_blank', 'noopener,noreferrer');
            }
            setLoading(false)
        }
        catch(err) {
            console.log('err', err)
            setLoading(false)
        }
    }

    function linkOrModal(notification:IFamilyUpdate){
        readNotification(notification._id);

        switch(notification.type){
            case notificationTypes.PENDING_RELATION_NEW:
            case notificationTypes.USER_NEW_SUGGESTION:
                history.push(`/dashboard/profile/${loggedInUserData._id}/2`);
                break;
            case notificationTypes.PENDING_RELATION_NEW_ADMIN:
                history.push(`/dashboard/profile/${notification.pObjectId}/2`);
                break;
            case notificationTypes.PENDING_RELATION_ACCEPTED:
                history.push(`/dashboard/profile/${notification.author._id}/1`);
                break;
            case notificationTypes.REQUEST_NEW:
                history.push({pathname: '/dashboard/requests', state: { subTab: "3", requestId: notification.pObjectId }});
                break;
            case notificationTypes.COMMENT_ON_REQUEST:
                history.push({pathname: '/dashboard/requests', state: { subTab: notification.pString, requestId: notification.pObjectId, scroll: "story-comment-section"}});
                break;                
            case notificationTypes.STORY_PUBLISHED:
            case notificationTypes.STORY_UPDATED:
                history.push({pathname: `/dashboard/story/${notification.pObjectId}`});
                break;
            case notificationTypes.COMMENT_ON_STORY:
                history.push({pathname: `/dashboard/story/${notification.pObjectId}`, state: {scroll: "story-comment-section"}});
                break;
            case notificationTypes.STORY_COAUTHOR_INVITE:
                if (notification.pObjectId) getStory(notification.pObjectId);
                break;
            case notificationTypes.STORY_COAUTHOR_INFORM_DONE:
            case notificationTypes.STORY_COAUTHOR_INFORM_UPDATE:
            case notificationTypes.COMMENT_TO_COAUTHORS:                
                if (notification.pObjectId) getStory(notification.pObjectId, true);
                break;            
            case notificationTypes.GROUP_ADDED_USERS:
            case notificationTypes.GROUP_UPDATED:
            case notificationTypes.GROUP_NEW_MANAGER:
            case notificationTypes.GROUP_MANAGEMENT_ENDS:                             
                setModal(notification.type as unknown as modalTypes);
                setCta(notification.cta);
                setTitle(notification.title);
                const temp = groups.find(x => (x as IGroup)._id === notification.pObjectId as string);
                temp === undefined ? setDetailGroup(null) : setDetailGroup(temp);
                break;
            case notificationTypes.PENDING_RELATION_DECLINED:
                setModal(notification.type as unknown as modalTypes);
                setCta(notification.author.firstname + " " + notification.author.lastname + notification.cta);
                setTitle(notification.title);
                break;                                             
            case notificationTypes.STORY_NEW_RELATED: 
                setModal(notification.type as unknown as modalTypes);
                setCta(notification.cta);
                setCtaButtonLink(`/dashboard/story/${notification.pObjectId}`);
                setTitle(notification.title);
                break;                                          
            case notificationTypes.USER_NEW_ADMIN:     
            case notificationTypes.USER_NEW_SUCCESSOR:   
            case notificationTypes.USER_NEW_FEATUREDBY:
                setModal(notification.type as unknown as modalTypes);
                setCta(notification.cta);
                setCtaButtonLink(`/dashboard/profile/${notification.author._id}/1`);
                setTitle(notification.title);
                break;
            case notificationTypes.GROUP_REMOVED_USERS:
            case notificationTypes.GROUP_DELETED:
            case notificationTypes.RELATION_DELETED:
            case notificationTypes.REQUEST_ACCEPTED:
            case notificationTypes.REQUEST_DECLINED:
            case notificationTypes.USER_REMOVED_ADMIN:
            case notificationTypes.USER_REMOVED_SUCCESSOR:
            case notificationTypes.USER_REMOVED_FEATUREDBY:
            case notificationTypes.USER_PASSED_AWAY:
                setModal(notification.type as unknown as modalTypes);
                setCta(notification.cta);
                setTitle(notification.title);
                break;  
            case notificationTypes.COMMENT_ON_MEDIA:
                setModal(notification.type as unknown as modalTypes);
                setCta(notification.cta);
                setTitle(notification.title);
                const tempMedia = media.find(x => (x as IMedia)._id === notification.pObjectId as string);
                tempMedia === undefined ? setDetailMedia(null) : setDetailMedia(tempMedia);
                break;
            case notificationTypes.SUBSCRIPTION_UPGRADE:
            case notificationTypes.SUBSCRIPTION_RESTART:
            case notificationTypes.SUBSCRIPTION_RENEW:
                history.push(`/dashboard/account/subscription/info`);
                break;
            case notificationTypes.SUBSCRIPTION_SHARE:
                history.push(`/dashboard/account/subscription/share`);
                break;                
            case notificationTypes.SUBSCRIPTION_CANCEL:
            case notificationTypes.SUBSCRIPTION_CHARGE_FAILING: // CtaButtonLink does not matter here
            case notificationTypes.SUBSCRIPTION_TRIAL_REMINDER: // CtaButtonLink does not matter here
                setModal(notification.type as unknown as modalTypes);
                setCta(notification.cta);
                setCtaButtonLink(`/dashboard/account/subscription/info`);
                setTitle(notification.title);
                break;
            case notificationTypes.SUBSCRIPTION_DEACTIVATION:
                setModal(notification.type as unknown as modalTypes);
                setCta(notification.cta);
                setCtaButtonLink(`/dashboard/account/subscription/buy`);
                setTitle(notification.title);
                break;
            case notificationTypes.SUBSCRIPTION_REFERRAL:
                setModal(notification.type as unknown as modalTypes);
                setCta(notification.cta);
                setCtaButtonLink(`/dashboard/referandsave/refer`);
                setTitle(notification.title);
                break;
        }

    }

    async function readNotification(_id:string) {
        try {
            const notification = [...notifications, ...pastNotifications].find(x => x._id === _id);
            if(!notification) {
                return;
            }

            if(notification.readBy.includes(loggedInUserData._id.toString())) {
                return;
            }

            const notifyRes = await instance.put('/notification', { notificationid: _id, readby: loggedInUserData._id });
            if(notifyRes.data) {
                const index = notifications.findIndex(x => x._id === _id);
                if(index >= 0) {
                    let temp = notifications.filter(x => x._id !== _id);
                    temp[index] = notifyRes.data;
                    setNotifications(temp);
                }
                else {
                    const pIndex = pastNotifications.findIndex(x => x._id === _id);

                    if(pIndex >= 0) {
                        
                        let temp = pastNotifications.filter(x => x._id !== _id);
                        temp[pIndex] = notifyRes.data;
                        setPastNotifications(temp);
                    }
                }
            }
        }
        catch(error) {
            console.log('error', error)
        }
    }

    function handleClose() {
        setModal(modalTypes.HIDDEN);
    };

    function displayNotificationName(notification:IFamilyUpdate) {

        if(false) { //will be the chat
            return notification.users.map((x:IUserDetails) => x.firstname).join(", ") 
        }
        else {
            if(notification.author && (notification.type === notificationTypes.GROUP_MANAGEMENT_ENDS || notification.type === notificationTypes.GROUP_NEW_MANAGER || notification.type === notificationTypes.SUBSCRIPTION_SHARE)) {
                return "Simirity" // System Administrator
            }
            else if(notification.author) {
                return notification.author.firstname + " " + notification.author.lastname
            }
            else {
                return "Unknown User"
            }
        }

    }

    function displayNotificationImage(notification:IFamilyUpdate) {
        if(notification.author && notification.type===notificationTypes.PENDING_RELATION_NEW_ADMIN) {
            return(
                <div className="multiple-images-with-admin">
                    <img className="big user-avatar" alt={notification.author.firstname} src={notification.author.avatar ? notification.author.avatar : default_avatar}/>
                    <img className="small user-avatar" alt="Administered Profile" src={notification.pString ? notification.pString : default_avatar}/>
                </div>
            )
        }
        else if(notification.author && (notification.type === notificationTypes.GROUP_MANAGEMENT_ENDS || notification.type === notificationTypes.GROUP_NEW_MANAGER || notification.type === notificationTypes.SUBSCRIPTION_SHARE)) {
            return(
                <div className="">
                    <img className="user-avatar" alt="System Administrator" src={logo_icon}/>
                </div>
            )
        }             
        else if(notification.author) {
            return(
                <div className="">
                    <img className="user-avatar" alt={notification.author.firstname} src={notification.author.avatar ? notification.author.avatar : default_avatar}/>
                </div>
            )
        }
        else {
            return(
                <div className="">
                    <img className="user-avatar" alt="Unknown User" src={default_avatar}/>
                </div>
            )            
        }
    }

    function getNotificationDate(notification:IFamilyUpdate) {
        var oneDay = new Date().getTime() - (1 * 24 * 60 * 60 * 1000)

        //
        if(oneDay > new Date(notification.createdAt).getTime() ) {
            return moment(notification.createdAt).format(dateFormat.MONTH_DAY)
        }
        else {
            var hours = Math.abs(new Date().getTime() - new Date(notification.createdAt).getTime()) / 36e5;
            if(Math.floor(hours) === 0) {

                var today = new Date();
                var diffMs = (today.getTime() - new Date(notification.createdAt).getTime());

                var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);

                return Math.abs(diffMins) + " mins"
            }
            else {
                return Math.floor(hours) +" hour"
            }
        }
    }

    function translateNotification(data:string){

        if(!data) return "";

        // return "en" notifications untouched
        if(loggedInUserData.settings.language==="en") return data;

        let found = data;

        config.translateableNotifications.find((x: { regExp: string | RegExp; toBeTranslated: any; }) => {
                const arrayL = data.match(new RegExp(x.regExp))
                if(arrayL && arrayL.length>0){
                    // max 3 parameters available to be translated
                    found = arrayL[3] ? t(x.toBeTranslated, { "p1": arrayL[1], "p2": arrayL[2], "p3": arrayL[3] }) : arrayL[2] ? t(x.toBeTranslated, { "p1": arrayL[1], "p2": arrayL[2] }) : t(x.toBeTranslated, { "p1": arrayL[1] });
                    return found;
                } else if(arrayL){
                    found = t(data);
                    return found;
                }
            }
        );

        return t(found);

    }

    function displayNotification(notification:IFamilyUpdate) {
        return(
            <div className={`family-update 
                ${notification.type===notificationTypes.PENDING_RELATION_NEW_ADMIN ? "admin-notification" : null} 
                ${notification.readBy.includes(loggedInUserData._id) ? "read" : 'new'}`
                } onClick={() => {linkOrModal(notification)}}>
               {displayNotificationImage(notification)}
                <div onClick={() => readNotification(notification._id)}>
                    {/*<p className="users">{notification.users.map(user => { return loggedInUserData.relations.filter(x => x.userid._id === user)})}</p>*/}
                    <p className="users">{displayNotificationName(notification)}</p>{notification.readBy.includes(loggedInUserData._id) ? null : <span className="dot"></span>}
                    <p className="text">{translateNotification(notification.title)}</p>
                    <p className="description">{translateNotification(notification.description)}</p>
                    <p className="date">{notification.createdAt ? getNotificationDate(notification) : ""}</p>
                </div>
            </div>
        );
    }

    return(
        <div className="family-update-page">
            {loading ?
            <div className="spinner-container">
                <Spinner animation="border" variant="#fff" />
            </div>
            :
            <div className="">
                {/*<p className="title">{t("Recent & pending")}</p>*/}
                <div className="family-updates-container new">                
                    {notifications.length ?
                        notifications.map((notification:IFamilyUpdate) => {
                            return(
                                displayNotification(notification)
                            );
                        })
                        :
                        <p>{t("No new notifications")}</p>
                    }
                </div>
                <div className="family-updates-container more">
                        {!showOld ? <p className="link-text" onClick={() => getUserPastNotifications()}>{t("Load more")}</p> : 
                            <div>
                                {pastNotifications.map((notification:IFamilyUpdate) => {
                                    return(
                                        displayNotification(notification)
                                    );
                                })}
                            </div>
                            }
                    </div>
            </div>}

            {modal === modalTypes.GROUP_UPDATED || 
            modal === modalTypes.GROUP_ADDED_USERS || 
            modal === modalTypes.GROUP_NEW_MANAGER_VIA_MODAL_BUTTON_CLICK ||
            modal === modalTypes.GROUP_MANAGEMENT_ENDS_VIA_MODAL_BUTTON_CLICK ?
                detailGroup !== null && <GroupDetailsModal user={loggedInUserData} administrator={modal === modalTypes.GROUP_NEW_MANAGER_VIA_MODAL_BUTTON_CLICK ? true : false} group={detailGroup} show={true} handleClose={() => setModal(modalTypes.HIDDEN)} updateGroups={() => {}} deleteGroup={() => {}}/>
            :
            modal === modalTypes.COMMENT_ON_MEDIA ? 
                detailMedia !== null && <StoryMediaCommentModal storyAccessToken={detailMedia.accessCommentToken || ""} visual={detailMedia} show={true} onHide={() => setModal(modalTypes.HIDDEN)} />
            :

            <Modal dialogClassName="family-updates" show={modal != modalTypes.HIDDEN} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{translateNotification(title)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="cta"><Markdown>{translateNotification(cta)}</Markdown></p>
                    {modal === modalTypes.GROUP_NEW_MANAGER ? 
                        <div className="btn-container">
                            <button className="default-button" onClick={() => setModal(modalTypes.GROUP_NEW_MANAGER_VIA_MODAL_BUTTON_CLICK)}>{t("View Group")}</button>
                        </div>
                    : modal === modalTypes.GROUP_MANAGEMENT_ENDS ?
                    <div className="btn-container">
                        <button className="default-button" onClick={() => setModal(modalTypes.GROUP_MANAGEMENT_ENDS_VIA_MODAL_BUTTON_CLICK)}>{t("View Group")}</button>
                    </div>
                    : modal === modalTypes.STORY_NEW_RELATED ?
                    <div className="btn-container">
                        <button className="default-button" onClick={() => {history.push(ctaButtonLink);setCtaButtonLink("")}}>{t("View Their Story")}</button>
                    </div>
                    : modal === modalTypes.SUBSCRIPTION_CANCEL ?
                    <div className="btn-container">
                        <button className="default-button" onClick={() => {history.push(ctaButtonLink);setCtaButtonLink("")}}>{t("View Subscription Info")}</button>
                    </div>
                    : modal === modalTypes.SUBSCRIPTION_CHARGE_FAILING || modal === modalTypes.SUBSCRIPTION_TRIAL_REMINDER ?
                    <div className="btn-container">                    
                        <Button style={{marginTop:25, marginBottom:25}} onClick={openFastspringAccountPage} className="default-button">{loading ? <Spinner animation="border" variant="#fff" /> : t("Update Card Details")}</Button>
                    </div>
                    : modal === modalTypes.SUBSCRIPTION_DEACTIVATION ?
                    <div className="btn-container">                    
                        <button className="default-button" onClick={() => {history.push(ctaButtonLink);setCtaButtonLink("")}}>{t("Buy Subscription")}</button>
                    </div>
                    : modal === modalTypes.SUBSCRIPTION_REFERRAL ?
                    <div className="btn-container">                    
                        <button className="default-button" onClick={() => {history.push(ctaButtonLink);setCtaButtonLink("")}}>{t("Referral Link")}</button>
                    </div>                                                              
                    : (modal === modalTypes.USER_NEW_ADMIN || modal === modalTypes.USER_NEW_SUCCESSOR || modal === modalTypes.USER_NEW_FEATUREDBY) ?
                    <div className="btn-container">
                        <button className="default-button" onClick={() => {history.push(ctaButtonLink);setCtaButtonLink("")}}>{t("View Their Profile")}</button>
                    </div>
                    : null}
                </Modal.Body>
            </Modal>

            }
            <Help category={"FAMILY"} pageName={"Family Updates"}></Help>
        </div>
    );
}

export default FamilyUpdates;
