import React, { Dispatch, useCallback, useContext, useEffect, useState, useRef } from "react"
import { useHistory, useLocation, useParams } from "react-router"
import instance from "../../../api/api"
import { IStory, IRelatedStory, IMedia } from "../../../interfaces/story"
import { IUserDetails } from "../../../interfaces/authentication"
import default_avatar from "../../../assets/images/default-avatar.jpg"
import Infocard from "../../../components/story/infocard.component"
import { mdiChevronDown, mdiChevronLeft, mdiChevronRight, mdiChevronUp, mdiDotsHorizontal, mdiInformationOutline, mdiStar, mdiStarOutline } from "@mdi/js"
import Icon from "@mdi/react"
import { DisplayStory } from "../../../components/story/blocks/display-story.component"
import { Spinner } from "react-bootstrap"
import { Context } from "../../home.page"
import StoryActionModal from "../../../components/story/story-action-modal.component"
import { IOption } from "../../../interfaces/family"
import config from "../../../config/config"
import Help from "../../../components/_helper/help.component"
import emoji_happiness from "../../../assets/images/notification/emoji-happiness.svg";
import { mdiClose } from "@mdi/js";
import "react-multi-carousel/lib/styles.css";
import AddToYourFamily from "../../../components/family/add-to-family.component"
import { notificationTypes } from "../family-updates.page"
import { getStoryPublishNotificationUsers } from "../../../components/story/_helper/collect-story-access"
import PrivateSharingUrl from "../../../components/story/_helper/private-sharing-url.component"
import { Modal } from 'react-bootstrap';
import { useDispatch } from "react-redux"
import { setCurrentPage } from "../../../store/actionCreators"
import feather from "../../../assets/images/feather.svg";
import CommentsContainer, { ECommentType } from "../../../components/comments/comments-container.component"
import UserCarousel, { ECarouselAction } from "../../../components/_helper/user-carousel.component"
import StoryMediaCommentModal from "../../../components/story/story-media-comment-modal.component"
import { INotification, NotificationType } from "../../../interfaces/notification"
import Notification, { notificationImages } from "../../../components/notifications/notification.component"
import { FacebookShareButton, TwitterShareButton } from "react-share"
import { mdiFacebook, mdiTwitter } from '@mdi/js';
import { useTranslation } from "react-i18next";
import { getStoryDate } from "../../../_helper/getStoryDate"

interface RouteParams {
    id: string
}

export enum eFeedbackTypes {
    DEFAULT="default",
    PRIVATE_URL="private url",
    NO_CONTACT="no contact",
    REPUBLISHED="REPUBLISHED",
    NON_SIMIRITY_PEOPLE_TAGGED="NON_SIMIRITY_PEOPLE_TAGGED"
}

function StoryDetails() {
    // story id from url params
    const { id } = useParams<RouteParams>()

    const dispatch: Dispatch<any> = useDispatch();
    const setPageName = useCallback((currentPage: string) => dispatch(setCurrentPage(currentPage)), [dispatch]);    

    const [story, setStory] = useState<IStory | null>(null)

    const [ infocardShow, setinfocardShow ] = useState<boolean>(false);
    const [ editCardShow, setEditCardShow ] = useState<boolean>(false);
    const [ showNext, setShowNext ] = useState<boolean>(false);
    const [ noOfSliderItemsToShow, setNoOfSliderItemsToShow ] = useState<number>(1);
    const [ sameTagStories, setSameTagStories ] = useState<IStory[]>([]);
    
    //modal
    const [ showMediaCommentModal, setShowMediaCommentModal ] = useState<boolean>(false);
    const [ selectedMedia, setSelectedMedia ] = useState<IMedia>();

    const [ storyFeedback, setStoryFeedback ] = useState<null | eFeedbackTypes>(null);

    const history = useHistory();
    const location : any = useLocation();

    const loggedInUserData = useContext(Context).user;
    const updateLoggedInUserData = useContext(Context).setUserDetails;

    // feedback
    const [addToFamilyModal, setAddToFamilyModal] = useState<boolean>(false);
    const [shareModal, setShareModal] = useState<boolean>(false);
    const [showPrivateSharingTT, setShowPrivateSharingTT] = useState<boolean>(false);    
    const [copied, setCopied] = useState<boolean>(false);    
    const [notification, setNotification] = useState<INotification>({ icon: "", title: "", text: "", success: NotificationType.problem });

    const [triggerCommentCounter, setTriggerCommentCounter] = useState(false)

    //ref for scroll
    const topRef = useRef<any>(null);
    const commentTopRef = useRef<any>(null);

    const { t } = useTranslation();

    //Check notification type
    useEffect(() => {
        setPageName('Stories');
        if(location.state) {
            //have to get some information from rerouting to be able to fill out query
            if(location.state.hasOwnProperty('feedback')) {
                setStoryFeedback(location.state.feedback)
            }
            if(location.state.hasOwnProperty('scroll') && commentTopRef.current!==null) {
                setTimeout(() => {
                    commentTopRef.current.scrollIntoView();
                }, 800);
            }
        }
    }, [location.state, commentTopRef.current])

    useEffect(() => {
        fetchStories()
        // eslint-disable-nxt-line react-hooks/exhaustive-deps
    }, [id])

    async function readStory() {
        try {
            await instance.post(`/publishedstories/read/${id}`);
            if(story !== null) {
                setStory(prev => { return { ...(prev as IStory), readBy:[...(prev as IStory).readBy, loggedInUserData._id] }})
            }
        }
        catch(error) {
            //nothing to do
        }
    }

    async function fetchStories() {
        try {
            const response = await instance.get(`/publishedstories/${id}`);

            if(!response.data.readBy.includes(loggedInUserData._id)) {
                readStory();
            }

            setStory(response.data)

            // count author as +1 as default
            response.data.participants.length<=0 ? setNoOfSliderItemsToShow(1) : response.data.participants.length === 1 ? setNoOfSliderItemsToShow(2) : setNoOfSliderItemsToShow(3);
            
            //Get stories with same tag
            const sameTagStories = await instance.get(`/publishedstories?storyTags=${response.data.storyTags.join(",")}`);
            setSameTagStories(sameTagStories.data)

        } catch (error) {
            history.push('/dashboard/stories');
        }
    }

    function getImage() {
        const featured = story?.featured as IUserDetails

        return featured.avatar ? featured.avatar : default_avatar
    }

    async function addToFavorites() {
        if(!loggedInUserData.favorites.stories.includes(id)) {
            const response = await instance.put(`/user/${loggedInUserData._id}`, { push: { "favorites.stories": id }});
            updateLoggedInUserData(response.data)
        }
        else {
            const response = await instance.put(`/user/${loggedInUserData._id}`, { pull: { "favorites.stories": id }});
            updateLoggedInUserData(response.data)
        }
    }

    async function informOthersOfUpdate() {

        if(story){
            try {
                const notificationData : any = {
                    author: loggedInUserData._id,
                    authorName: `${loggedInUserData.firstname} ${loggedInUserData.lastname}`,
                    users: getStoryPublishNotificationUsers(story, loggedInUserData),
                    type: notificationTypes.STORY_UPDATED,
                    title: t("Updated Story"),
                    description: story.title,
                    pObjectId: story._id
                }
                const response = await instance.post(`/notification/send`, { data: notificationData });
                close();
            } catch(error) {
                console.log('Failed to send notification.', error)
            }
        }

    }

    function close() {
        setStoryFeedback(null);
    }

    function republishedFeedback(close:Function) {
        return(
            <div className="notification-container feedback-story-default">
                <span onClick={() => close()}><Icon className="close-icon" size={0.8} path={ mdiClose } /></span>
                <img src={emoji_happiness} alt="emoji"/>
                <p className="notification-title">{t("Successfully republished")}</p>
                <p className="text">{t("Would you like to inform those with access about this update?")}</p>
                <button className="default-button" onClick={() => {informOthersOfUpdate()}}>{t("Inform Others of Update")}</button>
            </div>
        )
    }

    function noContactFeedback(close:Function) {
        return(
            <div className="notification-container feedback-story-default">
                <span onClick={() => close()}><Icon className="close-icon" size={0.8} path={ mdiClose } /></span>
                <img src={emoji_happiness} alt="emoji"/>
                <p className="notification-title">{t("Successfully published")}</p>
                <p className="text">{t("Benefit from sharing stories with family")}</p>
                <button className="default-button" onClick={() => {setAddToFamilyModal(true)}}>{t("Add to Family List")}</button>
                <AddToYourFamily show={addToFamilyModal} handleUpdateConnections={() => {}} handleClose={() => setAddToFamilyModal(false)} />
            </div>
        )
    }

    function defaultFeedback(close:Function) {
        return(
            <div className="notification-container feedback-story-default">
                <span onClick={() => close()}><Icon className="close-icon" size={0.8} path={ mdiClose } /></span>
                <img src={emoji_happiness} alt="emoji"/>
                <p className="notification-title">{t("Successfully published")}</p>
                <p className="notification-subtitle">{t("Your readers have been notified")}</p>
            </div>
        )
    }
    
    function privateUrlFeedback(close:Function, privateId:string) {
        return(
            <div className="notification-container feedback-story-default">
                <span onClick={() => close()}><Icon className="close-icon" size={0.8} path={ mdiClose } /></span>
                <img src={emoji_happiness} alt="emoji"/>
                <p className="notification-title">{t("Successfully published")}</p>
                <p className="notification-subtitle">{t("This story can be shared with friends using:")}</p>
                <p className="notification-link underlined-link-button" onClick={() => {navigator.clipboard.writeText(`${window.location.origin}/story/${privateId}`); copyHelper(); }}>{window.location.origin}/story/{privateId}</p>            
                <p className="notification-subtext">{t("Tap to copy link")}</p>
                {copied && <p className="copy">{t("Copied!")}</p>}
                <div className="share-with-text">{t("Share with")}:</div>
                <div className="buttons-container">
                    <FacebookShareButton
                        url={`${window.location.origin}/story/${privateId}`}
                        // quote={} // deprecated
                        className="default-button facebook-button">
                        <div><Icon size={1} path={ mdiFacebook } /></div>
                        <div className="button-text-social"><span>FACEBOOK</span></div>
                    </FacebookShareButton>
                    <div className="empty-space-to-socialicons"></div>
                    <TwitterShareButton
                        url={`${window.location.origin}/story/${privateId}`}
                        className="default-button twitter-button">
                        <Icon size={1} path={ mdiTwitter  } />
                        <span className="button-text-social">TWITTER</span>
                    </TwitterShareButton>
                </div>
            </div>
        )
    }

    function resetNotification() {
        setNotification({ icon: "", title: "", text: "", success: NotificationType.problem });
    }

    function nonSimirityPeopleTaggedFeedback(close:Function) {
        return(
            <div className="notification-container feedback-story-default">
                <span onClick={() => close()}><Icon className="close-icon" size={0.8} path={ mdiClose } /></span>
                <img src={emoji_happiness} alt="emoji"/>
                <p className="notification-title">{t("Successfully published")}</p>
                <p className="text">{t("Your story included people outside of Simirity that are not on your family list.")}</p>
                <button className="default-button" onClick={() => {setAddToFamilyModal(true)}}>{t("Add to Family List")}</button>
                <button className="default-button nth2" onClick={() => {setShareModal(true)}}>{t("Share Outside Simirity")}</button>
                <AddToYourFamily show={addToFamilyModal} handleUpdateConnections={() => {}} handleClose={() => setAddToFamilyModal(false)} />

                <Modal className="private-sharing-url-modal" show={shareModal} onHide={() => handleFinish()} backdrop={window.innerWidth <= 760 ? true : "static"}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("Share Outside Simirity")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="publish-page">
                        <div className="optional">
                            <p className="title">{t("Enable ‘Share with private link’ to create a shareable link to your story")}</p>
                        {story ? 
                            <PrivateSharingUrl 
                                setShowToolTip={setShowPrivateSharingTT} 
                                showTooltip={showPrivateSharingTT} 
                                story={story}
                                setNotificationEmails={setNotificationEmails} 
                                updateStory={() => null}
                                modal={true}
                                handleFinish={handleFinish} /> : null}                        
                        </div>
                        {notification.title.length ?
                            <Notification data={notification} close={() => resetNotification()} />
                        : null}
                    </Modal.Body>                    
                </Modal>
            </div>
        )
    }

    async function setNotificationEmails(emails:any){

        if(!emails.length) return;

        if(!story) return;

        try {
            await instance.post('/email', { type: "Share with Private Link", param1: story._id, param2: story.title, param3: emails});
            setNotification({ icon:notificationImages.happiness, title:t("Success"), text:t("Message sent successfully"), success:NotificationType.success })

        }
        catch(error) {
            setNotification({ icon:notificationImages.crying, title:t("Failed"), text:t("Message not sent"), success:NotificationType.problem })
        }           

    }

    function copyHelper() {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000)
    }   
    
    function handleFinish() {
        setShareModal(false);
    }    

    function getFeedback() {
        if(storyFeedback !== null) {
            // keep precedence!
            if (storyFeedback === eFeedbackTypes.NO_CONTACT){                
                return noContactFeedback(close);
            } else if (storyFeedback === eFeedbackTypes.REPUBLISHED){
                return republishedFeedback(close);
            } else if(storyFeedback === eFeedbackTypes.NON_SIMIRITY_PEOPLE_TAGGED){
                return nonSimirityPeopleTaggedFeedback(close);
            } else if(storyFeedback === eFeedbackTypes.PRIVATE_URL){
                // TODO: low: if story.privateId does not exist...                
                const privateId = ( story && story.privateId ) ? story.privateId : id;
                return privateUrlFeedback(close, privateId);
            } else {
                const privateId = ( story && story.privateId ) ? story.privateId : id;
                return defaultFeedback(close);
            }
        }
    }

    function openCommentSection(media:IMedia) {
        setSelectedMedia(media);
        setShowMediaCommentModal(true);
    }

    function addCommentToImageCallback() {
        setTriggerCommentCounter((prev) =>  !prev)
    }

    return (
        <div>
            {story ? (
                <div>
                    {getFeedback()}
                <div className="story-details" ref={topRef}>
                    <div className="main-info">
                        <div className="top">
                            <p onClick={() => setinfocardShow(true) } className="infocard modal-icon"><Icon className="storie-info-icon" path={ mdiInformationOutline } /></p>
                            <div className="title-container">
                                <p className="name title">
                                    {(story.featured as IUserDetails).nickname ? (story.featured as IUserDetails).nickname : `${(story.featured as IUserDetails).firstname}`}
                                </p>
                                
                                {story.date && story.updatedAt.length && (
                                    <p className="date">
                                        {getStoryDate(story.date.startDate)} {story.date.endDate && <span> - {getStoryDate(story.date.endDate)}</span>}
                                    </p>
                                )}
                            </div>
                            <p onClick={() => setEditCardShow(true) } className="editcard modal-icon"><Icon size={1} path={ mdiDotsHorizontal } /></p>
                        </div>


                        <div className="user-avatar" onClick={() => history.push('/dashboard/profile/' + (story.featured as IUserDetails)._id)}>
                            <img className="user-picture" src={getImage()} alt="user" />
                        </div>
                        {(story.author as IUserDetails)._id !== (story.featured as IUserDetails)._id ? <p className="author">Author: {`${(story.author as IUserDetails).firstname} ${(story.author as IUserDetails).lastname}`}</p> : null}
                        {story.coAuthors.length ? (
                            <p className="co-author">
                                {t("Co-author(s)")}:
                                {(story.coAuthors as IUserDetails[]).map((coauthor, index) => {
                                    return <span key={coauthor._id}>{` ${coauthor.firstname} ${coauthor.lastname}${index === story.coAuthors.length - 1 ? "" : ","}`}</span>
                                })}
                            </p>
                        ) : null}
                    </div>
                    <div className="separator"></div>
                    <div className="story-body">
                        <p className="title">{story.title}</p>
                        <DisplayStory triggerCommentCounter={triggerCommentCounter} story={story} showInteractions={true} openCommentSection={openCommentSection} />
                    </div>
                    <div className={`fav-container ${loggedInUserData.favorites.stories.includes(story._id) ? "active" : ""}`} onClick={() => addToFavorites()}>
                        <Icon className="big-icon" size={1.5} path={loggedInUserData.favorites.stories.includes(story._id) ? mdiStar : mdiStarOutline}/>
                        <p>{loggedInUserData.favorites.stories.includes(story._id) ? t("FAVOURITE") : t("ADD TO FAVOURITES")}</p>
                    </div>
                    {story.commentsEnabled && <div>
                        <div className="new-block">
                            <div className="add-story-svg">
                                <img src={feather} alt="" style={{zIndex: 100, position: "relative", top: "-8px"}} />
                                <div className="line"></div>
                            </div>
                        </div> 
                        <div ref={commentTopRef} className="comment-section">
                            <CommentsContainer accessToken={story.accessCommentToken || ""} commentsEnabled={true} title={"Story Comments"} related_entity={story._id} comment_type={ECommentType.STORY} />
                        </div>
                        <button onClick={() => topRef.current ? topRef.current.scrollIntoView() : null} className='light-button'>{t("Back to top")}</button>
                    </div>}
                    </div>
                    <div className="story-details-footer">
                        <div className="whats-next">
                            <div className={`whats-next-header ${showNext ? "show" : ""}`} onClick={() => setShowNext(!showNext)}>
                                <p className="main">{t("What's next")}</p>
                                <p className="info-text">{t("Ways to follow on from this story")}</p>
                                <Icon size={1} path={showNext ? mdiChevronUp : mdiChevronDown} />
                            </div>
                            {showNext ?
                            <div className="whats-next-body">
                                {story.storyTags.length ?
                                    <div>
                                        <p className="sub-header">{t("Similar stories")}</p>
                                        {story.storyTags.map((x, index) => {
                                            return(
                                                <div key={index} className="story-tag" onClick={() => history.push({pathname: '/dashboard/stories/2', state: { filters: { story:{storyTags: [(config.storyTags.find(data => data._id === x) as IOption)]} }} })} >
                                                    <p><span>{sameTagStories.filter((story: IStory) => (story.storyTags as string[]).includes(x as string)).length}</span>
                                                    {t((config.storyTags.find(data => data._id === x) as IOption).text)}
                                                    </p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    : null}
                                <p className="sub-header">{t("More stories related to")}</p>
                                <div className="carousel-container">
                                    <UserCarousel users={[(story.featured as IUserDetails), ...(story.participants as IUserDetails[])]} action={ECarouselAction.FILTER} noOfSliderItemsToShow={noOfSliderItemsToShow}/>
                                </div>
                                {story.related.length ?
                                    <div>
                                        <p className="sub-header">{t("Related stories")}</p>
                                        {story.related.map((x, index) => {
                                            return(
                                                <div key={index} className="story-tag" onClick={() => {window.scrollTo(0, 0); history.push({pathname: `/dashboard/story/${(x as IRelatedStory)._id}` })}} >
                                                    <p>
                                                    {(x as IRelatedStory).title}
                                                    </p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    : null}
                            </div> : null}
                        </div>

                    <Help category={"STORIES"} pageName={`STORY PAGE`} />
                    <Infocard story={story} show={infocardShow} clear={() => setinfocardShow(false)} />
                    <StoryActionModal story={story} author={(story.author as IUserDetails)._id === loggedInUserData._id || story.coAuthors.map(x => (x as IUserDetails)._id).includes(loggedInUserData._id)} show={editCardShow} onHide={() => setEditCardShow(false)} redirect={() =>  history.push('/dashboard/create', {data: story})}/>
                    {selectedMedia ? <StoryMediaCommentModal storyAccessToken={story.accessCommentToken || ""} addCommentCallback={addCommentToImageCallback} visual={selectedMedia} show={showMediaCommentModal} onHide={() => setShowMediaCommentModal(false)} /> : null}
                </div>
                </div>
            ) : (
                <div className="spinner-container">
                    <Spinner animation="border" variant="#fff" />
                </div>
            )}
        </div>

    )
}


// Carousel custom arrows
export function CustomRightArrow(props:any) {
    // const { onMove, carouselState: { currentSlide, deviceType } } = rest;
    // onMove means if dragging or swiping in progress.
    return(
        <div className="right-button" onClick={() => props.onClick()}>
            <Icon size={1} path={mdiChevronRight} />
        </div>
    );
  };

export function CustomLeftArrow(props:any){
    // const { onMove, carouselState: { currentSlide, deviceType } } = rest;
    // onMove means if dragging or swiping in progress.
    return(
        <div className="left-button" onClick={() => props.onClick()}>
            <Icon size={1} path={mdiChevronLeft} />
        </div>
    );  };

export default StoryDetails
