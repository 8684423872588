import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import instance from '../../api/api';
import { IUserDetails } from '../../interfaces/authentication';
import { IInvitation, IPendingRelationDB } from '../../interfaces/family';
import { welcomeTypes } from '../../_helper/enum/enum';
import { UserRelationStatus } from '../profile/profile-action-modal.component';
import AddToYourFamily from './add-to-family.component';
import PendingInvitations from './pending-invitations.component';
import { Row, Col } from "react-bootstrap";
import Icon from '@mdi/react';
import { mdiClockOutline } from '@mdi/js';
import default_avatar from "../../assets/images/default-avatar.jpg"
import { useTranslation } from "react-i18next";

/**
 * Local interface for input properties.
 */
interface IDetails {
    next:Function
    setWelcomeType:Function
}

/**
 * Saved user load from cookie
 * @param props : ISavedUser interface
 * @returns void
 */
function ConnectToFamily(props:IDetails) {

    const [ findFamily, setFindFamily ] = useState<boolean>(false);
    const [ pendingRelationsTo, setPendingRelationsTo ] = useState<IPendingRelationDB[]>([]);
    const [ pendingRelationsFrom, setPendingRelationsFrom ] = useState<IPendingRelationDB[]>([]);    
    const [ suggestions, setSuggestions ] = useState<IUserDetails[]>([]);
    const [ invitations, setInvitations ] = useState<IInvitation[]>([]);    
    const [ show, setShow ] = useState<boolean>(false);

    const { t } = useTranslation();    

    useEffect(() => {
        getConnections();
    }, [])

    useEffect(() => {
        if(pendingRelationsTo.length) {
            props.setWelcomeType(welcomeTypes.ENGAGE);
        }
    }, [pendingRelationsTo])

    async function getConnections() {        
        try {
            let pendingRelationResTo = await instance.get('/relation/pending?to=' + localStorage.userid);
            setPendingRelationsTo(pendingRelationResTo.data);

            let pendingRelationResFrom = await instance.get('/relation/pending?from=' + localStorage.userid);                   
            setPendingRelationsFrom(pendingRelationResFrom.data);

            let suggestionsRes = await instance.get('/user/suggestions/' + localStorage.userid);
            setSuggestions(suggestionsRes.data);

            let invitationsRes = await instance.get('/invitation?inviter=' + localStorage.userid);          
            setInvitations(invitationsRes.data);          

            if (pendingRelationResTo.data || pendingRelationResFrom.data || suggestionsRes.data) {
                props.setWelcomeType(welcomeTypes.ENGAGE);
            }
        }
        catch(error) {
            setPendingRelationsFrom([]);
            setPendingRelationsTo([]);
            setSuggestions([]);
            setInvitations([]);
        }
    }

    function updateConnections() {
        getConnections();
    }    

    function nextPageWelcome() {
        props.setWelcomeType(welcomeTypes.WELCOME);
        props.next();
    }

    function nextPageEngage() {
        props.setWelcomeType(welcomeTypes.ENGAGE);
        setFindFamily(true)
    }

    // next button is hidden by css when not first member of family. (button is part of component RegistrationDetails)
    let containerInfo = `connect-to-family-container ${!findFamily && !(pendingRelationsTo.length || pendingRelationsFrom.length || suggestions.length) ? "no-next-button" : ""}`;
    
    return(
        <div className={containerInfo}>
            <p className="connect-title">{t("Connect to family")}</p>
            <p className="connect-info">{t("For security, verify all new contact details")}</p>
            {pendingRelationsTo.length || pendingRelationsFrom.length || suggestions.length || invitations.length?
                <div className="connections-container family-members">
                    {pendingRelationsTo.map((relation:IPendingRelationDB) => {
                        return (<PendingInvitations myProfile={true} userid={localStorage.userid} relation={relation} type={UserRelationStatus.PENDING} acceptRelation={() => null} declineRelation={() => null}/>)
                    })}
                    {pendingRelationsFrom.map((relation:IPendingRelationDB) => {
                        return (
                            <Row key={relation._id} className="family-member">
                            <Col xs={10} className="member-detail">
                                <div className="img-container">
                                    {relation.to && <img className="user-picture" src={relation.to.avatar ? relation.to.avatar : default_avatar} alt="" />}
                                </div>
                                <div className="content">
                                    {relation.to && <p className="name">{`${relation.to.firstname} ${relation.to.lastname}`}</p>}
                                    <p className="invitation-info"><Icon className="icon" size={1} path={ mdiClockOutline } />{t("Pending confirmation")}</p>
                                </div>
                            </Col>
                          </Row>                            
                        )
                    })}                    
                    {suggestions.map((suggestion:IUserDetails) => {
                        return (<PendingInvitations myProfile={true} userid={localStorage.userid} relation={suggestion} type={UserRelationStatus.SUGGESTION} acceptRelation={() => null} declineRelation={() => null}/>)
                    })}
                    {invitations.map((invitation:IInvitation) => {
                        return (
                            <Row key={invitation._id} className="family-member">
                            <Col xs={10} className="member-detail">
                                <div className="img-container">
                                    <img src={default_avatar} className="user-picture" alt="" />
                                </div>
                                <div className="content">
                                    <p>{invitation.email}</p>
                                    <p className="invitation-info"><Icon className="icon" size={1} path={ mdiClockOutline } />{t("Invitation sent")}</p>
                                </div>
                            </Col>
                          </Row>
                        )
                    })}                    
                    <div className="someone-is-missing">
                        <p className="title">{t("Someone missing?")}</p>
                        <button className="link-button" onClick={() => setShow(true)}><span className="plus">+</span> <span className="btn-text">{t("Add to family list")}</span> </button>
                    </div>
                </div>
            :
                <div className="no-pending-container">
                    <p className="no-pending-text">{t("No pending contact requests")}</p>
                    {!findFamily ? 
                        <div className="decision-box">
                            <p>{t("Are you the first family member to join?")}</p>
                            <Button onClick={() => nextPageWelcome()}>{t("YES")}</Button>
                            <Button onClick={() => nextPageEngage()}>{t("NO")}</Button>
                        </div>
                    :
                        <div className="find-your-family-container">
                            <p className="title">{t("Find your family")}</p>
                            <p className="text">{t("Add family in 3 simple steps or add them later from the 'family' menu")}</p>
                            <Button className="link-button" onClick={() => setShow(true)}><span className="plus">+</span> <span className="btn-text">{t("Add to family list")}</span></Button>
                        </div>
                    }
                </div>
            }
            <AddToYourFamily show={show} handleUpdateConnections={updateConnections} handleClose={() => setShow(false)}/>
        </div>
    );
};

export default ConnectToFamily;