import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
//import { Button, Form, Row, Col } from 'react-bootstrap';
import default_avatar from "../../assets/images/default-avatar.jpg";
import TooltipHelp from '../notifications/tooltiphelp.component';
import { IUserOptionalData } from '../../interfaces/authentication';
//import countries from "countries-list";
import Notification, { notificationImages } from '../notifications/notification.component';
import { INotification, ITooltip, NotificationType } from '../../interfaces/notification';
import PhotoUploader from '../profile/photo-uploader.component';
import DatePicker from "react-datepicker";
import Icon from '@mdi/react';
import { mdiCalendar, mdiChevronRight  } from '@mdi/js';
import checkUnderAge from '../../_helper/check-underage';
//import Switch from "react-switch";
//import UniqueSimpleSelect from '../_helper/unique-simple-select.component';
import { useTranslation } from "react-i18next";
//import HubspotContactModal from '../settings/hubspot-contact.modal';
//import { Link } from 'react-router-dom';

/**
 * Local interface for input properties.
 */
interface IDetails {
    setUserDetails:Function
    userDetails:IUserOptionalData
    nextStep:Function
    updateUserData:Function
}

/**
 * Saved user load from cookie
 * @param props : ISavedUser interface
 * @returns void
 */
function DetailedRegistrationInformation(props:IDetails) {

    const [ tooltip, setTooltip ] = useState<boolean>(false);
    const [ notification, setNotification ] = useState<boolean>(false);
    const [ profilePicture, setProfilePicture ] = useState<string>('');

    //const [ monthlyNewsletter, setMonthlyNewsletter ] = useState<boolean>(false);

    const [showHubspotModal, setShowHubspotModal] = useState(false)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { t } = useTranslation();

    const notificationData : INotification = {
        icon:notificationImages.crying,
        title:t("18+ years old"),
        text:t("For legal reasons, users under 18 years old must ask a responsible family member to create an account on your behalf"),
        success: NotificationType.problem
    }    

    const tooltipData : ITooltip = {
        title: t("What's this?"),
        subtitle:t("Your data usage"),
        text: () => <p>{t("Unlike many modern platforms, Simirity does not use your personal data. This is a private service where your data is used by family only. This optional information is asked only to enhance the service for your family.")}</p>,
        clear: () => setTooltip(false),
        show: tooltip,
    }

    async function handleAction() {

        if(!(checkUnderAge(props.userDetails.birthdate))) {
            props.updateUserData()
        }else {
            setNotification(true)
        }
    }
/*
    function setupCountryOptions() {
        const countryOptions :any[] = Object.entries(countries.countries).map(([key, country]) => {
            return(
                {key:country.name, value:country.name}
            );
        }).sort((a, b) => a.key.localeCompare(b.key))

        return countryOptions
    }
*/    

    return(
        <div className="details-container">
            <p className="details-title">{t("Optional Information")}</p>
            <button className="underlined-link-button link-to-data-info" onClick={() => setTooltip(true)}>{t("See how your data is used")}</button>
            <div className="upload-container" onClick={handleShow}>            
                <img src={profilePicture.length ? profilePicture :  default_avatar} alt="avatar" className="user-picture" />
                <p className="link-button">{t("Show family who you are")}</p>
            </div>

            <Form className="registration-form">
                <Form.Group className="group birthdate">
                    <Form.Label className="top-label">{t("Birth date")}</Form.Label>
                    <Icon className="calendar-icon" size={1} path={ mdiCalendar } />
                    <DatePicker
                        required
                        scrollableYearDropdown
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dateFormat='dd-MMM-yyyy'
                        dropdownMode="select"
                        selected={props.userDetails.birthdate instanceof Date ? props.userDetails.birthdate : null}
                        maxDate={new Date()}
                        onChange={(date) => props.setUserDetails((prev:IUserOptionalData) => {return {...prev, birthdate:date}})}
                    />
                    <Form.Label className="bottom-label">{t("Visible to family only")}</Form.Label>
                    {notification ? <Notification data={notificationData} close={() => setNotification(false)}/> : null}
                </Form.Group>
                {/*<Row className="newsletter-row" onClick={() => setShowHubspotModal(true)}>
                    <Col xs={11}>
                        <p className="newsletter-title">{t("Family Newsletter")}</p>
                        <p className="newsletter-description">
                            {t("Dedicated to all things 'family'")}{" "}
                        </p>
                    </Col>
                    <Col className="newsletter-icon-container" xs={1}>
                        <Icon className="right-icon" size={1} path={mdiChevronRight} />
                    </Col>
                </Row>*/}
                {/*<Row className="setting-option-row" onClick={() => setShowHubspotModal(true)}>
                    <Col xs={11}>
                        <p className="settings-title">{t("Family Newsletter")}</p>
                        <p className="settings-description">
                            {t("We dedicate our newsletter to all things 'family'. Sharing Simirity updates and exploring new ideas for your family.")}{" "}
                            <Link to="/dashboard/terms">{t("Terms & Privacy")}</Link>.
                        </p>
                    </Col>
                        <div className="update-container">
                                <div className="monthly-update setting-option-row notifications-row switch-container">
                                    <p className="switch-status">{monthlyNewsletter ? t("On") : t("Off")}</p>
                                    <Switch className={`switch ${monthlyNewsletter ? "active-gold-switch" : ""}`} 
                                            offColor="#17243d"
                                            offHandleColor="#8B919E"
                                            onColor="#006238"
                                            onHandleColor="#006238"
                                            handleDiameter={20}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={14}
                                            width={32}
                                            onChange={(checked) => {props.setUserDetails((prev:IUserOptionalData) => {return {...prev, newsletter: checked}}); setMonthlyNewsletter(checked)}} 
                                            checked={monthlyNewsletter} />
                                </div>                 
                        </div>    
                </Row>  */}              
            </Form>
            <TooltipHelp  {...tooltipData} />
            <Button className="default-button" onClick={() => handleAction()}>{t("Next step")}<Icon size={1} path={ mdiChevronRight } /></Button>
            <PhotoUploader show={show} handleClose={handleClose} setProfilePicture={setProfilePicture} />
            {/*<HubspotContactModal withoutSidebar show={showHubspotModal} close={() => setShowHubspotModal(false)} />*/}
        </div>
    );
};

export default DetailedRegistrationInformation;