import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import './assets/css/style.scss';
import Application from './pages/application';
import { createStore, applyMiddleware, Store } from "redux";
import { Provider } from "react-redux";
import reducer from "./store/reducer";
import { Action, DispatchType, IState } from './interfaces/redux';
import thunk from "redux-thunk";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './i18n';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { MatomoProvider } from '@datapunt/matomo-tracker-react'
import { matomoInstance } from "./matomo/matomo.instance"
import { BrowserRouter } from 'react-router-dom';


Sentry.init({
    dsn: window.__RUNTIME_CONFIG__.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: window.__RUNTIME_CONFIG__.REACT_APP_SENTRY_ENVIRONMENT,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
})

const store: Store<IState, Action> & { dispatch: DispatchType } = createStore(reducer, applyMiddleware(thunk));

ReactDOM.render(
    <MatomoProvider value={matomoInstance}>
        <Provider store={store}>
            <Suspense fallback="loading">
                <BrowserRouter>
                    <Application />
                </BrowserRouter>
            </Suspense>
        </Provider>
    </MatomoProvider>,
    document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();