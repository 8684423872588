import instance from "../../api/api";

const vapidKey = window.__RUNTIME_CONFIG__.REACT_APP_PUSH_NOTIFICATION_VAPID_KEY;

export async function subscribeToNotify() {
    if(!vapidKey) {
        return;
    }
    
    //Create a subscription for the user, and post it into the database to check if it already subscribed or not
    try {
        const serviceWorker = await navigator.serviceWorker.ready;
        console.log('Push Notification: Service Worker has been loaded')
        const existingSub = await serviceWorker.pushManager.getSubscription();

        console.log('Push Notification: Subscription info has been received from pushManager')

        if(!existingSub) {
            console.log('Push Notification: No subscription found in pushManager', existingSub)
            // subscribe and return the subscription
            const subscription = await serviceWorker.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(vapidKey)
            });

            const res = await instance.post('notification/subscribe', subscription );
            console.log('Push Notification: New subscription has been created', res)
        }
        else {
            console.log('Push Notification: Subscription exists', existingSub)
        }
    }
    catch(err) {
        // tipical err if the user blocks notifications OR the response from the API is not 200
        console.log('Push notification: blocked by the user', err)
    }
}

//Helper function to convert url
const urlBase64ToUint8Array = function(base64String : string) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');
    
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}