import { useCallback, Dispatch, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../../store/actionCreators";
import default_avatar from "../../assets/images/default-avatar.jpg";
import smile_emoji from "../../assets/images/notification/emoji-happiness.svg"
import { Context } from "../../pages/home.page";
import config from "../../config/config";
import { useHistory } from "react-router-dom";
import { FacebookShareButton, TwitterShareButton } from "react-share"
import Icon from '@mdi/react';
import { mdiFacebook, mdiTwitter, mdiLinkVariant } from '@mdi/js';
import ReferEmail from "./refer-email.component";

interface IProps {
}

/**
 * 
 * @param props : 
 * @returns void
 */
function Refer(props:IProps) {

    const [copied, setCopied] = useState<boolean>(false);

    const [showReferEmail, setShowReferEmail] = useState(false)

    const dispatch: Dispatch<any> = useDispatch();
    const setPageName = useCallback((currentPage: string) => dispatch(setCurrentPage(currentPage)), [dispatch]);
    setPageName('Refer & Save');

    const user = useContext(Context).user;

    const history = useHistory();

    const referUrl = user.subscription?.privateId ? `${ window.location.origin}/referral/${user.subscription?.privateId}` : ""

    function copyHelper() {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, config.modalCloseTimeout)
    }

    const { t } = useTranslation();

    function getName() {
        if(user.subscription?.owner.nickname) {
            return user.subscription?.owner.nickname
        }
        return user.subscription?.owner.firstname
    }

    return(
        <div className="refer-container">
            <p className="title-text">{t("Help Families Discover Simirity")}</p>
            {user.subscription && user.subscription.subscription_type.fastspring_key === "familytrial" ?            
                <p className="info-text">{"Give others a trial Family Subscription like yours"}</p>
                : 
                <p className="info-text">{"Give Trial ‘Family’ Subscriptions and " + getName() + " saves $20 per referred person who buys a Subscription*"}</p>
            }
            {user.subscription && user.subscription.subscription_type.fastspring_key !== "familytrial" ?
                <div className="image-container">
                    <img className="user-picture" src={user.subscription?.owner.avatar ? user.subscription?.owner.avatar : default_avatar} alt="user" />
                    <div className="small-gray-section">
                        <p className="small-gray-text">{t("*Up to 100% off on next years Subscription. Purchase must be made prior to trial ending.")}</p>
                        <p className="small-gray-text">{t(" Subject to")} <span className="link-text" onClick={() => history.push("/dashboard/terms")}>Terms</span></p>     
                    </div>
                </div>
                :
                <div className="small-gray-section">
                    <p className="small-gray-text">{t(" Subject to")} <span className="link-text" onClick={() => history.push("/dashboard/terms")}>Terms</span></p>     
                </div>
            }
            <div className="referral-box-container">
                <div className="friends-green-box">
                    <p>{t("FRIENDS")}</p>
                    <p>{t("(90 DAY 'FAMILY' SUBSCRIPTION*)")}</p>
                </div>
                <div className="emoji-conatiner">
                    <img className="emoji" style={{width: "80px", marginTop: "-45px"}} alt="simle-emoji" src={smile_emoji}/>
                </div>
                <p className="referral-link">{t("Referal link:")}</p>

                <div className="ref-link-section">
                    <span className="icon-container">
                        <Icon size={0.8} path={mdiLinkVariant} />
                    </span>
                    <p className="link-text-referral" onClick={() => { navigator.clipboard.writeText(referUrl); copyHelper() }}>{referUrl}</p>
                </div>
                {copied && <p className="copy">Copied!</p>}
                
                <div className="buttons-container">
                    <button className="default-button" onClick={() => setShowReferEmail(true)}>Email</button>
                    <FacebookShareButton
                        url={referUrl}
                        // quote={} // deprecated
                        className="default-button facebook-button">
                        <Icon size={1} path={ mdiFacebook } />
                        <span className="button-text">FACEBOOK</span>
                    </FacebookShareButton>
                    <TwitterShareButton
                        url={referUrl}
                        className="default-button twitter-button">
                        <Icon size={1} path={ mdiTwitter  } />
                        <span className="button-text">TWITTER</span>
                    </TwitterShareButton>
                </div>
                <div className="small-gray-section"><p className="small-gray-text">* No credit card needed. ‘Family’ Subscription worth $15, with 10 GB media uploads.</p></div>
            </div>
            <ReferEmail show={showReferEmail} url={referUrl} handleClose={() => setShowReferEmail(false)} />
        </div>
    );
};

export default Refer;