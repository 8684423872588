const createImage = (url: string) =>
    new Promise<HTMLImageElement>((resolve, reject) => {
        const image = new Image()
        image.addEventListener("load", () => resolve(image))
        image.addEventListener("error", (error) => reject(error))
        image.src = url
    })

/**
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 */
export default async function getCroppedImg(imageSrc: string, pixelCrop: any) {
    const image: HTMLImageElement = await createImage(imageSrc)
    const canvas: HTMLCanvasElement = document.createElement("canvas")
    const ctx: CanvasRenderingContext2D | null = canvas.getContext("2d")

    const maxSize: number = Math.max(image.width, image.height)
    const safeArea: number = 2 * ((maxSize / 2) * Math.sqrt(2))

    // set each dimensions to double largest dimension to allow for a safe area for the image
    canvas.width = safeArea
    canvas.height = safeArea

    if (ctx !== null) {
        // draw image and store data.
        ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5)
        const data: ImageData = ctx.getImageData(0, 0, safeArea, safeArea)

        // set canvas width to final desired crop size - this will clear existing context
        canvas.width = pixelCrop.width
        canvas.height = pixelCrop.height

        // paste generated image with correct offsets for x,y crop values.
        ctx.putImageData(data, Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x), Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y))

        // As a blob
        return new Promise<any>((resolve) => {
            canvas.toBlob((file:any) => {
                resolve({blob:URL.createObjectURL(file), file:file})
            }, "image/jpeg")
        })
    }
}
