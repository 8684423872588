import React, { useContext, useEffect, useState } from 'react';
import { mdiPaperclip, mdiCodeTags, mdiLinkPlus, mdiLock, mdiMicrophoneOutline, mdiScriptTextOutline, mdiViewHeadline, mdiCamera, mdiVideo } from '@mdi/js';
import Icon from '@mdi/react';
import { Modal } from 'react-bootstrap';
import { blockTypes, IBlock, IStory } from '../../../interfaces/story';
import AttachmentBlockInput from './input-types/attachment-block-input.component';
import AudioBlockInput from './input-types/audio-block-input.component';
import EmbedBlockInput from './input-types/embed-block-input.component';
import LifelessonBlockInput from './input-types/lifelesson-block-input.component';
import LinkalbumBlockInput from './input-types/linkalbum-block-input-component';
import MediaBlockInput from './input-types/media-block-input.component';
import TextBlockInput from './input-types/text-block-input.component';
import VideoBlockInput from './input-types/video-block-input.component';
import { Context } from '../../../pages/home.page';
import { useHistory } from 'react-router-dom';
import { getAccesForBlock } from '../../_helper/limit-checkers';
import { useTranslation } from "react-i18next";
import { ESubscriptionStatus } from '../../../interfaces/subscription';

interface IProps {
    show: boolean
    handleClose: Function
    addToStory: Function
    blockType: blockTypes | boolean
    value: IBlock | boolean
    index: number //Where to put the block
    blockId:string
    updateStory:Function
    story:IStory
}

export interface IdefBlock {
    type:blockTypes
    icon:any
    titleText:string
    text:string
    active:boolean
}

export default function BlockPicker(props:IProps) {

    const setPageDataContext = useContext(Context).setPageData;
    const pageData = useContext(Context).pageData;

    const [ selected, setSelected ] = useState<blockTypes | boolean>(false);
    const [ disabledBlocks, setDisabledBlocks ] = useState<string[]>([]);

    const [textEditorFullScreen, setTextEditorFullScreen] = useState(false)

    //Context data
    const userData = useContext(Context).user;

    const history = useHistory();

    const { t } = useTranslation();

    let defaultBlocks:IdefBlock[] = [
        {type:blockTypes.TEXT, icon:mdiViewHeadline, titleText:t("Add Text"), text:"Text", active:true},
        {type:blockTypes.IMAGE, icon:mdiCamera, titleText:t("Add Photos"), text:"Photos", active:true},
        {type:blockTypes.VIDEO, icon:mdiVideo, titleText:t("Add Video"),  text:"Video", active:true},
        {type:blockTypes.AUDIO, icon:mdiMicrophoneOutline, titleText:t("Add Audio"), text:"Audio", active:true},
        {type:blockTypes.EMBED, icon:mdiCodeTags, titleText:t("Embed"), text:"Embed", active:true},
        {type:blockTypes.LINK_ALBUM, icon:mdiLinkPlus, titleText:t("Link Album"), text:"Link Album", active:true},
        {type:blockTypes.ATTACHMENT, icon:mdiPaperclip, titleText:t("Add Attachment"), text:"Attachment", active:true},
        {type:blockTypes.LIFE_LESSON, icon:mdiScriptTextOutline, titleText:t("Add Life Lesson"), text:"Life Lesson", active:true}
    ]    

    useEffect(() => {
        if(props.blockType) {
            setSelected(props.blockType);
        }
    }, [props.blockType, props.show])

    useEffect(() => {
        getLimit()
    }, [props.story.blocks])

    function getTypeInput() {
        switch (selected) {
            case blockTypes.TEXT:
                return <TextBlockInput setFullScreen={setTextEditorFullScreen} addBlockToStory={addBlockToStory} data={props.value ? props.value : false} />
            case blockTypes.MEDIA:
                return <MediaBlockInput story={props.story} updateStory={props.updateStory} addBlockToStory={addBlockToStory} data={props.value ? props.value : false} />
            case blockTypes.IMAGE:
                return <MediaBlockInput story={props.story} updateStory={props.updateStory} addBlockToStory={addBlockToStory} data={props.value ? props.value : false} />
            case blockTypes.VIDEO:
                return <VideoBlockInput story={props.story} updateStory={props.updateStory} addBlockToStory={addBlockToStory} data={props.value ? props.value : false} />
            case blockTypes.CAROUSEL:
                return <MediaBlockInput story={props.story} updateStory={props.updateStory} addBlockToStory={addBlockToStory} data={props.value ? props.value : false} />
            case blockTypes.AUDIO:
                return <AudioBlockInput story={props.story} closeModal={close} addBlockToStory={addBlockToStory} data={props.value ? props.value : false} />
            case blockTypes.ATTACHMENT:
                return <AttachmentBlockInput story={props.story} updateStory={props.updateStory} addBlockToStory={addBlockToStory} data={props.value ? props.value : false} />
            case blockTypes.LINK_ALBUM:
                return <LinkalbumBlockInput addBlockToStory={addBlockToStory} data={props.value ? props.value : false} />
            case blockTypes.EMBED:
                return <EmbedBlockInput addBlockToStory={addBlockToStory} data={props.value ? props.value : false} />                                    
            case blockTypes.LIFE_LESSON:
                return <LifelessonBlockInput addBlockToStory={addBlockToStory} data={props.value ? props.value : false} />
        }
    }

    function addBlockToStory(block: IBlock) {

        setSelected(false);
        const data = {...block};
        if(props.blockId.length) {
            data._id = props.blockId
        }

        props.addToStory(data, props.index);
        props.handleClose();
    }

    function close() {
        pageData.wavesurfers.forEach((wavesurfer) => {
            wavesurfer.destroy();
        })
        // destroy the existing wavesurfers
        const newPageData = {...pageData, wavesurfers: []}
        setPageDataContext(newPageData)
        setSelected(false);
        props.handleClose();
    }

    // determine (block) Title for the Modal Header
    const selectedBlockType = defaultBlocks.filter(blockType => selected === blockType.type);
    const selectedTitle = selectedBlockType.length ? selectedBlockType[0].titleText : t("Add Story Block");
    const selectedBlockText= selectedBlockType.length ? selectedBlockType[0].type : "";

   let selectedBlocktext;
    if(selectedBlockText === 'TEXT') {
        selectedBlocktext = `block-picker-modal ${textEditorFullScreen ? "text-editor-with-fullscreen" : "text-editor-width"} `;
   }
   else {
        selectedBlocktext = "block-picker-modal text-editor-width-no-fullscreen";
   }

    async function getLimit() {
        const disabled_blocks = await getAccesForBlock(userData, defaultBlocks)
        setDisabledBlocks(disabled_blocks)
    }

    return(
        <Modal dialogClassName={selectedBlocktext} backdrop="static" show={props.show} onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>{selectedTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!selected ? <div className="block-picker">
                    <p className="select">{t("Select block type")}</p>
                    {defaultBlocks.map((block, index) => {
                        const active = !disabledBlocks.includes(block.type);
                        return(
                            <div key={index} className={`select-box ${!active ? 'disabled' : ''}`} onClick={() => active ? setSelected(block.type) : null} role="button">
                                <Icon className="mdi-icon-select-box" size={1} path={block.icon} />
                                <p className="block-name">{t(block.text)}</p>
                                {!active && <Icon className="disabled-icon" size={1.1} path={mdiLock}/>}
                            </div>
                        )
                    })}
                    {(!userData.subscription || disabledBlocks.length || userData.subscription.status === ESubscriptionStatus.INACTIVE)  ? <p className="subscription-info"><span className="link-text" onClick={() => history.push("/dashboard/account/subscription/info")}>{t("Upgrade")}</span> {t("to unlock blocks")}</p> : null}
                </div> :
                <div className={"input " + selected}>{getTypeInput()}</div>}
            </Modal.Body>
        </Modal>
    );
};
