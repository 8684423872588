import React, { useContext, useEffect, useState } from 'react';
import Icon from '@mdi/react';
import { mdiChevronLeft, mdiMenu } from '@mdi/js';
import { Row, Col } from 'react-bootstrap';
import RightSidebar from './right-sidebar.component';
import { IState } from '../../interfaces/redux';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Context } from '../../pages/home.page';
import { useTranslation } from "react-i18next";

/**
 * Local interface for input properties.
 */
interface IProps {
}

/**
 *
 * @param props :  interface
 * @returns void
 */
function Header(props:IProps) {
    const [show, setShow] = useState<boolean>(false);

    const currentPage: string = useSelector((state: IState) => state.currentPage, shallowEqual);
    const pageData = useContext(Context).pageData;

    const history = useHistory();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { t } = useTranslation();    

    return(
        <Row className="header">
            <Col className="back-btn">
                {/* TODO: Put logo hear */}
                <span onClick={() => history.goBack()}><Icon size={1.4} path={ mdiChevronLeft } /></span>
            </Col>
            <Col className="page-title">
                <p>{t(currentPage)}</p>
            </Col>
            <Col className="menu-container">
                <span onClick={handleShow}><Icon size={1} path={ mdiMenu } /></span>
                {pageData.notifications > 0 ? <div className="dot" onClick={handleShow}>
                    <p>{pageData.notifications < 10 ? pageData.notifications : "9+"}</p>
                </div> : null }
                <RightSidebar show={show} handleClose={handleClose} />
            </Col>
        </Row>
    );
};

export default Header;
