import React, { Dispatch, useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../../../store/actionCreators";
import { Tabs, Tab, Badge } from "react-bootstrap";
import StoryRecievedRequests from "./story-recieved-requests.component";
import StoryRequestsCreate from "./story-requests-create.component";
import StorySentRequests from "./story-sent-requests.component";
import { useLocation, useHistory } from "react-router-dom";
import Help from "../../_helper/help.component";
import { Context } from "../../../pages/home.page";
import { useTranslation } from "react-i18next";

interface IProps {

}

export enum menu {
    CREATE = "1",
    SENT = "2",
    RECEIVED = "3",
}

export default function StoryRequests(props:IProps) {

    const [ key, setKey ] = useState(menu.CREATE);
    const [ sentListUpdated, setSentListUpdated ] = useState<number>(0);
    const [ numOfSentRequests, setNumOfSentRequests ] = useState<number>(0);    
    const [ requestId, setRequestId ] = useState<string>("");
    const location: any = useLocation();
    const history = useHistory();
    const dispatch: Dispatch<any> = useDispatch();
    const setPageName = useCallback((currentPage: string) => dispatch(setCurrentPage(currentPage)), [dispatch]);

    const pageData = useContext(Context).pageData;

    const [ numberOfReceivedRequests, setNumberOfReceivedRequests ] = useState<number>(0);

    const { t } = useTranslation();

    useEffect(() => {
        if(location.state && location.state.hasOwnProperty('requestId')){
            setRequestId(location.state.requestId);
        }
        if(location.state && location.state.hasOwnProperty('subTab')){
            setKey(location.state.subTab);
        }
    })

    useEffect(() => {
        setPageName('Requests');
    }, []);

    useEffect(() => {
        setNumberOfReceivedRequests(pageData.receivedRequests);
    }, [pageData]);      

    function updateSentList(){
        setSentListUpdated(sentListUpdated + 1);
    }

    function handleNumOfSentRequests(num:number){
        setNumOfSentRequests(num)
    }

    return(
        <div className="story-requests-container">
            <Tabs className="menu-tabs tabs-launchpad menu-level-2" activeKey={key} onSelect={(k) => {setKey(k as menu);history.push({pathname: "/dashboard/requests/" + (k as string) + "/list", state: { subTab: (k as menu)}})}}> {/*onSelect={(k) => setKey((k as menu))}>*/}
                <Tab eventKey={menu.CREATE} title={t("Create")}>
                    <StoryRequestsCreate handleUpdateSentList={updateSentList} numOfSentRequests={numOfSentRequests} />
                    <Help category={"REQUESTS"} pageName={"Story Requests - Create"}></Help>
                </Tab>
                <Tab eventKey={menu.SENT} title={t("Sent")}>
                    <StorySentRequests requestId={requestId} update={sentListUpdated} numOfSentRequests={handleNumOfSentRequests} />
                    <Help category={"REQUESTS"} pageName={"Story Requests - Sent"}></Help>
                </Tab>
                <Tab eventKey={menu.RECEIVED} title={
                    <React.Fragment>
                        {t("Received")}
                        {numberOfReceivedRequests > 0 && <span className="dot">{numberOfReceivedRequests}</span>}
                    </React.Fragment>
                    }>
                    <StoryRecievedRequests requestId={requestId} />
                    <Help category={"REQUESTS"} pageName={"Story Requests - Received"}></Help>                    
                </Tab>
            </Tabs>
        </div>
    );
}