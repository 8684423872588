import React, { useState, useContext } from 'react';
import { dateFormat } from '../../_helper/enum/enum';
import Icon from '@mdi/react';
import { mdiCameraOutline, mdiVolumeHigh, mdiCommentTextOutline, mdiEyeOutline, mdiMovieOutline } from "@mdi/js"
import { Modal } from "react-bootstrap"
import example_story from "../../assets/images/example-story3.jpg";
import example_picture from "../../assets/images/jakob-owens-M0M-FR2iedk-unsplash-1920x1280.jpg";
import logo_icon from "../../assets/images/logo-icon.jpg";
import moment from "moment";

/**
 * Local interface for input properties.
 */

interface IProps {
    openByLink?: boolean
}

/**
 * 
 * @param props : 
 * @returns void
 */
function ExampleStory(props:IProps) {

    const [showExampleStoryModal, setShowExampleStoryModal] = useState<boolean>(false);
 
    return(
        <div className="example-story-container story-item-container">
            <div className="story-item-content" onClick={() => {setShowExampleStoryModal(true)}}>
                {props.openByLink ? 
                <div className='open-by-link'>
                    <p className="link-button">View Example Story</p>
                </div>
                :
                <div>                            
                    <div className="story-item-info">
                        <h3 className="story-item-title">Example Story</h3>
                        <img src={logo_icon} className="user-picture" alt="user"/>
                        <p className="story-item-author"><span className="user-name">Simirity</span><span className="story-date">{moment(new Date()).format(dateFormat.SHORT)}</span></p>
                    </div>                    
                    <div className="story-blocks">
                        <div className="content">
                            <p>Illustrating how a story looks with a wide range of different story blocks.</p>                                    
                            <img alt="Example Picture" src={example_picture}/>
                        </div>
                    </div>   
                    <div className="story-item-footer">
                        <div className="toolbar">
                            <span title="image"><Icon className="icon" size={0.8} path={mdiCameraOutline} />9</span>
                            <span title="video"><Icon className="icon" size={0.8} path={mdiMovieOutline} />1</span>
                            <span title="audio"><Icon className="icon" size={0.8} path={mdiVolumeHigh} />1</span>
                            <span title="comments"><Icon className="icon" size={0.8} path={mdiCommentTextOutline} />25</span>
                        </div>
                        <div className="readtime">
                            <span title="read time"><Icon className="icon" size={0.8} path={mdiEyeOutline} />2 Min</span>
                        </div>
                    </div>
                </div>}
            </div>
            {showExampleStoryModal ?
                <Modal className="example-story-modal" show={showExampleStoryModal} onHide={() => setShowExampleStoryModal(false)}>
                    <Modal.Header>
                        <Modal.Title>Example Story</Modal.Title>
                        <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowExampleStoryModal(false)}></button>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <img alt="Example Story" src={example_story}/>
                        </div>
                    </Modal.Body>
                </Modal> : null}                      
        </div>
    );
};

export default ExampleStory;