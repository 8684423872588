import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import coin_hand from "../../../assets/images/coin-hands.svg";

export default function CreditWidget() {

    const history = useHistory();

    return(
        <div className="credit-widget">
            <img src={coin_hand} alt="coin-hand" />
            <p>Refer friends to save on your family’s Subscription</p>
            <Button className="default-button" onClick={() => history.push("/dashboard/referandsave/refer")}>Learn More</Button>
        </div>
    )
}