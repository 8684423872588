import React, { useContext, useEffect, useState } from 'react';
import default_avatar from "../../assets/images/default-avatar.jpg";
import Icon from '@mdi/react';
import { mdiCloseCircle } from '@mdi/js';
import instance from '../../api/api';
import { defaultUserData, IOption, IRelation } from '../../interfaces/family';
import { Modal } from 'react-bootstrap';
import { IUserDetails } from '../../interfaces/authentication';
import Notification, { notificationImages } from '../notifications/notification.component';
import { INotification, NotificationType } from '../../interfaces/notification';
import { profileTypes } from '../../_helper/enum/enum';
import { Context } from '../../pages/home.page';
import config from '../../config/config';

interface IProps {
    userid:string
    administrators: IUserDetails[]
    relations: IRelation[]  
}

const successNotificationData: INotification = { icon: notificationImages.like, title: "Success", text: "Successfully added an administrator", success: NotificationType.success }
const errorNotificationData: INotification = { icon: notificationImages.crying, title: "Fail", text: "Failed to update administrators", success: NotificationType.problem }

/**
 * 
 * @param props :  interface
 * @returns void
 */
function SelectAdministrator(props:IProps) {

    const [ administrators, setAdministrators ] = useState<IOption[]>([]);
    const [ familyMembers, setFamilyMembers ] = useState<IRelation[]>([]);
    const [ search, setSearch ] = useState<string>('');

    const [ selected, setSelected ] = useState<IOption>({_id:"", text:"", img:""});

    //Notifications
    const [ notification, setNotification ] = useState<boolean>(false);
    const [ errorNotification, setErrorNotification ] = useState<boolean>(false);

    const [ updateByInComponentState, setUpdateByInComponentState ] = useState<IUserDetails>(defaultUserData);

    //Modal
    const [ show, setShow ] = useState<boolean>(false);
  
    const loggedInUserData = useContext(Context).user;
    const updateLoggedInUserData = useContext(Context).setUserDetails;

    useEffect(() => {   
        getUserinfo(props.relations, props.administrators);
    }, [props.relations, props.administrators])

    useEffect(() => {   
        if (updateByInComponentState._id.length) {
            getUserinfo(updateByInComponentState.relations, updateByInComponentState.administrators);
        }
    }, [updateByInComponentState])    
    

    async function getUserinfo(relations:IRelation[], administrators:IUserDetails[]) {                 
        const admins: IOption[] = administrators.map((admin : IUserDetails) => { return {_id:admin._id, text: `${admin.firstname} ${admin.lastname}`, img:admin.avatar ? admin.avatar : ''}})
        const adminIds = admins.map(admin => admin._id)
        setAdministrators(admins);

        // only an Adult can be an Admin
        const canBeAdmins = relations.filter((member:IRelation) => member.userid.profileType === profileTypes.ADULT).filter((member:IRelation) => !adminIds.includes(member.userid._id))
        setFamilyMembers(canBeAdmins);

    }

    async function updateAdministrators(admins:string[]) {
        try {
            const response = await instance.put('/user/' + props.userid, { details: { administrators: admins }});

            // update Context only if this is Me
            if(props.userid===loggedInUserData._id) {
                updateLoggedInUserData(response.data);
            } else {
                setUpdateByInComponentState(response.data);
            }

            setNotification(true);
        }
        catch(error) {
            setErrorNotification(true);
        }
    }

    async function addAdministrator() {
        let admins = [...administrators, selected];
        if(!administrators.map(x => x._id).includes(selected._id)) {
            await updateAdministrators(admins.map(x => x._id));
        } else {
            setErrorNotification(true);         
        }

        setTimeout(() => {
            setShow(false);
            setNotification(false);
            setErrorNotification(false);
            setSearch('');
            setSelected({_id:"", text:"", img:""});
        }, config.modalCloseTimeout);        
    }

    async function removeAdministrator(admin:IOption) {
        try {
            let admins = administrators.filter(data => data._id !== admin._id);
            await updateAdministrators(admins.map(x => x._id));
            setNotification(true);
        }
        catch(error) {
            setErrorNotification(true); 
        }

        setTimeout(() => {
            setShow(false);
            setNotification(false);
            setErrorNotification(false);
        }, config.modalCloseTimeout); 
    }

    function closeModal() {
        setShow(false);
        setNotification(false);
        setErrorNotification(false);
    }
    return(
        <div className="select-administrator-container">
            <div className="group">
                <input className="admin-select" placeholder="Search name" value={search} onChange={(evt) => setSearch(evt.target.value)}></input>
                <p className="top-label">Search name</p>
            </div>
            {search.length ? 
            <div className="avalible-family-members">
                {familyMembers.filter((member:IRelation) => member.userid.firstname.match(new RegExp(search, "i")) || member.userid.lastname.match(new RegExp(search, "i"))).map(member => {
                    return(<p onClick={() => {setSelected({_id:member.userid._id, text: ` ${member.userid.firstname} ${member.userid.lastname}`, img: member.userid.avatar ? member.userid.avatar : ""}); setShow(true)}}>{member.userid.firstname} {member.userid.lastname}</p>);
                })}
            </div> : null}
            {administrators.length ? 
            <div className="administrators">
                {administrators.map(admin => {
                    return(
                        <div key={admin._id} className="admin-info">
                            <div className="profile">
                                <img src={admin.img ? admin.img : default_avatar} className="user-picture" alt="profile"></img>
                                <p>{admin.text}</p>
                            </div>
                            <span onClick={() => removeAdministrator(admin)}><Icon size={1} path={ mdiCloseCircle } /></span>
                        </div>
                    )
                })}
            </div> : null }
            <Modal className="administrator-modal" show={show} onHide={() => closeModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>Authorisation</Modal.Title>                
                </Modal.Header>
                <Modal.Body>
                    <p className="content-title">Adding an administrator</p>
                    <img className="user-picture" src={selected.img ? selected.img : default_avatar} alt="admin"/>
                    <p className="name">{selected.text}</p>
                    <p className="info-text">{props.userid===loggedInUserData._id ? "I hereby grant Administrators management rights on my profile and family contacts" : "I agree to give Admin rights on a 3rd parties profile to the aforementioned person"}, as described in the <a className="underlined-link-button" href="/dashboard/terms">Terms & Privacy</a></p>
                    <button className="default-button" disabled={!selected._id.length} onClick={() => addAdministrator()}>Grant Administrator access</button>               
                    {notification ? <Notification data={successNotificationData} close={() => setNotification(false)}/> : null}
                    {errorNotification ? <Notification data={errorNotificationData} close={() => setErrorNotification(false)}/> : null}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default SelectAdministrator;