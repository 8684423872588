import React, { useRef, useState } from 'react';
import Icon from "@mdi/react";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";

/**
 *
 * @param props :  interface
 * @returns void
 */
function TabSlider(props: any) {

    // This ref is for the ul element containing the tab links
    const scrl = useRef() as React.MutableRefObject<HTMLUListElement>;

    const [scrollX, setScrollX] = useState<number>(0); // For detecting start scroll position
    const [scrollEnd, setScrollEnd] = useState<boolean>(false); // For detecting end of scrolling

    const slide = (shift: number) => {
        scrl.current.scrollLeft += shift;
        setScrollX(scrollX + shift); // Updates the latest scrolled position

        // For checking if the scroll has ended
        if (Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth) {
            setScrollEnd(true);
        } else {
            setScrollEnd(false);
        }
    };

    // This will check scroll event and checks for scroll end
    const scrollCheck = () => {
        setScrollX(scrl.current.scrollLeft);
        if (Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth) {
            setScrollEnd(true);
        } else {
            setScrollEnd(false);
        }
    };
    const checkWindowWidthHandle = () => props.checkWindowWidth()
    const totalTab = props.children.length;

    return (checkWindowWidthHandle() ? (
        <div className="mobile-slider-tabs" style={{maxWidth: props.maxWidth}}>
            {scrollX !== 0 && totalTab > 3 && (
            <button className="prev left-button" onClick={() => slide(-120)}><Icon size={1} path={mdiChevronLeft} /></button>)}
            <ul ref={scrl} onScroll={scrollCheck}>
                {props.children}
            </ul>
            {!scrollEnd && totalTab > 3 && (
            <button className="next right-button" onClick={() => slide(+120)}><Icon size={1} path={mdiChevronRight} /></button>)}
        </div>
        ) : (<></>)        
    );
};

export default TabSlider;
