import React, { useState, useContext } from 'react';
import { Button, Modal } from "react-bootstrap"
import { useHistory } from 'react-router-dom';
import { welcomeTypes } from '../../_helper/enum/enum';
import { engagementData } from '../family/welcome-engage.component';
import { Tabs, Tab } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiCheck } from '@mdi/js';
import { Context } from "../../pages/home.page"
import AddToYourFamily from '../family/add-to-family.component';
import instance from "../../api/api";
import IFrameDisplay from '../_helper/iframe-display.component';

/**
 * Local interface for input properties.
 */

interface IDetails {
    welcomeType:string
    completed:boolean[]
}

/**
 * 
 * @param props : 
 * @returns void
 */
function WelcomeGame(props:IDetails) {

    const [learnMore, setLearnMore] = useState<boolean>(false); // do not remove for now

    const [showModal, setShowModal] = useState<boolean>(false);

    const [addToFamilyModal, setAddToFamilyModal] = useState<boolean>(false);

    const history = useHistory();

    //Logged in user
    const loggedInUserData = useContext(Context).user;

    let loggedInUserNoDbData = useContext(Context).userNoDb;

    const updateLoggedInUserNoDbData = useContext(Context).setUserNoDbDetails;    

    const actionButton = [
        { action: "/dashboard/launchpad/1", text: "First Story"},
        { action: `/dashboard/profile/${loggedInUserData._id}/2`, text: "Add Family"},
        { action: "/dashboard/requests", text: "Requests"},        
    ];

    async function updateUserGame() {

        let settings = loggedInUserData.settings;
        if(!settings) return;
        if(!settings.games) return;
        settings.games.welcome = false;

        const details = { settings }

        try {
            await instance.put('/user/' + localStorage.userid, {details:details});
            setShowModal(false);            
            history.push('/dashboard/stories/1'); // TODO: low: mitol frissul a context?
        }
        catch(error) {
            console.log('error', error)
        }
    }    

    function removeNow(){
        loggedInUserNoDbData.welcomeTodayGame = new Date();
        updateLoggedInUserNoDbData(loggedInUserNoDbData);
        history.push('/dashboard/stories/1'); // TODO: low: make it nicer other than redirect to refresh screen
        setShowModal(false);
    }    

    return(
        <div className="game-container registration-details-container welcome-engage">
            <p className="title">{`${props.welcomeType === welcomeTypes.WELCOME ? 'Getting started' : 'Getting started'}`}</p> {/* 'Welcome' : 'Engage'} Family` */}
            <button className="btn-close" onClick={() => setShowModal(true)}></button>
                <ul className="relative-titles nav nav-tabs">
                {engagementData.filter(x => x.type === props.welcomeType).map((element, idx) => {
                    return(
                        <li key={idx} className={props.completed.findIndex(x=>!x) === idx ? "active" : ""}>{actionButton[idx].text}</li>
                    )
                })}
                </ul>
            <Tabs activeKey={props.completed.findIndex(x=>!x)+1}>                     
                {engagementData.filter(x => x.type === props.welcomeType).map((element, idx) => {
                    return(
                        <Tab key={idx} 
                        eventKey={idx+1}
                        title={props.completed[idx] ? <Icon className="done-icon" size={1} path={ mdiCheck } /> : idx+1}
                        tabClassName={props.completed[idx] ? "completed" : ""}>
                        {/*learnMore ? */}
                            <div className="game-item-content item">
                                <IFrameDisplay pageName={idx===0 ? "getting_started-1" : idx===1 ? "getting_started-2" : "getting_started-3"} />
                                {/*<img src={element.imgUrl}  alt={actionButton[idx].text} className="user-picture" />
                                {//<p className="title">{element.title}</p>}
                                <p className="subtitle">{element.subtitle}</p>
                                <p className="description">{element.quote.text}</p>
                                <p className="author">{element.quote.author}</p>*/}
                                <Button className="default-button" onClick={() => idx===1 ? setAddToFamilyModal(true) : history.push(actionButton[idx].action)}>{actionButton[idx].text}</Button>
                            </div>
                        {/*}: <Button className="underlined-link-button" onClick={() => setLearnMore(true)}>Learn More</Button> */}
                        </Tab>                    
                    );
                })}
            </Tabs>
            <AddToYourFamily show={addToFamilyModal} handleUpdateConnections={() => {}} handleClose={() => setAddToFamilyModal(false)} />
            <Modal className="remove-banner-modal" backdrop="static" show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header>
                    <Modal.Title>Remove banner</Modal.Title>
                    <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowModal(false)}></button>                                
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="button-group">
                            <Button className="link-button" onClick={() => removeNow()}>
                                Remove Now Only
                            </Button>
                            <Button className="link-button" onClick={() => updateUserGame()}>
                                Remove Permanently
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>               
        </div>
    );
};

export default WelcomeGame;