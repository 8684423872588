import React, { useContext, useEffect, useState } from 'react';
import { all_emojis, ECommentEmoji, ICommentDB } from './comments-container.component';
import default_avatar from "../../assets/images/default-avatar.jpg";
import moment from 'moment';
import { dateFormat } from '../../_helper/enum/enum';
import Icon from '@mdi/react';
import { mdiCheck, mdiClose, mdiDelete, mdiPencil } from '@mdi/js';
import { Context } from '../../pages/home.page';
import { Form } from 'react-bootstrap';
import commentAPIInstance from '../../api/comment_api';
import ConfirmModal from '../_helper/confirm-modal.component';
import { IUserDetails } from '../../interfaces/authentication';
import { useTranslation } from "react-i18next";

/**
 * Local interface for input properties.
 */
interface IProps {
    comment:ICommentDB
    user:IUserDetails
    deleteComment:Function
    accessToken: string
}

/**
 * @param props :
 * @returns void
 */
function Comment(props:IProps) {

    //Edit
    const [ editComment, setEditComment ] = useState<boolean>(false);
    const [ newCommentText, setNewCommentText ] = useState<string>("");

    //delete
    const [ deleteConfrimModal, setDeleteConfirmModal ] = useState<boolean>(false);

    const userData = useContext(Context).user

    const { t } = useTranslation();

    useEffect(() => {
        if(props.comment.data){
            setNewCommentText(props.comment.data)
        }
    }, [props.comment.data])
    
    function getTimeElapsed(date:any) {
        let current = moment(date);
        let minutes = moment().diff(current, 'minutes');
        if(minutes === 0) {
            return t("now")
        }
        else if(minutes < 60) {
            return minutes + " " + t("mins ago")
        }
        else {
            let hours = moment().diff(current, 'hours');
            if(hours < 24) {
                return hours + " " + t("hours ago")
            }
            else {
                return moment(date).format(dateFormat.SHORT)
            }
        }
    }

    function getCommentImage() {
        if(props.comment.emoji && props.comment.emoji !== ECommentEmoji.NONE && props.user) {
            return(
                <div>
                    <img className='user-picture' src={props.user.avatar ? props.user.avatar : default_avatar} alt="avatar" />
                    <img className='emoji' src={all_emojis[props.comment.emoji]} alt="emoji"/>
                </div>
            );
        }
        else if(props.user) {
            return(
                <div>
                    <img className='user-picture' src={props.user.avatar ? props.user.avatar : default_avatar} alt="avatar" />
                </div>
            )
        }
        else {
            return(
                <div>
                    <img className='user-picture' src={default_avatar} alt="avatar" />
                </div>
            )
        }
    }

    function displayUser() {
        if(props.user) {
            return(<p className="user-name">{props.user.nickname ? props.user.nickname : props.user.firstname} </p>);
        }
        else {
            return(<p className="user-name">{t("Loading user...")}</p>)
        }
    }

    async function updateUserComment(evt:any) {

        evt.preventDefault()

        const data = {
            data: {
                _id:props.comment._id,
                comment: {
                    data:newCommentText
                }
            }
        }

        try {
            await commentAPIInstance.put("/", data, { headers: { Authorization: `Bearer ${props.accessToken}` } })
            setEditComment(false);
        }
        catch(error) {
            console.log('error', error)
        }
    }

    async function deleteUserComment() {
        try {
            await commentAPIInstance.delete("/" + props.comment._id, { headers: { Authorization: `Bearer ${props.accessToken}` } })
            props.deleteComment(props.comment._id)
            setDeleteConfirmModal(false)

        }
        catch(error) {
            console.log('error', error);
            setDeleteConfirmModal(false)
        }
    }


    return(
        <div className="comment-container">
            {userData._id === props.comment.user ? 
                <div className="comment-action">
                    <div className='edit-icon-container' onClick={() => setEditComment(prev => !prev)}>
                        <Icon className="edit-icon" size={0.9} path={editComment ? mdiClose : mdiPencil} />
                    </div> 
                    <div className='delete-icon-container' onClick={() => setDeleteConfirmModal(true)}>
                        <Icon className="delete-icon" size={0.9} path={mdiDelete} />
                    </div> 
                </div>
            : null}
            <div className="avatar-name-container">
                {getCommentImage()}
                <div className="name-avatar">
                    {displayUser()}
                    <p className="date">{getTimeElapsed(props.comment.createdAt)}</p>
                </div>
            </div>
            <div className="text-container">
                {editComment ? 
                    <Form onSubmit={updateUserComment}>
                        <Form.Group className="group update-comment-group">
                            <input type="text" id="new-profile" value={newCommentText} onChange={(e) => setNewCommentText(e.target.value)} />
                            <label className="top-label">{t("Edit comment")}</label>
                            <div className="submit-icon-container" onClick={updateUserComment}>
                                <Icon size={1} path={mdiCheck} />
                            </div>
                        </Form.Group>
                    </Form>
                    : 
                    <div>
                        <p>{props.comment.data}</p>
                        {props.comment.edited && <p className="edited">{t("Edited")}</p>}
                    </div>}

            </div>
            <ConfirmModal
                show={deleteConfrimModal}
                action="delete"
                handleClose={() => setDeleteConfirmModal(false)}
                modalTitle={"Delete Comment"}
                modalBody={() => (
                    <div>
                        <p className="subtitle">{t("Your comment will be permanantly deleted")}</p>
                    </div>
                )}
                reject={() => {
                    setDeleteConfirmModal(false)
                }}
                confirm={deleteUserComment}
            />
        </div>
    );
};

export default Comment;