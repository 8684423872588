import axios from "axios"

/**
 * Default instance for axios
 */
export const commentAPIInstance = axios.create({
    baseURL: window.__RUNTIME_CONFIG__.REACT_APP_COMMENT_API_URL,
    timeout: 15000,
})

export default commentAPIInstance
