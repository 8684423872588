import React, { useEffect, useState } from 'react';
import { Container, Row, Form, Button } from 'react-bootstrap';
import i18n from "../../i18n";
import { useTranslation } from 'react-i18next';
import instance from '../../api/api';
import IPage from '../../interfaces/page';

/**
 * Password reset page to be able to reset password
 * @param props : basic URL params
 * @returns void
 */
 const PasswordReset: React.FunctionComponent<IPage> = props => {

    //User have to correctly fill out normal and confirm field
    const [ password, setPassword ] = useState<string>('');
    const [ confirmPassword, setConfirmPassword ] = useState<string>('');

    //Feedback for user
    const [ feedback, setFeedback ] = useState<string>('');

    const { t } = useTranslation();

    //Clear feedback message after 5 seconds.
    useEffect(() => {
        if(props.match.params.lang) {
            i18n.changeLanguage(props.match.params.lang);
        }        
        setTimeout(() => {
            setFeedback('');
        }, 3000 )
    }, [ feedback ])

    /**
     * Get token and userID from url, and POST to backend with the new password.
     * 
     * @returns none if passwords do not match
     */
    async function resetPasswordAction() {
        let token = props.match.params.token;
        let userid = props.match.params.userid;

        if(password !== confirmPassword) {
            setFeedback(t("Passwords do not match"));
            return;
        }

        try {
            await instance.post(`/verification/reset/${userid}/${token}`, {password: password});

            //Redirect on succesfull password reset.
            setFeedback(t("Password changed successfully, redirecting to login"));
            setTimeout(() => {
                window.location.replace(`/login${props.match.params.lang ? "/"+props.match.params.lang : ''}`);
            }, 3000)
        }
        catch(err) {
            setFeedback(t("User does not exist, or token expired. Please ask for password reset again."));
        }
    }

    return(
        <Container className="password-reset-container">
            <p className="title">{t("Reset your password")}</p>
            <Row>
                <Form.Group className="password group">
                    <input type="password" placeholder={t("Enter new password")} value={password} onChange={(evt) => setPassword(evt.target.value)} />
                    <Form.Label className="top-label">{t("Enter new password")}</Form.Label>                    
                </Form.Group>

                <Form.Group className="password_confirm group">
                    <input type="password" placeholder={t("Confirm password")} value={confirmPassword} onChange={(evt) => setConfirmPassword(evt.target.value)}/>
                    <Form.Label className="top-label">{t("Confirm password")}</Form.Label>                    
                </Form.Group>
            </Row>
            { feedback.length ? <p>{feedback}</p> : null }
            <Button className="pw-reset-button default-button" onClick={resetPasswordAction}>{t("Update Password")}</Button>
        </Container>
    );
};

export default PasswordReset;