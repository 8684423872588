// BLOCKS

import { IRequest } from "../components/story/requests/story-recieved-requests.component";
import { ILocation } from "../components/_helper/google-map.component";
import { IGroup, IUserDetails } from "./authentication";
import { IOption } from "./family";

export enum blockTypes {
    TEXT = "TEXT",
    // Need multiple types of media
    MEDIA = "MEDIA",
    IMAGE = "IMAGE",
    VIDEO = "VIDEO",
    CAROUSEL = "CAROUSEL",
    // 
    AUDIO = "AUDIO",
    EMBED = "EMBED",
    LINK_ALBUM = "LINK_ALBUM",
    ATTACHMENT = "ATTACHMENT",
    LIFE_LESSON = "LIFE_LESSON"
}

interface ITags {
    users: string[] | IUserDetails[]
    others: string
}

export enum fileTypes {
    VIDEO="video",
    IMAGE="image",
    AUDIO="audio"
}

export interface IMedia {
    _id:string
    user:IUserDetails
    description?:string
    title?:string
    name:string
    tags: ITags
    data: string
    dataS: string | undefined
    dataXs: string | undefined
    dataM: string | undefined    
    fileType: fileTypes
    fileSize?: number
    relatedMedia: string | IMedia
    createdAt: Date
    updatedAt: Date
    relatedStory: IStory
    duration: number
    accessCommentToken?:string
}

export interface ILifelesson {
    _id: string
    createdBy: string | IUserDetails
    tags: string[]
    data: string
    createdAt: Date
    updatedAt: Date
    relatedStory: IStory
}

export interface IBlock {
    _id?:string
    type:blockTypes
    mediaTitle?:string
    description?:string
    multipleMedia:IMedia[]
    data:string
    media:IMedia
    tags?:string[]
    createdBy:IUserDetails | string
}

export interface IStoryDateMember {
    date: Date
    year: number
    month?: number
    day?: number
}


export interface storyDate {
    startDate: IStoryDateMember,
    endDate?: IStoryDateMember
}

export interface IRelatedStory {
    _id: string
    title: string
}

// STORY
export enum accessTypes {
    ALL_FAMILY="ALL_FAMILY",
    ALL_FAMILY_AND_FRIENDS="ALL_FAMILY_AND_FRIENDS",
    GROUP = "GROUP",
    INDIVIDUAL = "INDIVIDUAL",
    PRIVATE = "PRIVATE",
}

interface ILock {
    locked: boolean
    lockedBy: IUserDetails | null
}

export interface IStory {
    _id:string
    author:string | IUserDetails
    published: boolean
    title:string
    updatedAt:string
    blocks:IBlock[]
    date: storyDate
    storyTags: string[] | IOption[]
    featured: string | IUserDetails
    coAuthors: string[] | IUserDetails[]
    participants: string[] | IUserDetails[]
    access: string[] | IUserDetails[]
    accessGroups: string [] | IGroup[],
    accessIndividuals: string[] | IUserDetails[],
    accessPrivate: Boolean,
    accessFamily: accessTypes
    sLocation: ILocation
    createdAt: Date
    readTime: Number
    related: string[] | IRelatedStory[]
    request?: string | IRequest
    lock : ILock
    edited:boolean
    country?:string
    public: boolean
    readBy: string[]
    commentsEnabled: boolean
    privateId?:string
    insprirationId?: string
    accessCommentToken?: string
}