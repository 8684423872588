import React, { useState } from "react"
import Icon from '@mdi/react'
import { mdiFilter, mdiClose, mdiMagnify } from '@mdi/js';
import { Form } from "react-bootstrap"
import { filterOptions } from "./profile-groups.component";
import { useTranslation } from "react-i18next"

interface IProps {
    filters: IGroupFilters
    setFilters: Function
    filteredRelationNumber: number
}

export interface IGroupFilters {
    text: string
    option: string
}

function GroupFilters(props: IProps) {
    const avalibleFilters: filterOptions[] = [filterOptions.MY_GROUPS] // choosable filters showed in the select option

    const [ showFilters, setShowFilters ] = useState<boolean>(false); // show or hide the filter selector
    const [ showSearch, setShowSearch] = useState(false)

    const { t } = useTranslation();

    function handleFilterChange(event: React.ChangeEvent<HTMLSelectElement>) {
        props.setFilters({ ...props.filters, option: event.target.value })
        setShowFilters(false);
    }

    // function to dsiplay the number of active filters next to the filter button
    function countActiveFilters():string {
        return props.filters.option.length ? "(1)" : ""
    }

    return (
        <div className="family-filters">
            <div className="filters">
                <div className={"filter " + (props.filters.option.length ? "active" : "")}>
                    <div className="icon-container" onClick={() => setShowFilters(!showFilters)}>
                        <Icon size={0.8} path={ mdiFilter } />
                        <p>{t("Filters") + " " + countActiveFilters()}</p>
                    </div>
                    {showFilters ? (
                    <div className="group">
                        <Icon className="filter-icon" size={1} path={ mdiFilter } />
                        <Form.Select aria-label="" onChange={handleFilterChange} value={props.filters.option}>
                            <option value="">----</option>
                            {avalibleFilters.map(option => {
                                return (<option key={option} value={option}>{option}</option>)
                            })}
                        </Form.Select>
                        <div className="close-icon">
                            <span onClick={() => setShowFilters(!showFilters)}><Icon size={1} path={ mdiClose } /></span>
                        </div>
                    </div>
                    ) : null}
                </div>
                <div className={"search" + (props.filters.text.length ? " active" : "")} >
                    <span onClick={() => setShowSearch(!showSearch)}><Icon size={1} path={ mdiMagnify } /></span>
                    {showSearch && (
                    <div className="group">
                        <Icon className="search-icon" size={1.1} path={ mdiMagnify } />
                        <Form.Control
                            value={props.filters.text}
                            autoFocus
                            type="text"
                            onChange={(evt) => props.setFilters({ ...props.filters, text: evt.target.value })}
                            placeholder={t("Type to add keyword")}
                            />
                        <div className="close-icon">
                            <span onClick={() => setShowSearch(!showSearch)}><Icon size={1} path={ mdiClose } /></span>
                        </div>
                    </div>
                )}
                </div>
            </div>
        </div>
    )
}

export default GroupFilters
