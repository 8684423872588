import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Help from "../_helper/help.component";
import fastspring_logo from "../../assets/images/fastspring-logo.png";
import { useTranslation } from "react-i18next";

interface IProps {
    show:boolean
    onHide:Function
    product:any
    exectuePayment:Function
}

export default function PaymentSummaryModal(props:IProps) {

    //Translation
    const { t } = useTranslation();    

    return(
        <Modal dialogClassName="paymet-summary-modal" show={props.show} onHide={() => props.onHide()}>
            <Modal.Header>
                {t("Summary")}
            </Modal.Header>
            <Modal.Body>
                <div className="body-container">
                    <div className="items">
                        {props.product.subscription ? 
                        <Row className="item">
                            <Col className="name">
                                <p>{props.product.display},<br/> 1 {t("year")}</p>
                            </Col>
                            <Col className="price">
                                <p>{props.product.price}</p>
                            </Col>
                        </Row> : null}
                    </div>
                    <div className="prices">
                        <Row>
                            <Col className="title-text grand-total" lg={8}>
                                <p>{t("Grandtotal")}:</p>
                            </Col>
                            <Col className="price grand-total" lg={4}>
                                <p>{props.product.price}</p>
                            </Col>
                        </Row>
                    </div>
                    <div className="vat-text">
                        <p>{t("Prices are in USD and")} {props.product.priceValueWithoutTax===props.product.totalValue ? t("exclude VAT") : t("include VAT")}.</p>
                    </div>
                    <div className="button-container">
                        <Button className="default-button" onClick={() => props.exectuePayment()}>{t("Proceed to payment")}</Button>
                        <div className="fastspring-info">
                            <p className="info-text">{t("Fulfilled by")}</p>
                            <img src={fastspring_logo} alt="fastspring"/>
                        </div>
                    </div>
                </div>
                <div>
                    <Help category={"MyAccount"} pageName={"Summary"}></Help>
                </div>
            </Modal.Body>
        </Modal>
    )
}