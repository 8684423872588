import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";

export default function ExpertsPackages() {

    const { t } = useTranslation();

    return (
        <div className="experts-packages-widget">
            <p className="title">{t("Custom made packages for time with an Expert are listed below")}</p>
            <div className="content-section">
                <div className="no-records-box">
                    <p className="grey-text">{t("None available")}</p>
                    <p className="request-text">{t("Request a free introductory call to discuss your needs and receive your package options")}</p>
                    <a href="https://www.simirity.com/experts/" target="_blank" className="underlined-link-button"><Button className="default-button">{t("Learn more")}</Button></a>
                </div>
            </div>
        </div>
    )
}
