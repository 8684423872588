import Icon from '@mdi/react';
import React, { useContext, useEffect, useState, useRef } from 'react';
import instance from '../../api/api';
import { IStory } from '../../interfaces/story';
import default_avatar from "../../assets/images/default-avatar.jpg";
import { IUserDetails } from '../../interfaces/authentication';
import moment from 'moment';
import TooltipHelp from '../notifications/tooltiphelp.component';
import StoryFiltersBar, { sortOptions } from './story-filters-bar.component';
import gold_flag from "../../assets/images/bg-flag-gold.svg";
import green_flag from "../../assets/images/bg-flag-green.svg";
import { useHistory, useLocation } from 'react-router-dom';
import { Context } from '../../pages/home.page';
import StoryActionModal from './story-action-modal.component';
import StoryFilters, { IFilters, IRefactoredQueryObject, readTimeOptions } from './story-filters.component';
import { mdiAccount, mdiClose, mdiAccountGroup, mdiPencil, mdiPound, mdiCalendar, mdiEarth, mdiEye, mdiDotsHorizontal, mdiGoogleCirclesCommunities, mdiScriptTextOutline, mdiCheck, mdiMagnify } from '@mdi/js';
import { IOption } from '../../interfaces/family';
import { countries } from 'countries-list';
import { Spinner } from 'react-bootstrap';
import { getStoryDate } from '../../_helper/getStoryDate';
import IFrameDisplay from '../_helper/iframe-display.component';
import { useTranslation } from "react-i18next";

interface IProps {
}

export const newTooltip = {
    title: "What's this?",
    subtitle: "Newly Published",
    text: () => (
        <div style={{textAlign:'center'}}>
            <p className="subtitle">
                <img alt="flag" src={green_flag}/>
            </p>
            <p>Published after your last visit</p>
        </div>
    ),
}

export const unReadTooltip = {
    title: "What's this?",
    subtitle: "Unread story",
    text: () => (
        <div style={{textAlign:'center'}}>
            <p className="subtitle">
                <img alt="flag" src={gold_flag}/>
            </p>            
            <p>You have not read this story</p>
        </div>
    ),
}

export const defaultStoryFilters : IFilters = {
    date: { date: new Date(), year: null, month: null, day: null },

    storiesRelatingTo:[],

    featured:[],
    participants:[],
    storyAuthorCoAuthor:[],

    storyTags:[],
    lifeLessons:[],
    accessGroups:[],

    country:'',
    readTime:'',
    search: '',
    favorites:false,
    readLaterStories:false,
    unreadStories:false,
}

const filterIcons : any = {
    storiesRelatingTo:mdiAccount,
    featured:mdiAccount,
    participants:mdiAccountGroup,
    storyAuthorCoAuthor:mdiPencil,
    date: mdiCalendar,
    storyTags:mdiPound,
    lifeLessons:mdiScriptTextOutline, //TODO: cant find the icon
    accessGroups:mdiGoogleCirclesCommunities,
    country:mdiEarth,
    readTime:mdiEye,
    favorites:mdiCheck,
    readLaterStories:mdiCheck,
    unreadStories:mdiCheck,
    search:mdiMagnify
}

interface IbooleanReadableValues {
    [key: string]: any
    favorites:string,
    readLaterStories:string,
    unreadStories:string,
}

const booleanReadableValues:IbooleanReadableValues = {
    favorites:"Favourites",
    readLaterStories:"Read Later",
    unreadStories:"Unread Stories",
}

export interface ISort {
    field:string
    type:string
}

export default function AllPublishedStories(props: IProps) {
    
    const [ publishedStories, setPublishedStories ] = useState<IStory[]>([]);

    //Tooltips
    const [showNew, setShowNew] = useState<boolean>(false);
    const [showUnRead, setShowUnRead] = useState<boolean>(false);
    const [ selected, setSelected ] = useState<IStory | boolean>(false);

    //Filters
    const [ showFilters, _setShowFilters ] = useState<boolean>(false);
    const [ queryString, setQueryString ] = useState<string>("");
    const [ filtersActive, setFiltersActive ] = useState<boolean>(false);
    const [ stateLoaded, setStateLoaded ] = useState<boolean>(false);

    const filters = useContext(Context).storyFilters;
    const setFilters = useContext(Context).setStoryFilters;

    //sort and search
    const [ sort, setSort ] = useState<sortOptions>(sortOptions.PUBLISHED_DATE);
    const [ sortOption, setSortOption ] = useState<ISort>({field:'createdAt', type:'desc'});

    //pagination
    const [ pageNum, setPageNum ] = useState<number>(0);
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ hasMore, setHasMore] = useState<boolean>(true); //TODO: how to know how many records are
    const [ totalCount, setTotalCount ] = useState<Number>(0);
    // const observer = useRef();
    
    const history = useHistory();
    const location: any = useLocation();
    const loggedInUserData = useContext(Context).user;

    const { t } = useTranslation();

    //ref for story cleanup
    const queryStringRef = useRef<string>();
    useEffect(() => {
        queryStringRef.current = queryString;
    }, [queryString]);

    useEffect(() => {
        if(location.state && location.state.hasOwnProperty('filters') && location.state.filters.hasOwnProperty('story')) {
            //have to get some information from rerouting to be able to fill out query
            const newFilters = {...defaultStoryFilters, ...location.state.filters.story};
            setFilters(newFilters);
            setStateLoaded(true);
        } else {
            setStateLoaded(true)
        }
    }, []);

    useEffect(() => {
        //Only run automatic filtering if the state is already loaded.
        if(stateLoaded) {
            setupQueryString();
        }
    }, [filters, sortOption, stateLoaded ])

    useEffect(() => {
        if(stateLoaded && !loading) {
            fetchPublishedStories();
        }
    }, [queryString, filtersActive, pageNum])

    useEffect(() => {
        setPublishedStories([]); //Clear options;
        
        switch (sort){
            case sortOptions.PUBLISHED_DATE:
                setSortOption({field:'createdAt', type:'desc'})
                break
            case sortOptions.OLDEST:
                setSortOption({field:'date.startDate.date', type:'asc'})
                break
            case sortOptions.NEWEST:
                setSortOption({field:'date.startDate.date', type:'desc'})
                break
            default:
                setSortOption({field:'', type:''})
        }

    }, [ sort ])


    async function fetchPublishedStories() {
        try {
            setLoading(true);
            //Get newly published stories
            const newStories = await instance.get(`/publishedstories?${queryString}&pageNum=${pageNum}`);
            setPublishedStories((prev) => { return [...prev, ...newStories.data.result]});
            setHasMore(newStories.data.hasNextPage);
            setTotalCount(newStories.data.totalRecords)
            setLoading(false);

        } catch (error) {
            console.log("Failed to get published stories")
        }
    }

    function getImage(story:IStory) {
        const featured = story.featured as IUserDetails

        return featured.avatar ? featured.avatar : default_avatar
    }

    function setShowFilters() {
        _setShowFilters(!showFilters)
    }

    function removeAllfilters() {
        setFilters(defaultStoryFilters);
    }

    /**
     * Delete a filter by specific key.
     * @param key - The key for the filter we want to delete
     */
    function removeFilterByKey(key:string) {
        let tempFilterOject = { ...filters }
        if(tempFilterOject.hasOwnProperty(key)) {
            tempFilterOject[key] = defaultStoryFilters[key];
            setFilters({...tempFilterOject})
        }
    }

    function filterRow() {
        let emptyFilters = true;
        const filterRow = Object.entries(filters).map(([key, value]) => {
            if(Array.isArray(value)) {
                if(!value.length) {
                    return
                }
            }

            if(key === "date") {
                if((value.year === null && value.month === null && value.day === null)) {
                    return
                }
            }
            if(value) {
                emptyFilters = false;
                return(
                    <div className="active-filter-box">
                        <div>
                            <Icon className="info-icon" size={0.7} path={filterIcons[key]} />
                        </div>
                        {Array.isArray(value) ?
                            (key === "featured" || key === "storyAuthorCoAuthor" || key === "participants" || key === "storiesRelatingTo" ) ? <p>{value.map(x =>`${x.firstname} ${x.lastname}`).join(", ")}</p> : <p>{value.map(x => x.text).join(",")}</p>
                            :
                            (value === true || value === false) ? <p>{booleanReadableValues[key]}</p> : 
                            (key === 'date') ? <p>{moment(value.date).format(`${value.year ? "D" : ""} ${value.month ? "MMM," : ""} ${value.year ? "YYYY" : ""}`)}</p> : 
                            (key === 'country') ?  <p>{(countries as any)[value] ? (countries as any)[value].name : value}</p>:
                            <p>{value}</p>
                        }
                        {/* <p>{key}</p> */}
                        <div className="remove" onClick={() => removeFilterByKey(key)}>
                            <Icon className="close-icon" size={0.7} path={mdiClose} />
                        </div>
                    </div>
                )
            }
        })

        return filterRow;
    }

    async function setupQueryString() {
        const refactoredFilters:IRefactoredQueryObject = {};

        let filtersActive = false;

        Object.entries(filters).forEach(function([key, value]) {

            if(Array.isArray(value)) {
                if(!value.length) {
                    return
                }
            }

            //Not add date if
            if(key === "date") {
                if(value.day === null && value.month === null && value.year === null) {
                    return;
                }
            }

            if(value) {
                if(key === "search") {
                    refactoredFilters[key] = value;
                }
                else {
                    filtersActive = true;
                    if(key === "lifeLessons" || key === "storyTags" || key === "accessGroups") {
                        refactoredFilters[key] = value.map((x:IOption) => x._id);
                    }
                    else if(key === "featured" || key === "participants" || key === "storyAuthorCoAuthor" || key === "storiesRelatingTo") {
                        if(!value.length) {
                            return
                        }
                        refactoredFilters[key] = value.map((x:IUserDetails) => x._id);
                    }
                    else if(key === 'readTime') {
                        const time = readTimeOptions.find((x) => x.key === value);
                        if(time) {
                            refactoredFilters[key] = time.searchValue;
                        }
                    }
                    else if(key === "date") {
                        let dates:any = []
        
                        if(value.year !== null) {
                            dates.push(value.year)
                        }
                        if(value.month !== null) {
                            dates.push(value.month)
                        }
                        if(value.day !== null) {
                            dates.push(value.day)
                        }
                        refactoredFilters[key] = dates
                    }
                    else {
                        refactoredFilters[key] = value
                    }
                }
            }
        })

        let sortString = "";

        if(sortOption.field.length) {
            sortString = "sort=" + sortOption.field + "," + sortOption.type;
        }
        const filterString =   Object.keys(refactoredFilters).map(function(key) {
            return key + '=' + refactoredFilters[key]
        }).join('&');

        const queryString = sortString + (filterString.length ? "&" + filterString : '')

        if(queryString.length) {
            setPageNum(0);
            setPublishedStories([]);
            setQueryString(queryString);
            if(filterString.length && filtersActive) {
                setFiltersActive(true);
            }
            else {
                setFiltersActive(false);
            }
        }
        else {
            setPageNum(0);
            setQueryString('');
            setPublishedStories([]);
            setFiltersActive(false);
            fetchPublishedStories();
        }
    }

    return(
        <div className="all-published-container">
            <StoryFiltersBar removeAllfilters={removeAllfilters} filtersActive={filtersActive} showFilters={showFilters} setShowFilters={setShowFilters} setSort={setSort} sort={sort} search={filters.search} setSearch={setFilters}/>
            {!showFilters ? 
                <div className='all-stories'>
                    {filtersActive && <div className='active-filter-container'>{filterRow()}</div>}
                    <p className="story-count">{totalCount} {t("Stories")}</p>
                    {publishedStories.map((story:IStory, index:number) => {
                        const featured : IUserDetails = (story.featured as IUserDetails)
                        return(
                            <div className="story-container" onClick={() => history.push(`/dashboard/story/${story._id}`)} key={index}>
                            {/* <div className="story-container"> */}

                                <p className='story-title'>{story.title}</p>
                                <div className="action-container" onClick={(e) => { e.stopPropagation(); setSelected(story);}}>
                                    <Icon className="action" size={1} path={ mdiDotsHorizontal } />
                                </div>
                                <div className="img-author">
                                    <img className="user-picture" src={getImage(story)} alt="user" />
                                    <p className="story-author">{featured.nickname ? featured.nickname : featured.firstname}</p>
                                </div>
                                {loggedInUserData._id.length ?
                                <div>
                                    {loggedInUserData.lastVisited < story.createdAt ? <div className="flair" onClick={(e) => { e.stopPropagation(); setShowNew(true)}}>
                                        <img alt="flag" src={green_flag}/>
                                    </div> : null}
                                    {(!story.readBy.includes(loggedInUserData._id) && (story.author as IUserDetails)._id !== loggedInUserData._id) ? <div className="flair" onClick={(e) => { e.stopPropagation(); setShowUnRead(true)}}>
                                        <img alt="flag" src={gold_flag}/>
                                    </div> : null}
                                </div>
                                : null }

                                <p className="story-date">{story.date.endDate ? t("Starting")+" " + getStoryDate(story.date.startDate) : getStoryDate(story.date.startDate)}</p>                      
                            </div>
                        );
                    })}
                    {(!publishedStories.length && !loading) && <IFrameDisplay pageName={"stories-all"} />}
                    {selected && <StoryActionModal story={(selected as IStory)} author={((selected as IStory).author as IUserDetails)._id === loggedInUserData._id || (selected as IStory).coAuthors.map(x => (x as IUserDetails)._id).includes(loggedInUserData._id)} show={selected ? true : false} onHide={() => setSelected(false)} redirect={() =>  history.push('/dashboard/create', {data: (selected as IStory)})}/>}
                    {hasMore ? <button className="bordered-button" onClick={() => setPageNum(prev => prev+1)}> {loading ? <Spinner animation="border" variant="#fff" /> : t("Load more")}</button> : null}
                    {!hasMore && publishedStories.length ? <p className="has-more">{t("No more stories to display")}</p> : null}
                    <TooltipHelp
                        title={newTooltip.title}
                        subtitle={newTooltip.subtitle}
                        text={newTooltip.text}
                        show={showNew}
                        clear={() => setShowNew(false)}
                    />
                    <TooltipHelp
                        title={unReadTooltip.title}
                        subtitle={unReadTooltip.subtitle}
                        text={unReadTooltip.text}
                        show={showUnRead}
                        clear={() => setShowUnRead(false)}
                    />
                </div>
            :
                <StoryFilters setShowFilters={setShowFilters} queryString={queryString} filters={filters} setFilters={setFilters} setFiltersActive={setFiltersActive}/>}
        </div>
    );
}