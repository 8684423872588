import React, { FormEvent, useState } from 'react';
import { Tab, Tabs, Form } from 'react-bootstrap';
import config from '../../config/config';
import { useTranslation } from "react-i18next";

interface IProps {
    handleIsRelTypesBoxActive?:Function
    handleAction:Function
    relation:string
    relationError:boolean
}

/**
 *
 * @param props :  interface
 * @returns void
 */
function RelationshipPicker(props:IProps) {

    const [ show, setShow ] = useState(false);
    const [ tempInput, setTempInput ] = useState('');

    const { t } = useTranslation();

    function setRelation(relation:string) {
        props.handleAction(relation);
        setShow(false);
    }

    function handleInputChange(evt:FormEvent) {
        if(props.relation) {
            setRelation('');
            setShow(true);
            if(props.handleIsRelTypesBoxActive) {
                props.handleIsRelTypesBoxActive(true);
            }
        }
        setTempInput((evt.target as HTMLInputElement).value)
    }

    return(
        <div className="relationship-picker-container">
            <Form.Group className={"group" + (props.relationError ? " input-error" : "")}>
                <input type="text" onFocus={() => {setShow(true);if(props.handleIsRelTypesBoxActive) props.handleIsRelTypesBoxActive(true)}} placeholder={t("Select relationship")} value={props.relation ? t(props.relation).charAt(0).toUpperCase() + t(props.relation).slice(1) : tempInput} onChange={(evt) => handleInputChange(evt)}></input>
                <Form.Label className="top-label">{t("Select relationship")}</Form.Label>
            </Form.Group>
            {show ? 
            <div className="result-container">
                <Tabs>
                    {Object.keys(config.relations).map((group:string) => {
                        return(
                        <Tab eventKey={group} title={group}>
                            {config.relations[group].filter(x => x.includes(tempInput.toLowerCase())).map((relation:string, index) => {
                                return(<p key={index} className="select" onClick={() => setRelation(relation)}>{t(relation)}</p>)
                            })}
                        </Tab>);
                    })}
                </Tabs>
            </div> : null}
            {}
        </div>
    );
};

export default RelationshipPicker;
