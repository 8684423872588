export enum NotificationType {
    success = "success",
    info = "info",
    problem = "problem",
    caution = "caution"
}

export interface INotification {
    icon: string
    title: string 
    text: string | JSX.Element
    success: NotificationType
    closeBtnOff?: boolean
}

export interface ITooltip {
    contextKey?:string,
    title?:string,
    subtitle?:string,
    text?:() => JSX.Element,
    clear: () => void,
    show: boolean
}