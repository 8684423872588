import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import free_user from "../../../assets/images/free-user-widget.svg";

export default function FreeUserWidget() {

    const history = useHistory();

    return(
        <div className="credit-widget">
            <img src={free_user} alt="coin-hand" />
            <p>Subscribe to give family unlimited media rich stories & Premium Access</p>
            <Button className="default-button" onClick={() => history.push("/dashboard/account/subscription/buy")}>Buy Subscription</Button>
        </div>
    )
}
