import React, { useEffect, useRef, useState } from 'react';
import Icon from '@mdi/react'
import { mdiClose, mdiSortVariant, mdiCheck, mdiMenuDown, mdiFilterOutline, mdiCheckCircle } from '@mdi/js';
import { useTranslation } from "react-i18next";

interface IProps {
    setSort: Function
    sort: sortOptions
    sortOptions: string[]
    search: string
    setSearch: Function
    setShowFilters: Function
    showFilters: boolean
    filtersActive: boolean
    removeAllfilters: Function
}

export enum sortOptions {
    PUBLISHED_DATE="Published date",
    OLDEST="Oldest story date",
    NEWEST="Newest story date",
    VIDEOS_FIRST="Videos first",
    IMAGES_FIRST="Images first"
}

export default function StoryVisualsFiltersBar(props:IProps) {

    //sort
    const [showSort, setShowSort] = useState<boolean>(false)

    const { t } = useTranslation();    

    function updateSort(filter:sortOptions) {
        props.setSort(filter);
        setShowSort(false)
    }

    const wrapperRef = useRef<HTMLDivElement>(null);
    const upperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside, false);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside, false);
        };
    }, []);
    function handleClickOutside(event:any) {
        if(wrapperRef.current && !wrapperRef?.current?.contains(event.target as Node) && !upperRef?.current?.contains(event.target as Node)) {
            setShowSort(false);
        }
    };

    return(
        <div className='story-filters-bar story-filters-bar-visuals'>
            {!props.showFilters ? <div className="filters">
                <div className="left-filters">
                    <div ref={upperRef} className="sort-options" onClick={() => setShowSort(!showSort)}>
                        <Icon className="filter-icon" size={1} path={ mdiSortVariant } />
                        <p>{t(props.sort)}</p>
                        <Icon className="selected-option" size={0.8} path={ mdiMenuDown } />
                    </div>
                    {showSort && 
                        <div ref={wrapperRef} className="options">
                            {Object.entries(sortOptions).filter(x => (props.sortOptions as string[]).includes(x[0])).map(([key, value]) => {
                                return(
                                    <div className={"option" + (props.sort === value ? " "+t("selected") : "")} onClick={() => updateSort(value)}>
                                        <p>{t(value)}</p>
                                        {props.sort === value && <Icon className="selected-option" size={0.8} path={ mdiCheck } />}
                                    </div>
                                )
                            })}
                        </div>}
                </div>
                <div className="right-filters">
                    <div className={"filter" + (props.filtersActive ? " active" : "")} >
                        <div className="icon-container" onClick={() => props.setShowFilters()}>
                            <Icon className="status-icon" size={0.8} path={ mdiFilterOutline } />
                            {props.filtersActive ? <div className="active-additional-icon"><Icon className="close-icon" size={0.6} path={mdiCheckCircle} /></div> : null}
                            <p>{t("Filters")}</p>
                        </div>
                    </div>
                </div>
            </div>
            :
            <div className="filters filters-enabled">
                <div className="left-filters">
                    <div className="sort-options set-filter">
                        <Icon size={1} path={ mdiFilterOutline } />
                        <p>{t("Set Filters")}</p>
                    </div>
                    <div className="right-filters close">
                        {props.filtersActive && <p className="remove" onClick={() => props.removeAllfilters()}>{t("Remove All")}</p>}
                        <div onClick={() => props.setShowFilters()}>
                            <Icon size={1} path={ mdiClose } />
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );
}