import React from 'react';
import { Modal } from 'react-bootstrap';
import CreateGroup from './create-group.component';
import { useTranslation } from "react-i18next";

interface IProps {
    show:boolean
    handleClose: () => void
    updateGroups: Function
}


export default function CreateNewGroupModal(props:IProps) {

    const { t } = useTranslation();

    function close() {
        props.handleClose()
    }

    return(
        <Modal className="create-new-group-modal" show={props.show} onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title className="title">{t("Create new group")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CreateGroup close={close} updateGroups={props.updateGroups} ctaText={t("Create Group")}/>
            </Modal.Body>
        </Modal>
    );
}