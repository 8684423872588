import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import instance from "../../../api/api";
import { IUserDetails } from "../../../interfaces/authentication";

interface IProps {
    user:IUserDetails
}

export default function SubscriptionUpgrade(props:IProps) {

    const [ loading, setLoading ] = useState<boolean>(false);
    
    const { t } = useTranslation();
    const history = useHistory();

    async function upgrade() {
        setLoading(true)
        try {

            await instance.post('/fastspring/subscription/upgrade/' + props.user.subscription?.fastspring_id);

            setTimeout(() => {
                setLoading(false);
                history.push("/dashboard/account/subscription/info", { upgrade: true });
            }, 2000)

        }
        catch(error) {
            setLoading(false)
            console.log('error', error)
        }
    }

    return(
        <div className="subscription-upgrade-container">
            <div className="upper-container">
                <p>{t("Upgrade")}</p>
                <p>{t("Your current Subscription Period to Family+ in just one click*")}</p>
            </div>  
            <div className="lower-container">
                <p className="base-info">{t("Your personalised upgrade fee takes account of the days remaining in your Subscription Period and the value of your current Subscription.")}</p>
                <div className="button-container">
                    <button className="default-button" onClick={upgrade}>{loading ? <Spinner animation="border" variant="#fff" /> : t("Purchase Upgrade")}</button>
                    <p>{t("* Subject to ")}<span onClick={() => history.push("/dashboard/terms")} className="link-text">{t("Terms & Conditions")}</span></p>
                    <button className="light-button" onClick={() => history.push("/dashboard/referandsave/refer")}>{t("Sharing Subscription Costs")}</button>
                </div>
            </div>
        </div>
    )
}