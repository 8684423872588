import React, { useState } from "react"
import { Modal, Form, Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import instance from "../../api/api"
import Notification, { notificationImages } from "../notifications/notification.component"
import { INotification, NotificationType } from "../../interfaces/notification"
import axios, { AxiosError } from "axios"

interface IProps {
    show: boolean
    close: Function
}

const successNotification = { icon: notificationImages.happiness, title: "Success", text: "Your password is updated", success: NotificationType.success }

const olPasswordNotification = {
    icon: notificationImages.crying,
    title: "Fail",
    text: "The old password is not correct",
    success: NotificationType.problem,
}
const wrongConfirmPasswordNotification = {
    icon: notificationImages.crying,
    title: "Fail",
    text: "The password and the confirm password do not match",
    success: NotificationType.problem,
}

const wrongNewPasswordNotification = {
    icon: notificationImages.crying,
    title: "Fail",
    text: "Password has to be at least 8 character, and must contain a number.",
    success: NotificationType.problem,
}

const failedUpdateNotification = {
    icon: notificationImages.crying,
    title: "Fail",
    text: "Failed to update the password. Try again later.",
    success: NotificationType.problem,
}

function ChangePasswordModal(props: IProps) {
    const { t } = useTranslation()

    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const [notification, setNotification] = useState<INotification>({ icon: "", title: "", text: "", success: NotificationType.problem })

    async function handlePasswordChange(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()
        try {
            const body = {
                oldPassword: currentPassword,
                newPassword: newPassword,
                passwordConfirm: confirmPassword,
            }
            await instance.post("/auth/password-change", body)
            setNotification(successNotification)
            setCurrentPassword("")
            setNewPassword("")
            setConfirmPassword("")
            setTimeout(() => {
                closeModal()
            }, 2000)
        } catch (error) {
            // show error notifications
            console.log("Error", error)
            const isAxiosError = axios.isAxiosError(error)
            if (isAxiosError && (error as AxiosError).response?.data === "The old password is not correct") {
                setNotification(olPasswordNotification)
            } else if (isAxiosError && (error as AxiosError).response?.data === "The password and the confirm password do not match") {
                setNotification(wrongConfirmPasswordNotification)
            } else if (isAxiosError && (error as AxiosError).response?.data === "Password has to be at least 8 character, and must contain a number") {
                setNotification(wrongNewPasswordNotification)
            } else {
                setNotification(failedUpdateNotification)
            }
        }
    }

    function closeModal() {
        setCurrentPassword("")
        setNewPassword("")
        setConfirmPassword("")
        setNotification({ icon: "", title: "", text: "", success: NotificationType.problem })
        props.close(false)
    }

    return (
        <Modal className="change-password-modal" backdrop="static" show={props.show} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="change-password-form" onSubmit={(e) => handlePasswordChange(e)}>
                    <Form.Group className="group password">
                        <input required type="password" className={"string-input"} placeholder="Old Password" value={currentPassword} onChange={(evt) => setCurrentPassword(evt.target.value)} />
                        <Form.Label className="top-label">{t("Old Password")}</Form.Label>
                    </Form.Group>
                    <Form.Group className="group password">
                        <input required type="password" className={"string-input"} placeholder="New Password" value={newPassword} onChange={(evt) => setNewPassword(evt.target.value)} />
                        <Form.Label className="top-label">{t("New Password")}</Form.Label>
                        <Form.Label className="bottom-label">{t("At least 8 characters, with letters and numbers.")}</Form.Label>
                    </Form.Group>
                    <Form.Group className="group password">
                        <input required type="password" className={"string-input"} placeholder="New Password again" value={confirmPassword} onChange={(evt) => setConfirmPassword(evt.target.value)} />
                        <Form.Label className="top-label">{t("Confirm Password")}</Form.Label>
                    </Form.Group>
                    <div className="button-group">
                        <Button
                            disabled={!currentPassword || !newPassword || !confirmPassword || currentPassword === newPassword}
                            className="change-password-btn default-button"
                            variant="primary"
                            type="submit">
                            {t("Change Password")}
                        </Button>
                    </div>
                    {notification.text ? <Notification data={notification} close={() => null} /> : <></>}
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default ChangePasswordModal
