import React, { useContext, useEffect, useState } from 'react';
import { IUserDetails } from '../../interfaces/authentication';
import { Context } from '../../pages/home.page';
import { IOption, IRelation } from '../../interfaces/family';
import TooltipHelp from '../notifications/tooltiphelp.component';
import UniqueListInput from '../_helper/unique-list-input.component';
import config from '../../config/config';
import { ILifelessonFilters } from '../story/story-lifelessons.component';
import { useTranslation } from "react-i18next";

interface IProps {
    filters:ILifelessonFilters
    setFilters:Function
    setShowFilters:Function
    queryString:string
    count: Number
}

export default function ProfileLifelessonsFilters(props:IProps) {

    const [ showFeaturedTooltip, setShowFeaturedTooltip ] = useState<boolean>(false);

    const [ userOptions, setUserOptions ] = useState<IUserDetails[]>([]);

    const userData = useContext(Context).user;

    const { t } = useTranslation();

    //Setup options
    useEffect(() => {
        if(userData._id.length) {
            setUserOptions([...userData.relations.map((relation:IRelation) => relation.userid), userData])
        }
    }, [])

    function updateUserFilterField(key:string, value:IUserDetails) {
        const listType = (props.filters[key] as IUserDetails[]).map(x => x._id);
        if(listType.includes(value._id)) {
            props.setFilters({...props.filters, [key]: props.filters[key].filter((x:IUserDetails) => x._id !== value._id)})
        }
        else {
            props.setFilters({...props.filters, [key]:[...props.filters[key], value]})
        }
    }

    function updateIOptionField(key:string, value:IOption) {
        const optionType = (props.filters[key] as IOption[]).map(x => x._id);
        if(optionType.includes(value._id)) {
            props.setFilters({...props.filters, [key]: props.filters[key].filter((x:IUserDetails) => x._id !== value._id)})
        }
        else {
            props.setFilters({...props.filters, [key]:[...props.filters[key], value]})
        }
    }

    function showStories() {
        props.setShowFilters(false);
    }

    return(
        <div className='story-filters'>
            <p className='filter-group-title'>{t("Filters")}</p>
            <div className="people">
                <UniqueListInput placeholder={t("Life Lesson Category")} icon={true} data={props.filters.lifelessonTags} options={config.lifelessonTags} translateable={true} handleAction={(value:IOption) => updateIOptionField("lifelessonTags", value)}/>
            </div>
            <button className="default-button" onClick={() => showStories()}>{t("Show {{var}} Life Lesson(s)", {"var": props.count})}</button>
            <TooltipHelp contextKey={'story_filters_lifelessons_in_stories_of'} show={showFeaturedTooltip} clear={() => setShowFeaturedTooltip(false)} />
        </div>
    )
}