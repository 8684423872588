import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { IExplanation, IOption } from '../../../interfaces/family';
import UniqueListInput from '../../_helper/unique-list-input.component';
import instance from '../../../api/api';
import { IStory } from '../../../interfaces/story';
import { useTranslation } from "react-i18next";

interface IFormError {
    relationTo: boolean,
    relationFrom: boolean,
    email: boolean,
    firstName: boolean,
    lastName: boolean,
    diedOn: boolean,
    birthdate: boolean
}

interface IProps {
    email:string
    setEmail:(email:string) => void
    explanation:IExplanation
    setExplanation:Function
    formError:IFormError
    setFormError:Function
}

// Before 2024.01.26.
/*
const reasonsToJoin = [
    {_id:'1', text:'Avoid losing our family stories', img:''}, 
    {_id:'2', text:'Create a unique family legacy', img:''}, 
    {_id:'3', text:'Encourage kids interest in family', img:''},
    {_id:'4', text:'Enjoy regular family news & chats', img:''},
    {_id:'5', text:'Get to know our family better', img:''},
    {_id:'6', text:'Share stories & photos privately', img:''},
    {_id:'7', text:'Save special times for prosperity', img:''},
    {_id:'8', text:'Unite family in a shared project', img:''}
];
*/

// Starting 2024.01.26. MLP2-1089
const reasonsToJoin = [
    {_id:'1', text:'What role has luck played in your life?', img:''}, 
    {_id:'2', text:'What would surprise us about your past?', img:''}, 
    {_id:'3', text:'What are your fondest childhood memories?', img:''},
    {_id:'4', text:'Would you change anything about your life?', img:''},
    {_id:'5', text:'What are your 3 most special moments?', img:''},
    {_id:'6', text:'Which are your treasured possessions?', img:''},
    {_id:'7', text:'What is on your bucket list?', img:''},
    {_id:'8', text:'What are you most grateful for?', img:''},
    {_id:'9', text:'What would you like to be remembered for?', img:''},
    {_id:'10', text:'What are your hopes for the future?', img:''}    
];

export default function AdultForm(props:IProps) {

    const [ storyOptions, setStoryOptions ] = useState<IOption[]>([]);

    const { t } = useTranslation();

    useEffect(() => {
        getPublicStories();
    }, []);

    async function getPublicStories() {
        const response = await instance.get('/publishedstories?public=true');
        setStoryOptions(
            response.data.map((story:IStory) => { return {_id:story.privateId, text:story.title, img:''} })
        )
    }

    function updateReasons(selectedData:IOption) {
        if(props.explanation.reasons.filter((data:IOption) => data._id === selectedData._id).length) {
            const tempReasons = props.explanation.reasons.filter(story => story._id !== selectedData._id);
            props.setExplanation((prev:IExplanation) => { return {...prev, reasons:tempReasons} })
        }
        else {
            props.setExplanation((prev:IExplanation) => { return {...prev, reasons:[...props.explanation.reasons, selectedData]} })
        }
    }

    function updateExamples(selectedData:IOption) {
        if(props.explanation.examples.filter((data:IOption) => data._id === selectedData._id).length) {
            const tempStories = props.explanation.examples.filter(story => story._id !== selectedData._id);
            props.setExplanation((prev:IExplanation) => { return {...prev, examples:tempStories} });
        }
        else {
            props.setExplanation((prev:IExplanation) => { return {...prev, examples:[...props.explanation.examples, selectedData]} })
        }
    }

    function updateMessage(message:string) {
        props.setExplanation((prev:IExplanation) => { return {...prev, message: message} })
    }

    function handleEmailChange(value:string) {
        props.setEmail(value);
        props.setFormError((prevState:IFormError) => {
            let temp = prevState;
            temp.email = false;
            return temp;
        })
    }
    
    return(
        <div className="add-to-family-adult-modal">
            <p className="subtitle">{t("Invite via email")}</p>
            <Form.Group className="group">
                <input className={props.formError.email ? "input-error" : ""} placeholder={t("Email")} value={props.email} onChange={(evt) => handleEmailChange(evt.target.value)} type="email"></input>
                <label className="top-label">{t("Email")} <span>*</span></label>
            </Form.Group>
            <Form.Group className="group explanation">
                <p className="subtitle">{t("Invitation explanation")}</p>
                <p className="sub-subtitle">{t("Optional ways to encourage invitees")}</p>
                <Form.Group className="group personal-message"> 
                    <input type="text" placeholder={t("Personal message")} value={props.explanation.message} onChange={(evt) => updateMessage(evt.target.value)}/>
                    <label className="top-label">{t("Personal message")}</label>
                </Form.Group>
                <UniqueListInput iconRef={''} icon={true} placeholder={t("Optional example stories from you")} handleAction={updateExamples} data={props.explanation.examples} options={storyOptions} />
                <p className="sub-subtitle left">{t("'Share with Private Link' must be enabled for selection")}</p>
                <UniqueListInput iconRef={''} icon={true} placeholder={t("Stories you’d like them to create")} handleAction={updateReasons} data={props.explanation.reasons} options={reasonsToJoin}/>
                <p className="sub-subtitle left">{t("Initial ideas only, to help them get started")}</p>
            </Form.Group>
        </div>
    )
}