import React, { useState, useEffect, createContext } from "react";
import IPage from "../interfaces/page";
import { Route, NavLink, Redirect, useRouteMatch, Switch, RouteComponentProps, useHistory } from "react-router-dom";
import { Row, Col, Nav } from "react-bootstrap";
import Header from "../components/menu-items/header.component";
import { IState } from "../interfaces/redux";
import { shallowEqual, useSelector } from "react-redux";
import AddToYourFamily from "../components/family/add-to-family.component";
import dashboard_routes from "../config/dashboard_routes";
import Footer from "../components/menu-items/footer.component";
import Icon from '@mdi/react';
import { mdiBookOpenPageVariant, mdiLightbulb, mdiFeather, mdiAccountGroup, mdiHumanGreetingVariant  } from '@mdi/js';
import horizontal_logo from "../assets/images/horizontal-logo.svg";
import logout from '../_helper/_logout';
import instance from "../api/api";
import { IUserDetails } from "../interfaces/authentication";
import { defaultUserData } from "../interfaces/family";
import { subscribeToNotify } from "./dashboard/notification-subscription.component";
import axios from "axios";
import { io, Socket } from "socket.io-client";
import config from "../config/config";
import { menu } from "./dashboard/profile.page";
import { IFilters } from "../components/story/story-filters.component";
import { defaultStoryFilters } from "../components/story/story-published-all.component";
import { IVisualFilters } from "../components/story/story-visuals.component";
import i18n from "../i18n";
import ScrollToTop from "../_helper/ScrollToTop";
import { ILifelessonFilters } from "../components/story/story-lifelessons.component";
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { IdentifyUser } from "../_helper/userguiding/userguiding";

type ContextType = {
    user: IUserDetails;
    setUserDetails: (user:IUserDetails) => void;
    userNoDb: IUserNoDbDetails;
    setUserNoDbDetails: (user:IUserNoDbDetails) => void;
    pageData: IPageData;
    setPageData: (pageData:IPageData) => void;
//    helpQuestions: any[];
//    setHelpQuestions: (helpQuestions:any[]) => void;
    helpCategories: any[];
    setHelpCategories: (helpCategories:any[]) => void;
    videos: any[];
    setVideos: (videos:any[]) => void;
    storyFilters: IFilters;
    setStoryFilters: (filterData:IFilters) => void;
    visualFilters: IVisualFilters;
    setVisualFilters: (filterData:IVisualFilters) => void;
    lifelessonFilters: ILifelessonFilters;
    setLifelessonFilters: (filterData:ILifelessonFilters) => void;
    whatSThis: any;
    setWhatSThis: (whatSThis:any[]) => void;    
    updateUserData: () => void;
};

export interface IPageData {
    notifications: number
    receivedRequests: number
    socket:Socket | null
    wavesurfers: any[]
    numOfMyPublishedStories: number
}

interface IUserNoDbDetails {
    welcomeTodayGame: Date | null
    engageTodayGame: Date | null
}

interface IWhatSThis {
    id: number
    attributes: IWhatSThisAttributes
}

interface IWhatSThisAttributes {
    identifier: string
    subtitle: string
    content: string
}

export const defaultVisualFilters:IVisualFilters = {
    date: { date: new Date(), year: null, month: null, day: null },
    featured: [],
    participants: [],
    favorites:false,
    storyTags:[],
    country:'',
}

export const defaultLifelessonFilters:ILifelessonFilters = {
    author: [],
    featured: [],
    lifelessonTags:[]
}

export enum UniqueSocketTarget {
    USER_UPDATE="user-update",
    USER_UPDATE_SUBSCRIPTION_ACTIVATED="user-update-subscription-activated"
}

const defaultUserNoDbData = { welcomeTodayGame: null, engageTodayGame: null }

const default_page_data = { notifications:0, receivedRequests:0, socket:null, wavesurfers: [], numOfMyPublishedStories: 0 }

export const Context = createContext<ContextType>({
    user: defaultUserData,
    setUserDetails: () => {}, 
    userNoDb: defaultUserNoDbData,
    setUserNoDbDetails: () => {},        
    pageData:default_page_data, 
    setPageData: () => {}, 
    helpCategories:[], 
    setHelpCategories: () => {},
    videos:[], 
    setVideos: () => {},
    storyFilters: defaultStoryFilters,
    setStoryFilters: () => {},
    visualFilters: defaultVisualFilters,
    setVisualFilters: () => {},
    lifelessonFilters: defaultLifelessonFilters,
    setLifelessonFilters: () => {},    
    whatSThis: [],
    setWhatSThis: () => {},    
    updateUserData: () => {}
});

const Home: React.FunctionComponent<IPage> = (props) => {
    const [addToFamilyModal, setAddToFamilyModal] = useState<boolean>(false);

    //Context data
    const [ userDetails, setUserDetails ] = useState<IUserDetails>(defaultUserData);
    const [ userNoDbDetails, setUserNoDbDetails ] = useState<IUserNoDbDetails>(defaultUserNoDbData);    
    const [ pageData, setPageData ] = useState<IPageData>(default_page_data);
    //const [ helpQuestions, setHelpQuestions ] = useState<any>([]);
    const [ helpCategories, setHelpCategories ] = useState<any>([]);
    const [ whatSThis, setWhatSThis ] = useState<any>([]);
    const [ videos, setVideos ] = useState<any>([]);    

    //filters
    const [ storyFilters, setStoryFilters ] = useState<IFilters>(defaultStoryFilters);
    const [ visualFilters, setVisualFilters ] = useState<IVisualFilters>(defaultVisualFilters);
    const [ lifelessonFilters, setLifelessonFilters ] = useState<ILifelessonFilters>(defaultLifelessonFilters);

    let { url } = useRouteMatch();
    const history = useHistory();
    const isAuthenticated: boolean = useSelector((state: IState) => state.isAuthenticated, shallowEqual);

    const { t } = useTranslation(); 

    useEffect(() => {  
        if(localStorage.userid) {
            getUserData();
            //fetchHelpQuestions();
            fetchHelpCategories();
        }
    }, []);

    useEffect(() => {
        //TODO: nem biztos hogy jó lehet hogy már a nem NULL ra is lefut, de az nem biztos hogy be is töltött
        if ( 'serviceWorker' in navigator ) {
            subscribeToNotify();
        }
    }, [navigator])

    // this runs when language is changed
    useEffect(() => {
        changeLanguage();  
    }, [userDetails.settings.language])


    function changeLanguage() {
        if(userDetails._id.length) {
            fetchWhatSThis(userDetails.settings.language);
            fetchVideos(userDetails.settings.language);
            i18n.changeLanguage(userDetails.settings.language);    
        }
    }

    async function getUserData() {      
        try {
            // get user record for the first time
            const user = await instance.get(`/user/${localStorage.userid}`);

            // update lastVisited and lastVisitedBefore
            const userResponse = await instance.put("/user/" + localStorage.userid, { details: { lastVisited: new Date(), lastVisitedBefore: user.data.lastVisited } });
            let tempUserData = userResponse.data;

            if(userResponse.data.subscription && userResponse.data.subscription.subscription_type.upload_limit 
                && moment(userResponse.data.subscription.expiration).subtract(1, 'y') > moment(new Date())){

                // assumption: expiration date is maximum +1 year from the purchase date
                // then: trial or vip -> family might be X+12 months, therefore, the upload limit for a family should be 50+X GB
               
                let tempSubsType = userResponse.data.subscription.subscription_type;

                // trial: 60, vip: 100
                if(userResponse.data.subscription.startedAs==="familytrial"){
                    tempSubsType.upload_limit = 60;
                } else if(userResponse.data.subscription.startedAs==="family-vip"){
                    tempSubsType.upload_limit = 100;
                }

                tempUserData = {...userResponse.data, [userResponse.data.subscription]: tempSubsType}
            }
            setUserDetails(tempUserData);
        }
        catch(error) {
            console.log('error, logout', error)
            logout()
        }
    }

    function updateUserData() {
        getUserData();
    }
/*
    async function fetchHelpQuestions() {        
        axios.get(`${config.contentServerURL}/help-questions`).then(resp => {
            setHelpQuestions(resp.data);
        }, error => {
            console.log("Failed to load help questions", error)
        });
    }
*/

    async function fetchHelpCategories() {         
        axios.get(`${config.contentServerURL}/api/help-categories?populate=help_questions&sort[0]=category&sort[1]=page_name&pagination[pageSize]=100`).then(resp => { // Strapi v4 query format
            setHelpCategories(resp.data.data);
        }, error => {
            console.log("Failed to load help categories", error)
        });
    }
    
    async function fetchWhatSThis(language:string) {
        axios.get(`${config.contentServerURL}/api/what-s-this?locale=${language}&pagination[pageSize]=100`).then(resp => { // Strapi v4 query format
            setWhatSThis(transformWhatSThisToObject(resp.data.data));   
        }, error => {
            console.log("Failed to load help categories", error)
        });
    }

    async function fetchVideos(language:string) {
        axios.get(`${config.contentServerURL}/api/videos?pagination[pageSize]=100`).then(resp => { // Strapi v4 query format. add ?locale=${language later}
            setVideos(resp.data.data);          
        }, error => {
            console.log("Failed to load videos", error)
        });
    }     

    function transformWhatSThisToObject(array:IWhatSThis[]){
        let newObj = {};
        array.forEach(x => {
            newObj = {...newObj, [x.attributes.identifier]: {subtitle: x.attributes.subtitle, content: x.attributes.content}} 
        });

        return newObj;
    }

    //Connect users socket to the backend to be able to use notifications
    useEffect(() => {
        if(userDetails._id.length && window.__RUNTIME_CONFIG__.REACT_APP_API_URL) {
            //set sotcket
            const socket = io(window.__RUNTIME_CONFIG__.REACT_APP_API_URL, {
                reconnectionDelayMax: 10000,
                query: {
                    userid: userDetails._id
                }
            });

            setPageData({...pageData, socket:socket});

            socket.on(userDetails._id, data => {
                if(data.type==="PENDING_RELATION_ACCEPTED" || data.type==="RELATION_DELETED"){
                    // update Context
                    getUserData();
                }
                getUserNotificationsSinceLastCheck();
            });

            //Update user on backend user change example: subscription update (add/remove member), deactivation, charge completed
            socket.on(UniqueSocketTarget.USER_UPDATE, data => {
                getUserData();
                getUserNotificationsSinceLastCheck();
            })

            //Update user on backend user change example: subscription activation (creation)
            socket.on(UniqueSocketTarget.USER_UPDATE_SUBSCRIPTION_ACTIVATED, data => {
                getUserNotificationsSinceLastCheck();
            })            

            //Get past notifications
            getUserNotificationsSinceLastCheck();

            //set users language for the page
            changeLanguage();

        }
        window.__RUNTIME_CONFIG__.REACT_APP_USE_USERGUIDING === "true" &&
            userDetails._id &&
            IdentifyUser(userDetails._id, userDetails)
    }, [userDetails])

    async function getUserNotificationsSinceLastCheck() {
        const response = await instance.get('/notification/count-new');
        setPageData((prev) => {
            return { ...prev, notifications: response.data.result || 0 }
        })
    }


    return (
        <div className="main-wrapper">
            <Context.Provider value={{
                user: userDetails,
                setUserDetails:setUserDetails, 
                userNoDb: userNoDbDetails,
                setUserNoDbDetails:setUserNoDbDetails,                                
                pageData:pageData, 
                setPageData:setPageData, 
                helpCategories:helpCategories, 
                setHelpCategories:setHelpCategories,
                videos:videos,
                setVideos:setVideos,
                storyFilters:storyFilters, 
                setStoryFilters:setStoryFilters,
                visualFilters:visualFilters, 
                setVisualFilters:setVisualFilters,
                lifelessonFilters:lifelessonFilters, 
                setLifelessonFilters:setLifelessonFilters,
                whatSThis: whatSThis,
                setWhatSThis: setWhatSThis,
                updateUserData:updateUserData
            }}>
                <ScrollToTop>
                    <div className="navigation-menu">
                        <div className="content"> 
                            <img onClick={() => history.push("/dashboard/stories/1")} src={horizontal_logo} alt="Logo" className="logo" />         
                            <Nav.Item>                        
                                <NavLink activeClassName="active" to={`${url}/stories`}><Icon size={1} path={ mdiBookOpenPageVariant } />{t("Stories")}</NavLink>
                            </Nav.Item>
                            <Nav.Item>                        
                                <NavLink activeClassName="active" to={`${url}/inspiration`}><Icon size={1} path={ mdiLightbulb } />{t("Inspiration")}</NavLink>
                            </Nav.Item>
                            <Nav.Item>                        
                                <NavLink isActive={(match, location) => location.pathname.includes("launchpad/1") ? true : false } activeClassName="active" to={`${url}/launchpad/1`}><Icon size={1} path={ mdiFeather } />{t("Creation")}</NavLink>
                            </Nav.Item>                         
                            <Nav.Item>                        
                                <NavLink activeClassName="active" to={`${url}/requests`}><Icon size={1} path={ mdiHumanGreetingVariant } />{t("Requests")}</NavLink>
                            </Nav.Item>                            
                            {/*<div className="nav-item">                        
                                <button><Icon size={1} path={ mdiForum } />Chat</button>
                            </div>*/}
                            <Nav.Item>                        
                                <NavLink activeClassName="active" to={'/dashboard/profile/' + localStorage.userid + "/" + menu.FAMILY + "/familyPage"}><Icon size={1} path={ mdiAccountGroup } />{t("Family")}</NavLink>
                            </Nav.Item>
                        </div>
                    </div>
                    <div className="content-wrapper">
                        <div className="content">
                            <Header />
                            <Row className="body">
                                {/* Second router to create routing for the home page. */}
                                <Col lg={10} className="p-0">
                                    <Switch>
                                        <Route exact path="/dashboard">
                                            <Redirect to="/dashboard/stories/1" />
                                        </Route>
                                        {dashboard_routes.map((route, index) => {
                                            /**
                                             * key: map returns awlays need a key
                                             * path: the routes path
                                             * exact: exact route or not
                                             * render: function to parse any props the component needs. Only render component if not private, or user loged in, else redirect
                                             */
                                            return (
                                                <Route
                                                    key={index}
                                                    path={route.path}
                                                    exact={route.exact}
                                                    render={(props: RouteComponentProps<any>) => {
                                                        if (route.private) {
                                                            if (isAuthenticated) {
                                                                return <route.component {...props} {...route.props} />;
                                                            } else {
                                                                return <Redirect to={"/login"} />;
                                                            }
                                                        } else {
                                                            return <route.component {...props} {...route.props} />;
                                                        }
                                                    }}
                                                />
                                            );
                                        })}
                                    </Switch>
                                </Col>
                            </Row>
                        </div>

                        <Footer />
                        <AddToYourFamily show={addToFamilyModal} handleUpdateConnections={() => {}} handleClose={() => setAddToFamilyModal(false)} />
                    </div>
                </ScrollToTop>
            </Context.Provider>
        </div>
    );
};

export default Home;
