//import axios from "axios"
import axios, { AxiosRequestConfig, AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios'
import Cookies from "universal-cookie"
import logout from "../_helper/_logout"
import { setCookies } from "../components/_helper/cookies"

const cookies = new Cookies()

/**
 * Default instance for axios (upload only)
 */
export const instanceUpload = axios.create({
    baseURL: window.__RUNTIME_CONFIG__.REACT_APP_UPLOAD_URL,
    timeout: 1800000,
})

interface AdaptAxiosRequestConfig extends AxiosRequestConfig {
    headers: AxiosRequestHeaders
  }

// request interceptor --> add token to the request
/*
instance.interceptors.request.use(function (config) {
    const token = cookies.get("token")
    if (config.headers) {
        config.headers.Authorization = token ? `Bearer ${token}` : ""

        return config
    }
})
    */

// Interceptors
instanceUpload.interceptors.request.use(
    (config): AdaptAxiosRequestConfig => {
        const token = cookies.get("token")
        if (config.headers) {
            config.headers.Authorization = token ? `Bearer ${token}` : ""
    
//            return config
        }        
      return config;
    },
    (error): any => {
      return Promise.reject(error);
    }
  );

// Add a response interceptor
instanceUpload.interceptors.response.use(
    (response) => {
        return response
    },
    async (error) => {
        // get the original request and the refresh_token from the cookies
        const originalRequest = error.config
        const refresh_token = cookies.get("refresh_token")

        if (!refresh_token) {
            // if the refresh token does not exist logout the user and clear both tokens
            logout() // !!! never remove !!!
        }

        // When response code is 401, try to refresh the token.
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest && refresh_token) {
            originalRequest._retry = true

            try {
                // axios request (this is not the instance to avoid the interceptor loop if the refresh request is also 401 )
                const response = await axios.post(window.__RUNTIME_CONFIG__.REACT_APP_API_URL + "/auth/refresh", { refresh_token })
                // save the new tokens into cookie
                setCookies(response.data.token, response.data.refreshToken);

                // add the new token to the original request
                originalRequest.headers.Authorization = `Bearer ${response.data.token}`
                return axios(originalRequest)
            } catch (error) {
                // if the refresh was unsuccessful logout the user
                logout()
            }
        }

        throw error
    }
)

export default instanceUpload
