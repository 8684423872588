import React, { useEffect, useRef, useState } from "react";
import Icon from '@mdi/react'
import { mdiCloseCircle, mdiMenuDown } from '@mdi/js';
import default_avatar from "../../../assets/images/default-avatar.jpg";
import { IUserDetails } from "../../../interfaces/authentication";

interface IProps {
    handleAction:Function
    data:IUserDetails[]
    options:IUserDetails[]
    placeholder:string
}

export default function UniqueUserListInput(props:IProps) {

    const [show, setShow] = useState<boolean>(false);

    const wrapperRef = useRef<HTMLDivElement>(null);
    const upperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside, false);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside, false);
        };
    }, []);

    function handleClickOutside(event:any) {
        if(wrapperRef.current && !wrapperRef?.current?.contains(event.target as Node) && !upperRef?.current?.contains(event.target as Node)) {
            setShow(false);
        }
    };

    return(
        <div className="unique-list-input-container">
            <div className="example-story-select group" ref={upperRef}>
                {props.data.length ?
                    <div>
                        <div className="option-wrapper" onClick={(e) => {setShow(!show)}}>
                            {props.data.map((selected:IUserDetails) => {
                                return(
                                    <div className="option" key={selected._id}>
                                        <img className="user-picture" alt="profile-avatar" src={selected.avatar ? selected.avatar : default_avatar}/>
                                        <p>{`${selected.firstname} ${selected.lastname}`}</p>
                                        <div className="remove-icon">
                                            <span onClick={(e) => {e.stopPropagation(); props.handleAction(selected)}}><Icon size={1} path={ mdiCloseCircle } /></span>
                                        </div>
                                    </div>
                                    );
                            })}
                            <Icon className="wrapper-icon" size={1} path={ mdiMenuDown }/>
                            <p className="top-label">{props.placeholder}</p>
                        </div>
                    </div>
                :
                <p onClick={() => setShow(!show)} >{props.placeholder}<Icon className="wrapper-icon" size={1} path={ mdiMenuDown }/></p>}
            </div>
            {show ? <div className="example-story-list" ref={wrapperRef}>
                {props.options
                .sort((a, b) => (`${a.firstname} ${a.lastname}` > `${b.firstname} ${b.lastname}`) ? 1 : -1)
                .filter((option:IUserDetails) => !props.data.map((x:IUserDetails) => x._id).includes(option._id))
                .map((value:IUserDetails) => {
                    return(
                        <p onClick={() => props.handleAction(value)} key={value._id}>{`${value.firstname} ${value.lastname}`}</p>
                    );
                })}
            </div> : null}
        </div>
    );
}
