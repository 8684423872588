import React from 'react';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import { INotification } from '../../interfaces/notification';

/**
 * images
 */
import emoji_crying from "../../assets/images/notification/emoji-crying.svg";
import emoji_happiness from "../../assets/images/notification/emoji-happiness.svg";
import emoji_in_love from "../../assets/images/notification/emoji-in_love.svg";
import emoji_laughing from "../../assets/images/notification/emoji-laughing.svg";
import emoji_like_placeholder from "../../assets/images/notification/emoji-like_placeholder.svg";
import emoji_like from "../../assets/images/notification/emoji-like.svg";
import emoji_supervised from "../../assets/images/notification/emoji-surprised.svg";
import emoji_neutral from "../../assets/images/notification/emoji-neutral.svg";

interface IProps {
    data:INotification
    children?:any
    close: () => void
}

type IImages = {
    [key: string]: string;
};
   
const images:IImages = {
    crying:emoji_crying,
    happiness:emoji_happiness,
    inLove:emoji_in_love,
    laughing:emoji_laughing,
    likePlaceHolder:emoji_like_placeholder,
    like:emoji_like,
    supervised:emoji_supervised,
    noimage: '',
    neutral:emoji_neutral
}

export enum notificationImages {
    crying="crying",
    happiness="happiness",
    inLove="inLove",
    laughing="laughing",
    likePlaceHolder="likePlaceholder",
    like="like",
    supervised="supervised",
    noimage='',
    neutral="neutral"
}

/**
 * @param props
 * @returns void
 */
function Notification(props:IProps) {

    return(
        <div className={`notification-container ${props.data.success}`} >
            {props.data.closeBtnOff ? null : <span onClick={() => props.close()}><Icon className="close-icon" size={0.8} path={ mdiClose } /></span>}
            {props.data.icon != '' ? <img src={images[props.data.icon]} alt="emoji"/> : null}
            <p className="notification-title">{props.data.title}</p>
            <p className="notification-text">{props.data.text}</p>
            <div>
                {props.children}
            </div>
        </div>
    );
};

export default Notification;