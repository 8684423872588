import React, { useEffect, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader"
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";

interface IProps {
    specificLocation?: ILocation
    id: string
    setLocation: Function
}

export interface ILocation {
    lat: number
    lng: number
}

export default function GoogleMap(props:IProps) {

    const [ coords, setCoords ] = useState<ILocation | boolean>(false);
    const [ map, setMap ] = useState<google.maps.Map>();
    const [ marker, setMarker ] = useState<any>();
    const [ hasInput, setHasInput ] = useState<boolean>(false);

    useEffect(() => {
        if(!coords) {
            getLocation();
        }
    }, [props.specificLocation])

    useEffect(() => {
        if(coords) {
            loadmap();
        }
    }, [coords])

    useEffect(() => {
        if(map) {
            initAutocomplete();
        }
    }, [map])

    //Get users current location or load specific location from props
    function getLocation() {
        let coords = {lat: 0, lng:0}
        if(props.specificLocation) {
            coords = {lat: props.specificLocation.lat, lng:props.specificLocation.lng };
        }
        else {
            coords = {lat:51.5072, lng:0.1276};
        }
        props.setLocation(coords);
        setCoords(coords);

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position:any) => {

                // if the permission is there and the story has a location already then don't do anything
                // if the permission has just been received then set current location
                props.specificLocation && props.specificLocation.lat && props.specificLocation.lng 
                    ? coords = {lat: props.specificLocation.lat, lng: props.specificLocation.lng }
                    : coords = {lat: position.coords.latitude, lng: position.coords.longitude};

                props.setLocation(coords);
                setCoords(coords);                
            });
        }        

    }

    function loadmap() {
        const loader = new Loader({
            apiKey: "AIzaSyA67fZM5QRSfKEY5y3EdrZ_AHXmuAu0p90",
            version: "weekly",
            libraries: ["places"]
        });
    
        loader.load().then(() => {
            let center = (coords as ILocation)

            let map = new google.maps.Map(document.getElementById(props.id) as HTMLElement, {
                center: center,
                fullscreenControl: false, //Disable full screen
                streetViewControl: false, // Disable street view
                mapTypeControl: true,
                mapTypeControlOptions: {
                    style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: google.maps.ControlPosition.BOTTOM_CENTER,
                  },
                zoom: 8,
            });

            const marker = new google.maps.Marker({
                position: center,
                map: map,
                draggable:true,
                title:"Drag me!"
            });

            setMarker(marker);
            marker.addListener('dragend', dragEnd);
            setMap(map);
        });
    }

    function dragEnd(event:any) {
        props.setLocation({lat:event.latLng.lat(), lng:event.latLng.lng()})
    }

    function initAutocomplete() {
        if(!map) {
            return;
        }

        // Create the search box and link it to the UI element.
        const input = document.getElementById("pac-"+ props.id) as HTMLInputElement;

        const searchBox = new google.maps.places.SearchBox(input);
            
        // Bias the SearchBox results towards current map's viewport.
        map.addListener("bounds_changed", () => {
          searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
        });
      
      
        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        searchBox.addListener("places_changed", () => {
            const places = searchBox.getPlaces();
        
            if (places && places.length === 0) {
                return;
            }

            if(!places) {
                return;
            }
        
            places.forEach((place) => {
                    if (!place.geometry || !place.geometry.location) {
                        return;
                    }
                    let coords = place.geometry.location;

                    if(place.geometry.location) {
                        const lat = coords.lat();
                        const lng = coords.lng()
                        const latlng = new google.maps.LatLng(lat, lng);
                        marker.setPosition(latlng);
                        props.setLocation({lat:lat, lng:lng})
                        map.setCenter(new google.maps.LatLng(lat, lng));
                    }
                });
        });
    }

    function checkInput(evt:any) {
        if((evt.target as HTMLSelectElement).value && !hasInput) {
            setHasInput(true)
        }
        else if(!(evt.target as HTMLSelectElement).value) {
            setHasInput(false)
        }
    }

    return(
        <div className="google-map-container">
            <input id={`pac-${props.id}`} className="controls" type="text" onChange={checkInput} placeholder={"Search address or move the pin"} />
            {!hasInput && <Icon className="search" size={0.9} path={mdiMagnify} />}
            <div className="map" id={props.id} style={{width: "300px;", height: "400px"}}></div>
        </div>
    );
}