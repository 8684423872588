import React, { useState, useRef } from "react";
import { mdiCloudUpload } from "@mdi/js";
import Icon from "@mdi/react";
import { AudioDisplay } from "./audio-display.component";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface IProps {
    addToStory:Function
    title:string
    cancel:Function
    buttonName:string
    hasTitle:boolean
    loading:boolean
}

export default function AudioUploader(props:IProps) {

    const [ file, setFile ] = useState<any>(false)
    const [ fileString, setFileString ] = useState<any>(false);

    const inputFile = useRef<HTMLInputElement | null>(null);

    const { t } = useTranslation();

    function _setFile(evt:any) {

        var file = evt.target.files[0];
        let blobFile = new File([file], file.name, {type: file.type})
        let url = URL.createObjectURL(blobFile)

        if (file) {
            setFile(file)
            setFileString(url);
        }
    }

    async function convertAndAddToStory() {
        // TODO: medium: error handling
        let blob = await fetch(fileString).then(r => r.blob()).catch(error => console.log(error.message));
        props.addToStory(blob);
    }

    return(
        <div className="audio-uploader">
            {!file ? <div>
                <div onClick={() => inputFile.current ? inputFile.current.click() : null} className="upload-drag-and-drop">
                    <Icon size={2} path={mdiCloudUpload}/>
                    <p>{t("Choose file")}</p>
                </div>
                <input accept="audio/*" className="upload-button" type="file" id="drag-and-drop" ref={inputFile} onChange={(evt) =>_setFile(evt)} hidden />
            </div>
            :
            <div className="uploaded-audio">
                <AudioDisplay file={fileString} id={"audio-uploader"} />
            </div>}
            {!file && <p className="upload-information">{t("Select audio recordings up to {{var}}", {"var": "100MB"})}</p>}
            <button className="default-button" disabled={(props.hasTitle ? !props.title.length : false) || !file} onClick={() => convertAndAddToStory()}>{props.loading ? <Spinner animation="border" variant="#fff" /> : t("Add to Story")}</button>
            <button className="light-button" onClick={() => props.cancel()}>{t("Cancel")}</button>
        </div>
    )
}