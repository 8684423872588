import React, { useEffect, useState } from 'react';
import { Container, Button, Form } from 'react-bootstrap';
import instance from '../../api/api';
import Notification, { notificationImages } from '../../components/notifications/notification.component';
import IPage from '../../interfaces/page';
import { INotification, NotificationType } from '../../interfaces/notification';
import i18n from "../../i18n";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

/**
 * Page for users who forgot their password
 * @param props : unused for now
 * @returns void
 */
 const ForgottenPassword: React.FunctionComponent<IPage> = props => {

    const [ email, setEmail ] = useState<string>('');
    const [ resetPassTooltip, setResetPassTooltip ] = useState<boolean>(false);
    const [ errorNotification, setErrorNotification ] = useState<boolean>(false);

    const { t } = useTranslation();

    const history = useHistory();    

    const notificationData: INotification = { icon:notificationImages.like, title:t("Check your email"), text:t("A password reset has been sent"), success:NotificationType.success}
    const errorNotificationData: INotification = { icon:notificationImages.like, title:t("Try again later"), text:t("Failed to send out the e-mail"), success:NotificationType.problem}
    
    useEffect(() => {
        if(props.match.params.lang) {
            i18n.changeLanguage(props.match.params.lang);
        }
    }, []);

    async function requestPasswordReset(evt: React.FormEvent) {
        evt.preventDefault();

        try {
            await instance.post('/verification/reset', { email:email });
            setResetPassTooltip(true);
            setTimeout(() => {
                setResetPassTooltip(false);
            }, 10000)
        }
        catch(err) {
            setErrorNotification(true);
            setTimeout(() => {
                setErrorNotification(false);
            }, 10000)
            console.log('error', err)
        }
    }

    return(
        <Container className="forgot-password-container">
            <p className="title">{t("Forgotten password")}</p>
            <p className="info-title">{t("Enter your account email")}</p>
            <Form className="forgot-password-form">
                <input className="string-input" type="email" placeholder="Email" value={email} autoFocus={true} onChange={(evt) => setEmail(evt.target.value)}/> 
                <p className="info">{t("A password reset link will be sent to you")}</p>
                <div className="button-group">
                    <Button disabled={!(email.length)} className="button default-button" variant="primary" type="submit" onClick={requestPasswordReset}>{t("Send reset link")}</Button>
                    <Button className="button-space link-button" type="submit" onClick={() => history.push(`/login${props.match.params.lang ? "/"+props.match.params.lang : ''}`)}>{t("Return to login")}</Button>                    
                </div>
            </Form>
            {resetPassTooltip ? <Notification data={notificationData} close={() => setResetPassTooltip(false)}/> : null}
            {errorNotification ? <Notification data={errorNotificationData} close={() => setErrorNotification(false)}/> : null}

        </Container>
    );
};

export default ForgottenPassword;