import { useEffect, useRef, useState } from "react";
import Icon from '@mdi/react'
import { mdiCloseCircle } from '@mdi/js';
import { IRelatedStory } from "../../../interfaces/story";
import instance from "../../../api/api";
import { useTranslation } from "react-i18next";

interface IProps {
    handleAction:Function
    data:IRelatedStory[]
    placeholder:string
}

export default function UniqueListFreeInput(props:IProps) {

    const [show, setShow] = useState<boolean>(false);

    // what the user just typed in the input field
    const [data, setData] = useState<string>("");
    
    // options/results returning from database that is shown to the user
    const [options, setOptions] = useState<IRelatedStory[]>([]);

    const wrapperRef = useRef<HTMLDivElement>(null);
    const upperRef = useRef<HTMLDivElement>(null);

    const { t } = useTranslation();

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside, false);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside, false);
        };
    }, []);

    useEffect(() => {
        // we need at least 2 characters to trigger
        if(data.length > 1) {
            // run fetch when user stopped typing
            const timeOutId = setTimeout(() => fetchPublishedStories(), 500);
            return () => clearTimeout(timeOutId);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, [data])    

    function handleClickOutside(event:any) {
        if(wrapperRef.current && !wrapperRef?.current?.contains(event.target as Node) && !upperRef?.current?.contains(event.target as Node)) {
            setShow(false);
        }
    };
    
    async function fetchPublishedStories() {
        try {

            // get all published stories by title the user typed
            const newStories = await instance.get(`/publishedstories?title=${data}`);
            
            // put them into options
            const temp : IRelatedStory[] = [];
            newStories.data.map((x: IRelatedStory) => temp.push({_id: x._id, title: x.title}));
            if(temp) {
                setOptions(temp);
                setShow(true);
            }

        } catch (error) {
            console.log("Failed to get published stories")
        }
    }

    return(
        <div className="unique-list-input-container unique-list-free-input-container group">
            {props.data.length ?
                <div className="example-story-select group" ref={upperRef}>
                    <div>
                        <div className="option-wrapper" onClick={(e) => {setShow(!show)} }>
                            {props.data.map(selected => {
                                return(
                                    <div className={`option ${selected.hasOwnProperty('editable') ? "locked" : ""}`} key={selected._id}>
                                        <p>{selected.title}</p>
                                        <div className="remove-icon">
                                            <span onClick={(e) => {e.stopPropagation(); props.handleAction(selected)}}><Icon size={1} path={ mdiCloseCircle } /></span>
                                        </div>
                                    </div>
                                    );
                            })}
                            <p className="top-label">{props.placeholder}</p>
                        </div>
                    </div>
                </div>
                : null }
            <input placeholder={t("Type Story Title...")} value={data} onChange={(evt) => setData(evt.target.value)} />
            <p className="top-label">{props.placeholder}</p>
            {show ? <div className="example-story-list" ref={wrapperRef}>
                {options
                    .sort((a, b) => (a.title > b.title) ? 1 : -1)
                    .filter((option:IRelatedStory) => !props.data.map((x:IRelatedStory) => x._id).includes(option._id))
                    .map((value:IRelatedStory) => {
                        return(
                            <p onClick={() => {props.handleAction(value); setShow(!show); setData("");}} key={value._id}>{value.title}</p>
                        );
                    })}
            </div> : null}
        </div>
    );
}
