import React, {useState} from 'react';
import { IStory } from '../../../interfaces/story';
import default_avatar from "../../../assets/images/default-avatar.jpg";
import moment from "moment";
import { useHistory } from 'react-router';
import { IUserDetails } from '../../../interfaces/authentication';
import TooltipHelp from '../../notifications/tooltiphelp.component';
import blue_flag from '../../../assets/images/bg-flag-blue.svg'
import { dateFormat } from '../../../_helper/enum/enum';
import { Spinner } from 'react-bootstrap';

interface IProps {
    data: IStory[]
    loaded: boolean
}

const renewTooltip = {
    title: "What's this?",
    subtitle: "Republish pending",
    text: () => (
        <div style={{textAlign:'center'}}>
            <p className="subtitle">
                <img alt="flag" src={blue_flag}/>
            </p>            
            <p>This published story has author updates that are not visible to viewers. Finish your editing and Republish (on Step 3) to make the changes live.</p>
        </div>
    ),
}

/**
 * Create story page
 * 
 * @param props : props
 * @returns void
 */
export default function EditedStories(props: IProps) {
    const history = useHistory();
    const [showRenew, setShowRenew] = useState<boolean>(false);

    function navigate(story: IStory) {
        history.push('/dashboard/create', { data: story });
    }

    return (
        <div className="unpublished-stories">
            {props.data.length ? <p className="information">{props.data.length} stories to be published</p> : props.loaded ? <p className="information">There are no story awaiting publication</p> :
                <div className="spinner-container">
                    <Spinner animation="border" variant="#fff" />
                </div>}
            {props.data.sort((a, b) => { return new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf() }).map((story: IStory, index) => {
                const author = (story.author as IUserDetails)
                const avatar = author.avatar ? author.avatar : default_avatar;

                return (
                    <div className="up-story" onClick={() => navigate(story)} key={index}>
                        <p className="story-title">{story.title}</p>
                        <img className="user-picture" alt="profile-avatar" src={avatar} />
                        <p className="author">{(story.author as IUserDetails).firstname}</p>

                        {story.published && story.edited ?
                            <div className="flair" onClick={(e) => {e.stopPropagation();setShowRenew(true)}}>
                                <img alt="flag" src={blue_flag} />
                            </div>
                            : null}

                        <p className="last-modified">{moment(story.updatedAt).format(dateFormat.SHORT)}</p>

                    </div>
                )
            })}

            <TooltipHelp
                title={renewTooltip.title}
                subtitle={renewTooltip.subtitle}
                text={renewTooltip.text}
                show={showRenew}
                clear={() => setShowRenew(false)}
            />
        </div>
    );
};