import React, { Dispatch, useCallback, useEffect } from "react"
import { Row, Col } from "react-bootstrap"
import Icon from '@mdi/react'
import { mdiChevronRight } from '@mdi/js'
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../../../store/actionCreators";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

/**
 * /Settings page
 * 
 */

function Settings() {

    const dispatch: Dispatch<any> = useDispatch();
    const setPageName = useCallback((currentPage: string) => dispatch(setCurrentPage(currentPage)), [dispatch]);

    const { t } = useTranslation();

    useEffect(() => {
        setPageName(t("Settings"));
    }, [])

    const history = useHistory();

    return (
        <div className="settings">
            <div className="setting-group-row">
                <p className="setting-group-title">{t("Account")}</p>
            </div>
            <Row className="setting-option-row" onClick={() => history.push(`/dashboard/settings/security`)}>
                <Col xs={11}>
                    <p className="settings-title">{t("Login & Security")}</p>
                    <p className="settings-description">{t("Manage login & security settings")}</p>
                </Col>
                <Col className="icon-container" xs={1}>
                    <Icon className="right-icon" size={1.5} path={ mdiChevronRight } />
                </Col>
            </Row>
            <Row className="setting-option-row" onClick={() => history.push(`/dashboard/settings/media`)}>
                <Col xs={11}>
                    <p className="settings-title">{t("Media Quality")}</p>
                    <p className="settings-description">{t("Manage image, video and audio quality")}</p>
                </Col>
                <Col className="icon-container" xs={1}>
                    <Icon className="right-icon" size={1.5} path={ mdiChevronRight } />
                </Col>
            </Row>
            <Row className="setting-option-row" onClick={() => history.push(`/dashboard/settings/language`)}>
                <Col xs={11}>
                    <p className="settings-title">{t("Language")}</p>
                    <p className="settings-description">{t("Select application language")}</p>
                </Col>
                <Col className="icon-container" xs={1}>
                    <Icon className="right-icon" size={1.5} path={ mdiChevronRight } />
                </Col>
            </Row>                       
            <div className="setting-group-row">
                <p className="setting-group-title">{t("Communication")}</p>
            </div>
            <Row className="setting-option-row" onClick={() => history.push(`/dashboard/settings/newsletter`)}>
                <Col xs={11}>
                    <p className="settings-title">{t("Email & Newsletter")}</p>
                    <p className="settings-description">{t("Manage newsletter")}</p>
                </Col>
                <Col className="icon-container" xs={1}>
                    <Icon className="right-icon" size={1.5} path={ mdiChevronRight } />
                </Col>
            </Row>
            <Row className="setting-option-row" onClick={() => history.push(`/dashboard/settings/notifications`)}>
                <Col xs={11}>
                    <p className="settings-title">{t("Notifications")}</p>
                    <p className="settings-description">{t("Fine tune your notifications")}</p>
                </Col>
                <Col className="icon-container" xs={1}>
                    <Icon className="right-icon" size={1.5} path={ mdiChevronRight } />
                </Col>
            </Row>    
        </div>
    )
}

export default Settings
