import React, { useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { IStory } from '../../interfaces/story';
import { DisplayStory } from './blocks/display-story.component';
import { useTranslation } from "react-i18next";

interface IProps {
    show:boolean
    onHide:Function
    story: IStory
}

export default function StoryPreview(props:IProps){

    const topRef = useRef<any>(null)

    const { t } = useTranslation();    

    return(
        <Modal className="story-preview-modal" show={props.show} onHide={props.onHide}>
            <Modal.Header ref={topRef} className="" closeButton>
                <p className="header-title">{t("Preview")}</p>
            </Modal.Header>
            <Modal.Body className="story-preview-body">
                <p className="story-title">{props.story.title}</p>
                <DisplayStory story={props.story} showInteractions={false}/>
                <div className="line"></div>
                <div className="preview-information">
                    <p>{t("NOTE")}</p>
                    <p>{t("Photo narrations & tagged people are only shown when story is published")}</p>
                    <button onClick={() => topRef.current ? topRef.current.scrollIntoView() : null} className='light-button'>{t("Back to top")}</button>
                </div>

            </Modal.Body>
        </Modal>
    );
}