import React, { Dispatch, useCallback, useEffect, useState } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import IPage from "../../interfaces/page";
import { setAuthentication } from "../../store/actionCreators";
import Cookies from "universal-cookie";
import { ISimpleUser } from "../../interfaces/authentication";
import SavedUser from "../../components/authentication/saveduser.component";
import TooltipHelp from "../../components/notifications/tooltiphelp.component";
import Notification, { notificationImages } from "../../components/notifications/notification.component";
import instance from "../../api/api";
import { ILoginResponse } from "../../interfaces/responses";
import { INotification, NotificationType } from '../../interfaces/notification';
import { profileTypes } from "../../_helper/enum/enum";
import * as EmailValidator from 'email-validator';
import Icon from '@mdi/react';
import { mdiHelpCircle, mdiLock, mdiEye, mdiEyeOff } from '@mdi/js';
import i18n from "../../i18n";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { setCookies } from "../../components/_helper/cookies";
import logout from "../../_helper/_logout";

const tooltip = {
    title: "What's this?",
    subtitle: "Remember me",
    text: () => <p>If enabled, your email address is saved so only your password is required for logging in.</p>,
};

const resetNotificationData = { icon:notificationImages.crying, title:"Check your email", text:"A password reset has been sent", success:NotificationType.success }
const failedResetNotificationData = { icon:notificationImages.crying, title:"Failed to send out email", text:"Please try again later", success:NotificationType.problem }

/**
 * Login page for user authentication
 * @param props : unused for now
 * @returns void
 */
const Login: React.FunctionComponent<IPage> = (props) => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [rememberMe, setRememberMe] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);

    //Helper states for tooltips and notifications
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const [resetPassTooltip, setResetPassTooltip] = useState<boolean>(false);
    const [failedResetPassTooltip, setFailedResetPassTooltip] = useState<boolean>(false);

    //status of the user checking;
    const [savedUser, setSavedUser] = useState<boolean>(false);
    const [savedUserData, setSavedUserData] = useState<ISimpleUser>({firstname: "", lastname: "", email: "", avatar: ""});

    //Errorhandling
    const [ notification, setNotification ] = useState<INotification>({icon:'', title:'', text:'', success:NotificationType.problem});

    //Histry for redirect after login
    const history = useHistory();

    //Cookies to save if user requested it.
    const cookies = new Cookies();

    const { t } = useTranslation();

    function resetNotification() {
        setNotification({icon:'', title:'', text:'', success:NotificationType.problem});
    }
    //Set the email equal to the data provided from the user
    function handeEmailChange(evt: React.FormEvent<HTMLInputElement>) {
        setEmail(evt.currentTarget.value);
    }

    //Set the password equal to the data provided from the user
    function handlePasswordChange(evt: React.FormEvent<HTMLInputElement>) {
        setPassword(evt.currentTarget.value);
    }

    //Return to normal login screen and reset saved email to none.
    function resetSavedUser() {
        cookies.remove("saved");
        setEmail("");
        setSavedUser(false);
    }

    /**
     * Redux functions to save if authentication was complete or not.
     */
    const dispatch: Dispatch<any> = useDispatch();
    const setAuth = useCallback((isAuthenticated: boolean) => dispatch(setAuthentication(isAuthenticated)), [dispatch]);

    /**
     * Main useffect:
     * Have to check if there is a user already used this browser and data saved in the cookies
     */
    useEffect(() => {
        // TODO: LOW: put lang in the cookie?
        if(props.match.params.lang) {
            i18n.changeLanguage(props.match.params.lang);
        }        
        
        // do this when browser window was closed (token must be not alive) but the refresh_token may still be alive        
        // TODO: MEDIUM: this should rather happen in Redux by action / dispatch
        if (!cookies.get('token') && cookies.get('refresh_token')) {
            updateOnWindowStart(cookies.get('refresh_token'));
        }

        if (cookies.get("saved")) {
            let saved = cookies.get("saved")[Object.keys(cookies.get("saved"))[0]];

            let user: ISimpleUser = {
                firstname: saved.firstname,
                lastname: saved.lastname,
                email: saved.email,
                avatar: saved.avatar
            };

            setEmail(saved.email);
            setSavedUserData(user);
            setSavedUser(true);
        }
        setLoaded(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function updateOnWindowStart(refresh_token:string) { 
        try {
            const response = await axios.post(window.__RUNTIME_CONFIG__.REACT_APP_API_URL + "/auth/refresh", { refresh_token })
            setCookies(response.data.token, response.data.refreshToken);
            setAuth(true);
            history.push("/");
        } catch (error) {
            // if the refresh was unsuccessful logout the user and clear both tokens
            logout() // !!! never remove !!!
        }
    }

    /**
     * Login request
     * response:
     *      - 200: token
     *      - 403: Unauthorized
     */
    async function handleLogin(evt: React.FormEvent) {
        evt.preventDefault();

        //Remove whitespaces from password
        let pwWithoutWhitSpaces = password.replace(/\s/g, "");

        //Email validation
        if(!EmailValidator.validate(email)) {
            setNotification({...notification, icon:notificationImages.crying, title:"Error", text:"Invalid e-mail format"});
            return
        }

        try {
            let response = await axios.post<ILoginResponse>(`${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/auth/login`, { email: email, password: pwWithoutWhitSpaces });

            if(response.data.user.profileType === profileTypes.DECEASED) {
                history.push(`/inactive${props.match.params.lang ? "/"+props.match.params.lang : ''}`, {user: response.data.user} );
                return
            } 

            if (rememberMe) {
                let savedUsers = cookies.get("saved");
                let user = response.data.user;
                if (savedUsers) {
                    if (!Object.keys(savedUsers).includes(user.email)) {
                        let saveUser = { [response.data.user.email]: { ...response.data.user }, ...savedUsers };
                        cookies.set("saved", saveUser);
                    }
                } else {
                    let saveUser = { [response.data.user.email]: { ...response.data.user } };
                    cookies.set("saved", saveUser);
                }
            }

            if (response.data.verified) {
                setCookies(response.data.token, response.data.refreshToken);
                if(response.data.user._id) {
                    localStorage.setItem('userid', response.data.user._id); //TODO: HAve to check this as well
                }
                setAuth(true);
                history.push("/");
            } else {
                history.push("/verification", { email: response.data.user.email, firstname:response.data.user.firstname, lastname:response.data.user.lastname });
            }
        } catch (err:any) {
            if(err.response.status == '403'){
                if(err.response.data == '1' || err.response.data == '0') {
                    setNotification({...notification, icon:notificationImages.crying, title:"Warning", text:`Email or Password is incorrect. You have ${err.response.data+1} attempt(s) left before your account is locked. You can reset your password using the forgotten password link below.`});
                    return;
                }
                setNotification({...notification, icon:notificationImages.crying, title:"Error", text:"Email or Password is incorrect"});
                return;                           
            } else if(err.response.status == '429') {
                setNotification({...notification, icon:notificationImages.crying, title:"Account Locked", text:"Due to suspicious activity, your account has been locked. Contact info@simirity.com to unlock your account."});
                return;                                           
            }
            setNotification({...notification, icon:notificationImages.crying, title:"Error", text:"Unexpected error when logging in"});
            return;
        }
    }

    async function resetPasswordRequest() {
        try {
            await instance.post("/verification/reset", { email: email });
            setResetPassTooltip(true);
            setTimeout(() => {
                setResetPassTooltip(false);
            }, 10000);
        } catch (err) {
            setFailedResetPassTooltip(true);
        }
    }

    function clearTooltip() {
        setShowTooltip(false);
    }

    /**
     * Show tooltip if needed
     * Only load Form if we decided if user is saved or not
     * Display error if needed
     * Autofocus the first input : email if not saved user, pw if saved
     * Display reset pass tooltip if reseted a saved user password
     */
    return (
        <Container className="login-container">
            <p className="title">{t("Log in")}</p>
            <p className="register-link">
                {t("Please log in or")}{" "}
                <Link to={`/register${props.match.params.lang ? "/"+props.match.params.lang : ''}`} className="underlined-link-button">
                    {t("register")}
                </Link>
            </p>
            <TooltipHelp title={tooltip.title} subtitle={tooltip.subtitle} text={tooltip.text} show={showTooltip} clear={clearTooltip} />
            {loaded ? (
                <Form className="login-form">
                    {/* TODO: too much time to wait, username input pops up before decide to have user. Fix it */}
                    <Form.Group className="group">
                        {!savedUser ? <input className="string-input" type="email" placeholder={t("Email")} value={email} autoFocus={true} onChange={handeEmailChange} /> : <SavedUser firstname={savedUserData.firstname} lastname={savedUserData.lastname} email={savedUserData.email} avatar={savedUserData.avatar} reset={resetSavedUser} />}
                        {!savedUser ? <Form.Label className="top-label">{t("Email")}</Form.Label>: null }
                    </Form.Group>
                    <Form.Group className="group password-input">
                        <input className="string-input" autoFocus={savedUser ? true : false} type={showPassword ? "text" : "password"} placeholder={t("Password")} value={password} onChange={handlePasswordChange} />
                        <Form.Label className="top-label">{t("Password")}</Form.Label>
                        <span onClick={() => setShowPassword(!showPassword)}><Icon className="icon" path={showPassword ? mdiEye : mdiEyeOff} /></span>
                    </Form.Group>
                    <div className="checkbox-input-container">
                        <div className="checkbox-input-click" onClick={() => setRememberMe(prev => !prev)}>
                            <Form.Check className="checkbox-input" type="checkbox" label={t("Remember me")} checked={rememberMe} />
                        </div>
                        <span onClick={() => setShowTooltip(true)} ><Icon path={mdiHelpCircle} /></span>             
                    </div>
                    {notification.title.length ? <Notification data={{...notification}} close={() => resetNotification()} />: null}

                    {/* Disable button if Email or password not provided. */}
                    <div className="button-group">
                        <Button disabled={!(email.length && password.length)} className="login-button default-button" type="submit" onClick={handleLogin}>
                            <Icon className="icon" path={mdiLock} />{t("Log in")}
                        </Button>
                    </div>
                </Form>
            ) : null}
            <Row className="cta-row">
                <Col xs={12}>
                    <p className="register-link">
                        Log in to access your encrypted stories or{" "}
                        <Link to={`/register${props.match.params.lang ? "/"+props.match.params.lang : ''}`} className="underlined-link-button">
                            {t("register")}
                        </Link>
                    </p>
                </Col>
                <Col xs={12} className="forgot-pw-link">
                    {resetPassTooltip ? <Notification data={resetNotificationData} close={() => resetNotification()} /> : null}
                    {failedResetPassTooltip ? <Notification data={failedResetNotificationData} close={() => setFailedResetPassTooltip(false)} /> : null}

                    <Button className="forgot-pw underlined-link-button" onClick={() => (savedUser ? resetPasswordRequest() : history.push(`/forgotten-password${props.match.params.lang ? "/"+props.match.params.lang : ''}`))}>
                        {t("Forgot your password ?")}
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default Login;
