import Cookies from "universal-cookie"
import moment from 'moment'

const cookies = new Cookies()

export function setCookies(token:string, refreshToken:string) {

  // Short-lived access tokens, long-lived refresh tokens, and blacklists are a great approach for most services.   
  // https://medium.com/@getintheshell/finally-understanding-the-benefits-of-a-long-lived-refresh-token-bf021176a9d1
  // https://www.linkedin.com/advice/1/what-best-practices-oauth-token-expiration-skills-it-operations-ytzle
  // TODO: MEDIUM: Create refresh token blacklist

  // Frontend and Api token expiration must match until
  // TODO: MEDIUM: Api returns expiration time that is taken over by Frontend implemented
  // Hard-coded expiration unit of measures:
  // access_token always minutes
  // refresh_token always days

  cookies.set("token", token, { path: "/", expires: moment().add(10, 'm').toDate() });
  cookies.set("refresh_token", refreshToken, { path: "/", expires: moment().add(14, 'd').toDate() });
}