import { mdiChevronLeftCircle, mdiChevronRightCircle, mdiCloseCircle } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useEffect, useRef, useState } from 'react';
import { IMedia } from '../../interfaces/story';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

interface IProps {
    close:Function
    images: IMedia[]
    mainIndex?: number
}

export default function ImageFullscreenViewer(props:IProps) {

    const [mainIndex, setMainIndex ] = useState<number>(0);

    useEffect(() => {
        if(props.mainIndex) {
            setMainIndex(props.mainIndex)
        }
    }, []);

    const customRenderThumb = (children:any) =>
        children.map((item:any) => {
            // return <VideoPlayer url={item.key} />
            if(item.props.children.type === "img") {
                return <img src={item.props.children.props.src} alt=""/>;
            }
            else {
                return  <div className="video-thumbnail">
                    <video controls={false} preload="metadata">
                        <source  src={item.props.children.props.src} type="video/mp4"/>
                    </video>   
                </div>
        }
    });

    return(
        <div className="image-viewer-fullscreen">
            <div className='top-bar'>
                <div className='icon-container' onClick={() => props.close()}>
                    <Icon className="close" size={2} path={mdiCloseCircle} />
                </div>
            </div>
           <div className="main-image-container">
                <Carousel 
                    autoPlay={false}
                    infiniteLoop={true}
                    swipeable={true}
                    renderArrowPrev={prevArrow}
                    renderArrowNext={nextArrow}
                    showIndicators={false}
                    //onClickThumb={() => console.log('clicked on thumbnail')}
                    showStatus={false}
                    useKeyboardArrows={true}
                    showArrows={true}
                    selectedItem={mainIndex}
                    onChange={(index:any) => setMainIndex(index)}
                    showThumbs={false}
                    //centerMode={true}
                    //centerSlidePercentage={100}
                    //dynamicHeight={true}
                    //renderThumbs={customRenderThumb}
                >
                    {props.images.map((media, index) => {
                        return(
                            <div key={media.data} className="main-image">
                                {media.fileType === 'image' ?
                                    <img alt='this is an alt' src={media.data} />
                                : 
                                <DisplayVideo index={index} mainIndex={mainIndex} src={media.data}/>
                                }
                            </div>
                        );
                    })}
                </Carousel>
            </div>
        </div>
    );
};

function DisplayVideo(props:any) {

    const ref = useRef<any>(null);

    useEffect(() => {
        if(props.index !== props.mainIndex) {
            pause();
        }
    }, [props.mainIndex])

    const pause = () => {
        if(ref.current) {
            ref.current.pause();

        }
    }

    return(
        <video ref={ref} controls={true} preload="metadata">
            <source  src={props.src} type="video/mp4"/>
        </video>    
    )
}
 
function prevArrow(prev:any) {
    return(
        <div onClick={prev}>
            <Icon style={{zIndex:1000}} className="left" size={2} path={mdiChevronLeftCircle} />
        </div>
    )
}

function nextArrow(next:any) {
    return(
        <div onClick={next}>
            <Icon className="right" size={2} path={mdiChevronRightCircle} />
        </div>
    )
}