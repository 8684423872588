import React, { useEffect, useState } from "react";
import happy_emoji from "../../../assets/images/notification/emoji-happiness.svg";
import crying_emoji from "../../../assets/images/notification/emoji-crying.svg";
import neutral_emoji from "../../../assets/images/notification/emoji-neutral.svg";
import { Row, Col, Button } from "react-bootstrap";
import { ISubscription } from "../../../interfaces/subscription";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import TooltipHelp from "../../notifications/tooltiphelp.component";

interface IProps {
    subscription:ISubscription
} 

enum EUsageStatus {
    GOOD="GOOD",
    WARNING="WARNING",
    OUT="OUT"
}

interface ISiteData {
    emoji:any
    bannerColor:string
    color:string
    text:string
}

interface IStatusValue {
    [key: string]: ISiteData;
}

export default function SubscriptionMediaUsageWidget(props:IProps) {

    const [ usageStatus, setUsageStatus ] = useState<EUsageStatus>(EUsageStatus.GOOD);
    const [ gbRemaining, setGbRemaining ] = useState<number>(0);
    
    //tootlips
    const [ showTT, setShowTT ] = useState<boolean>(false);

    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        const gb_remaining = Math.round(100*(props.subscription.subscription_type.upload_limit - props.subscription.allUploadInPeriod)) / 100;

        gb_remaining < 0 ? setGbRemaining(0) : setGbRemaining(gb_remaining);

        if(gb_remaining <= 0) {
            setUsageStatus(EUsageStatus.OUT)
        }
        else if(gb_remaining < 10) {
            setUsageStatus(EUsageStatus.WARNING)
        }
    }, [props.subscription])

    const status_values:IStatusValue = {
        "GOOD": {
            emoji: happy_emoji,
            bannerColor: "#ebffd6",
            color: "#006238",
            text: t("Your family has enough media uploads remaining")
        },
        "WARNING": {
            emoji: neutral_emoji,
            bannerColor: "#FAF3C6",
            color: "#006238",
            text: t("Your family might run out of media uploads")
        },
        "OUT": {
            emoji: crying_emoji,
            bannerColor: "#f75f5f",
            color: "white",            
            text: t("Your family has run out of media uploads")
        }
    }


    return(
        <div className="media-usage-widget">
            <div className="usage" style={{backgroundColor:status_values[usageStatus].bannerColor}}>
                <p className="usage-text" style={{color:status_values[usageStatus].color}}>{gbRemaining} GB</p>
                <p className="usage-info" style={{color:status_values[usageStatus].color}}>{t("Media uploads remaining")}*</p>
            </div>
            <div className="emoji-conatiner">
                <img className="emoji" alt="simle-emoji" src={status_values[usageStatus].emoji}/>
            </div>
            <div className="info-text-container">
                <p>{t(status_values[usageStatus].text)}</p>
                <p className="gray-text">* {t("At 0 GB, family cannot add additional photos, videos & audio to stories")}</p>
            </div>
            {props.subscription.subscription_type.fastspring_key !== "familytrial" && 
                <div className="details-container">
                    <p className="title-text">{t("Upload Allowance")}</p>
                    <Row>
                        <Col>
                            <p>{t("Uploads remaining")}</p>
                        </Col>
                        <Col>
                            <p>{gbRemaining} GB</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>{t("Media Boost remaining")}</p>
                            <p onClick={() => setShowTT(true)} className="tooltip-text">{t("What's this?")}</p>
                        </Col>
                        <Col>
                            <p>0 GB</p>
                        </Col>
                    </Row>
                </div>
            }
            {props.subscription.subscription_type.fastspring_key !== "familytrial" &&             
                <div className="button-container">
                    <Button className="default-button" onClick={() => history.push("/dashboard/account/media-boost/info")}>{t("Upload more")}</Button>
                    <p>{t("with Media Boosts")}</p>
                </div>
            }
            {props.subscription.subscription_type.fastspring_key !== "familytrial" && 
                <TooltipHelp contextKey={'myaccount_subscription_media_boosts'} show={showTT} clear={() => setShowTT(false)} />
            }
        </div>
    )
}