import React, { useState, useEffect } from 'react';
import { mdiHelpCircle, mdiCloseCircle, mdiLinkVariant, mdiRefresh } from '@mdi/js';
import Icon from '@mdi/react';
import { Form } from 'react-bootstrap';
import TooltipHelp from '../../notifications/tooltiphelp.component';
import Switch from "react-switch"
import instance from "../../../api/api"
import config from '../../../config/config';
import { INotification, NotificationType } from '../../../interfaces/notification';
import Notification, { notificationImages } from '../../notifications/notification.component';
import { useTranslation } from "react-i18next";
import { IGroup } from '../../../interfaces/authentication';
/*
// maximum number of emails
const maximumEmail = 10
*/
interface IProps {
    setShowToolTip:Function
    showTooltip:boolean
    group:IGroup
    //setNotificationEmails: Function
    updateGroups: Function
    modal?:boolean
    handleFinish: Function
}
/*
const errorNotification: INotification = {
    icon: notificationImages.crying,
    title: "Warning",
    text: "Maximum reached for this email send",
    success: NotificationType.problem,
}
*/

const successNotification: INotification = { icon:notificationImages.happiness, title:"Success", text:"", success:NotificationType.success }

export default function PrivateSharingUrlForPrivateGroups(props:IProps) {
/*
    const [email, setEmail] = useState<string>('');
    const [emails, setEmails] = useState<string[]>([]);
*/    
    const [copied, setCopied] = useState<boolean>(false);
    const [url, setUrl] = useState<string>('');

    // only used when modal is true (~ success message). otherwise the component works with "checked".
    const [isPublic, setIsPublic] = useState<boolean>(false);

    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [showErrorNotification, setShowErrorNotification] = useState<boolean>(false);
    const [showSuccessNotification, setShowSuccessNotification] = useState<boolean>(false);    

    const { t } = useTranslation();

    const failedGetNotification: INotification = {
        icon: notificationImages.crying,
        title: t("Failed"),
        text: t("We can't generate an URL right now"),
        success: NotificationType.problem,
    }    

    useEffect(() => {
        if(props.group.privateId || isPublic) {
            getPrivateUuid();
        }
    }, [props.group.privateId, isPublic])

    function copyHelper() {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, config.modalCloseTimeout)
    }

    function updatePrivateSharing(checked:boolean) {
        if(!checked) {
            /*
            setEmail('');
            setEmails([]);
            props.setNotificationEmails([]);
            */
            removePrivateGroupSharing()
        }
        if(!props.modal) {
            //props.updateStory("public", checked)
        }
        setIsPublic(checked)
    }

    async function removePrivateGroupSharing() {
    
        try {
            const response = await instance.put('/group', { _id:[props.group._id], query:{ privateId: "" } });
            props.updateGroups(response.data);
        }
        catch(error:any) {
            console.log(error.response)
        }
    }

    async function getPrivateUuid() {
        try {
            const response = await instance.get("/group/private/generate/" + props.group._id);
            setUrl(window.location.origin + "/dashboard/group/" +  encodeURIComponent(response.data))
            //props.updateStory("privateId", response.data)
        }
        catch(err:any) {
            setShowErrorNotification(true);
            setUrl("Story isn't public")
        }
    }

    async function reGenerateUuid() {
        try {
            const response = await instance.get("/group/private/generate/" + props.group._id, {
                params: {
                    new:true
                }
            });
            setUrl(window.location.origin + "/dashboard/group/" +  encodeURIComponent(response.data))
            //props.updateStory("privateId", response.data)

        }
        catch(err:any) {
            setShowErrorNotification(true);
            setUrl("Story isn't public")
        }
    }
/*
    function addEmail(){
        if(emails.length<maximumEmail) {
            setEmails(prev => [...prev, email]);
            props.setNotificationEmails((prev:string[]) => [...prev, email]); 
            setEmail('');
        } else {
            setShowNotification(true);
        }
    }
*/

    function add3Dots(string:string, limit:number){
        const dots = "...";
        if(string.length > limit)
        {
            string = string.substring(0,limit) + dots;
        }
        return string;
    }

    return(
        <div>
            <div className="switch-container">
                <div className="infos">
                    <span>{t("Non-family group")}</span>
                    <span onClick={() => props.setShowToolTip(true)}>
                        <Icon size={1} path={mdiHelpCircle} />
                    </span>
                </div>
                <div className="status">
                    <span className="switch-status">{props.group.privateId || isPublic ? t("On") : t("Off")}</span>
                    <Switch
                        className={` ${props.group.privateId || isPublic ? "active-gold-switch" : ""}`}
                        offColor="#17243d"
                        offHandleColor="#8B919E"
                        onColor="#006238"
                        onHandleColor="#006238"
                        handleDiameter={20}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={14}
                        width={32}
                        onChange={(checked:boolean) => updatePrivateSharing(checked)}
                        checked={props.group.privateId ? true : false || isPublic}
                    />
                </div>
                <TooltipHelp contextKey={'profile_family_groups_non_family'}
                    show={props.showTooltip}
                    clear={() => props.setShowToolTip(false)}
                />
            </div>
            {props.group.privateId || isPublic ? (
                <div className="private-sharing-url-container">
                    {/*<div className="input-container">
                        <Form onSubmit={(evt:any) => { evt.preventDefault(); addEmail()}}>
                            <Form.Group className="group">
                                <input type="email" className=""  placeholder="Email" onChange={(evt) => setEmail(evt.target.value)} value={email}/>
                                <Form.Label className="top-label">Email</Form.Label>
                            </Form.Group>
                            <button disabled={!email.length} className="add-button" type="submit">Add</button>
                        </Form>
                        <p className="info-text">{t("Automatically emails a link to your story")}</p>
                        {showNotification ? <Notification data={errorNotification} close={() => setShowNotification(false)} /> : null}                        
                    </div>
                    */}
                    {/*emails.length ? <div className="added-emails">
                        {emails.map(x => {
                            return(
                                <div className="email">
                                    <p>{x}</p>
                                    <div className="icon-container" onClick={() => {setEmails(prev => prev.filter(email => email !== x)); props.setNotificationEmails((prev:string[]) => prev.filter(email => email !== x))}}>
                                        <Icon path={mdiCloseCircle} size={0.8}/>
                                    </div>
                                </div>
                            )
                        })}
                    </div> : null*/}
                    <p className="info-text">{copied ? t("Copied!") : url}</p>
                    <div className="button-container">
                        <button className="default-button left" onClick={() => {navigator.clipboard.writeText(url); copyHelper()}}>{t("Copy link")}</button>
                        <button className="default-button right" onClick={reGenerateUuid}>{t("Reset link")}</button>
                    </div>                    
                    {/*<p className="link-type" onClick={() => {navigator.clipboard.writeText(url); copyHelper()}}>{add3Dots(url,42)}</p>*/}

                    {showErrorNotification ? <Notification data={failedGetNotification} close={() => setShowErrorNotification(false)} /> : null}

                    <div className="buttons">                    
                        {/*props.modal ? 
                            <button className='default-button' onClick={() => makeStoryPublic()}>{t("Save and Share")}</button> : null*/}
                        {props.modal ? 
                            <button className='link-button' onClick={() => props.handleFinish()}>{t("Cancel")}</button> : null}
                    </div>
                    
                    {showSuccessNotification ? <Notification data={successNotification} close={() => setShowSuccessNotification(false)} /> : null}                    
                </div>
            ) : null}
        </div>
    );
}