import React, { useContext, useEffect, useState } from 'react';
import default_avatar from "../../assets/images/default-avatar.jpg";
import Icon from '@mdi/react';
import { mdiCloseCircle } from '@mdi/js';
import instance from '../../api/api';
import { defaultUserData, IOption, IRelation } from '../../interfaces/family';
import { Modal } from 'react-bootstrap';
import { IUserDetails } from '../../interfaces/authentication';
import Notification, { notificationImages } from '../notifications/notification.component';
import { INotification, NotificationType } from '../../interfaces/notification';
import { profileTypes } from '../../_helper/enum/enum';
import { Context } from '../../pages/home.page';
import config from '../../config/config';

interface IProps {
    userid:string
    featuredBy: IUserDetails[]
    relations: IRelation[]
}

const successNotificationData: INotification = { icon: notificationImages.like, title: "Success", text: "Successfully added person", success: NotificationType.success }
const errorNotificationData: INotification = { icon: notificationImages.crying, title: "Fail", text: "Failed to add person", success: NotificationType.problem }

/**
 * 
 * @param props :  interface
 * @returns void
 */
function SelectFeatured(props:IProps) {

    const [ featuredBy, setFeaturedBy ] = useState<IOption[]>([]);
    const [ familyMembers, setFamilyMembers ] = useState<IRelation[]>([]);
    const [ search, setSearch ] = useState<string>('');

    const [ selected, setSelected ] = useState<IOption>({_id:"", text:"", img:""});

    //Notifications
    const [ notification, setNotification ] = useState<boolean>(false);
    const [ errorNotification, setErrorNotification ] = useState<boolean>(false);

    const [ updateByInComponentState, setUpdateByInComponentState ] = useState<IUserDetails>(defaultUserData);    

    //Modal
    const [ show, setShow ] = useState<boolean>(false);

    const loggedInUserData = useContext(Context).user;    
    const updateLoggedInUserData = useContext(Context).setUserDetails;

    useEffect(() => {
        getUserinfo(props.relations, props.featuredBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.relations, props.featuredBy])

    useEffect(() => {   
        if (updateByInComponentState._id.length) {
            getUserinfo(updateByInComponentState.relations, updateByInComponentState.featuredBy);
        }
    }, [updateByInComponentState])        

    async function getUserinfo(relations:IRelation[], featuredBy:IUserDetails[]) {
        const people: IOption[] = featuredBy.map((person : IUserDetails) => { return {_id:person._id, text: `${person.firstname} ${person.lastname}`, img:person.avatar ? person.avatar : ''}})
        const peopleIds = people.map(person => person._id)        
        setFeaturedBy(people);

        // only an Adult or Child can be a Featured Profile
        const canBeFeatured = relations.filter((member:IRelation) => (member.userid.profileType === profileTypes.ADULT || member.userid.profileType === profileTypes.CHILD)).filter((member:IRelation) => !peopleIds.includes(member.userid._id))
        setFamilyMembers(canBeFeatured);
    }

    async function updateFeaturedBy(people:string[]) {
        try {
            const response = await instance.put('/user/' + props.userid, { details: { featuredBy: people }});

            // update Context only if this is Me
            if(props.userid===loggedInUserData._id) {
                updateLoggedInUserData(response.data);
            } else {
                setUpdateByInComponentState(response.data);
            }

            setNotification(true);            
        }
        catch(error) {
            setErrorNotification(true);
        }
    }

    async function addFeaturedBy() {
        let people = [...featuredBy, selected];
        if(!featuredBy.map(x => x._id).includes(selected._id)) {       
            await updateFeaturedBy(people.map(x => x._id));
        } else {
            setErrorNotification(true);
        }

        setTimeout(() => {
            setShow(false);
            setNotification(false);
            setErrorNotification(false);
            setSearch('');
            setSelected({_id:"", text:"", img:""});
        }, config.modalCloseTimeout);        
    }

    async function removeFeaturedBy(person:IOption) {
        try {
            let people = featuredBy.filter(data => data._id !== person._id);     
            await updateFeaturedBy(people.map(x => x._id));
            setNotification(true)
        }
        catch(error) {
            setErrorNotification(true);
        }

        setTimeout(() => {
            setShow(false);
            setNotification(false);
            setErrorNotification(false);
        }, config.modalCloseTimeout);   
    }

    function closeModal() {
        setShow(false);
        setNotification(false);
        setErrorNotification(false);
    }
    return(
        <div className="select-administrator-container">
            <div className="group">
                <input className="admin-select" placeholder="Search name" value={search} onChange={(evt) => setSearch(evt.target.value)}></input>
                <p className="top-label">Search name</p>
            </div>
            {search.length ? 
            <div className="avalible-family-members">
                {familyMembers.filter((member:IRelation) => member.userid.firstname.match(new RegExp(search, "i")) || member.userid.lastname.match(new RegExp(search, "i"))).map(member => {
                    return(<p onClick={() => {setSelected({_id:member.userid._id, text: ` ${member.userid.firstname} ${member.userid.lastname}`, img: member.userid.avatar ? member.userid.avatar : ""}); setShow(true)}}>{member.userid.firstname} {member.userid.lastname}</p>);
                })}
            </div> : null}
            {featuredBy.length ? 
            <div className="administrators">
                {featuredBy.map(person => {
                    return(
                        <div key={person._id} className="admin-info">
                            <div className="profile">
                                <img src={person.img ? person.img : default_avatar} className="user-picture" alt="profile"></img>
                                <p>{person.text}</p>
                            </div>
                            <span onClick={() => removeFeaturedBy(person)}><Icon size={1} path={ mdiCloseCircle } /></span>
                        </div>
                    )
                })}
            </div> : null }
            <Modal className="administrator-modal" show={show} onHide={() => closeModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>Authorisation</Modal.Title>                
                </Modal.Header>
                <Modal.Body>
                    <p className="content-title">Adding a person who can write about me</p>
                    <img className="user-picture" src={selected.img ? selected.img : default_avatar} alt="person"/>
                    <p className="name">{selected.text}</p>
                    <p className="info-text">I hereby grant access to the person named above to write stories about me, as described in the <a className="underlined-link-button" href="/dashboard/terms">Terms & Privacy</a></p>
                    <button className="default-button" disabled={!selected._id.length} onClick={() => addFeaturedBy()}>Grant access to write about me</button>
                    {notification ? <Notification data={successNotificationData} close={() => setNotification(false)}/> : null}
                    {errorNotification ? <Notification data={errorNotificationData} close={() => setErrorNotification(false)}/> : null}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default SelectFeatured;