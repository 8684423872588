import { useEffect } from 'react';
import { useLocation } from 'react-router';

const ScrollToTop = (props: { children: any }) => {
  const location = useLocation();

  // where location is not changing (you tipically remain on the same page)
  // this below won't run so you have to deal with such pages one by one
  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
      setTimeout(() => { window.scrollTo(0, 0); }, 100); // for mobiles
    }
  }, [location]);

  return <>{props.children}</>;
};

export default ScrollToTop;
