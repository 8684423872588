import React, { useContext, useState } from 'react';
import { IInvitation, IPendingRelation, IPendingRelationDB } from '../../interfaces/family';
import default_avatar from "../../assets/images/default-avatar.jpg";
import { Form } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiEmailOutline, mdiMapMarker, mdiCheckCircle, mdiCloseCircle } from '@mdi/js';
import instance from '../../api/api';
import config from '../../config/config';
import { AxiosResponse } from 'axios';
import { UserRelationStatus } from '../profile/profile-action-modal.component';
import { IUserDetails } from '../../interfaces/authentication';
import { profileTypes } from "../../_helper/enum/enum";
import Cookies from "universal-cookie"
import * as EmailValidator from 'email-validator';
import { Context } from '../../pages/home.page';
import { useTranslation } from "react-i18next";

interface IProps {
    userid:string
    myProfile?: boolean
    profileType?: string
    administrator?: boolean
    relation:IPendingRelationDB | IUserDetails | IInvitation
    type:UserRelationStatus
    acceptRelation: Function
    declineRelation: Function
}

interface IFeedbackWithId {
    id:string // id or email!
    feedback:string
    feedbackType?: string
}

/**
 *
 * @param props :  interface
 * @returns void
 */
function PendingInvitations(props:IProps) {

    const [ relationType, setRelationType ] = useState<string>("-");
    const [ feedback, setFeedback ] = useState<IFeedbackWithId>({id:'', feedback:''});

    const cookies = new Cookies();

    const updateLoggedInUserData = useContext(Context).setUserDetails;

    const { t } = useTranslation();    

    async function accept(relation:IUserDetails) {

        if(props.type === UserRelationStatus.SUGGESTION) {

            const relationData = {
                from:props.userid,
                toEmail:relation.email,
                relationType:relationType,
                suggestions:[],
            }

            let response = await instance.post('/relation/pending', relationData);
            if (response.status === 200) {
                props.acceptRelation(response.data, relation.email, UserRelationStatus.SENT)
                setFeedback({id:relation.email, feedback:t("Relation request sent"), feedbackType:"accept"});

                // update Context only if this is Me
                if(props.myProfile) {                
                    let responseUserUpdated = await instance.get('/user/' + props.userid );
                    updateLoggedInUserData(responseUserUpdated.data);                
                }

                //Remove suggestion after creating a pending relation
                await instance.delete("/user/suggestions/" + props.userid, { data: { id: response.data.to._id }, headers: { Authorization: `Bearer ${cookies.get("token")}` } } );
            } else {
                setFeedback({id:relation.email, feedback:response.data});
            }
      
        }
        else {        
            let response = await instance.post('/relation', { id: props.relation._id, relationType:relationType });

            if (response.status === 200) {   
                props.acceptRelation(response.data, props.relation._id, UserRelationStatus.ACTIVE)
                setFeedback({id:relation._id, feedback:t("Request approved"), feedbackType:"accept"});

                // update Context only if this is Me
                if(props.myProfile) {                
                    let responseUserUpdated = await instance.get('/user/' + props.userid );
                    updateLoggedInUserData(responseUserUpdated.data);                
                }
            } else {
                setFeedback({id:relation._id, feedback:response.data});
            }
            
        }
        
        setRelationType("");        
    }

    async function decline(relation:IUserDetails) {
        try {
            let response:AxiosResponse;

            if(props.type ===  UserRelationStatus.SUGGESTION) {
                response = await instance.delete("/user/suggestions/" + props.userid, { data: { email: relation.email }, headers: { Authorization: `Bearer ${cookies.get("token")}` } } );

                if (response.status === 200) {
                    props.declineRelation(relation._id)
                    setFeedback({id:relation.email, feedback:t("Request declined"), feedbackType:"decline"});                       
                }

            }
            else {
                response = await instance.delete("/relation/pending", { data: {id: props.relation._id}, headers: { Authorization: `Bearer ${cookies.get("token")}` } });
                if (response.status === 200) {
                    props.declineRelation(props.relation._id)
                    setFeedback({id:relation._id, feedback:t("Request declined"), feedbackType:"decline"});                    
                }  
            }

            setRelationType("");            

        } catch (error) {
            setFeedback({id:relation._id, feedback:t("Failed to decline request"), feedbackType:"decline"});
        }
    }

    function getRealtions() {
        let result:string[] = []
        Object.values(config.relations).forEach((relationList:string[]) => {
            result = result.concat(relationList)
        });

        return result
    }

    const relation = props.type === UserRelationStatus.SUGGESTION ? (props.relation as IUserDetails) : ((props.relation as IPendingRelation).from as IUserDetails);

    const feedbackInfo = `feedback ${feedback.feedbackType}`;

    const notChildProfile = props.profileType !== profileTypes.CHILD ? true : false;

    return(
        <div key={relation._id} className="pending-invitation-container">
            <div className="image-container">
                {relation && <img src={relation.avatar ? relation.avatar : default_avatar} alt="avatar" className="user-picture" />}
            </div>
            <div className="details-container">
                <div className="detail-content">
                    <p className="name">{relation.firstname} {relation.lastname}</p>
                    {(feedback.feedback==="" && EmailValidator.validate(relation.email)) ? <p className="email"><Icon size={1} path={ mdiEmailOutline } /> {relation.email}</p> : null } 
                    {(feedback.feedback!=="" && relationType) ? <p className="relation-type">{t(relationType)}</p> : null}
                    {feedback.feedback==="" && (relation.country || relation.state) ? <p className="country"><Icon size={1} path={ mdiMapMarker } /> {relation.country ? relation.country : ""}, {relation.state ? relation.state : ""}</p> : null}
                </div>
                {(props.administrator || notChildProfile) ?
                <div>
                    {(feedback.id === relation._id || feedback.id === relation.email) ?
                        <p className={feedbackInfo}>
                            {feedback.feedbackType==="accept" ? 
                                <Icon className="accept" path={ mdiCheckCircle  } />
                            :
                                <Icon className="refuse" path={ mdiCloseCircle  } />      
                            }
                        {feedback.feedback}
                        </p>
                    :
                    <div>
                        {/*
                        <Form.Select aria-label="Default select example" value={relationType} onChange={(evt:any) => setRelationType((evt.target as HTMLInputElement).value)}>
                            <option className="default" value="">{t("Select relationship")}</option>
                            {getRealtions().map((type:string, index) => {
                                return(<option key={index} className="relations" value={type}>{t(type)}</option>)
                            })}
                        </Form.Select>  
                        */}
                        <div className="cta-block">
                            {props.userid !== localStorage.userid ? <p>{t("Add to their family?")}</p> : <p>{t("Add to family?")}</p>}
                            <div className="actions">
                                <span onClick={() => {relationType !== "" && accept(relation) }}><Icon className={relationType === "" ? "disabled" : "accept"} size={1.4} path={ mdiCheckCircle } /></span>
                                <span onClick={() => { decline(relation) }}><Icon className="refuse" size={1.4} path={ mdiCloseCircle  } /></span>
                            </div>
                        </div>                      
                    </div> } 
                </div>
                :
                <div>{t("Ask your parent to approve or reject")}</div> }
            </div>
        </div>
    );
};

export default PendingInvitations;
