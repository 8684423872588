import React, { useEffect, useCallback, Dispatch, useState, useContext } from "react"
import { useDispatch } from "react-redux"
import { setCurrentPage } from "../../store/actionCreators"
import { IUserDetails } from "../../interfaces/authentication"
import { useHistory, useLocation } from "react-router"
import DeceasedAdminForm from "../../components/deceased/deceased-admin.component"
import DeceasedNonAdmin from "../../components/deceased/deceased-nonadmin-component"
import instance from "../../api/api"
import { IRelation } from "../../interfaces/family"
import Help from "../../components/_helper/help.component"
import { Context } from "../home.page"

enum RightToUpdate {
    DEFAULT = 0,
    ALLOWED = 1,
    NOT_ALLOWED = 2,
}

export interface deceasedData {
    _id:string
    administrators:IUserDetails[]
    successors:IUserDetails[]
    relations:IRelation[]
}

function DeceasedUpdater() {
    const location: any = useLocation()
    const history = useHistory()

    const userData = useContext(Context).user;

    const dispatch: Dispatch<any> = useDispatch()
    const setPageName = useCallback((currentPage: string) => dispatch(setCurrentPage(currentPage)), [dispatch])

    // necessary datas of the deceased user
    const [deceasedUser, setDeceasedUser] = useState<deceasedData>({ _id: "", administrators: [], successors: [], relations:[]})
    // state to store the rights of the logged in user (allowed or not allowed to set the profile to deceased)
    const [rightToUpdateUser, setrightToUpdateUser] = useState(RightToUpdate.DEFAULT)

    useEffect(() => {
        // if the user does not arrive from the proper page (location does not contain the state), he is pushed back onto the previous page
        if (!location.state) {
            history.push("/dashboard");
        } else {
            // set page name
            setPageName("User deceased")
            // get the data of the deceased user (administrators, successors)
            getDeceasedUser()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * function to get the neccessary data of the deceased user
     */
    async function getDeceasedUser() {
        try {

            // id of the deceased user
            const userid = location.state.userid
            const query = `/user/${userid}`

            // axios request for the deceased user's data 
            const userResponse = await instance.get(query)

            setDeceasedUser(userResponse.data);
            checkRight(userResponse.data);

        } catch (error) {}
    }

    /**
     * Function to check the rights of the logged in user. He/she has rights to update the rpfile if:
     * 1. He is admin or successor of the deceased profile
     * 2. The deceased profile does not have admin and successor
     * @returns 
     */
    function checkRight(deceased:deceasedData) {

        // The administators and successors fields of the deceased profile are empty: the user has rights
        if (!deceased.administrators.length && !deceased.successors.length) {
            setrightToUpdateUser(RightToUpdate.NOT_ALLOWED)
            return
        }

        // check that the logged in user is admin or successor of the profile
        const userInAdminOrSuccessors =
            deceased.administrators.some((admin:IUserDetails) => admin._id === userData._id) ||
            deceased.successors.some((successor:IUserDetails) => successor._id === userData._id)
            
        if (userInAdminOrSuccessors) {
            setrightToUpdateUser(RightToUpdate.ALLOWED)
        } else {
            setrightToUpdateUser(RightToUpdate.NOT_ALLOWED)
        }
    }

    return(
        <div>
            {rightToUpdateUser === RightToUpdate.ALLOWED ?
                // if the logged in user has the rights: the upadate form is returned
                <DeceasedAdminForm deceased={deceasedUser} />
            :
                // if the logged in user does not have the rights: the non admin component is returned
                <DeceasedNonAdmin admins={deceasedUser.administrators} />
            }
            <Help category={"FAMILY"} pageName={`User Deceased`} />
        </div>
    );
}

export default DeceasedUpdater
