import { createInstance } from "@datapunt/matomo-tracker-react"

const urlBase = window.__RUNTIME_CONFIG__.REACT_APP_MATOMO_URL_BASE || "false"
const siteId = window.__RUNTIME_CONFIG__.REACT_APP_MATOMO_SITE_ID || "1"

export const matomoInstance = createInstance({
    urlBase: urlBase,
    siteId: parseInt(siteId),
    // userId: "UID76903202", // optional, default value: `undefined`.
    // trackerUrl: "https://LINK.TO.DOMAIN/tracking.php", // optional, default value: `${urlBase}matomo.php`
    // srcUrl: "https://LINK.TO.DOMAIN/tracking.js", // optional, default value: `${urlBase}matomo.js`
    disabled: urlBase === "false", // optional, false by default. Makes all tracking calls no-ops if set to true.
    // heartBeat: {
    //     // optional, enabled by default
    //     active: true, // optional, default value: true
    //     seconds: 10, // optional, default value: `15
    // },
    // linkTracking: false, // optional, default value: true
    // configurations: {
    //     // optional, default value: {}
    //     // any valid matomo configuration, all below are optional
    //     disableCookies: true,
    //     setSecureCookie: true,
    //     setRequestMethod: "POST",
    // },
})
