import React, { useContext, useEffect, useState } from "react"
import { Button, Row, Col, Spinner } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { IUserDetails } from "../../../interfaces/authentication";
import { dateFormat } from "../../../_helper/enum/enum";
import ConfirmModal from "../../_helper/confirm-modal.component";
import moment from "moment";
import { useTranslation } from "react-i18next";
import SubscriptionMediaUsageWidget from "./subscription-media-usage-widget.component";
import CreditWidget from "./subscription-credit-widget.component";
import FreeUserWidget from "./subscription-free-user-widget.component";
import instance from "../../../api/api";
import config from '../../../config/config';
import { ESubscriptionStatus } from "../../../interfaces/subscription";
import { blockTypes } from "../../../interfaces/story";
import Notification, { notificationImages } from "../../notifications/notification.component";
import { INotification, NotificationType } from "../../../interfaces/notification";
import TooltipHelp from "../../notifications/tooltiphelp.component";
import { getSpecificAccess } from "../../_helper/limit-checkers";
import { Context } from "../../../pages/home.page";

interface IInfoProps {
    user: IUserDetails
    updateUserData: Function
}

export default function SubscriptionInfo(props: IInfoProps) {

    const [confirmCancelModal, setConfirmCancelModal] = useState<boolean>(false);
    const [confirmRestartModal, setConfirmRestartModal] = useState<boolean>(false);
    const [confirmDowngradeModal, setConfirmDowngradeModal] = useState<boolean>(false);

    const [cardDetailsOk, setCardDetailsOk] = useState<boolean>(true); // true so it does not flash at start

    //tootlips
    const [showTT, setShowTT] = useState<boolean>(false);
    const [showAllTT, setShowAllTT] = useState<boolean>(false);
    const [showTrialTT, setShowTrialTT] = useState<boolean>(false);    
    const [upgradeFeedback, setUpgradeFeedback] = useState<boolean>(false);

    //loader
    const [loading, setLoading] = useState<boolean>(false);

    const [notification, setNotification] = useState<INotification>({ icon: "", title: "", text: "", success: NotificationType.problem });

    const [freeUserBlockData, setFreeUserBlockData] = useState<any>({ image: 0, audio: 0 })

    const history = useHistory();
    const location: any = useLocation();
    const userData = useContext(Context).user;

    const { t } = useTranslation();    

    const uploadMemberNotification = { icon:notificationImages.crying, title: t("Upload Limit Reached"), text: t("You cannot upload additional photos, audio, video or attachments. Contact your Subscription owner."), success:NotificationType.problem, closeBtnOff:true };
    const expiredNotification = { icon: notificationImages.crying, title: t("Subscription Expired"), text: t("All Subscription users downgraded to Free accounts"), success: NotificationType.problem, closeBtnOff: true };
    const successNotificationData = { icon: notificationImages.happiness, title: t("Success"), text: t("Your Subscription is upgraded from Family to Family+"), success: NotificationType.success };

    useEffect(() => {
        if (props.user._id.length && !props.user.subscription) {
            getFreeUserUsedBlocks();
        }
        if (props.user._id.length && props.user.subscription && 
                (props.user.subscription.subscription_type.fastspring_key === "familytrial" || props.user.subscription.subscription_type.fastspring_key === "family-vip")
            ) {
            getFastspringAccountData();
        }
    }, [props.user._id])

    useEffect(() => {
        if (location.state && location.state.upgrade) {
            setUpgradeFeedback(true);
            window.history.replaceState({}, document.title)
        }
    }, [location.state])

    async function cancelSubscription() {
        try {
            if (!props.user.subscription) {
                return;
            }
            setLoading(true)

            const res = await instance.delete("/subscriptions/fastspring/" + props.user.subscription._id);
            props.updateUserData((prev: IUserDetails) => { return { ...prev, subscription: res.data } });
            setConfirmCancelModal(false);

            setLoading(false)
        }
        catch (err: any) {
            setLoading(false)
            console.log('error', err)
        }
    }

    async function restartSubscription() {
        try {
            if (!props.user.subscription) {
                return;
            }

            setLoading(true);

            const res = await instance.post("/subscriptions/fastspring/" + props.user.subscription._id);
            props.updateUserData((prev: IUserDetails) => { return { ...prev, subscription: res.data } });
            setConfirmRestartModal(false);

            setLoading(false)

        }
        catch (err: any) {
            setLoading(false)
            console.log('error', err)

        }
    }

    async function downgradeSubscription() {
        try {
            if (!props.user.subscription) {
                return;
            }

            setLoading(true);

            await instance.put("/subscriptions/" + props.user.subscription._id, { $pull: { members: {$in :[props.user._id]} } })
            props.updateUserData((prev: any) => { return { ...prev, subscription: undefined } })
            setConfirmDowngradeModal(false)
            history.push("/dashboard/account/subscription/info")

            setLoading(false)
        }
        catch (error) {
            setLoading(false)
            console.log('error', error)
        }
    }

    function displayDetails() {

        if (props.user.subscription) {
            const isExpired = props.user.subscription.status === ESubscriptionStatus.INACTIVE;

            return (
                <div className="subscription-details-container">
                    <Row>
                        <Col>
                            <p>{t("Subscription type")}</p>
                        </Col>
                        <Col>
                            <p>{props.user.subscription.subscription_type.name} {isExpired ? "(Expired)" : ""}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col><p>{t("Subscription owner")}</p></Col>
                        <Col><p>{props.user.subscription.owner.firstname} {props.user.subscription.owner.lastname}</p></Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className={isExpired ? "expired" : ''}>
                                {props.user.subscription.status === ESubscriptionStatus.ACTIVE && props.user.subscription.subscription_type.fastspring_key !== "familytrial" 
                                    ? t("Subscription auto-renewal") : isExpired ? t("Subscription expired") 
                                    : t("Subscription ends")}
                            </p>
                            <p onClick={() => setShowTrialTT(true)} className="tooltip-text">{t("What's this?")}</p>
                        </Col>
                        <Col><p className={isExpired ? "expired" : ''}>{moment(props.user.subscription.expiration).format(dateFormat.CUSTOM)}</p></Col>
                    </Row>
                    {(userData.subscription && userData.subscription.owner._id === userData._id && userData.subscription.status !== ESubscriptionStatus.INACTIVE) ? <Row>
                        <Col><p>{isExpired ? t("Users downgraded") : t("Subscription seats")}</p></Col>
                        <Col><p>{isExpired ? props.user.subscription.members.length + 1 + " " + t("people") : t("{{var}} of {{var2}} used", {"var": props.user.subscription.members.length + 1, "var2": props.user.subscription.subscription_type.seats})}</p></Col>
                    </Row> : <></>}
                    {isExpired ?
                        <Row>
                            <Col>
                                <p>{t("All media uploaded")}</p>
                                <p onClick={() => setShowAllTT(true)} className="tooltip-text">{t("What's this?")}</p>
                            </Col>
                            <Col><p>{props.user.subscription.allUploadInPeriod} GB</p></Col>
                        </Row>
                        :
                        <>
                            <Row>
                                <Col>
                                    <p>{t("Subscription upload limit (shared)")}</p>
                                    <p onClick={() => setShowTT(true)} className="tooltip-text">{t("What's this?")}</p>

                                </Col>
                                <Col><p>{props.user.subscription.subscription_type.upload_limit} GB</p></Col>
                            </Row>
                            <Row>
                                <Col><p>{t("All uploads in period")}</p></Col>
                                <Col><p>{props.user.subscription.allUploadInPeriod} GB</p></Col>
                            </Row>
                            <Row>
                                <Col><p>{t("Your uploads in period")}</p></Col>
                                <Col><p>{props.user.uploadsInPeriod} GB</p></Col>
                            </Row>
                        </>
                    }
                </div>
            )
        }
        else {
            return (
                <div className="subscription-details-container">
                    <Row>
                        <Col>
                            <p>{t("Subscription type")}</p>
                        </Col>
                        <Col>
                            <p>{userData.account ? "Team" : "Free"}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col><p>{t("Text based stories available")}</p></Col>
                        <Col><p>{t("Unlimited")}</p></Col>
                    </Row>
                    <Row>
                        <Col><p>{t("Photo uploads available")}</p></Col>
                        <Col><p>{freeUserBlockData.image}</p></Col>
                    </Row>
                    <Row>
                        <Col><p>{t("Audio available")}</p></Col>
                        <Col><p>{freeUserBlockData.audio}</p></Col>
                    </Row>
                </div>
            )
        }
    }

    async function getFreeUserUsedBlocks() {
        let uploadedImage = await getSpecificAccess(blockTypes.IMAGE);
        const uploadedAudio = await getSpecificAccess(blockTypes.AUDIO);
        // TODO: low: https://simirity.atlassian.net/browse/MLP2-629
        setFreeUserBlockData({ image: config.freeUserLimits[blockTypes.IMAGE] - uploadedImage < 0 ? 0 : config.freeUserLimits[blockTypes.IMAGE] - uploadedImage, audio: config.freeUserLimits[blockTypes.AUDIO] - uploadedAudio })
    }

    async function openFastspringAccountPage() {
        setLoading(true)
        try {
            const res = await instance.get("/fastspring/account/authenticate") // + props.user.fastspring_account_id);
            if (res.data.accounts && res.data.accounts[0]) {
                const data = res.data.accounts[0];
                props.user.subscription?.subscription_type.fastspring_key === "familytrial" ?
                    window.open(data.url + "#/trials", '_blank', 'noopener,noreferrer') :
                    window.open(data.url + "#/subscriptions", '_blank', 'noopener,noreferrer');
            }
            setLoading(false)
        }
        catch (err) {
            // TODO: medium: sentry
            setNotification({icon:notificationImages.crying, title: t("Failed to load Fastspring (payment processing service) Account Page"), text: t("Try again later"), success: NotificationType.problem})                        
            setLoading(false)
        }
    }

    async function getFastspringAccountData() {
        setLoading(true)
        try {
            const res = await instance.get("/fastspring/account/data")
            if (res.data && res.data.payment.methods === 1 && res.data.payment.active === 1) {
                setCardDetailsOk(true)
            } else {
                setCardDetailsOk(false)                
            }
            setLoading(false)
        }
        catch (err) {
            // TODO: medium: sentry
            setNotification({icon:notificationImages.crying, title: t("Failed to load Fastspring (payment processing service) Account Data"), text: t("Try again later"), success: NotificationType.problem})                        
            setLoading(false)
        }
    }    

    function resetNotification() {
        setNotification({ icon: "", title: "", text: "", success: NotificationType.problem });
    }    

    return (
        <div className="subscription-info">
            {props.user.subscription && props.user.subscription.owner._id !== props.user._id &&
                props.user.subscription.subscription_type.upload_limit - props.user.subscription.allUploadInPeriod <= 0 ? <Notification data={uploadMemberNotification} close={() => null}/> : null}            
            {props.user.subscription && props.user.subscription.status === ESubscriptionStatus.INACTIVE && <Notification data={expiredNotification} close={() => null} />}
            {upgradeFeedback ? <Notification data={successNotificationData} close={() => setUpgradeFeedback(false)} /> : null}
            {notification.title.length ?
                <Notification data={notification} close={() => resetNotification()} />
            : null}
            {props.user.subscription && props.user.subscription.owner._id === props.user._id && props.user.subscription.subscription_type.fastspring_key === "familytrial" && !cardDetailsOk ? 
                <Notification data={
                    { icon: notificationImages.neutral, 
                    title: `Trial Ends ${moment(props.user.subscription.expiration).format(dateFormat.CUSTOM)}`, 
                    text: "Enjoy 10% off by paying before your trial ends.", 
                    success: NotificationType.info, closeBtnOff: true }} close={() => null} >
                    <Button style={{marginTop:25, marginBottom:25}} onClick={openFastspringAccountPage} className="default-button">{loading ? <Spinner animation="border" variant="#fff" /> : "Payment Options"}</Button>
                </Notification> :
            props.user.subscription && props.user.subscription.owner._id === props.user._id && props.user.subscription.subscription_type.fastspring_key === "family-vip" && !cardDetailsOk ? 
                <Notification data={
                    { icon: notificationImages.neutral, 
                    title: `Extend with 50% off`,
                    text: `Enjoy a VIP discount of 50% on two additional years by entering payment details before ${moment(props.user.subscription.expiration).format(dateFormat.CUSTOM)}`,
                    success: NotificationType.info, closeBtnOff: true }} close={() => null} >
                    <Button style={{marginTop:25, marginBottom:25}} onClick={openFastspringAccountPage} className="default-button">{loading ? <Spinner animation="border" variant="#fff" /> : "Payment Options"}</Button>
                </Notification>                
                : null}
            <div className="subscription-details-container">
                <div>
                    {displayDetails()}
                </div>
            </div>
            <div className="button-container">

            </div>
            <div className="media-boost-widget-container">

            </div>
            <div className="button-container action-buttons">
                {props.user.subscription && props.user.subscription === undefined ? <Button className="default-button" onClick={() => history.push("/dashboard/account/subscription/buy")}>{t("Buy Subscription")}</Button> : null}
                {props.user.subscription === undefined ? null
                    : props.user.subscription.owner._id === props.user._id && props.user.subscription.status !== ESubscriptionStatus.INACTIVE ?
                        <Button className="light-button" onClick={() => props.user.subscription?.status === ESubscriptionStatus.CANCELLED ? setConfirmRestartModal(true) : setConfirmCancelModal(true)}>{props.user.subscription.status === ESubscriptionStatus.CANCELLED ? t("Restart subscription") : t("Cancel subscription")}</Button>
                        : props.user.subscription.status === ESubscriptionStatus.INACTIVE ?
                            <div className="expired-data-container">
                                <p>With each day that passes, <span className="link-text" onClick={() => window.open('https://www.simirity.com/why-simirity/', '_blank', 'noopener,noreferrer')}>family is missing out.</span></p>
                                {props.user.subscription.owner._id === props.user._id ? <p>Resubscribe today to upgrade your family's experience.</p> : <p>Become your family's Subscription owner to upgrade your family's experience.</p>}
                                <Button className="default-button" onClick={() => history.push("/dashboard/account/subscription/buy")}>{props.user.subscription.owner._id === props.user._id ? "Resubscribe" : "Subscribe"}</Button>
                                <p className="gray-text">Enabling media-rich stories and full access</p>
                            </div>
                            :
                            <Button className="light-button" onClick={() => setConfirmDowngradeModal(true)}>{t("Downgrade subscription to free")}</Button>
                }
            </div>
            {props.user.subscription && props.user.subscription.owner._id === props.user._id && props.user.subscription.status !== ESubscriptionStatus.INACTIVE ? <SubscriptionMediaUsageWidget subscription={props.user.subscription} /> : null}
            {props.user.subscription && props.user.subscription.owner._id !== props.user._id && props.user.subscription.status !== ESubscriptionStatus.INACTIVE ? <CreditWidget /> : null}
            {!props.user.subscription && !props.user.account ? <FreeUserWidget /> : null}
            <ConfirmModal
                show={confirmCancelModal}
                action={"delete"}
                handleClose={() => setConfirmCancelModal(false)}
                modalTitle="Confirm"
                modalBody={() => (
                    <div className="confirm-cancel-modal-body">
                        <p className="subtitle">{t("Cancel subscription")}</p>
                        <p><span>{t("WARNING")}:</span> {t("When your current Subscription period ends, all users of your Subscription will be downgraded to Free accounts and any Savings will be lost.")}</p>
                    </div>
                )}
                reject={() => setConfirmCancelModal(false)}
                confirm={cancelSubscription}
                loading={loading}
            />
            <ConfirmModal
                show={confirmDowngradeModal}
                action={"delete"}
                handleClose={() => setConfirmDowngradeModal(false)}
                modalTitle="Confirm"
                modalBody={() => (
                    <div className="confirm-cancel-modal-body">
                        <p className="subtitle">{t("Downgrade SUBSCRIPTION to Free")}</p>
                        <p>{t("By proceeding, your family connections and stories will remain, but you will lose the benefits of the subscription you currently access.")}</p>
                    </div>
                )}
                reject={() => setConfirmDowngradeModal(false)}
                confirm={downgradeSubscription}
                loading={loading}
            />
            <ConfirmModal
                show={confirmRestartModal}
                action={"delete"}
                handleClose={() => setConfirmRestartModal(false)}
                modalTitle="Confirm"
                modalBody={() => (
                    <div className="confirm-cancel-modal-body">
                        <p className="subtitle">{t("Restart subscription")}</p>
                        <p>{t("By proceeding, you will be billed for a new Subscription when the current one ends.")}</p>
                    </div>
                )}
                reject={() => setConfirmRestartModal(false)}
                confirm={restartSubscription}
                loading={loading}
            />
            <TooltipHelp contextKey={'myaccount_subscription_upload_limit'} show={showTT} clear={() => setShowTT(false)} />
            <TooltipHelp contextKey={'myaccount_subscription_info'} show={showAllTT} clear={() => setShowAllTT(false)} />
            <TooltipHelp contextKey={'myaccount_subscription_trial'} show={showTrialTT} clear={() => setShowTrialTT(false)} />            
        </div>
    )
}
