import { mdiPause, mdiPlay } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState, useContext } from "react";
import ReactPlayer from 'react-player/lazy';
import { Context } from "../../pages/home.page";
import * as Sentry from "@sentry/react";

interface IProps {
    url:string
    errorMessage?: string
}
export default function VideoPlayer(props:IProps) {

    const [playing, setPlaying] = useState<boolean>(false);
    const [volume, setVolume] = useState<number>(0.8);
    const [duration, setDuration] = useState<number>(0);
    const [played, setPlayed] = useState<number>(0)
    const [controls, setControls] = useState<boolean>(true);
    const [canPlay, setCanPlay] = useState(true)

    const userData = useContext(Context).user;    

    useEffect(() => {
        if(playing) {
            setControls(true);
        }
        else {
            setControls(false);
        }
    }, [playing])

    useEffect(() => {
        const canPlay = ReactPlayer.canPlay(props.url)
        setCanPlay(canPlay)
    }, [props.url])


    function handleDuration(duration:number){
        setDuration(duration)
    }

    function handlePause() {
        setPlaying(false);
    }

    function handlePlay() {
        setPlaying(true)
    }

    function handlePlayPause() {
        setPlaying(!playing)
    }

    function handleProgress(state:any) {
        setPlayed(state.played)
    }

    if (!canPlay) {
        return (
            <div className="video-player-container video-error">
                <p>{props.errorMessage ? props.errorMessage : "Video being optimised"}</p>
            </div>
        )
    }

    return(
        <div className="video-player-container">
            <ReactPlayer 
                className="react-player"
                url={props.url}
                playing={playing}
                volume={volume}
                onPlay={handlePlay}
                width="100%"
                height="100%"
                onPause={handlePause}
                controls={controls}
                onDuration={handleDuration}
                onProgress={handleProgress}
                //onReady={() => console.log('onReady')}
                //onStart={() => console.log('onStart')}
                config={{ file: { 
                    attributes: {
                      controlsList: 'nodownload noremoteplayback'
                    }
                }}}
                onError={(err) => 
                    {   
                        console.log("Video being optimised", 
                            err?.srcElement?.error.code,
                            err?.srcElement?.error.message,
                            err?.srcElement?.networkState,
                            err?.srcElement?.currentSrc);
                        Sentry.captureMessage(`Video being optimised`, {
                                user: {id: userData._id, mediaUrl: `${err?.srcElement?.currentSrc}`}, 
                                tags: {
                                        'frontendErrMsg':'video-being-optimised',
                                        'srcElement.errorCode': `${err?.srcElement?.error.code}`,
                                        'srcElement.errorMessage': `${err?.srcElement?.error.message ? err?.srcElement?.error.message : "n/a"}`,
                                        'srcElement.networkState': `${err?.srcElement?.networkState}`,
                                    }
                        });
                        //return setCanPlay(false)
                    }}
            />
            {!playing && 
            <div className="play" onClick={handlePlayPause}>
                <Icon className="play-icon" size={1} path={playing ? mdiPause : mdiPlay}/>
            </div>
            }
        </div>
    );
}