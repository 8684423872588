import React, { Dispatch, useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import config from "../../config/config";
import { setCurrentPage } from "../../store/actionCreators";
import axios from 'axios';
import Markdown from "markdown-to-jsx";
import { ICMSPage } from "./cookies.page";
import { useTranslation } from "react-i18next";

/**
 * Terms page
 * 
 */

function Terms() { 

    const dispatch: Dispatch<any> = useDispatch();
    const { t } = useTranslation();    
    const setPageName = useCallback((currentPage: string) => dispatch(setCurrentPage(currentPage)), [dispatch]);
    setPageName(t("Terms & Privacy"));

    const [content, setContent] = useState<ICMSPage>();

    useEffect(() => {
        fetchCategory();
    }, [])

    async function fetchCategory() {        
        axios.get(`${config.contentServerURL}/api/pages?filters[category][$eq]=terms`).then(resp => { // Strapi v4 query format
            setContent(resp.data.data[0]);
        }, error => {
            console.log("Failed to get cookie policy", error)
        });      
    }    

    return (
        <div className="terms container">
            {content ? <Markdown>{content.attributes.body}</Markdown> : null}
        </div>
    )
}

export default Terms
