import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translation.json'; 
import translationHU from './locales/hu/translation.json';

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    hu: {
        translation: translationHU
    }
};

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: 'en',
    //debug: true,
    //lng: 'hu',
    interpolation: {
        escapeValue: false,
    }
});


export default i18n;