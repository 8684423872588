import React, { useEffect, useRef, useState } from "react";
import Icon from '@mdi/react'
import { mdiLightbulb, mdiCloseCircle, mdiMenuDown } from '@mdi/js';
import { IOption } from "../../interfaces/family";
import default_avatar from "../../assets/images/default-avatar.jpg";
import { useTranslation } from "react-i18next";

interface IProps {
    handleAction:Function
    data:IOption[]
    options:IOption[]
    placeholder:string
    icon:boolean
    iconRef?:string
    translateable?: boolean
}

export default function UniqueListInput(props:IProps) {

    const [show, setShow] = useState<boolean>(false);

    const wrapperRef = useRef<HTMLDivElement>(null);
    const upperRef = useRef<HTMLDivElement>(null);

    const { t } = useTranslation();    

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside, false);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside, false);
        };
    }, []);

    function handleClickOutside(event:any) {
        if(wrapperRef.current && !wrapperRef?.current?.contains(event.target as Node) && !upperRef?.current?.contains(event.target as Node)) {
            setShow(false);
        }
    };

    return(
        <div className="unique-list-input-container">
            <div className="example-story-select group" ref={upperRef}>
                {props.data.length ?
                    <div>
                        <div className="option-wrapper" onClick={(e) => {setShow(!show)}}>
                            {props.data.map((selected:IOption) => {
                                return(
                                    <div className={`option ${selected.hasOwnProperty('editable') ? "locked" : ""}`} key={selected._id}>
                                        {!props.icon ? <img className="user-picture" alt="profile-avatar" src={selected.img ? selected.img : default_avatar}/> : props.iconRef ? <div className="icon"><span><Icon size={0.8} path={ mdiLightbulb } /></span></div>  : null}
                                        <p>{props.translateable ? t(selected.text) : selected.text}</p>
                                        <div className="remove-icon">
                                            <span onClick={(e) => {e.stopPropagation(); props.handleAction(selected)}}><Icon size={1} path={ mdiCloseCircle } /></span>
                                        </div>
                                    </div>
                                    );
                            })}
                            <Icon className="wrapper-icon" size={1} path={ mdiMenuDown }/>
                            <p className="top-label">{props.placeholder}</p>
                        </div>
                    </div>
                :
                <p onClick={() => setShow(!show)} >{props.placeholder}<Icon className="wrapper-icon" size={1} path={ mdiMenuDown }/></p>}
            </div>
            {show ? <div className="example-story-list" ref={wrapperRef}>
                {props.options
                .sort((a, b) => (a.text > b.text) ? 1 : -1)
                .filter((option:IOption) => !props.data.map((x:IOption) => x._id).includes(option._id))
                .map((value:IOption) => {
                    return(
                        <p onClick={() => props.handleAction(value)} key={value._id}>{props.translateable ? t(value.text) : value.text}</p>
                    );
                })}
            </div> : null}
        </div>
    );
}
