import React from "react";
import { useTranslation } from "react-i18next";
import happy_emoji from "../../../assets/images/experts-info-icon.svg";
import { Button } from "react-bootstrap";

export default function ExpertsInfo() {

    const { t } = useTranslation();

    return (
        <div className="experts-info-widget">
            <p className="title">{t("Let our Experts create the stories of your life")}</p>
            <div className="content-section">
                <div className="content">{t("Choose between a full storywriting service including media file addition, or assistance as you create your story. Ensuring the highest quality stories to share with your family.")}</div>
                <div className="image-container">
                    <img className="emoji" alt="simle-emoji" src={happy_emoji} />
                </div>
                <div className="content-box">
                    <div className="box-title">{t("Getting started")}</div>
                    <div className="nr-container">
                        <div className="box-nr">1</div>
                    </div>
                    <div className="box-subtitle">{t("Free call")}</div>
                    <div className="box-text">{t("A 30 minute video call gives us a chance to get to know one another, answer your questions and create an initial story shortlist.")}</div>
                    <div className="nr-container"><div className="box-line"></div></div>
                    <div className="nr-container">
                        <div className="box-nr">2</div>
                    </div>
                    <div className="box-subtitle">{t("PACKAGE & PAYMENT")}</div>
                    <div className="box-text">{t("An customised Package will be created giving you our quote for access to an Expert. Place your purchase to proceed to booking.")}</div>
                    <div className="nr-container"><div className="box-line"></div></div>

                    <div className="nr-container">
                        <div className="box-nr">3</div>
                    </div>
                    <div className="box-subtitle">{t("SCHEDULE & PREPARE")}</div>
                    <div className="box-text">{t("Book your next call and check your emails for any preparatory tasks.")}</div>
                    <div className="learn-more-button-section"><a href="https://www.simirity.com/experts/" target="_blank" className="underlined-link-button"><Button className="default-button">{t("Learn more")}</Button></a></div>
                </div>
            </div>
        </div>
    )
}
