import { IGroup, IUserDetails } from "../../../interfaces/authentication";
import { IRelation } from "../../../interfaces/family";
import { accessTypes, IStory } from "../../../interfaces/story";

// clone of same const at API enum.ts
const relationshipTypesFriend: string[] = ["friend", "guest"]; // change it together with the above

// clone of same function at API stories.controller.ts
export function getStoryPublishNotificationUsers(story:IStory, loggedInUser:IUserDetails) {
    const userIds:any[] = [];
    const userGroups = loggedInUser.groups;
    const userFamilyRelations = loggedInUser.relations.filter((relation => !relationshipTypesFriend.includes(relation.type))).map((relation) => ((relation as IRelation).userid as IUserDetails)._id)
    const userAllRelations = loggedInUser.relations.map((relation) => ((relation as IRelation).userid as IUserDetails)._id)

    if(story.accessFamily === accessTypes.PRIVATE) {
        return [];
    }
    else if(story.accessFamily === accessTypes.ALL_FAMILY_AND_FRIENDS) {
        userIds.push(userAllRelations)
    }

    else if(story.accessFamily === accessTypes.ALL_FAMILY) {
        userIds.push(userFamilyRelations)
    }

    else if(story.accessFamily === accessTypes.GROUP) {
        userGroups.map((group) => {
            if((story.accessGroups as IGroup[]).map(x => x._id).includes((group as IGroup)._id)) {
                userIds.push(((group as IGroup).members as IUserDetails[]).map(x => x._id))
                userIds.push(((group as IGroup).administrator as IUserDetails)._id);
            }
        })
    }

    else if(story.accessFamily === accessTypes.INDIVIDUAL) {
        userIds.push(story.accessIndividuals)
    }
    //Add fix users;
    userIds.push(((story.participants as IUserDetails[]).map(x => x._id), (story.coAuthors as IUserDetails[]).map(x => x._id)));

    // author should not get a notification
    if(loggedInUser._id.toString() !== (story.author as IUserDetails)._id.toString()) userIds.push(((story.author as IUserDetails))._id);

    // featured=author should not get a notification
    if(loggedInUser._id.toString() !== (story.featured as IUserDetails)._id.toString()) userIds.push(((story.featured as IUserDetails))._id);

    const uniques = new Set(userIds.flat().map(x => x.toString()))
    const uniqueArray = Array.from(uniques.values())
    return uniqueArray;
}