import React from 'react';
import { Redirect } from "react-router-dom";

/**
 * Basic Notfound: - We do not display notfound page, only redirecting to Home.
 * @returns Redirect to Home route
 */
export default function NotFound() {
    
    return(
        <Redirect exact to={'/dashboard'} />
    );
}