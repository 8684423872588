import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import instance from '../../../api/api';
import StoryLaunchPad from '../../../components/story/story-launchpad.component';
import EditedStories from '../../../components/story/to-do/unpublished.component';
import Help from '../../../components/_helper/help.component';
import IPage from '../../../interfaces/page';
import { IStory } from '../../../interfaces/story';
import { setCurrentPage } from '../../../store/actionCreators';
import { useTranslation } from "react-i18next";

interface RouteParams {
    page: string
}

/**
 * Create story page
 *
 * @param props : props
 * @returns void
 */
const LaunchPad: React.FunctionComponent<IPage> = props => {

    const dispatch: Dispatch<any> = useDispatch();
    const setPageName = useCallback((currentPage: string) => dispatch(setCurrentPage(currentPage)), [dispatch]);

    const [ loaded, setLoaded ] = useState<boolean>(false);
    const [inProgressStories, setInProgressStories] = useState<IStory[]>([]);

    //Tabs
    const params = useParams<RouteParams>();
    const history = useHistory();

    const { t } = useTranslation();    

    useEffect(() => {
        setPageName(t("Creation"));
        getInProgressStories();
    }, []);

    useEffect(() => {
        if (inProgressStories.length){
            setLoaded(true)
        }
    }, [inProgressStories]);


    async function getInProgressStories() {
        try {
            //Get edited stories
            const editedResponse = await instance.get('/stories?edited=true&published=true');
            const editedData: IStory[] = editedResponse.data;

            //Get unpunlished stories
            const unpublishedResponse = await instance.get('/stories?published=false');
            const unpublishedData: IStory[] = unpublishedResponse.data;

            //Concat them
            const result = editedData.concat(unpublishedData);
            setInProgressStories(result);
        }
        catch (error) {
            console.log('Error', error)
        }
    }

    function handleRouteChange(route: string) {
        history.push("/dashboard/launchpad/" + route);
    }

    return (
        <div className="create-container">
            <Tabs className="menu-tabs tabs-launchpad" onSelect={(k) => { handleRouteChange(k as string); }} defaultActiveKey={params.page}>
                <Tab eventKey={"1"} title={t("New")}>
                    <StoryLaunchPad />
                    <Help category={"PUBLISHING"} pageName={`Launchpad`}></Help>
                </Tab>
                <Tab eventKey={"2"} title={t("In Progress")}>
                    <EditedStories data={inProgressStories} loaded={loaded} />
                    <Help category={"PUBLISHING"} pageName={"In Progress"}></Help>
                </Tab>
            </Tabs>
        </div>
    );
};

export default LaunchPad;
