import React, { useEffect, useRef, useState, useContext } from 'react';
import { blockTypes, fileTypes, IBlock, IMedia, IStory } from '../../../interfaces/story';
import parse, { domToReact } from 'html-react-parser';
import Icon from '@mdi/react';
import { mdiAccountGroup, mdiCloudDownload, mdiCommentOutline, mdiDotsHorizontal, mdiPause, mdiPlay, mdiReplay, mdiStar, mdiVolumeHigh, mdiStarOff, mdiArrowExpand, mdiFastForward30, mdiRewind30 } from '@mdi/js';
import WaveSurfer from 'wavesurfer.js';
import { Carousel, Modal, Spinner } from 'react-bootstrap';
import VideoPlayer from '../../_helper/video-player.component';
import { useHistory } from 'react-router-dom';
import config from '../../../config/config';
import { IOption } from '../../../interfaces/family';
import { ReactComponent as IconLifelesson } from "../../../assets/images/icon-life_lesson.svg";
import { formatBytes } from '../../../_helper/format-bytes';
import DOMPurify from 'dompurify';
import iconFlickr from "../../../assets/images/icon-flickr.png";
import iconGooglephotos from "../../../assets/images/icon-google_photos.png";
import { IUserDetails } from '../../../interfaces/authentication';
import { Context } from '../../../pages/home.page';
import instance from "../../../api/api"
import ImageFullscreenViewer from '../../_helper/image-fullscreen-viewer';
import commentAPIInstance from '../../../api/comment_api';
import { ECommentType } from '../../comments/comments-container.component';
import { getDotClassName } from '../../../_helper/carousels';
import { useTranslation } from "react-i18next";

interface IProps {
    story: IStory
    showInteractions: boolean
    openCommentSection?: Function
    triggerCommentCounter?: boolean
}

interface IDisplayComponent {
    block: IBlock
    index: number
    showInteractions?: boolean
    story?: IStory
    doubleClickChecker?: Function
    openFullscreen?: Function
    openCommentSection?: Function
    commentsEnabled?: boolean
    triggerCommentCounter?: boolean
}

export function DisplayStory(props: IProps) {

    const [ imageFullScreen, setImageFullScreen ] = useState<boolean>(false)
    const [ images, setImages ] = useState<IMedia[]>([]);
    const [ mainIndex, setMainIndex ] = useState<number>(0);

    function getMedia() {
        const media:IMedia[] = [];
        props.story.blocks.forEach((block, index) => {
            if(block.multipleMedia.length) {
                media.push( ...block.multipleMedia );
            }
            if(block.media) {
                media.push( block.media );
            }
        });
        return media;
    }

    function doubleClickChecker(e:any, _id:string) {
        switch (e.detail) {
            case 2:
                openFullscreen(_id);
                break;
            default:
                return;
        }
    };

    function openFullscreen(_id:string) {
        const media = getMedia();
        const index = media.findIndex(x => x._id === _id);
        setMainIndex(index ? index : 0);
        //setImages(media.filter(x => x.fileType === 'image' || x.fileType === 'video').flat());
        // don't filter for image and video otherwise in case of audio the fullscreen would jump to the wrong position of the sequence of media MLP2-1036
        setImages(media.flat());
        setImageFullScreen(true);
    }

    function openCommentSection(visual:IMedia) {
        if(props.openCommentSection) {
            props.openCommentSection(visual)
        }
    }

    function displayBlock(block: IBlock, index: number) {
        switch (block.type) {
            case blockTypes.TEXT:
                return <DisplayText story={props.story} block={block} index={index} />
            case blockTypes.IMAGE:
                return <DisplayImage story={props.story} triggerCommentCounter={props.triggerCommentCounter} commentsEnabled={props.story.commentsEnabled} openCommentSection={openCommentSection} openFullscreen={openFullscreen} doubleClickChecker={doubleClickChecker} block={block} index={index} showInteractions={props.showInteractions} />
            case blockTypes.VIDEO:
                return <DisplayVideo story={props.story} triggerCommentCounter={props.triggerCommentCounter} commentsEnabled={props.story.commentsEnabled} openCommentSection={openCommentSection} openFullscreen={openFullscreen} doubleClickChecker={doubleClickChecker} block={block} index={index} showInteractions={props.showInteractions} />
            case blockTypes.CAROUSEL:
                return <Displaycarousel story={props.story} triggerCommentCounter={props.triggerCommentCounter} commentsEnabled={props.story.commentsEnabled} openCommentSection={openCommentSection} openFullscreen={openFullscreen} doubleClickChecker={doubleClickChecker} block={block} index={index} showInteractions={props.showInteractions} />
            case blockTypes.AUDIO:
                return <DisplayAudio story={props.story} triggerCommentCounter={props.triggerCommentCounter} block={block} index={index} showInteractions={props.showInteractions} />
            case blockTypes.LIFE_LESSON:
                return <DisplayLifelesson story={props.story} block={block} index={index} />
            case blockTypes.ATTACHMENT:
                return <DisplayAttachment story={props.story} block={block} index={index} />
            case blockTypes.LINK_ALBUM:
                return <DisplayLinkalbum story={props.story} block={block} index={index} />
            case blockTypes.EMBED:
                return <DisplayEmbed block={block} index={index} />

        }
    }

    return (
        <div className={"display-block"}>
            {props.story.blocks.map((block, index) => {
                return (
                    <div className={"display-block"}>{displayBlock(block, index)}</div>

                )
            })}
            {imageFullScreen && <ImageFullscreenViewer mainIndex={mainIndex} close={() => setImageFullScreen(false)} images={images} />}
        </div>
    );
}


/**
 * Local function for displaying audio block
 */
export function DisplayAudio(props: any) {

    //Audio elements
    const [waveSurfer, setWaveSurfer] = useState<any>(false);
    const [play, setPlay] = useState<boolean>(false);
    const [showFav, setShowFav] = useState<boolean>(false);
    const [pause, setPause] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);

    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (wrapperRef.current && !waveSurfer) {
            const audio = new Audio((props.block.media.data as string));

            const wavesurfer = WaveSurfer.create({
                backend: 'MediaElement',                
                container: '#waveform_' + props.index,
                waveColor: 'grey',
                progressColor: 'grey',
                barWidth: 3,
                barGap: 2,
                height: 100
            });

            wavesurfer.load(audio)
            setWaveSurfer(wavesurfer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.block])

    useEffect(() => {
        if (waveSurfer) {
            waveSurfer.backend.on('audioprocess', function (time: any) {
                let current = parseFloat(time.toFixed(1));
                let max = parseFloat(waveSurfer.backend.getDuration().toFixed(1))
                if ((current + 0.2) >= max) {
                    setPlay(false);
                    setPause(false);
                }

                if (waveSurfer.isPlaying()) {
                    const totalTime = waveSurfer.getDuration();
                    const currentTime = waveSurfer.getCurrentTime();
                    const remainingTime = totalTime - currentTime;

                    let hours = Math.floor(remainingTime / 3600);
                    let mins = Math.floor(remainingTime / 60) - (hours * 60);
                    let secs = Math.floor(remainingTime % 60);

                    const element = document.getElementById(`time-remaining ${props.index}`);
                    if (element !== null) {
                        element.innerText = mins.toString().padStart(2, '0') + ':' + secs.toString().padStart(2, '0');
                    }
                }
            });
            
            waveSurfer.on('ready', function () {
                setLoaded(true)              
            });
            
        }
        return () => {
            if (waveSurfer) {            
                pauseAudio();
            }
        };        
    }, [waveSurfer])

    function playAudio() {
        waveSurfer.play();
        setPlay(props.index);
        setPause(false);
    }

    function pauseAudio() {
        waveSurfer.pause();
        setPause(true);
    }

    return (
        <div className="display-block-audio">
            <div className="content">
                <div ref={wrapperRef} className="wave" id={'waveform_' + props.index}></div>
                <p className="optional-desc">{props.block.media && props.block.media.description}</p>
                {!loaded ? <Spinner animation="border" variant="#fff" /> :
                <div>            
                        <div className="backward" onClick={() => waveSurfer.skipForward(30)}>
                            <Icon size={2} path={mdiFastForward30} />
                        </div>
                        {(play === props.index && !pause) ?
                            <button className="record-button pause" onClick={() => (play === props.index && !pause) ? pauseAudio() : playAudio()}><Icon size={1} path={mdiPause} /></button>
                            : (play === props.index && pause) ?
                                <button className="record-button play" onClick={() => (play === props.index && !pause) ? pauseAudio() : playAudio()}><Icon size={1} path={mdiPlay} /></button>
                                :
                                <button className="record-button play" onClick={() => (play === props.index && !pause) ? pauseAudio() : playAudio()}><Icon size={1} path={mdiVolumeHigh} /></button>
                        }
                        <div className="forward" onClick={() => waveSurfer.skipBackward(30)}>
                            <Icon size={2} path={mdiRewind30} />
                        </div>
                        {props.showInteractions ? <div className="interactions">
                            <div className="interaction-container favourite-audio" onClick={() => setShowFav(true)}>
                                <Icon className="big-icon" size={1} path={mdiDotsHorizontal} />
                            </div>
                        </div> : null}
                        {play === props.index && !pause ? <p className="audio-user"><span id={`time-remaining ${props.index}`}>0.00</span> remaining</p> : <p className="audio-user">{props.block.media.user.firstname} {props.block.media.user.lastname}</p>}
                </div>                
                }                
            </div>
            <AddToFavouriteModal show={showFav} onHide={() => setShowFav(false)} mediaId={props.block.media._id} />
        </div>
    )
}

/**
 * Only one without media !!
 * @param props
 * @returns
 * 
 * We use parse() here since we want to turn HTML string into JSX deliberately.
 * Do not use parse() without sanitizing / purifying as it is NOT XSS safe!
 * (The rich text editor - react-draft-wysiwyg - is XSS safe that is why in conjunction with parse() we are safe.)
 * 
 */
export function DisplayText(props: IDisplayComponent) {
    const isCoAuthorBlock = (props.story?.coAuthors as IUserDetails[]).find((x) => x._id === (props.block.createdBy as string))

    // replace the empty paragraphs by a new line
    function showEmptyLines(textBlock: string) {
        const textWithLines = textBlock.replaceAll("<p></p>", "<p class='empty'> </p>")
        return textWithLines
    }

    return (
        <div className={`${isCoAuthorBlock ? "co-author-block-text" : "display-block-text"}`}>
            {isCoAuthorBlock ? <p className="co-author"> {`${isCoAuthorBlock.firstname}  ${isCoAuthorBlock.lastname}`}: </p> : null}
            <div className="text-data">{parse(showEmptyLines(props.block.data))}</div>
        </div>
    )
}

export function DisplayImage(props: IDisplayComponent) {

    const [showAudio, setShowAudio] = useState<boolean>(false);
    const [showPeople, setShowPeople] = useState<boolean>(false);
    const [showFav, setShowFav] = useState<boolean>(false);

    const [ numOfComments, setNumOfComments ] = useState<number>(0);

    useEffect(() => {
        getCommentNum()
    }, [props.triggerCommentCounter])

    if (!props.block.media) return (<div></div>);

    function openCommentSection() {
        if(props.openCommentSection) {
            props.openCommentSection(props.block.media)
        }
    }

    async function getCommentNum() {
        try{
            const res = await commentAPIInstance.get("/", { 
                params: { 
                    pageNo:1,
                    query : {
                        related_entity:props.block.media._id,
                        comment_type:ECommentType.MEDIA
                    }
                },
                headers: {"Authorization" : `Bearer ${props.story?.accessCommentToken}`}
            })
            setNumOfComments(res.data.totalRecords);
        }
        catch(error) {
            return;
        }
    }

    return (
        <div className="display-block-media">
            {props.block.media.description && <p className="optional-desc">{props.block.media.description}</p>}

            <div className="content one-image-in-gallery">
                <img onClick={(evt) => props.doubleClickChecker ? props.doubleClickChecker(evt, props.block.media._id) : null} className="story-block-image" alt="Block" src={props.block.media.dataM ? props.block.media.dataM : props.block.media.data} />

                {/* Interactions */}
                    <div className="interactions">
                        {props.block.media.relatedMedia ? <PlayNarration block={props.block} show={showAudio} setShow={setShowAudio} index={props.index} /> : null}
                        {props.showInteractions ? <div>
                            <div className="interaction-container favourite" onClick={() => setShowFav(true)}>
                                <Icon className="big-icon" size={1} path={mdiDotsHorizontal} />
                            </div>

                            {props.commentsEnabled ? 
                            <div className="interaction-container comment" onClick={() => {openCommentSection()} }>
                                   {numOfComments > 0 ? <div className="added">
                                        <p>{numOfComments}</p>
                                    </div> : null}
                                <Icon className="big-icon" size={1} path={mdiCommentOutline} />
                            </div> : null}

                            {(props.block.media.tags.users.length || props.block.media.tags.others) ?
                                <div className="interaction-container people" onClick={() => setShowPeople(true)}>
                                    <div className="added">
                                        <p>{numOfTaggedString(props.block.media.tags.users, props.block.media.tags.others)}</p>
                                    </div>
                                    <Icon className="big-icon" size={1} path={mdiAccountGroup} />
                                </div> : null
                            }
                        </div> : null}
                    </div>
            </div>
            {/* Modals */}
            <ShowTaggedPeople block={props.block} show={showPeople} setShow={setShowPeople} index={props.index} />
            <AddToFavouriteModal openFullscreen={props.openFullscreen} show={showFav} onHide={() => setShowFav(false)} mediaId={props.block.media._id} />
        </div>
    )
}

export function DisplayVideo(props: IDisplayComponent) {

    const [showPeople, setShowPeople] = useState<boolean>(false);
    const [showFav, setShowFav] = useState<boolean>(false);

    const [ numOfComments, setNumOfComments ] = useState<number>(0);

    useEffect(() => {
        getCommentNum()
    }, [props.triggerCommentCounter])

    function openCommentSection() {
        if(props.openCommentSection) {
            props.openCommentSection(props.block.media)
        }
    }

    async function getCommentNum() {
        try{
            const res = await commentAPIInstance.get("/", { 
                params: { 
                    pageNo:1,
                    query : {
                        related_entity:props.block.media._id,
                        comment_type:ECommentType.MEDIA
                    }
                },
                headers: {"Authorization" : `Bearer ${props.story?.accessCommentToken}`}
            })
            setNumOfComments(res.data.totalRecords);
        }
        catch(error) {
            return;
        }
    }

    return (
        <div className="display-block-media display-block-video">
            {props.block.media.description && <p className="optional-desc">{props.block.media.description}</p>}
            <div className="content">
                <div onClick={(evt) => props.doubleClickChecker ? props.doubleClickChecker(evt, props.block.media._id) : null}>
                    <VideoPlayer url={props.block.media.data} />
                </div>
                {/* Interactions */}
                {props.showInteractions ? <div className="interactions">
                    {props.commentsEnabled ? 
                        <div className="interaction-container comment" onClick={() => openCommentSection() }>
                                {numOfComments > 0 ? <div className="added">
                                    <p>{numOfComments}</p>
                                </div> : null}
                            <Icon className="big-icon" size={1} path={mdiCommentOutline} />
                        </div> : null}

                    <div className="interaction-container favourite" onClick={() => setShowFav(true)}>
                        <Icon className="big-icon" size={1} path={mdiDotsHorizontal} />
                    </div>

                    {(props.block.media.tags.users.length || props.block.media.tags.others) ?
                        <div className="interaction-container people" onClick={() => setShowPeople(true)}>
                            <div className="added">
                                <p>{numOfTaggedString(props.block.media.tags.users, props.block.media.tags.others)}</p>
                            </div>
                            <Icon className="big-icon" size={1} path={mdiAccountGroup} />
                        </div> : null
                    }
                </div> : null}
            </div>
            <ShowTaggedPeople block={props.block} show={showPeople} setShow={setShowPeople} index={props.index} />
            <AddToFavouriteModal openFullscreen={props.openFullscreen} show={showFav} onHide={() => setShowFav(false)} mediaId={props.block.media._id} />
        </div>
    )
}

export function Displaycarousel(props: IDisplayComponent) {

    const [activeIndex, setIndex] = useState<number>(0);

    const handleSelect = (selectedIndex: number, e: any) => {
        setIndex(selectedIndex);
    };

    const [showAudio, setShowAudio] = useState<boolean>(false);
    const [showPeople, setShowPeople] = useState<boolean>(false);
    const [showFav, setShowFav] = useState<boolean>(false);

    const [ numOfComments, setNumOfComments ] = useState<number>(0);

    useEffect(() => {
        getCommentNum()
    }, [activeIndex, props.triggerCommentCounter])

    function openCommentSection() {
        if(props.openCommentSection) {
            props.openCommentSection(props.block.multipleMedia[activeIndex])
        }
    }

    async function getCommentNum() {
        try{
            const res = await commentAPIInstance.get("/", { 
                params: { 
                    pageNo:1,
                    query : {
                        related_entity:props.block.multipleMedia[activeIndex]._id,
                        comment_type:ECommentType.MEDIA
                    }
                },
                headers: {"Authorization" : `Bearer ${props.story?.accessCommentToken}`}
            })
            setNumOfComments(res.data.totalRecords);
        }
        catch(error) {
            return;
        }
    }

    return (
        <div className="display-block-carousel display-block-media">
            {props.block.multipleMedia[activeIndex].description && <p className="optional-desc">{props.block.multipleMedia[activeIndex].description}</p>}
            <div className="content">

                <Carousel indicatorLabels={getDotClassName(props.block.multipleMedia, activeIndex)} activeIndex={activeIndex} className={props.block.multipleMedia.length >= 5 ? "multidots" : "simpledots"} onSelect={handleSelect} interval={null} indicators={true}>
                    {props.block.multipleMedia.map((media: IMedia, index: number) => (
                        <Carousel.Item
                            className={`${activeIndex === index ? "acitve" : ""}`}
                            key={index}
                            onClick={(evt) => props.doubleClickChecker ? props.doubleClickChecker(evt, media._id) : null}
                            >
                            {(media.fileType !== fileTypes.VIDEO) ?
                                <img src={media.dataM ? media.dataM : media.data} alt="uploaded" className="story-block-image" loading="lazy" />
                                :
                                <VideoPlayer url={media.data} />
                            }
                        </Carousel.Item>
                    ))}
                </Carousel>

                {/* Interactions */}
                <div className="interactions">
                    {props.block.multipleMedia[activeIndex].relatedMedia ? <PlayNarration block={props.block} show={showAudio} setShow={setShowAudio} index={props.index} currentIndex={activeIndex} /> : null}

                    {props.showInteractions ? <div>
                        {props.commentsEnabled ? 
                            <div className="interaction-container comment" onClick={() => openCommentSection() }>
                                    {numOfComments > 0 ? <div className="added">
                                        <p>{numOfComments}</p>
                                    </div> : null}
                                <Icon className="big-icon" size={1} path={mdiCommentOutline} />
                            </div> : null}
                        <div className="interaction-container favourite" onClick={() => setShowFav(true)}>
                            <Icon className="big-icon" size={1} path={mdiDotsHorizontal} />
                        </div>
                        {(props.block.multipleMedia[activeIndex].tags.users.length || props.block.multipleMedia[activeIndex].tags.others) ?
                            <div className="interaction-container people" onClick={() => setShowPeople(true)}>
                                <div className="added">
                                    <p>{numOfTaggedString(props.block.multipleMedia[activeIndex].tags.users, props.block.multipleMedia[activeIndex].tags.others)}</p>
                                </div>
                                <Icon className="big-icon" size={1} path={mdiAccountGroup} />
                            </div> : null}
                    </div> : null}
                </div>
            </div>

            <p className="current-page">{activeIndex + 1} of {props.block.multipleMedia.length}</p>
            <ShowTaggedPeople block={props.block} currentIndex={activeIndex} show={showPeople} setShow={setShowPeople} index={props.index} />
            <AddToFavouriteModal openFullscreen={props.openFullscreen} show={showFav} onHide={() => setShowFav(false)} mediaId={props.block.multipleMedia[activeIndex]._id} />
        </div>
    )
}

/**
 * Display Life Lesson
 * @param props
 * @returns
 */
export function DisplayLifelesson(props: IDisplayComponent) {
    const isCoAuthorBlock = (props.story?.coAuthors as IUserDetails[]).find(x => x._id === (props.block.createdBy as string))

    const history = useHistory();
    
    const { t } = useTranslation();

    return (
        <div className={`${isCoAuthorBlock ? "co-author-display-block-lifelesson" : ""}`}>
            {isCoAuthorBlock ? <p className="co-author"> {`${isCoAuthorBlock.firstname}  ${isCoAuthorBlock.lastname}`}: </p> : null}
            <div className="display-block-lifelesson">
                <div className="icon">
                    <IconLifelesson />
                </div>
                <div className="container">
                    <p>{props.block.data}</p>
                    {props.block.tags && props.block.tags.length ? 
                        <p>{t("Life Lesson(s)")}: {props.block.tags.map((tag, index) => {
                                return(
                                    <div className='tag' key={tag}>
                                        <a className="underlined-link-button-tags" onClick={() => history.push({pathname: '/dashboard/stories/4', state: { filters: { lifelessons: (config.lifelessonTags.find(data => data._id === tag) as IOption) }} })}>
                                            <span>{t((config.lifelessonTags.find(data => data._id === tag) as IOption).text)}</span>
                                        </a>
                                        <span>{`${index === (props.block.tags as string[]).length - 1 ? "" : ", "}`}</span>
                                    </div>
                                )}
                            )}
                        </p>
                       : null }
                </div>
            </div>
        </div>
    )
}

/**
 * Display Attachment
 * @param props
 * @returns
 */
export function DisplayAttachment(props: IDisplayComponent) {

    return (
        <div className="display-block-attachment">
            {props.block.media ?
                <div className="container">
                    {props.block.media.description && props.block.media.description.length ?
                        <p className="title">{props.block.media.description}</p>
                        : null}
                    <div className="icon">
                        <a href={props.block.media.data}>
                            <Icon size={1} path={mdiCloudDownload} />
                        </a>
                    </div>
                    <div className="attachment">
                        <p>
                            <a className="underlined-link-button" href={props.block.media.data}>
                                {props.block.media.name}
                            </a>
                        </p>
                        {props.block.media.fileSize ?
                            <p className="size">
                                {formatBytes(props.block.media.fileSize)}
                            </p>
                            : null
                        }
                    </div>
                </div>
                : null}
        </div>
    )
}

/**
 * Display External Album Link
 * @param props
 * @returns
 */
export function DisplayLinkalbum(props: IDisplayComponent) {

    const { t } = useTranslation();    

    return (
        <div className="display-block-linkalbum">
            <div className="container">
                <div className="album">
                    {props.block.description && props.block.description.length ?
                        <p className="title">{props.block.description}</p>
                        : null}
                    <p className='view'><a className="link-button" href={props.block.data} target="_blank" rel="noopener noreferrer">{t("View Photo Album")}</a></p>
                    {(props.block.tags as string[]).length ?
                        <p>
                            <a className="underlined-link-button" href={props.block.data} target="_blank" rel="noopener noreferrer">

                                <img className="provider-icon" alt="provider-icon" src={(props.block.tags as string[])[0] === "FLICKR" ? iconFlickr : iconGooglephotos} />
                                {t("on")} {config.linkAlbumProviders.find((data: any) => data._id === (props.block.tags as string[])[0]).text}
                            </a>
                        </p>
                        : null}
                </div>
            </div>
        </div>
    )
}

/**
 * Display Embed
 * @param props
 * @returns
 */
export function DisplayEmbed(props: IDisplayComponent) {

    return (
        <div className="display-block-embed">
            <div className="container">
                <div className="album">
                    {props.block.description && props.block.description.length ?
                        <p className="title">{props.block.description}</p>
                        : null}
                    {/*<p className='view'>View Photo Album</p>*/}
                    {(props.block.tags as string[]).length ?
                        <div>
                            {parse(DOMPurify.sanitize((props.block.data as string), { ALLOWED_TAGS: ['iframe'] }))}
                            <p>From {config.embedProviders.find((data: any) => data._id === (props.block.tags as string[])[0]).text}</p>
                        </div>
                        : null}
                </div>
            </div>
        </div>
    )
}


/**
 * Add People Modal and data
 * */
interface IaddPeopleModal {
    block: IBlock
    show: boolean
    setShow: Function
    index: any
    currentIndex?: number
}

function ShowTaggedPeople(props: IaddPeopleModal) {

    const media = (props.currentIndex !== undefined && props.block.multipleMedia) ? props.block.multipleMedia[props.currentIndex] : props.block.media
    const history = useHistory();

    const { t } = useTranslation();    

    function displayTagged() {
        return media.tags.users.map((x: any) => {
            return (<span><span onClick={() => history.push('/dashboard/profile/' + x._id + "/1")} className="tagged-user">{x.firstname} {x.lastname}</span>, </span>)
        })
    }
    return (
        <Modal dialogClassName="members-modal-display" show={props.show} onHide={() => props.setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Featured People</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(media.fileType !== fileTypes.VIDEO) ?
                    <img className="story-block-image" alt="Block" src={media.dataM ? media.dataM : media.data} />
                    :
                    <div className="video-cont">
                        <VideoPlayer url={media.data} />
                    </div>
                }
                <div className="tagged">
                    {media.tags.users.length ?
                        <div className="family">
                            <p>{t("Family")}</p>
                            <p>{displayTagged()}</p>
                        </div> : null}
                    {media.tags.others ?
                        <div className="others">
                            <p>{t("Others")}</p>
                            <p>{media.tags.others}</p>
                        </div> : null}
                </div>
            </Modal.Body>
        </Modal>
    );
}


/**
 * Add narration modal
 */
interface IaddNarrationModal {
    block: IBlock
    show: boolean
    setShow: Function
    index: any
    currentIndex?: number
}

export function PlayNarration(props: IaddNarrationModal) {

    const [hasMedia, setHasMedia] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);

    //Audio elements
    const [waveSurfer, setWaveSurfer] = useState<any>(false);
    const [play, setPlay] = useState<boolean>(false);
    const [pause, setPause] = useState<boolean>(false);

    const wrapperRef = useRef<HTMLDivElement>(null);

    const media = (props.currentIndex !== undefined && props.block.multipleMedia) ? props.block.multipleMedia[props.currentIndex] : props.block.media

    useEffect(() => {
        if (wrapperRef.current && !waveSurfer && hasMedia) {
            if (!(media.relatedMedia as IMedia).data) {
                return;
            }
            const audio = new Audio(((media.relatedMedia as IMedia).data as string));

            const wavesurferLoc = WaveSurfer.create({
                container: '#waveform_' + media._id,
                waveColor: 'grey',
                progressColor: 'grey',
                barWidth: 3,
                barGap: 2,
                height: 100
            });
            wavesurferLoc.load(audio);
            setWaveSurfer(wavesurferLoc);
            //setLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasMedia, props.show])

    useEffect(() => {
        if (waveSurfer) {
            waveSurfer.backend.on('audioprocess', function (time: any) {
                let current = parseFloat(time.toFixed(1));
                let max = parseFloat(waveSurfer.backend.getDuration().toFixed(1))
                if ((current + 0.2) >= max) {
                    setPlay(false);
                    setPause(false);
                }

                if (waveSurfer.isPlaying()) {
                    const totalTime = waveSurfer.getDuration();
                    const currentTime = waveSurfer.getCurrentTime();
                    const remainingTime = totalTime - currentTime;

                    let hours = Math.floor(remainingTime / 3600);
                    let mins = Math.floor(remainingTime / 60) - (hours * 60);
                    let secs = Math.floor(remainingTime % 60);

                    const element = document.getElementById(`time-remaining ${props.index}`);
                    if (element !== null) {
                        element.innerText = mins.toString().padStart(2, '0') + ':' + secs.toString().padStart(2, '0');
                    }
                }
            });
            waveSurfer.on('ready', function () {
                setLoaded(true)
            });                
        }
        return () => {
            if (waveSurfer) {            
                pauseAudio();
            }
        };          
    }, [waveSurfer])

    useEffect(() => {
        if (media && media.relatedMedia) {
            setHasMedia(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.block.media])

    function playAudio() {
        waveSurfer.play();
        setPlay(props.index);
        setPause(false);
    }

    function pauseAudio() {
        waveSurfer.pause();
        setPause(true);
    }

    return (
        <div className="display-audio-interaction">
            <div ref={wrapperRef} className="wave" id={'waveform_' + media._id}></div>
            {loaded && <div className={`interaction-container audio ${play === props.index ? "played" : ""}`} onClick={() => (play === props.index && !pause) ? pauseAudio() : playAudio()}>
                {(play === props.index && !pause) ?
                    <Icon size={1} path={mdiPause} />
                    : (play === props.index && pause) ?
                        <Icon size={1} path={mdiPlay} />
                        :
                        <Icon size={1} path={mdiVolumeHigh} />
                }

                {(!(play === props.index) && loaded) ?
                    <p className="record-user">{(media.relatedMedia as IMedia).user.firstname} {(media.relatedMedia as IMedia).user.lastname}</p>
                    :
                    <p className="record-user"><span id={`time-remaining ${props.index}`}>0.00</span></p>
                }
            </div>}
        </div>
    );
}

interface IFavModal {
    show: boolean
    onHide: Function
    mediaId: string
    openFullscreen?: Function
}

function AddToFavouriteModal(props: IFavModal) {
    const [ favorite, setFavorite ] = useState(false);

    const loggedInUserData = useContext(Context).user;
    const updateLoggedInUserData = useContext(Context).setUserDetails;

    const { t } = useTranslation();    

    useEffect(() => {
        if (loggedInUserData.favorites.media.includes(props.mediaId)) {
            setFavorite(true);
        }
    }, [])

    async function addToFavMedia() {
        if (!loggedInUserData.favorites.media.includes(props.mediaId)) {
            const response = await instance.put(`/user/${loggedInUserData._id}`, { push: { "favorites.media": props.mediaId } });
            updateLoggedInUserData(response.data);
            setFavorite(true);
        }
        else {
            const response = await instance.put(`/user/${loggedInUserData._id}`, { pull: { "favorites.media": props.mediaId } });
            updateLoggedInUserData(response.data)
            setFavorite(false);
        }
    }

    function openFullscreen() {
        if(props.openFullscreen) {
            props.openFullscreen(props.mediaId);
        }
        props.onHide()
    }

    return (
        <Modal className="story-action-modal" show={props.show} onHide={props.onHide}>
            <Modal.Header className="" closeButton>
            </Modal.Header>
            <Modal.Body className="edit-card-content">
                {props.openFullscreen ? <div className="link-row" onClick={() => openFullscreen()}>
                    <Icon size={1} path={ mdiArrowExpand } /><p className="main-text-single">{t("View Fullscreen")}</p>
                </div> : null}
                <div className="link-row" onClick={() => addToFavMedia()}>
                { !favorite ? <><Icon size={1} path={mdiStar} /><p className="main-text-info"> {t("Add to Favourite Media")} </p></>
                : <><Icon size={1} path={mdiStarOff} /><p className="main-text-info"> {t("Remove from Favourite Media")} </p></>}
                    <p className="info-text">For easy future access</p>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export function numOfTaggedString(userTags: any, otherTags: any) {
    let numOfTaggedString = userTags.length ? userTags.length : "";
    if (otherTags) numOfTaggedString += otherTags.length ? "+" : "";
    if (numOfTaggedString == "+") numOfTaggedString = "1+";
    return numOfTaggedString;
}
