import React, { Dispatch, useCallback, useContext, useEffect, useState } from "react"
import { Row, Col } from "react-bootstrap"
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../../../store/actionCreators";
import { Context } from "../../home.page"
import instance from "../../../api/api";
import Help from "../../../components/_helper/help.component";
import { IOption } from "../../../interfaces/family";
import config from "../../../config/config";
import UniqueSingleSelect from "../../../components/_helper/unique-single-select.component";
import i18n from "../../../i18n";
import axios from "axios";
import { useTranslation } from 'react-i18next';

/**
 * /settings/language page
 * 
 */

function Language() {

    const { t } = useTranslation();    

    const userDetails = useContext(Context).user;    

    const [ language, setLanguage ] = useState<string>( (userDetails.settings && userDetails.settings.language) ? userDetails.settings.language : "en");
    const dispatch: Dispatch<any> = useDispatch();
    const setPageName = useCallback((currentPage: string) => dispatch(setCurrentPage(currentPage)), [dispatch]);

    const updateLoggedInUserData = useContext(Context).setUserDetails; 
    const updateWhatSThis = useContext(Context).setWhatSThis;    

    useEffect(() => {
        setPageName(t("Language"));
    }, []);

    useEffect(() => {
        if ( userDetails.settings && userDetails.settings.language && userDetails.settings.language !== language ) {
            updateUserData();
            i18n.changeLanguage(language);
            fetchWhatSThis(language);
        };
    }, [language]);

    /**
     * list the selected as IOption[]
     * @returns
     */
    function getLanguageText() {
        return { _id: language, text: (config.languages.find(data => data._id === language) as IOption).text, img: "" }
    }

    async function fetchWhatSThis(language:string) {
        axios.get(`${config.contentServerURL}/api/what-s-this?locale=${language}`).then(resp => { // Strapi v4 query format
            updateWhatSThis(resp.data.data);
        }, error => {
            console.log("Failed to load help categories", error)
        });
    }      

    async function updateUserData() {

        const details = {
            settings: {...userDetails.settings, language: language}
        }

        try {
            const response = await instance.put('/user/' + localStorage.userid, {details:details});
            updateLoggedInUserData(response.data);
        }
        catch(error) {
            console.log('error', error)
        }
    }

    return (
        <div className="settings final media-quality">
            <div className="settings-container-media">
            <Row className="">
                <Col xs={12}>
                    <p>{t("Select application language")}</p>
                </Col>
            </Row>             
            {(userDetails.settings && userDetails.settings.media) ? 
                <div>
                    <Row className="setting-option-row quality-container">
                        <Col xs={12}>
                            <UniqueSingleSelect
                                placeholder={t("Language")} 
                                handleAction={(data:IOption) => setLanguage(data._id)}
                                data={getLanguageText()}
                                options={config.languages}
                            />
                        </Col>
                    </Row>
                </div>
                : null} 
            </div>                 
            <Help category={"SETTINGS"} pageName={"Language"}></Help>
        </div>
        
    )
}

export default Language
