import React from 'react';

import { mdiChevronRight, mdiChevronLeft } from '@mdi/js';
import Icon from '@mdi/react';
import { useHistory } from 'react-router-dom';
import { IUserDetails } from '../../interfaces/authentication';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import default_avatar from "../../assets/images/default-avatar.jpg";

interface IProps {
    users:IUserDetails[]
    noOfSliderItemsToShow:number
    action:ECarouselAction
}

export enum ECarouselAction {
    FILTER="FILTER",
    PUSH="PUSH"
}

export default function UserCarousel(props:IProps) {

    const history = useHistory();

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: props.noOfSliderItemsToShow
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: props.noOfSliderItemsToShow
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: props.noOfSliderItemsToShow
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: props.noOfSliderItemsToShow
        }
    };

    function onClickAction(user:IUserDetails) {
        if(props.action === ECarouselAction.FILTER) {
            history.push({pathname: '/dashboard/stories/2', state: { filters: { story:{ featured: [user]}} } })
        }
        else {
            history.push({pathname: '/dashboard/profile/' + user._id })
        }
    }

    //Remove the duplicate objects from more stories related to
    const uniqueIds: string[] = [];

    const uniqueUsers = props.users.filter(element => {
        const isDuplicate = uniqueIds.includes(element._id);

        if (!isDuplicate) {
            uniqueIds.push(element._id);

            return true;
        }

        return false;
    });

    return(
        <div className="carousel-container">
            <Carousel responsive={responsive} customLeftArrow={<CustomLeftArrow />} customRightArrow={<CustomRightArrow />}>
                {uniqueUsers.map((user:IUserDetails) => {
                    return(
                        <div key={user._id} className="featured" onClick={() => onClickAction(user)} >
                            <img className="user-picture" src={user.avatar ? user.avatar : default_avatar} alt="user" />
                            <p className="name">
                                {user.nickname ? user.nickname : `${user.firstname}`}
                            </p>
                        </div>
                    );
                })}
            </Carousel>
        </div>
    );
}

// Carousel custom arrows
export function CustomRightArrow(props:any) {
    return(
        <div className="right-button" onClick={() => props.onClick()}>
            <Icon size={1} path={mdiChevronRight} />
        </div>
    );
};

export function CustomLeftArrow(props:any){
    return(
        <div className="left-button" onClick={() => props.onClick()}>
            <Icon size={1} path={mdiChevronLeft} />
        </div>
    );
};
