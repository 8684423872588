import React, { useState } from "react"
import { Modal, Form } from "react-bootstrap"
import { mdiCloseCircle, mdiCheck } from "@mdi/js"
import Icon from "@mdi/react"
import { INotification, NotificationType } from "../../interfaces/notification"
import Notification, { notificationImages } from "../notifications/notification.component"
import instance from "../../api/api"

// maximum number of emails
const maximumEmail = 20

interface IProps {
    show: boolean
    url: string
    handleClose: () => void
}

function ReferEmail(props: IProps) {
    // email input
    const [email, setEmail] = useState<string>("")
    // added emails
    const [emails, setEmails] = useState<string[]>([])

    const [showErrorNotification, setShowErrorNotification] = useState<boolean | INotification>(false)

    function addEmail() {
        if (!email) {
            return
        }
        if (emails.length < maximumEmail) {
            setEmails((prev) => [...prev, email])
            setEmail("")
        } else {
            setShowErrorNotification({ icon: notificationImages.crying, title: "Warning", text: "Maximum reached for this email send", success: NotificationType.problem })
        }
    }

    async function sendEmails(){

        if(!emails.length) return;

        try {
            await instance.post('/email', { type: "Referral", param1: props.url, param2: emails});
            setShowErrorNotification({ icon:notificationImages.happiness, title:"Success", text:"Message sent successfully", success:NotificationType.success })
        }
        catch(error) {
            setShowErrorNotification({ icon:notificationImages.crying, title:"Failed", text:"Message not sent", success:NotificationType.problem })
        }
    }    

    return (
        <Modal className="refer-email" show={props.show} onHide={() => props.handleClose()} backdrop={window.innerWidth <= 760 ? true : "static"}>
            <Modal.Header closeButton>
                <Modal.Title>Email</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="info-text">Enter one or more recipients of this offer</p>
                <div className="input-container">
                    <Form
                        onSubmit={(evt: any) => {
                            evt.preventDefault()
                            addEmail()
                        }}>
                        <Form.Group className="group">
                            <input type="email" className="" placeholder="Email" onChange={(evt) => setEmail(evt.target.value)} value={email} />
                            <Form.Label className="top-label">Email</Form.Label>
                            <button type="submit" className="submit-icon-container">
                                <Icon size={1} path={mdiCheck} />
                            </button>
                        </Form.Group>
                    </Form>
                    {showErrorNotification ? <Notification data={showErrorNotification as INotification} close={() => setShowErrorNotification(false)} /> : null}
                </div>
                {emails.length ? (
                    <div className="added-emails">
                        <p className="emails-label">Recipient email</p>
                        {emails.map((x) => {
                            return (
                                <div className="email">
                                    <p>{x}</p>
                                    <div
                                        className="icon-container"
                                        onClick={() => {
                                            setEmails((prev) => prev.filter((email) => email !== x))
                                        }}>
                                        <Icon path={mdiCloseCircle} size={0.8} />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                ) : null}
                <button className="default-button" disabled={emails.length === 0} onClick={sendEmails}>
                    EMAIL LINK & INSTRUCTIONS
                </button>
            </Modal.Body>
        </Modal>
    )
}

export default ReferEmail
