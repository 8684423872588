import { useContext } from "react";
import { Context } from "../../pages/home.page";

interface IProps {
  pageName: string
}

interface IVideo {
  id: number
  attributes: IVideoAttributes
}

interface IVideoAttributes {
  vimeo_id?: string
  page_name: string 
}

export default function IFrameDisplay(props: IProps) {

    const videos : IVideo[] = useContext(Context).videos;

    const video = videos.find(x => x.attributes.page_name.toLowerCase() === props.pageName.toLowerCase()); 
    
    return (
      <div>
        {video?.attributes?.vimeo_id && <div className="iframe-empty-page">
          <iframe src={`https://player.vimeo.com/video/${video.attributes.vimeo_id}`} width="700" height="362" allow="autoplay; fullscreen; picture-in-picture"></iframe>
        </div>}
      </div>
    )
}