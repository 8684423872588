import React, { useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IUserDetails } from '../../../interfaces/authentication';
import UniqueListInput from '../../_helper/unique-list-input.component';
import default_avatar from "../../../assets/images/default-avatar.jpg"
import { Context } from '../../../pages/home.page';
import { IOption, IRelation } from '../../../interfaces/family';
import instance from '../../../api/api';
import Notification, { notificationImages } from '../../notifications/notification.component';
import { INotification, NotificationType } from '../../../interfaces/notification';
import config from '../../../config/config';
import { useTranslation } from "react-i18next";

interface IProps {
    showAddToTheirFamily:boolean
    setShowAddToTheirFamily: Function
    user: IUserDetails
}

export default function AddToTheirFamilyList(props: IProps) {

    const [ suggestions, setSuggestions ] = useState<IOption[]>([]);

    //User feedback
    const [ showNotification, setShowNotification ] = useState<boolean>(false);
    const [ showErrorNotification, setShowErrorNotification ] = useState<boolean>(false);

    const loggedInUserData = useContext(Context).user;

    const { t } = useTranslation();

    const successNotification: INotification = { icon: notificationImages.like, title: t("Success"), text: t("Successfully proposed connections"), success: NotificationType.success }
    const errorNotification: INotification = { icon: notificationImages.crying, title: t("Failed"), text: t("Failed to send Suggestions"), success: NotificationType.problem }    

    function updateIndividuals(selectedData:IOption) {
        if(suggestions.filter((data:IOption) => data._id === selectedData._id).length) {
            const temp = suggestions.filter((data:IOption) => data._id !== selectedData._id);
            setSuggestions(temp);
        }
        else {
            setSuggestions((prev:IOption[]) => { return [...prev, selectedData]})
        }
    }

    async function updateSuggestions() {

        const suggestionIds: string[] = suggestions.map(x => x._id)

        try {
            await instance.put('/user/suggestions/' + props.user._id, {suggestions: suggestionIds});
            setSuggestions([])
            setShowNotification(true)
            setTimeout(() => {
                setShowNotification(false);
                props.setShowAddToTheirFamily(false);
            }, config.modalCloseTimeout)              
        }
        catch(error) {
            console.log('Failed to update User suggestions');
            setShowErrorNotification(true)
        }
    }

    /**
     * function to filter out the not proper suggestions
     * @returns the filtered suggestion array
     */
    function setOptionalSuggestions() {
        // filter out 1.: the own profile of the user 2.: the users that are already in the 3rd parties contact list
        const filteredRelations =  loggedInUserData.relations.filter(relation => relation.userid._id !== props.user._id && (!props.user.relations.some(userRelation => userRelation.userid._id === relation.userid._id)) )
        return filteredRelations
    }

    return(
        <Modal className="connect-modal" show={props.showAddToTheirFamily} onHide={() => props.setShowAddToTheirFamily(false) }>
            <Modal.Header closeButton>
                <Modal.Title>{t("Add to {{var}}'s Family List", {"var": props.user.firstname})}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img className="user-picture top-image" alt="avatar" src={props.user.avatar ? props.user.avatar : default_avatar}/>
                <p className="name">{props.user.firstname + " " + props.user.lastname}</p>
                <p className="relation">{t("Select Family members to add to {{var}}'s list", {"var": props.user.firstname})}</p>
                <UniqueListInput iconRef={''} icon={false} placeholder={"Search People"} handleAction={updateIndividuals} data={suggestions} options={setOptionalSuggestions().map((relation:IRelation) => {return {_id:relation.userid._id, text: `${relation.userid.firstname} ${relation.userid.lastname}`, img: relation.userid.avatar || ""}})}/>
                <button className="default-button" disabled={!suggestions.length} onClick={() =>updateSuggestions()}>{t("Send contact request")}</button>
                {showNotification ? <Notification data={successNotification} close={() => setShowNotification(false)} /> : null}
                {showErrorNotification ? <Notification data={errorNotification} close={() => setShowNotification(false)} /> : null}
                <p className="bottom-text">{t("Affected users will be sent a contact request")}</p>

            </Modal.Body>
        </Modal>
    )
}