import { useContext, useEffect, useState } from 'react';
import { mdiCurrencyUsd } from '@mdi/js';
import Icon from '@mdi/react';
import { Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom'
import instance from '../../api/api';
import onboarding from "../../assets/images/onboarding.svg";
import { Context } from '../../pages/home.page';
import { INotification, NotificationType } from '../../interfaces/notification';
import Notification, { notificationImages } from '../../components/notifications/notification.component';

const single_discount_value = 20;

/**
 * Local interface for input properties.
 */

interface IProps {
}

/**
 * 
 * @param props : 
 * @returns void
 */
function Save(props:IProps) {

    const [ saving, setSaving ] = useState<number>(0);
    const [ price, setPrice ] = useState<number>(0);
    
    const [notification, setNotification] = useState<INotification>({ icon: "", title: "", text: "", success: NotificationType.problem });

    const [loading, setLoading] = useState<boolean>(false);

    const history = useHistory();
    const { t } = useTranslation();

    const user = useContext(Context).user;

    useEffect(() => {
        getSavings();
        if(user.subscription && user.subscription.subscription_type.fastspring_key === "familytrial"){
            setNotification({icon:notificationImages.neutral, title:'Subscribe to save', text:"Trial Subscriptions do not earn savings", success: NotificationType.info, closeBtnOff: true})
        }
    }, [user.subscription])
    
    async function getSavings() {

        try {
            setLoading(true)
            const subRes = await instance.get("/fastspring/subscription/" + user.subscription?.fastspring_id);
            setPrice(subRes.data.priceInPayoutCurrency);
            if(subRes.data.discount && subRes.data.discountInPayoutCurrency) {
                setSaving(subRes.data.discountInPayoutCurrency);
            }
            setLoading(false)
        }
        catch(err) {
            // TODO: medium: sentry
            setNotification({icon:notificationImages.crying, title:'Failed to load savings', text:"Try again later", success: NotificationType.problem})
        }
    }

    function calculateReferNumber() {
        return Math.round((price-saving) / single_discount_value)
    }

    function resetNotification() {
        setNotification({ icon: "", title: "", text: "", success: NotificationType.problem });
    }    
    
    return(
        <div className="save-container">
            <p className='title-text'>Current savings</p>
            {notification.title.length ?
                <Notification data={notification} close={() => resetNotification()} />
            : null}            
            <div className='savings-widget'>
                <div className='saving'>
                    <p>{loading ? <Spinner animation="border" variant="#fff" /> : `$${saving}`}</p>
                    <p>OFF NEXT YEARS SUBSCRIPTION</p>
                </div>
                <div className='icon-container'>
                    <Icon size={1.3} path={ mdiCurrencyUsd }/>
                </div>
                <div className='info-container'>
                    <p className='small-gray-text'>Savings automatically given to {user.subscription?.owner.firstname} {user.subscription?.owner.lastname}, and applied to next years Subscription</p>
                </div>
            </div>
            {user.subscription && user.subscription.subscription_type.fastspring_key !== "familytrial" &&
                <div className="information-widget">
                    <p>REFER {calculateReferNumber()} MORE FAMILIES </p>
                    <p className="information-widget-description">FOR 100% OFF NEXT YEARS SUBSCRIPTION</p>
                    <img src={onboarding} alt="onboarding-hand" />
                    <Button className="default-button" onClick={() => history.push("/dashboard/referandsave/refer")}>{t("Refer Families")}</Button>
                </div>
            }
        </div>
    );
};

export default Save;
