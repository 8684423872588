import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { Online, Offline } from 'react-detect-offline';


const OfflineDetect = () => {
    const [showOnlineModal, setShowOnlineModal] = useState(false);

    const handleStatusChange = (online: any) => {
        if (online) {
            setShowOnlineModal(true);
            setTimeout(() => {
                setShowOnlineModal(false);
            }, 5000);
        } else {
            setShowOnlineModal(true);
        }
    }

    return (
        <>
            <Online onChange={handleStatusChange}>
                <Alert className="offline-detect-alert" show={showOnlineModal} variant="success" transition>
                    <p>You are back online</p>
                </Alert>
            </Online>

            <Offline>
                <Alert className="offline-detect-alert" show={true} variant="danger" transition>
                    <p>You are offline</p>
                </Alert>
            </Offline>
        </>
    )
}

export default OfflineDetect;