import React from 'react';
import { Link } from 'react-router-dom';
import default_avatar from "../../assets/images/default-avatar.jpg";

/**
 * Local interface for input properties.
 */
interface ISavedUser {
    firstname:string,
    lastname:string,
    email:string,
    reset:Function
    avatar:string
}

/**
 * Saved user load from cookie
 * @param props : ISavedUser interface
 * @returns void
 */
function SavedUser(props:ISavedUser) {

    return(
        <div className="saved-user-container">
            <img src={props.avatar ? props.avatar : default_avatar} alt="avatar" className="user-picture" />
            <p className="name">{props.firstname} {props.lastname}</p>
            <p className="email">{props.email}</p>
            <Link to="/login" className="link-button" onClick={() => props.reset()}>Change</Link>
        </div>
    );
};

export default SavedUser;