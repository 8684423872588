import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import countries from "countries-list";
import DatePicker from "react-datepicker";
import Icon from '@mdi/react';
import { mdiCalendar } from '@mdi/js';
import { IUserDetails } from '../../../interfaces/authentication';
import { useTranslation } from "react-i18next";

interface ILocation {
    country: string
    state: string
}

interface IFormError {
    relationTo: boolean,
    relationFrom: boolean,
    email: boolean,
    firstName: boolean,
    lastName: boolean,
    diedOn: boolean,
    birthdate: boolean
}

interface IProps {
    firstname: string
    setFirstname: (firstname: string) => void
    lastname: string
    setLastname: (lastname: string) => void
    birthdate: null | Date
    setBirthdate: (date: null | Date) => void
    diedOn: null | Date
    setDiedOn: (date: null | Date) => void
    location: ILocation
    setLocation: Function
    administrators: IUserDetails[]
    setAdministrators: Function
    formError: IFormError
    setFormError: Function
}

export default function DeceasedForm(props: IProps) {

    const { t } = useTranslation();

    const startYear = new Date(1000, 1, 1);

    function handleFormChange(dataType: string, value: any) {
        switch (dataType) {
            case "firstName":
                props.setFirstname(value);
                props.setFormError((prevState: IFormError) => {
                    let temp = prevState;
                    temp.firstName = false;
                    return temp;
                });
                break;
            case "lastName":
                props.setLastname(value);
                props.setFormError((prevState: IFormError) => {
                    let temp = prevState;
                    temp.lastName = false;
                    return temp;
                });
                break;
            case "birthdate":
                props.setBirthdate(value);
                props.setFormError((prevState: IFormError) => {
                    let temp = prevState;
                    temp.birthdate = false;
                    return temp;
                });
                break;
            case "diedOn":
                props.setDiedOn(value);
                props.setFormError((prevState: IFormError) => {
                    let temp = prevState;
                    temp.diedOn = false;
                    return temp;
                });
                break;
        }
    }

    return (
        <div>
            <p className="subtitle">{t("Required")}</p>
            <Form.Group className="group">
                <input className={props.formError.firstName ? "input-error" : ""} placeholder={t("First name")} value={props.firstname} onChange={(evt) => handleFormChange("firstName", evt.target.value)} type="text"></input>
                <label className="top-label">{t("First name")}</label>
            </Form.Group>
            <Form.Group className="group">
                <input className={props.formError.lastName ? "input-error" : ""} placeholder={t("Last name")} value={props.lastname} onChange={(evt) => handleFormChange("lastName", evt.target.value)} type="text"></input>
                <label className="top-label">{t("Last name")}</label>
            </Form.Group>
            <Form.Group className="group date">
                <DatePicker
                    className={props.formError.birthdate ? "input-error" : ""}
                    dateFormat='dd-MMM-yyyy'
                    dropdownMode="select"
                    selected={props.birthdate}
                    minDate={startYear}
                    maxDate={new Date()}
                    onChange={(date) => { date instanceof Date ? handleFormChange("birthdate", date) : handleFormChange("birthdate", null)}}
                    showYearDropdown
                    dateFormatCalendar="MMMM"
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    showMonthDropdown
                />
                <label className="top-label">{t("Birth date")}</label>
                <Icon className="calendar-icon" size={1} path={mdiCalendar} />
            </Form.Group>
            <Form.Group className="group date">
                <DatePicker
                    className={props.formError.diedOn ? "input-error" : ""}
                    scrollableYearDropdown
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dateFormat='dd-MMM-yyyy'
                    dropdownMode="select"
                    selected={props.diedOn}
                    maxDate={new Date()}
                    onChange={(date) => { date instanceof Date ? handleFormChange("diedOn", date) : handleFormChange("diedOn", null) }}
                    minDate={startYear}
                    dateFormatCalendar="MMMM"
                    yearDropdownItemNumber={15}
                />
                <label className="top-label">{t("Died on")}</label>
                <Icon className="calendar-icon" size={1} path={mdiCalendar} />
            </Form.Group>
            <p className="subtitle">{t("Optional")}</p>
            <Form.Group className="group">
                <Form.Select onChange={(evt) => props.setLocation((prev: ILocation) => { return { ...prev, country: (evt.target as HTMLInputElement).value } })} value={props.location.country}>
                    <option disabled value=""></option>
                    {Object.values(countries.countries).sort((a, b) => a.name.localeCompare(b.name)).map(country => {
                        return (
                            <option key={country.name} value={country.name}>{country.name}</option>
                        );
                    })}
                </Form.Select>
                <label className="top-label">{t("Country")}</label>
            </Form.Group>
            {/*<Form.Group className="group administration">
                <p className="subtitle">Profile Administration</p>
                <p className="top-text">Let's you and other manage their profile</p>
                <ControlledSelectAdministrator user={loggedInUserData} administrators={props.administrators} setAdministrators={props.setAdministrators} />
                </Form.Group>*/}
        </div>
    );
}