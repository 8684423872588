import React, { Dispatch, useCallback, useContext, useEffect, useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useDispatch } from 'react-redux';
import { setAuthentication } from '../../store/actionCreators';
import IPage from '../../interfaces/page';
import Notification, { notificationImages } from '../../components/notifications/notification.component';
import instance from '../../api/api';
import { INotification, NotificationType } from '../../interfaces/notification';
import Icon from '@mdi/react';
import { mdiChevronRight  } from '@mdi/js';
import { Context } from '../home.page';
import i18n from "../../i18n";
import { useTranslation } from 'react-i18next';
import { ESubscriptionStatus } from '../../interfaces/subscription';
import { setCookies } from '../../components/_helper/cookies';

const defaultNotificationValue : INotification = {icon:notificationImages.happiness, title:"", text:"", success:NotificationType.success}

/**
 * Inactive accout page for decased status users
 * @param props : unused for now
 * @returns void
 */
 const Verification: React.FunctionComponent<IPage> = props => {

    const [ code, setCode ] = useState<string>('');
    const [ notification, setNotification ] = useState<INotification>(defaultNotificationValue);
    const [ email, setEmail ] = useState<string>('');

    const updateLoggedInUserData = useContext(Context).setUserDetails;

    const history = useHistory();
    const cookies = new Cookies();
    const { t } = useTranslation();

    useEffect(() => {
        if(props.match.params.lang) {
            i18n.changeLanguage(props.match.params.lang);
        }           
        if(props.location.state) {
            if(props.location.state.referral || props.location.state.familyVip || props.location.state.newsletter) {
                addFastSpringScript();
            }

            if(props.location.state.email && props.location.state.firstname && props.location.state.lastname) {
                setEmail(props.location.state.email)
            }
            else {
                history.push(`/login${props.match.params.lang ? "/"+props.match.params.lang : ''}`);
            }
        }
        else {
            history.push(`/login${props.match.params.lang ? "/"+props.match.params.lang : ''}`);
        }

        return () => {
            //remove fastspring script
            if(window.__RUNTIME_CONFIG__.REACT_APP_FASTSPRING_SCRIPT_ID) {
                const script = document.getElementById(window.__RUNTIME_CONFIG__.REACT_APP_FASTSPRING_SCRIPT_ID);

                if (script && script.parentNode) {
                    script.parentNode.removeChild(script);
                }
            }
        }
    }, [])

    //Remove notification from the screen after 5 seconds.
    function resetNotification() {
        setNotification(defaultNotificationValue)
    }

    /**
     * Redux functions to save if authentication was complete or not.
     */
    const dispatch: Dispatch<any> = useDispatch();
    const setAuth = useCallback((isAuthenticated: boolean) => dispatch(setAuthentication(isAuthenticated)), [dispatch])

    /**
     * Send the security code to the user.
     */
    async function handleConfirmation() {
        try {
            // send familyVip token for deletion to API
            const body = props.location.state.familyVip ? { token:code, email:email, sToken: props.location.state.familyVip } : { token:code, email:email };
            let response = await instance.post('/verification/email', body);
            setCookies(response.data.token, response.data.refreshToken);
            localStorage.setItem('userid', response.data.user._id);
            updateLoggedInUserData(response.data.user);
            
            setNotification({icon:notificationImages.happiness, title:"Successful verification", text:"We will redirect you to finish your registration.", success:NotificationType.success});

            if(props.location.state.referral) {
                const subscriptionRes = await instance.get("/subscriptions/" + props.location.state.referral);
                if(subscriptionRes.data.status !== ESubscriptionStatus.INACTIVE) {
                    subscribe();
                }
            }
            else if(props.location.state.familyVip){
                subscribeFamilyVip();  
            }
            else if(props.location.state.newsletter){
                subscribeTrial();  
            }
            else {
                setTimeout(() => {
                    setAuth(true);
                    history.push(`/registration-details${props.match.params.lang ? "/"+props.match.params.lang : ''}`);
                }, 2000)
            }
        }
        catch(err) {
            setNotification({icon:notificationImages.crying, title:"Incorrect security code", text:"Please try again or click 'resend security code' for a new code.", success:NotificationType.problem});
        }
    }

    /**
     * Resend the security code to the user
     */
    async function reSendSecurityCode() {
        try {
            await instance.post('/verification/email/resend', { email:email });
            setNotification({icon:notificationImages.happiness, title:"Verification code sent", text:"Please check your e-mail.", success:NotificationType.success});
        }
        catch(err:any) {
            setNotification({icon:notificationImages.crying, title:"We couldn't send verification code", text: err?.response?.data || 'Unknown Error' , success:NotificationType.problem});
        }
    }

    async function webhookcallback(data:any) {

        try {        

            /*

            let response = await instance.get("/subscriptions/types");
            
            let subType : any;

            let subscriptionData : any;

            if(data.items[0].product === "3-months-family"){

                subType = response.data.find((x:ISubscriptionType) => x.fastspring_key === "familytrial");

                subscriptionData = {
                    owner:localStorage.userid,
                    fastspring_id: data.items[0].subscription, //always 1 product
                    expiration: moment().add(90, 'd'),
                    members: [],
                    payments: [],
                    subscription_type:subType._id,
                    referral:props.location.state.referral,
                    trial:true
                }
            } else if(data.items[0].product === "family-vip"){

                subType = response.data.find((x:ISubscriptionType) => x.fastspring_key === "family-vip");

                subscriptionData = {
                    owner:localStorage.userid,
                    fastspring_id: data.items[0].subscription, //always 1 product
                    expiration: moment().add(1, 'y'),
                    members: [],
                    payments: [],
                    subscription_type:subType._id,
                    trial:true,
                    token: props.location.state.familyVip
                }
            }

            const subResponse = await instance.post("/subscriptions", subscriptionData);

            const userResponse = await instance.put('/user/' + localStorage.userid, { details: { subscription: subResponse.data._id, fastspring_account_id:data.account }});

            updateLoggedInUserData(userResponse.data)
            */

            setTimeout(() => {
                setAuth(true);
                history.push(`/registration-details${props.match.params.lang ? "/"+props.match.params.lang : ''}`);
            }, 2000)
        }
        catch(err) {
            setNotification({icon:notificationImages.crying, title:"We couldn't redirect", text:"Please try again later", success:NotificationType.problem});
        }        
    }

    function addFastSpringScript() {

        if( !(window.__RUNTIME_CONFIG__.REACT_APP_FASTSPRING_SCRIPT_ID && window.__RUNTIME_CONFIG__.REACT_APP_FASTSPRING_POPUP_STOREFRONT_TRIAL && window.__RUNTIME_CONFIG__.REACT_APP_FASTSPRING_SRC && window.__RUNTIME_CONFIG__.REACT_APP_FASTSPRING_DATA_ACCESS_KEY) ) {
            return;
        }
        const scriptId = window.__RUNTIME_CONFIG__.REACT_APP_FASTSPRING_SCRIPT_ID;
        const existingScript = document.getElementById(scriptId);

        if (!existingScript) {
            const storeFrontToUse = window.__RUNTIME_CONFIG__.REACT_APP_FASTSPRING_POPUP_STOREFRONT_TRIAL;
            const script = document.createElement("script");
    
            script.type = "text/javascript";
            script.id = scriptId;
            script.src = window.__RUNTIME_CONFIG__.REACT_APP_FASTSPRING_SRC;

            script.dataset.storefront = storeFrontToUse;

            // Make sure to add callback function to window so that the DOM is aware of it
            window.popupWebhookCallback = webhookcallback;
            

            script.setAttribute("data-popup-webhook-received", "popupWebhookCallback");
            script.setAttribute("data-data-callback", "fastSpringCallBack");
            script.setAttribute("data-access-key", window.__RUNTIME_CONFIG__.REACT_APP_FASTSPRING_DATA_ACCESS_KEY);

            document.body.appendChild(script);
        }
    }

    async function subscribe() {
        if(window.fastspring) {
            const payload = {
                contact: {
                    email:email,
                    firstName:props.location.state.firstname,
                    lastName:props.location.state.lastname,
                    company: props.location.state.referral // only known field we can send referral id thru Frontend->Fastspring->API
                },
                items: 
                    [
                        {
                            product: "3-months-family",
                            quantity: 1,
                        }
                    ]
            }

            // get encrypted data from the api
            const response = await instance.post("/fastspring/encrypt", { payload })
            const { securePayload, secureKey } = response.data            

            window.fastspring.builder.secure(securePayload, secureKey)
            window.fastspring.builder.checkout();
        }
    }

    async function subscribeFamilyVip() {
        if(window.fastspring) {
            const payload = {
                contact: {
                    email:email,
                    firstName:props.location.state.firstname,
                    lastName:props.location.state.lastname
                },
                items: 
                    [
                        {
                            product: "family-vip",
                            quantity: 1,
                        }
                    ]
            }

            // get encrypted data from the api
            const response = await instance.post("/fastspring/encrypt", { payload })
            const { securePayload, secureKey } = response.data            

            window.fastspring.builder.secure(securePayload, secureKey)
            window.fastspring.builder.checkout();
        }
    }


    async function subscribeTrial() {
        if(window.fastspring) {
            const payload = {
                contact: {
                    email:email,
                    firstName:props.location.state.firstname,
                    lastName:props.location.state.lastname,
                },
                items: 
                    [
                        {
                            product: "3-months-family",
                            quantity: 1,
                        }
                    ]
            }

            // get encrypted data from the api
            const response = await instance.post("/fastspring/encrypt", { payload })
            const { securePayload, secureKey } = response.data            

            window.fastspring.builder.secure(securePayload, secureKey)
            window.fastspring.builder.checkout();
        }
    }


    return(
        <Container className="confirmation-container">
            <p className="confirm-title">{t("Confirm email")}</p>
            <p className="sub-text">{t("Enter the security code sent to:")} {email}</p>
            <Form.Group className="group">
                <input className="security-code" placeholder="Security code (8 digits)" value={code} onChange={(evt) => setCode(evt.target.value)}/>
                <Form.Label className="top-label">{t("Security code")}</Form.Label>
            </Form.Group>
            <Button disabled={code.length < 8} className="confirm-button default-button" onClick={handleConfirmation}>{t("Next step")} <Icon size={1} path={ mdiChevronRight } /></Button>
            <Button className="resend-button link-button" onClick={reSendSecurityCode}>{t("Resend security code")}</Button>
            {notification.title.length ? <Notification data={notification} close={() => resetNotification()}/> : null}
        </Container>
    );
};

export default Verification;