import React, { useState } from "react"
import { Button } from "react-bootstrap"
import default_avatar from "../../../assets/images/default-avatar.jpg"
import RelationshipPicker from "../../family/relationship-picker.component"
import instance from "../../../api/api"
import { IRelation } from "../../../interfaces/family"
import { useTranslation } from "react-i18next";

interface IProps {
    relation: IRelation
    updateRelationship: Function
    userid: string
    handleClose: Function
    handleIsRelTypesBoxActive: Function
}

function ChangeRelationship(props: IProps) {
    const [relation, setRelation] = useState<string>("")

    const { t } = useTranslation();    

    async function handleChangeRelationship(id:string) {
        const dataForChange = {
            newRelation: relation,
            userId: props.userid
        }
        // TODO: notifications and error handling
        try {
            const response = await instance.put(`/relation/${id}`, dataForChange)
            if (response.status === 200) {
                props.updateRelationship(response.data)
                props.handleClose()
            }

        } catch (error) {
            console.log("error", error)
        }
    }

    return (
        <div className="change-relationship">
            <div className="family-members">
                <div className="family-member">
                    <div className="member-detail">
                        <div className="img-container">
                          <img src={props.relation.userid.avatar ? props.relation.userid.avatar : default_avatar} className="user-picture" alt="" />
                        </div>
                        <div className="content">
                        <p className="name">{`${props.relation.userid.firstname} ${props.relation.userid.lastname}`}</p>
                        <p className="relation">{t(props.relation.type)}</p>
                        </div>
                    </div>
                </div>
            </div>
            <RelationshipPicker relationError={false} handleAction={setRelation} relation={relation} handleIsRelTypesBoxActive={props.handleIsRelTypesBoxActive}/>
            <div className="button-group">
                <Button disabled={!relation.length} className="default-button" onClick={() => handleChangeRelationship(props.relation._id)}>
                    {t("Update relationship")}
                </Button>
            </div>
        </div>
    )
}

export default ChangeRelationship
