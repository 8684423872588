import React, { useEffect, useState } from "react";
import {  mdiPaperclip } from "@mdi/js";
import Icon from "@mdi/react";
import { blockTypes, IBlock, IMedia, IStory } from "../../../../interfaces/story";
import { Spinner } from "react-bootstrap";
import instance from "../../../../api/api";
import instanceUpload from "../../../../api/upload";
import { INotification, NotificationType } from "../../../../interfaces/notification";
import Notification, { notificationImages } from "../../../notifications/notification.component";
import { Form } from "react-bootstrap";
import Help from "../../../_helper/help.component";
import UploadLimitReachedModal from "../../upload-limit-reached.modal";
import axios, { AxiosError } from "axios"
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";

interface IProps {
    addBlockToStory: Function
    data: IBlock | boolean
    updateStory: Function
    story: IStory
}

interface IBlobFile {
    blob:string
    file:File
}

export default function AttachmentBlockInput(props:IProps) {

    const [ data, setData ] = useState<IBlobFile>();

    const [ desc, setDesc ] = useState<string>("");

    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    //notifications
    const [ errorNotification, setErrorNotification ] = useState(false);
    const [ errorFileSizeNotification, setFileSizeErrorNotification ] = useState(false);    
    const [uploadLimitReachedModal, setUploadLimitReachedModal] = useState(false);

    const { t } = useTranslation();    

    const errorNotificationData : INotification = {
        icon: notificationImages.crying,
        title: t("Failed"),
        text: t("Can't add Block to Story without an attachment"),
        success: NotificationType.problem
    }
    
    const errorFileSizeNotificationData : INotification = {
        icon: notificationImages.crying,
        title: t("Failed"),
        text: t("Attachment must be under 10 MB"),
        success: NotificationType.problem
    }    


    useEffect(() => {
        
        if(props.data) {
            const block = props.data as IBlock;

            if(block.media) {
                const data = {file: new File([block.media.data], block.media.name, {type:blockTypes.ATTACHMENT}), blob:block.media.data};
                setData(data);

                if (block.media.description) {
                    setDesc(block.media.description);
                };
            }
        }
    }, [])

    function selectAttachment(evt:any) {
        const file = evt.target.files[0];
        const data = {file:file, blob:URL.createObjectURL(file)}
        setData(data);
    }

    async function addToStory() {
        //Show error if no file selected
        if(!data) {
            setErrorNotification(true)
            return;
        }

        //Show error if size > 10 MB
        if(data.file.size>10000000) {
            setFileSizeErrorNotification(true)
            return;
        }

        setIsLoading(true);

        //We can only update the description
        if(props.data) {
            const media = (props.data as IBlock).media
            if (desc) {
                media.description = desc;
                updateMediaDesc(media);
            }
            setIsLoading(false);
            // TODO: low: this should be a proper update instead of calling addBlockToStory just because a description update
            props.addBlockToStory({data:media.name, multipleMedia:[], media:media, type:blockTypes.ATTACHMENT})
        }
        else {
            //Init formdata and cookies
            const formdata = new FormData();

            let file = data.file;

            formdata.append("file", file);
            formdata.append("story", props.story._id)
            formdata.append("type", blockTypes.ATTACHMENT)
            formdata.append("isBlock", "true")

            if (desc) {
                formdata.append("description", desc);
            }

            try {
                const response = await instanceUpload.post('/upload', formdata);
                setIsLoading(false);               
                props.addBlockToStory({data:response.data.data, multipleMedia:[], media:response.data._id, type:blockTypes.ATTACHMENT})
            }
            catch(error) {
                const isAxiosError = axios.isAxiosError(error)
                if ( isAxiosError && (error as AxiosError).response?.data === "Upload Limit Reached" ) {
                    setUploadLimitReachedModal(true)
                } else if (isAxiosError && (error as AxiosError).response?.data === "The file type is incorrect") {
                    errorNotificationData.text = t("The file type is incorrect")
                    setErrorNotification(true)
                    console.log("Error while uploading attachment: ", error)
                } else {
                    errorNotificationData.text = t("Media server is not responding, try again a few minutes later");
                    setErrorNotification(true);            
                    console.log('Error while uploading attachment: ', error)
                    Sentry.captureException(error, {user: {id: localStorage.userid}, tags: { 'mediaErrMsg': 'Error while uploading attachment'}});                    
                }
            }
        }
    }

    async function updateMediaDesc(media:IMedia) {
        if(!media) return;
        const data = {
            description: desc
        }
        try {
            const res = await instance.put('/media/' + media._id, data);
            // props.updateBlockMedia(props.block._id, res.data)
        }
        catch(error) {
            console.log('Error', error)
        }
    }

    return(
        <div className="img-upload attachment-upload">
            {!data ? 
            <div className="empty-page">
                <div className="icon-group">                
                    <div className="attachment">
                        <Icon className="image-icon paperclip" size={2} path={ mdiPaperclip }/>
                    </div>
                </div>
                <div className="upload-container-button">
                    <label htmlFor="single" className="button-label"> {t("Select attachment")} </label>
                    <input accept=".pdf,.txt,.rtf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.odt,.ods,.odp" className="upload-button" type="file" id="single" onChange={selectAttachment} hidden multiple/>
                </div>
                <p className="grey-text">{t("Supported file formats")}: PDF, TXT, RTF, Microsoft Office, OpenOffice</p>
                <p className="grey-text">{t("Maximum file size")}: 10 MB</p>
            </div>
            :
            <div className="empty-page">
                <div>
                    <div className="icon-group">
                        <div className="attachment">
                            <Icon className="image-icon paperclip" size={2} path={ mdiPaperclip }/>
                        </div>
                        <p>{data.file.name} {t("selected")}</p>
                    </div>
                    
                </div>
                <div className="record-audio">
                    <Form.Group className="group">
                        <input placeholder="Description" value={desc} onChange={(evt) =>  evt.target.value.length <= 50 ? setDesc(evt.target.value) : null} type="text"></input>
                        <label className="top-label">{t("Description")}</label>
                        <p className="sub-subtitle right">{desc.length} / 50</p>
                    </Form.Group>
                </div>                    

                {errorNotification ? <Notification data={errorNotificationData} close={() => setErrorNotification(false)}/> : null}
                {errorFileSizeNotification ? <Notification data={errorFileSizeNotificationData} close={() => { setData(undefined); setFileSizeErrorNotification(false) }}/> : null}

                <button className="default-button" onClick={addToStory}>
                    {isLoading ? <Spinner animation="border" variant="#fff" /> : t("Add to story")}
                </button>                

            </div>
            }
            <Help category={"PUBLISHING"} pageName={"Adding Attachments"}></Help>
            <UploadLimitReachedModal show={uploadLimitReachedModal} setUploadLimitReachedModal={setUploadLimitReachedModal} />

        </div>        
    );
}