import { useEffect, useState } from "react";
import { blockTypes, IBlock } from "../../../../interfaces/story";
import { IOption } from "../../../../interfaces/family";
import UniqueListInput from "../../../_helper/unique-list-input.component";
import config from "../../../../config/config";
import Help from "../../../_helper/help.component";
import { useTranslation } from "react-i18next";

interface IProps {
    addBlockToStory: Function
    data: IBlock | boolean
}

export default function LifelessonBlockInput(props: IProps) {

    const [ lifelesson, setLifelesson ] = useState<string>('');
    const [ lifelessonTags, setLifelessonTags ] = useState<string[]>([]);

    const { t } = useTranslation();    

    useEffect(() => {
        if(props.data) {
            setLifelesson((props.data as IBlock).data);
            setLifelessonTags(((props.data as IBlock).tags) as string[]);
        }
    }, [])    

    function addToStory() {

        if(lifelesson) {
            props.addBlockToStory({data:lifelesson, tags:lifelessonTags, type:blockTypes.LIFE_LESSON})
        }

    }
    
    /**
     * list the selected lifelessonTags as IOption[]
     * @returns
     */
     function getlifelessonTags() {
        return lifelessonTags.map(tag => { return { _id: tag, text: t((config.lifelessonTags.find(data => data._id === tag) as IOption).text), img: "" } })
    }
        
    /**
     * update lifelessonTags
     * @param selectedData
     */
     function updateLifelessonTags(selectedData: IOption) {
        if ((lifelessonTags as string[]).includes(selectedData._id)) {
            const temp = (lifelessonTags as string[]).filter(data => data !== selectedData._id)
            setLifelessonTags(temp);            
        } else {
            setLifelessonTags([...lifelessonTags, selectedData._id])
       }
    }       

    return(
        <div className="lifelesson-container">
            <div className="inner">
                <p className="subtitle">{t("Create Life Lessons to help your family")}</p>           
                <div className="inputs">
                    <div className="group">
                        <textarea placeholder={t("Life Lesson")} rows={3} value={lifelesson} onChange={(evt) => evt.target.value.length <= 300 ? setLifelesson(evt.target.value) : false} />
                        <label className="top-label">{t("Life Lesson")}</label>
                        <p className="sub-subtitle right">{lifelesson.length} / 300</p>                    
                    </div>
                    <UniqueListInput iconRef={''} 
                        icon={false} 
                        placeholder={t("Life Lesson Category")} 
                        handleAction={(selectedData: IOption) => updateLifelessonTags(selectedData)}
                        data={getlifelessonTags()}
                        options={config.lifelessonTags} 
                        translateable={true} />
                </div>                
                <button className="default-button" disabled={(lifelesson.length > 0 ? false : true)} onClick={() => addToStory()}>{t("Add to Story")}</button>
            </div>
            <Help category={"PUBLISHING"} pageName={"Adding Life Lessons"}></Help>         
        </div>
        )
}