import IRoute from "../interfaces/routes";
import Home from "../pages/home.page";
import Login from "../pages/authentication/login.page";
import Registration from "../pages/authentication/registration.page";
import InactiveAccount from "../pages/authentication/inactive.page";
import PasswordReset from "../pages/authentication/password-reset.page";
import RegistrationDetails from "../pages/authentication/registration-details.page";
import NotFound from "../_helper/_404";
import ForgottenPassword from "../pages/authentication/forgot-password.page";
import Verification from "../pages/authentication/verification.page";
import Welcome from "../pages/welcome.page";
import PublicStory from "../pages/public_story.page";
import Referal from "../pages/referral/referral.page";
import FamilyVip from "../pages/family-vip.page";
/**
 * Keep every route in one array, to be able to loop trough them, and have everything in one place to manage
 */
const routes: IRoute[] = [
    {
        path:'/welcome/:id/:lang?',
        name:'Welcome',
        component: Welcome,
        exact: true,
        private:false
    },
    {
        path:'/dashboard',
        name:'Home',
        component:Home,
        exact:false,
        private:true
    },
    {
        path:'/login/:lang?',
        name:'Login',
        component: Login,
        exact: true,
        private:false
    },
    {
        path:'/register/:lang?',
        name:'Registration',
        component:Registration,
        exact:true,
        private:false
    },
    {
        path:'/inactive/:lang?',
        name:'Inactive Account',
        component:InactiveAccount,
        exact:true,
        private:false
    },
    {
        path:'/password-reset/:userid/:token/:lang?',
        name:'Reset password',
        component: PasswordReset,
        exact:true,
        private:false
    },
    {
        path:'/registration-details/:lang?',
        name:'Registration Details',
        component:RegistrationDetails,
        exact:true,
        private:true
    },
    {
        path:'/forgotten-password/:lang?',
        name:'Forgotten Password',
        component:ForgottenPassword,
        exact:true,
        private:false
    },
    {
        path:'/verification/:lang?',
        name:'Email verification',
        component:Verification,
        exact:true,
        private:false
    },
    {
        path:'/story/:id',
        name:'Public Story',
        component:PublicStory,
        exact:true,
        private:false
    },
    {
        path:'/referral/:id/:lang?',
        name:'Referal',
        component:Referal,
        exact:true,
        private:false
    },
    {
        path:'/family-vip/:id',
        name:'FamilyVip',
        component:FamilyVip,
        exact:true,
        private:false
    },
    //404 - Not found
    {
        path:'*',
        name:'404',
        component:NotFound,
        exact:true,
        private:false
    }
];

export default routes;