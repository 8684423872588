import { AuthAction, ClearAction, SetCurrentPageAction } from "../interfaces/redux";
import { ActionTypes } from "./actionTypes"

/**
 * Easy way to decide if user is authenticated or not.
 */
export function setAuthentication(isAuthenticated: boolean) {
    const action: AuthAction = {
        type: ActionTypes.SET_IS_AUTHENTICATED,
        isAuthenticated,
    };
    
    return action;
};

export function setCurrentPage(currentPage: string) {
    const action: SetCurrentPageAction = {
        type: ActionTypes.SET_CURRENT_PAGE,
        currentPage
    }

    return action
}

/**
 * Clear redux
 */
export function clearRedux() {
    const action: ClearAction = {
        type: ActionTypes.CLEAR_REDUX
    };

    return action;
};
