import React, { useEffect, useContext, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { ESubscriptionStatus } from "../../interfaces/subscription";
import { Context } from "../../pages/home.page";
import TabSlider from "../menu-items/tab-slider";
import Help from "../_helper/help.component";
import SubscriptionBuy from "./subscription/subscription_buy.component";
import SubscriptionInfo from "./subscription/subscription_info.component";
import SubscriptionShare from "./subscription/subscription_share.component";

enum menu {
    INFO = "info",
    SHARE = "share",
    BUY = "buy",
}

interface RouteParams {
    mainMenu: string
    subMenu:string
}


/**
 * Subscription page
 *
 */
function Subscription() {

    // params for the tab menu
    const params = useParams<RouteParams>();

    //tabs
    const [key, setKey] = useState<menu>(menu.INFO);

    // for checking window width to toggle mobile tabs navigation
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const MAX_WIDTH = 500;
    const checkWindowWidth = (condition: string = "lte", threshold: number = MAX_WIDTH) => (condition === "lte" ? windowWidth <= threshold : windowWidth > threshold);
    const detectSize = () => { setWindowWidth(window.innerWidth); };
    
    const [activeTab, setActiveTab] = useState<menu>(menu.INFO);
    const isTabActive = (tabName: menu) => (activeTab === tabName ? "active" : "");

    const history = useHistory();
    const userData = useContext(Context).user;
    const updateUserData = useContext(Context).setUserDetails;

    //translation
    const { t } = useTranslation();

    useEffect(() => {
        window.addEventListener("resize", detectSize);
        return () => { window.removeEventListener("resize", detectSize); };
    }, [windowWidth]);

    useEffect(() =>  {
        if(params.subMenu && params.subMenu !== key) {
            setKey(params.subMenu as menu);
            setActiveTab(params.subMenu as menu)
        }      
    }, [ params.subMenu ])
    
    function handleSetKey(tabItem: menu) {
        history.push("/dashboard/account/" + params.mainMenu + "/" + tabItem);
    }

    return (
        <div className="profile-container">
            <TabSlider checkWindowWidth={checkWindowWidth} maxWidth={MAX_WIDTH}>
                <li className={isTabActive(menu.INFO)} onClick={() => { handleSetKey(menu.INFO); }}>{t("Info")}</li>
                {(userData.subscription && userData.subscription.owner._id === userData._id && userData.subscription.status !== ESubscriptionStatus.INACTIVE) ? <li className={isTabActive(menu.SHARE)} onClick={() => { handleSetKey(menu.SHARE); }}>{t("Share")}</li> : null}
                <li className={isTabActive(menu.BUY)} onClick={() => { handleSetKey(menu.BUY); }}>{t("Buy")}</li>
            </TabSlider>
            {(checkWindowWidth() && isTabActive(menu.INFO)) && 
                <div>
                    {/* TODO: add help */}
                    <SubscriptionInfo updateUserData={updateUserData} user={userData}/>
                    <Help category={"PUBLISHING"} pageName={"Adding Attachments"}></Help>
                </div>
            }
            
            {(checkWindowWidth() && isTabActive(menu.SHARE)) &&  
                <SubscriptionShare updateUserData={updateUserData} user={userData}/>
            }
            {(checkWindowWidth() && isTabActive(menu.BUY)) && 
                <SubscriptionBuy updateUserData={updateUserData} user={userData}/>
            }

            {checkWindowWidth("gt") &&
            <Tabs className="profile-tabs menu-tabs submenu-tabs" activeKey={key} onSelect={(k) => { handleSetKey(k as menu); }}>
                <Tab eventKey={menu.INFO} title={t("Info")}>
                    <div>
                        <SubscriptionInfo updateUserData={updateUserData} user={userData}/>
                        <Help category={"MyAccount"} pageName={"Subscription - Info"}></Help>
                    </div>
                </Tab>
                {(userData.subscription && userData.subscription.owner._id === userData._id && userData.subscription.status !== ESubscriptionStatus.INACTIVE) ? <Tab eventKey={menu.SHARE} title={t("Share")}>
                    <div>
                        <SubscriptionShare updateUserData={updateUserData} user={userData}/>
                        <Help category={"MyAccount"} pageName={"Subscription - Share"}></Help>
                    </div>
                </Tab> : null}
                {((userData.subscription && userData.subscription.owner._id === userData._id && !userData.subscription.trial) // owner of family, familyplus
                || (userData.subscription && userData.subscription.owner._id !== userData._id) // members
                || !userData.subscription) && // free
                    <Tab eventKey={menu.BUY} title={t("Buy")}>
                        <div>
                            <SubscriptionBuy updateUserData={updateUserData} user={userData} />   
                            <Help category={"MyAccount"} pageName={"Subscription - Buy"}></Help>
                        </div>
                    </Tab>
                }
            </Tabs>
            }
        </div>
    );
}

export default Subscription;
