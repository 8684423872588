import React, { useContext, useState } from "react"
import { Form, Button } from "react-bootstrap"
import DatePicker from "react-datepicker"
import Icon from "@mdi/react"
import { mdiCalendar } from "@mdi/js"
import instance from "../../api/api"
import { INotification, NotificationType } from "../../interfaces/notification"
import Notification, { notificationImages } from "../notifications/notification.component"
import SelectAdministrator from "../family/select-administrator.component"
import { deceasedData } from "../../pages/dashboard/deceased-update.page"
import config from "../../config/config"
import { useHistory } from "react-router";
import { Context } from '../../pages/home.page';
import moment from "moment"

interface IDataForUpdate {
    diedon: Date | string | null
    personalMessage: string
}

interface IProps {
    deceased: deceasedData
}

interface IDeceasedRequest {
    updaterid: string
    diedon: Date | string | null
    personalMessage?: string
}

/**
 * Used notifications in component
 */
const successNotification: INotification = { icon: notificationImages.like, title: "Success", text: "This profile updated successfully. The profile's successors have administrator rights.", success: NotificationType.success }
const errorNotification: INotification = { icon: notificationImages.crying, title: "Fail", text: "Failed set user to deceased.", success: NotificationType.problem }

function DeceasedAdminForm(props: IProps) {
    // data for the request (diedon is a required data)
    const [dataForUpdate, setDataForUpdate] = useState<IDataForUpdate>({ diedon: null, personalMessage: "" })

    //Notification
    const [showNotification, setShowNotification] = useState<boolean>(false)
    const [showErrorNotification, setShowErrorNotification] = useState<boolean>(false)

    const updateLoggedInUserData = useContext(Context).setUserDetails;    

    const history = useHistory()    

    /**
     * Function to handle the submit of the form
     */
    async function handleUpdate() {
        try {

            const requestBody: IDeceasedRequest = {
                updaterid: localStorage.userid,
                diedon: moment(dataForUpdate.diedon?.toLocaleString()).parseZone().toISOString(),
                personalMessage: dataForUpdate.personalMessage
            }
            const response = await instance.put(`/user/deceased/${props.deceased._id}`, requestBody)

            // show the notification if the update was successful
            if (response.status === 200) {
                setShowNotification(true)
                updateLoggedInUserData(response.data)
                setTimeout(() => {
                    history.push(`/dashboard/profile/${props.deceased._id}/1`);
                }, config.modalCloseTimeout)                

            } else {
                setShowErrorNotification(true)
            }
        } catch (error) {
            setShowErrorNotification(true)
        }
    }

    return (
        <div className="deceased-update">
            <div className="top-content">
                <p className="content-title">We are so very sorry to hear of your loss.</p>
                <p>By proceeding, their account will be retired. Their profile and stories remain forever.</p>
                <p>Adminstrators will be able to manage their groups, accept new contacts and create stories for their profile.</p>
            </div>
            <Form>
                <Form.Group className="group">
                    <p className="group-title">Died on</p>
                    <Icon className="calendar-icon" size={1} path={mdiCalendar} />
                    <DatePicker
                        required
                        scrollableYearDropdown
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dateFormat="dd-MMM-yyyy"
                        dropdownMode="select"
                        selected={dataForUpdate.diedon instanceof Date ? dataForUpdate.diedon : null}
                        placeholderText="Please select date"
                        maxDate={new Date()}
                        onChange={(date) => {
                            date instanceof Date ? setDataForUpdate({ ...dataForUpdate, diedon: date }) : setDataForUpdate({ ...dataForUpdate, diedon: null })
                        }}
                    />
                </Form.Group>
            </Form>
            <div className="group">
                <p className="group-title">Administrators</p>
                <p className="subtitle">You may wish to add administrators to their profile</p>
                <SelectAdministrator userid={props.deceased._id} administrators={props.deceased.administrators} relations={props.deceased.relations}/>
            </div>
            <div className="group">
                <p className="group-title">Notification</p>
                <p className="subtitle">Upon completion, a Family Update notification will be sent to all their family contacts. Please write a personal message that will be included.</p>
                <Form className="deceased-text-area">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Control
                            as="textarea"
                            rows={5}
                            value={dataForUpdate.personalMessage}
                            placeholder="Personal message"
                            onChange={(evt) => setDataForUpdate({ ...dataForUpdate, personalMessage: evt.target.value })}
                        />
                    </Form.Group>
                </Form>
            </div>
            <div>
                {showNotification ? <Notification data={successNotification} close={() => setShowNotification(false)} /> : null}
                {showErrorNotification ? <Notification data={errorNotification} close={() => setShowErrorNotification(false)} /> : null}
                <Button disabled={(!dataForUpdate.diedon || !dataForUpdate.personalMessage || showNotification)} onClick={handleUpdate} className="default-button">
                    Proceed
                </Button>
            </div>
        </div>
    )
}

export default DeceasedAdminForm
