import React, { useState, useEffect, Dispatch, useCallback, useContext } from "react"
import { Spinner, Tab, Tabs } from "react-bootstrap"
import ProfileInfo from "../../components/profile/profile-info.component"
import instance from "../../api/api"
import ProfileFamily from "../../components/profile/profile-family.component"
import { IUserDetails } from "../../interfaces/authentication"
import { defaultUserData, IPendingRelationDB, IRelation } from "../../interfaces/family"
import { useParams, useHistory } from "react-router"
import { Context } from "../home.page"
import Icon from '@mdi/react';
import { mdiDotsHorizontal } from '@mdi/js';
import ProfileAction, { UserRelationStatus } from "../../components/profile/profile-action-modal.component"
import Help from "../../components/_helper/help.component"
import ProfileVisuals from "../../components/profile/profile-visuals.component"
import TabSlider from '../../components/menu-items/tab-slider';
import ProfileVoice from "../../components/profile/profile-voice.component"
import { useDispatch } from "react-redux"
import { setCurrentPage } from "../../store/actionCreators"
import AccessModal from "../../components/_helper/access-modal.component"
import ProfileLifelessons from "../../components/profile/profile-lifelessons.component"
import { useTranslation } from "react-i18next";

export enum menu {
    INFO = "1",
    FAMILY = "2",
    VISUALS = "3",
    VOICE = "4",
    LIFE_LESSONS = "5",
}

interface RouteParams {
    id: string
    mainMenu: string
    familyPage: string    
}

function Profile() {

    // params for the tab menu
    const params = useParams<RouteParams>();

    // user datas
    const [ user, setUser ] = useState<IUserDetails>(defaultUserData);

    const dispatch: Dispatch<any> = useDispatch();
    const setPageName = useCallback((currentPage: string) => dispatch(setCurrentPage(currentPage)), [dispatch]);

    //Administration
    const [ admin, setAdmin ] = useState<boolean>(false);
    const [ myProfile, setMyProfile ] = useState<boolean>(false);
    const [ adminPending, setAdminPending ] = useState<string[]>([]);
    const [ adminRelation, setAdminRelation ] = useState<IRelation>({_id:"", createdAt:"", updatedAt:"", type:"", userid: defaultUserData, group:"FAMILY"});

    // Action modal
    const [showActionModal, setShowActionModal] = useState(false);
    const [ accessModal, setAccessModal ] = useState<boolean>(false);

    //Loader
    const [ loaded, setLoaded ] = useState<boolean>(false);

    //tabs
    const [key, setKey] = useState<menu>(menu.INFO);

    // for checking window width to toggle mobile tabs navigation
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const MAX_WIDTH = 500;
    const checkWindowWidth = (condition: string = "lte", threshold: number = MAX_WIDTH) => (condition === "lte" ? windowWidth <= threshold : windowWidth > threshold);
    const detectSize = () => { setWindowWidth(window.innerWidth); };

    const [activeTab, setActiveTab] = useState<menu>(menu.INFO);
    const isTabActive = (tabName: menu) => (activeTab === tabName ? "active" : "");

    //Logged in user
    const userData = useContext(Context).user;
    const updateLoggedInUserData = useContext(Context).setUserDetails;

    const history = useHistory();

    const { t } = useTranslation();

    useEffect(() => {
        window.addEventListener("resize", detectSize);
        return () => { window.removeEventListener("resize", detectSize); };
    }, [windowWidth]);


    useEffect(() => {
        if(params.id && userData._id.length) {
            setLoaded(false);
            getProfileData();
        }

        if(params.id === userData._id) {
            setPageName(t("My Profile"));
        }
        else {
            setPageName(t("Contact Profile"));
        }
        //Remove mainmenu state

    }, [ params.id, userData ]);

    useEffect(() =>  {
        if(params.familyPage) {
            // if the referrer is the main menu then rename page
            setPageName(t("Family"));
        } else if(params.id === userData._id) {
            setPageName(t("My Profile"));
        }
        else {
            setPageName(t("Contact Profile"));
        }

        if(params.mainMenu && params.mainMenu !== key) {
            setKey(params.mainMenu as menu);
            setActiveTab(params.mainMenu as menu);
        }
    }, [ params.mainMenu ])

    function handleSetKey(tabItem: menu) {
        history.push("/dashboard/profile/" + params.id + "/" + tabItem);
    }

    async function getProfileData() {
        try {
            const userid = params.id;
            let user: IUserDetails;

            if(userid !== userData._id) {
                const userResponse = await instance.get(`/user/${userid}`);
                user = userResponse.data
                setUser(userResponse.data);
            }else {
                setUser({...userData})
                user = userData
            }

            const userAdministrators = user.administrators
            if(userid === userData._id) {
                setMyProfile(true);
                setAdmin(false)
            }
            else {
                setMyProfile(false);
                if(userAdministrators.map(x => x._id).includes(userData._id)) {
                    setAdmin(true);
                }

                let pendingFromRes = await instance.get(`/relation/pending?from=${userData._id}`);
                setAdminPending(pendingFromRes.data.map((x:IPendingRelationDB) => x.to._id));
                const relation:IRelation | undefined = userData.relations.find((realtion:IRelation) => realtion.userid._id === user._id);
                if(relation) {
                    setAdminRelation(relation)
                }
            }

            setLoaded(true);
            setActiveTab(menu.INFO);
        }
        catch(error) {
            console.log('Failed to load profile data.', error);
            history.goBack();
        }
    }

    function updateUserField(field:string, newValue: string) {
        setUser((prev) =>  ({...prev, [field]: newValue}))
        if(myProfile) {
            updateLoggedInUserData({...userData, [field]: newValue})
        }
    }

    function setUserRelationStatus() {
        switch (adminRelation.userid.profileType) {
            case "Deceased":
                return UserRelationStatus.DECEASED
            default:
                return UserRelationStatus.ACTIVE
        }
    }

    function updateRelationship(response: IRelation) {
        setAdminRelation(response)
    }

    /*
    function checkAccess(route:string) {

        if(!userData.subscription) {
            setAccessModal(true);
        }
        else {
            handleSetKey(route as menu)
        }
    }
    */

    return (
        <div className={params.familyPage ? "profile-container family-page" : "profile-container"}>
            <div className="top-container">
                {/*!myProfile && adminRelation._id && loaded &&  <div className="icon">
                    <Icon className="icon" size={1} path={ mdiForumOutline } />
                </div>*/}
                <div className="profile-name">
                    <p className="title text-center my-4">{user.nickname ? user.nickname : user.firstname}</p>
                </div>
                {!myProfile && adminRelation._id && loaded && <div className="icon">
                    <span onClick={() => setShowActionModal(true)}><Icon className="icon" size={1} path={ mdiDotsHorizontal } /></span>
                    <ProfileAction
                        userid={user._id}
                        // userRelationType={UserRelationStatus.DECEASED}
                        userRelationType={setUserRelationStatus()}
                        relation={adminRelation}
                        show={showActionModal}
                        handleClose={() => setShowActionModal(false)}
                        myProfile={true}
                        administrator={admin}
                        declineRelation={() => window.location.reload()}
                        updateRelationship={updateRelationship}/>
                </div>}
            </div>
            <TabSlider checkWindowWidth={checkWindowWidth} maxWidth={MAX_WIDTH}>
                <li className={/*isTabActive(menu.INFO)*/ params.mainMenu === menu.INFO ? "active" : ""} onClick={() => { handleSetKey(menu.INFO); }}>{t("Info")}</li>
                <li className={/*isTabActive(menu.FAMILY)*/ params.mainMenu === menu.FAMILY ? "active" : ""} onClick={() => { handleSetKey(menu.FAMILY); }}>{t("Family")}</li>
                <li className={isTabActive(menu.VISUALS)} onClick={() => { handleSetKey(menu.VISUALS); }}>{t("Visuals")}</li>
                <li className={isTabActive(menu.VOICE)} onClick={() => { handleSetKey(menu.VOICE); }}>{t("Voice")}</li>
                <li className={isTabActive(menu.LIFE_LESSONS)} onClick={() => { handleSetKey(menu.LIFE_LESSONS); }}>{t("Life Lessons")}</li>
            </TabSlider>
            {(checkWindowWidth() && params.mainMenu === menu.INFO) &&
                <>
                    <ProfileInfo myProfile={myProfile} user={user} administrator={admin} relation={adminRelation.type} updateUser={updateUserField} familyPage={params.familyPage} />
                    {myProfile ?
                        <Help category={"FAMILY"} pageName={"My Info"}></Help>
                        :
                        <Help category={"FAMILY"} pageName={"Info"}></Help>
                    }
                </>
            }

            {(checkWindowWidth() && params.mainMenu === menu.FAMILY) &&
                <ProfileFamily
                    user={user}
                    administrator={admin}
                    myProfile={myProfile}
                    adminPending={adminPending}
                    setAdminPending={setAdminPending}
                />
            }
            {(checkWindowWidth() && isTabActive(menu.VISUALS)) &&
            <>
                <ProfileVisuals
                    user={user}
                    administrator={admin}
                    myProfile={myProfile}
                />
                <Help category={"FAMILY"} pageName={"Visuals"}></Help>
            </>
            }

            {(checkWindowWidth() && isTabActive(menu.VOICE)) &&
            <>
                <ProfileVoice
                    user={user}
                    administrator={admin}
                    myProfile={myProfile}
                />
                <Help category={"FAMILY"} pageName={"Voices"}></Help>
            </>
            }

            {(checkWindowWidth() && isTabActive(menu.LIFE_LESSONS)) &&
            <>
                <ProfileLifelessons
                    user={user}
                    administrator={admin}
                    myProfile={myProfile}
                />
                <Help category={"FAMILY"} pageName={"Life Lessons"}></Help>
            </>
            }

            {checkWindowWidth("gt") &&
            <Tabs className="profile-tabs menu-tabs" activeKey={key} onSelect={(k) => { handleSetKey(k as menu) }}>
                <Tab eventKey={menu.INFO} title={t("Info")}>
                    {loaded ? <ProfileInfo myProfile={myProfile} user={user} administrator={admin} relation={adminRelation.type} updateUser={updateUserField} familyPage={params.familyPage} /> : <div className="spinner-container"><Spinner animation="border" variant="#fff" /></div> }
                    {myProfile ?
                        <Help category={"FAMILY"} pageName={"My Info"}></Help>
                        :
                        <Help category={"FAMILY"} pageName={"Info"}></Help>
                    }
                </Tab>
                <Tab eventKey={menu.FAMILY} title={t("Family")}>
                    {loaded ?
                    <ProfileFamily
                        user={user}
                        administrator={admin}
                        myProfile={myProfile}
                        adminPending={adminPending}
                        setAdminPending={setAdminPending}
                    /> :  <div className="spinner-container"><Spinner animation="border" variant="#fff" /></div>}
                </Tab>
                <Tab eventKey={menu.VISUALS} title={t("Visuals")}>
                    {key === menu.VISUALS && <ProfileVisuals
                        user={user}
                        administrator={admin}
                        myProfile={myProfile}
                     />}
                    <Help category={"FAMILY"} pageName={"Visuals"}></Help>
                </Tab>
                <Tab eventKey={menu.VOICE} title={t("Voice")}>
                    {key === menu.VOICE && <ProfileVoice
                        user={user}
                        administrator={admin}
                        myProfile={myProfile} />}
                    <Help category={"FAMILY"} pageName={"Voice"}></Help>
                </Tab>
                <Tab eventKey={menu.LIFE_LESSONS} title={t("Life Lessons")}>
                    {key === menu.LIFE_LESSONS && <ProfileLifelessons
                        user={user}
                        administrator={admin}
                        myProfile={myProfile}
                    />}
                    <Help category={"FAMILY"} pageName={"Life Lessons"}></Help>
                </Tab>
            </Tabs>
            }
            <AccessModal show={accessModal} onHide={() => setAccessModal(false)}/>
        </div>
    )
}

export default Profile
