import React, { useState } from 'react';
import { mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import { blockTypes, IBlock, IStory } from '../../../interfaces/story';
import BlockPicker from './block-picker.component';
import { Block } from './block.component';
import TooltipHelp from '../../notifications/tooltiphelp.component';
import { useTranslation } from "react-i18next";

interface IProps {
    addBlockToStory: Function
    blocks: IBlock[]
    deleteBlock: Function
    starterBlock: blockTypes | boolean
    updateBlockMedia: Function
    showImageCarousel: Function
    updateStory: Function
    isAuthor: boolean
    story: IStory
}


export default function BlockBuilder(props:IProps) {

    const [ show, setShow ] = useState<boolean>(false);
    const [ index, setIndex ] = useState<number>(0);
    const [ id, setId ] = useState<string>("")
    const [ blockType, setBlockType ] = useState<boolean | blockTypes>(false);

    //Edit value
    const [ value, setValue ] = useState<IBlock | boolean>(false);

    //Tooltips
    const [showStoryBlockToolip, setShowStoryBlockToolip ] = useState<boolean>(false);

    const { t } = useTranslation();

    function addNewBlock(index:number) {
        setId('');
        setIndex(index);
        setBlockType(false)
        setShow(true);
        setValue(false);
    }

    function editBlock(block:IBlock, index:number) {
        if(block._id) {
            setId(block._id);
            setIndex(index);
            setBlockType(block.type);
            setShow(true);
            setValue(block);
        }
    }

    return(
        <div className="block-builder">
            <div>
                <div className="story-block" onClick={() => addNewBlock(0)}>
                {!props.blocks.length && 
                    <p className="help-message" onClick={() => addNewBlock(index + 1)}>{t("Add your first Story Block")}</p>}
                    <div className="add-story-svg"><Icon size={1} path={mdiPlus} /></div>
                    <div className="line"></div>
                </div>
                {/*!props.blocks.length && props.starterBlock ?
                    <div className="missing-input-container">
                        <div className="missing-input" onClick={() => {setBlockType( props.starterBlock); setShow(true); setId('')}}>
                            {props.starterBlock === blockTypes.TEXT ? <p>{t("Tap to add Text")}</p> : props.starterBlock === blockTypes.IMAGE ? <p>{t("Tap to add Image")}</p> : <p>{t("Tap to add Audio")}</p>}
                        </div>
                        <div className="new-block" onClick={() => addNewBlock(index + 1)}>
                            <div className="add-story-svg"><Icon size={1} path={mdiPlus} />
                            <div className="line"></div>
                            </div>
                        </div>
                        <p className="help-message" onClick={() => addNewBlock(index + 1)}>{t("Add Story Block")}</p>
                    </div>
                    :
                    null
                */}
                {props.blocks.map((block:IBlock, index:number) => {
                    return(
                        <div className="story-block" key={block?._id ? block._id + index : index}>
                            <Block isAuthor={props.isAuthor} showImageCarousel={props.showImageCarousel} updateBlockMedia={props.updateBlockMedia} editBlock={editBlock} block={block} index={index} updateBlock={props.addBlockToStory} deleteBlock={props.deleteBlock} blocksLength={props.blocks.length}/>
                            <div className="add-story-svg" onClick={() => addNewBlock(index + 1)}>
                                <Icon size={1} path={mdiPlus} />
                                <div className="line"></div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <BlockPicker 
                story={props.story}
                updateStory={props.updateStory} 
                value={value} 
                blockId={id} 
                index={index}
                blockType={blockType ? blockType : false} 
                show={show} 
                handleClose={() =>{setBlockType(false);setShow(false)}} 
                addToStory={props.addBlockToStory} 
            />
            {!(props.blocks.length >= 1) ? <p onClick={() => setShowStoryBlockToolip(true)} className="tooltip-text">{t("What's this?")}</p> : null}
            <TooltipHelp contextKey={'story_story_add_story_block'} show={showStoryBlockToolip} clear={() => setShowStoryBlockToolip(false)} />

        </div>
    );
};
