import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { mdiStar, mdiLightbulb, mdiPen } from '@mdi/js';
import Icon from '@mdi/react';
import { Context } from '../../pages/home.page';
import instance from '../../api/api';
import { useHistory } from 'react-router-dom';
import { navigatedToRequest } from './requests/story-requests-create.component';
import { IStory } from '../../interfaces/story';
import { useTranslation } from "react-i18next";

interface IProps {
    show:boolean
    onHide:Function
    author:boolean
    redirect: Function
    story:IStory
}

export default function StoryActionModal(props:IProps){

    const loggedInUserData = useContext(Context).user;
    const updateLoggedInUserData = useContext(Context).setUserDetails;
    const history = useHistory();

    const { t } = useTranslation();    
        
    async function addtoReadLater() {
        if(!loggedInUserData.readLaterStories.includes(props.story._id)) {
            const response = await instance.put(`/user/${loggedInUserData._id}`, { push: { "readLaterStories": props.story._id }});
            updateLoggedInUserData(response.data)
        }
        else {
            const response = await instance.put(`/user/${loggedInUserData._id}`, { pull: { "readLaterStories": props.story._id }});
            updateLoggedInUserData(response.data)
        }
    }

    async function  addToFavorites() {
        if(!loggedInUserData.favorites.stories.includes(props.story._id)) {
            const response = await instance.put(`/user/${loggedInUserData._id}`, { push: { "favorites.stories": props.story._id }});
            updateLoggedInUserData(response.data)
        }
        else {
            const response = await instance.put(`/user/${loggedInUserData._id}`, { pull: { "favorites.stories": props.story._id }});
            updateLoggedInUserData(response.data)
        }
    }

    return(
        <Modal className="story-action-modal" show={props.show} onHide={props.onHide}>
            <Modal.Header className="" closeButton>
            </Modal.Header>
            <Modal.Body className="edit-card-content">
                <div className="link-row" onClick={addToFavorites}>
                    <Icon size={1} path={ mdiStar } /><p className="main-text-info">{loggedInUserData.favorites.stories.includes(props.story._id) ? t("Remove from Favourite Stories") : t("Add to Favourite Stories")}</p>
                    <p className="info-text">{t("For easy future access")}</p>
                </div>
                {!props.author && <div className="link-row" onClick={addtoReadLater}>
                    <Icon size={1} path={ mdiPen } /><p className="main-text-info">{loggedInUserData.readLaterStories.includes(props.story._id) ? t("Remove from Read Later List") : t("Add to Read Later List")}</p>
                    <p className="info-text">{t("For easy access later")}</p>
                </div>}
                <div className="link-row" onClick={() => history.push({pathname: '/dashboard/requests', state: { from:navigatedToRequest.STORY, id: props.story._id, data:props.story.title }}) }>
                    <Icon size={1} path={ mdiLightbulb } /><p className="main-text-info">{t("Attach to 'Story Request'")}</p>
                    <p className="info-text">{t("Attach to Story Request as example story")}</p>
                </div>
                {props.author && <div className="link-row" onClick={() => props.redirect()}>
                    <Icon size={1} path={ mdiPen } /><p className="main-text-single">{t("Edit Story")}</p>
                </div>}
            </Modal.Body>
        </Modal>
    );
}