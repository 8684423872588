import React from "react";
import { useTranslation } from "react-i18next";

export default function ExpertsHistory() {

    const { t } = useTranslation();

    return (
        <div className="experts-history-widget">
            <p className="title">{t("Hours purchased")}</p>
            <div className="content-section">
                <div className="no-records-box">{t("No records")}</div>
            </div>
        </div>
    )
}
