import React, { useEffect, useState } from "react";
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import RichTextEditor from "../../../_helper/rich-text-editor";
import draftToHtml from 'draftjs-to-html';
import { blockTypes, IBlock } from "../../../../interfaces/story";
import { mdiFullscreen, mdiFullscreenExit } from '@mdi/js';
import htmlToDraft from 'html-to-draftjs';
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";

interface IProps {
    addBlockToStory: Function
    data: IBlock | boolean
    setFullScreen: Function
}

export default function TextBlockInput(props: IProps) {

    const [data, setData] = useState<any>(EditorState.createEmpty());
    const [clickedIcon, setClickedIcon] = useState('');
    const [clickedText, setClickedText] = useState('');

    const { t } = useTranslation();    

    useEffect(() => {
        if(props.data) {
            const blocksFromHTML = htmlToDraft((props.data as IBlock).data);
            const state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );
            const data = EditorState.createWithContent(state)
            setData(data);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function addToStory() {

        let value = draftToHtml(convertToRaw(data.getCurrentContent()));
        if(data.getCurrentContent().hasText()) {
            props.addBlockToStory({data:value, type:blockTypes.TEXT})
        }
        else {
            props.addBlockToStory({data:"", type:blockTypes.TEXT})
        }
        setData(EditorState.createEmpty());
    }
   
    const handleClick = () => {
        props.setFullScreen((prev: boolean) => !prev)
        clickedIcon ? setClickedIcon('') : setClickedIcon(mdiFullscreenExit);
        clickedText ? setClickedText('') : setClickedText(t("Exit fullscreen"));
      };

    return(
        <div className="rich-text-editor-container">
            <RichTextEditor setValue={setData} inputData={data}/>
                <button className="default-button" onClick={() => addToStory()}>{t("Add to Story")}</button>
                <span onClick={handleClick} className="edit-in-fullscreen-icon"><Icon size={1} path={clickedIcon || mdiFullscreen} />{clickedText || t("Edit in fullscreen")}</span>  
        </div>
        )
}