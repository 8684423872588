import { Action, IState } from "../interfaces/redux"
import { ActionTypes } from "./actionTypes"
import Cookies from 'universal-cookie'

// Reducers must always be 100% synchronous and "pure", 
// with no side effects ( https://redux.js.org/style-guide/#reducers-must-not-have-side-effects )
// https://redux.js.org/tutorials/essentials/part-5-async-logic
// https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-re-authorization-by-extending-fetchbasequery

//Load cookies to be able to detect if user have valid jwt token.
const cookies = new Cookies();

const initialState: IState = {
    // Check cookies to make sure if the user is logged in or not  
    isAuthenticated: cookies.get('token') ? true : false,
    currentPage: '',
}

/**
 * 
 * @param state State interface located in interfaces
 * @param action Action enum store every action possible on Redux store
 * @returns Updates redux state
 */
const reducer = (
    state: IState = initialState,
    action: Action 
  ): IState => {
        switch (action.type) {
            case ActionTypes.SET_IS_AUTHENTICATED:
                return {
                    ...state,
                    isAuthenticated: action.isAuthenticated,
                }
            case ActionTypes.SET_CURRENT_PAGE:
                return {
                    ...state,
                    currentPage: action.currentPage
                }
            case ActionTypes.CLEAR_REDUX:
                return {
                    ...initialState
                }
        }
    return state;
}

export default reducer