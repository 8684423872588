import React, { useState } from 'react';
import { Carousel, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import create_stories from "../../assets/images/welcome-engage/create-stories.svg";
import add_family from "../../assets/images/welcome-engage/add-family.svg";
import send_proposal from "../../assets/images/welcome-engage/send-proposal.svg";
import inspire_family from "../../assets/images/welcome-engage/inspire-family.svg";
import coauthor_stories from "../../assets/images/welcome-engage/coauthor-stories.svg"; 
import { welcomeTypes } from '../../_helper/enum/enum';
import IFrameDisplay from '../_helper/iframe-display.component';

/**
 * Local interface for input properties.
 */

interface IDetails {
    welcomeType:string
}

interface IQuote {
    text:string
    author:string
}

interface IWelcomeEngage {
    type:string
    imgUrl:string
    title:string
    subtitle:string
    quote:IQuote
}

export const engagementData:IWelcomeEngage[] = [
    {
        type:welcomeTypes.WELCOME,
        imgUrl:create_stories,
        title:"Create Stories",
        subtitle:"Create example stories to show what can be done.",
        quote:{
            text:"“I recommend enabling the ‘share with private link’ feature, so you can include stories in invitations to join”",
            author:"- Janet, USA"
        }
    },
    {
        type:welcomeTypes.WELCOME,
        imgUrl:add_family,
        title:"Add Family",
        subtitle:"Invite family or create profiles for children & ancestors. ",
        quote:{
            text:"“By attaching example stories and explaining potential benefits of Simirity, invitation recipients were keen to join”",
            author:"- Carl, England"
        }
    },
    {
        type:welcomeTypes.WELCOME,
        imgUrl:send_proposal,
        title:"Send Story Requests",
        subtitle:"Engage family with your ideas for their stories.",
        quote:{
            text:"“Thanks to Story Requests, I get to read the stories I want while ensuring our family heritage is saved for future generations”",
            author:"- Sylvie, Canada"
        }
    },
    {
        type:welcomeTypes.ENGAGE,
        imgUrl:inspire_family,
        title:"Inspire Family",
        subtitle:"View inspiration to help plan your family’s stories.",
        quote:{
            text:"“With so many ideas to look through, no-one in my family has the excuse of not knowing what to write!”",
            author:"- Janet, USA"
        }
    },
    {
        type:welcomeTypes.ENGAGE,
        imgUrl:send_proposal,
        title:"Send Story Requests",
        subtitle:"Engage family with your ideas for their stories.",
        quote:{
            text:"“Thanks to Story Requests, I get to read the stories I want while ensuring our family heritage is saved for future generations”",
            author:"- Sylvie, Canada"
        }
    },
    {
        type:welcomeTypes.ENGAGE,
        imgUrl:coauthor_stories,
        title:"Co-author Stories",
        subtitle:"Enjoy the process of creating stories with your family.",
        quote:{
            text:"“Creating stories is a family affair. With the creation experience being just as important as the end story”",
            author:"- Sylvie, Canada"
        }
    },
]

/**
 * 
 * @param props : 
 * @returns void
 */
function WelcomeEngage(props:IDetails) { // do not delete WelcomeEngage componenet for now, even if it's not used currently

    const [index, setIndex] = useState<number>(0);

    const history = useHistory();

    function handleSelect(selectedIndex:number) {
      setIndex(selectedIndex);
    };

    function handleSlide() {
        if(index >= 2) {
            history.push("/")
        }else {
            let next = index + 1;
            setIndex(next);
        }
    }

    return(
        <div className="welcome-engage">
            <p className="top-text">{`${props.welcomeType === welcomeTypes.WELCOME ? 'Welcome' : 'Engage'} your family in three steps`}</p>
            <Carousel variant="dark" activeIndex={index} interval={null} onSelect={handleSelect} slide={true} touch={true}>
                {engagementData.filter(x => x.type === props.welcomeType).map(element => {
                    return(
                        <Carousel.Item key={element.title} className="item">
                            <IFrameDisplay pageName={"quick-tour"} />
                            {/*<img src={element.imgUrl}  alt="avatar" className="user-picture" />
                            <p className="title">{element.title}</p>
                            <p className="subtitle">{element.subtitle}</p>
                            <p className="description">{element.quote.text}</p>
                            <p className="author">{element.quote.author}</p>*/}
                        </Carousel.Item>
                    );
                })}
            </Carousel>
            <Button className="default-button" onClick={handleSlide}>{index >= 2 ? "Finish" : "Next"}</Button>
        </div>
    );
};

export default WelcomeEngage;