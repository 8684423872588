import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { IUserDetails } from "../../../interfaces/authentication";
import { IRelation } from "../../../interfaces/family";
import default_avatar from "../../../assets/images/default-avatar.jpg"
import instance from "../../../api/api";
import { useTranslation } from "react-i18next";
import ConfirmModal from "../../_helper/confirm-modal.component";
import Notification, { notificationImages } from "../../notifications/notification.component";
import { INotification, NotificationType } from "../../../interfaces/notification";
import { ESubscriptionStatus } from "../../../interfaces/subscription";
import { profileTypes } from "../../../_helper/enum/enum";

interface IProps {
    user:IUserDetails
    updateUserData:Function
}

const errorNotification: INotification = { icon: notificationImages.crying, title: "", text: "Exceeds the seats available. Please select individually.", success: NotificationType.problem }

export default function SubscriptionShare(props:IProps) {

    const [ loading, setLoading ] = useState<boolean>(false);
    const [ confrimModal, setConfirmModal] = useState<boolean>(false);
    const [ selectedId, setSelectedId ] = useState<string>('');
    const [ selectAllId, setSelectedAllId ] = useState<string[]>([]);
    const [ newUsers, setNewUsers ] = useState<IRelation[]>([]);

    //local relations
    const [ relations, setRelations ] = useState<IRelation[]>([]);

    //feedback
    const [ limitNotification, setLimitNotification ] = useState<boolean>(false);

    const history = useHistory();

    useEffect(() => {
        setRelations(props.user.relations)

        // new users are...
        const newUsers = relations.filter((relation) => 
            // not member already
            !props.user.subscription?.members.map(x => x._id).includes(relation.userid._id) 
            // not deceased            
            && relation.userid.profileType !== profileTypes.DECEASED
            // not with (another) premium access already
            && !(relation.userid.subscription) // && relation.userid.subscription.status === ESubscriptionStatus.ACTIVE)
            );

        setNewUsers(newUsers);

    }, [props.user.relations])

    //Translation
    const { t } = useTranslation();

    async function selectAllClickHandle() {
        const members:string[] = [];

        if(!props.user.subscription) {
            return;
        }


        const remainingSeats = props.user.subscription?.subscription_type.seats - props.user.subscription?.members.length;

        if(newUsers.length > remainingSeats) {
            //TODO: need feedback
            return;
        }

        props.user.relations.forEach((relation:IRelation) => {
            if(!relation.userid.subscription && !props.user.subscription?.members.map(x => x._id).includes(relation.userid._id)) {
                members.push(relation.userid._id)
            }
        });
        setSelectedAllId(members)
        setConfirmModal(true)
    }

    async function selectAll() {
        let data:any = {
            $push: {
                members: {$each: selectAllId}
            }
        };

        const updateSubscriptionResponse = await instance.put("/subscriptions/" + props.user.subscription?._id, data);

        //update user
        props.updateUserData((prev:any) => {
            return {...prev, subscription:updateSubscriptionResponse.data}
        })

        //update local relations
        setRelations(prev => {
            return prev.map((relation:IRelation) => {
                if(!relation.userid.subscription && !props.user.subscription?.members.map(x => x._id).includes(relation.userid._id)) {
                    relation.userid.subscription = props.user.subscription
                }
                return relation;
            })
        })
        setSelectedAllId([])
        setConfirmModal(false)
    }

    async function modifyAccess(_id:string) {
        if(loading || ! props.user.subscription) {
            return;
        }

        setLoading(true);

        try {
            let data = {};
            const remove = checkUserHasShare()

            if(!remove && props.user.subscription?.members.length >= (props.user.subscription?.subscription_type.seats-1)) {
                return;
            }

            if(remove) {
                data = {
                    $pull: {
                        members: {$in :[_id]}
                    }
                }
            }
            else {
                data = {
                    $push: {
                        members: {$each :[_id]}
                    }
                }
            }

            

            const updateSubscriptioResponse = await instance.put("/subscriptions/" + props.user.subscription?._id, data);

            //update global user
            props.updateUserData((prev:any) => {
                return {...prev, subscription:updateSubscriptioResponse.data}
            })

            //update local relations
            setRelations(prev => {
                return prev.map((relation:IRelation) => {
                    if(relation.userid._id === _id) {
                        let add = data.hasOwnProperty('$push');

                        let temp = {...relation};
                        if(add) {
                            temp.userid.subscription = props.user.subscription
                        }
                        else {
                            delete temp.userid.subscription
                        }
                        return temp
                    }
                    else {
                        return relation
                    }
                })
            })

            setLoading(false)

        }
        catch(error) {
            console.log('error', error)
            setLoading(false)
        }

        setSelectedId('');
        setConfirmModal(false);
    }

    function toggleUser(_id:string) {
        setSelectedId(_id); 
        setConfirmModal(true)
    }

    function checkUserHasShare() {
        return props.user.subscription?.members.map(x => x._id).includes(selectedId)
    }

    const removeBody = (
        <div className="confirm-cancel-modal-body">
            <p className="subtitle">{t("Deselecting user")}</p>
            <p>{t("Deselected users will be downgraded to a Free Account and won't be able to create unlimited media rich stories or enjoy Premium Access.")}</p>
        </div>
    )

    const addBody = (
        <div className="confirm-cancel-modal-body">
            <p className="subtitle">{t("Selecting user")}</p>
            <p>{t("Selected users will join your Subscription, entitling them to full access and uploading media files to stories.")}</p>
            <p className="paragraph-header">{t("Sharing your media upload allowance")}</p>
            <p>{t("Any uploads by this user will count towards your Subscription's Media Upload Allowance. You can monitor their uploads on the Share page.")}</p>
        </div>
    )

    return(
        <div className="subscription-share-container">
            {props.user.subscription ? 
            <>
            <div className="title-container">
                <p>{t("Share your Subscription")}</p>
                <p>{t("And give family shared access to your subscription's {{var}}GB media upload allowance" , {var: props.user.subscription.subscription_type.upload_limit})}</p>
            </div>
            <div className="seat-info-container">
                <p>{props.user.subscription.members.length + 1} {t("from")} {props.user.subscription.subscription_type.seats} {t("seats used")}</p>
                {props.user.subscription.subscription_type.fastspring_key === "family" ? <p ><span className="link-to" onClick={() => history.push("/dashboard/account/subscription/buy")}>{t("Upgrade to Family+")}</span> {t("for up to 25 seats")}</p> : null}
            </div>
            <div className="relation-container">
                {newUsers.length ? <p className="link-to small-text" onClick={() => selectAllClickHandle()}>{t("Select all")}</p> : null}
                {limitNotification ? <Notification data={errorNotification} close={() => setLimitNotification(false)} /> : null}
                <div className="relations">
                    {relations.filter((x:IRelation) => x.userid.profileType !== profileTypes.DECEASED).map((relation:IRelation, index:number) => {
                        return(
                            <Row key={relation._id} className={"relation"}>
                                <Col xs={10} className="member-detail" onClick={ () => history.push('/dashboard/profile/' + relation.userid._id + "/1") } >
                                    <div className="img-container">
                                        {relation.userid && <img className="user-picture" src={relation.userid.avatar ? relation.userid.avatar : default_avatar} alt="" />}
                                    </div>
                                    <div className="content">
                                        <p className="name">{`${relation.userid.firstname} ${relation.userid.lastname}`}</p>
                                       {props.user.subscription?.members.map(x => x._id).includes(relation.userid._id) ? 
                                            <p className="used">{relation.userid.uploadsInPeriod} GB {t("media uploaded")}</p>
                                            : 
                                            relation.userid.subscription && relation.userid.subscription.status === ESubscriptionStatus.ACTIVE ? 
                                            <p className="already-premium">{t("Already with Premium Access")}</p>
                                            : 
                                            <p className="used">{t("Free account")}</p>
                                        }
                                    </div>
                                </Col>
                                {(!(relation.userid.subscription && relation.userid.subscription.status === ESubscriptionStatus.ACTIVE) || props.user.subscription?._id === relation.userid.subscription._id) ?
                                <Col xs={2} className="menu-btn">
                                    <Form.Check className="checkbox" type="checkbox" checked={props.user.subscription?.members.map(x => x._id).includes(relation.userid._id)} onChange={() => toggleUser(relation.userid._id)} />
                                </Col> : null}
                            </Row>
                        )
                    })}
                    <Row key={props.user._id} className={"relation"}>
                        <Col xs={10} className="member-detail" onClick={ () => history.push('/dashboard/profile/' + props.user._id + "/1") } >
                            <div className="img-container">
                                <img className="user-picture" src={props.user.avatar ? props.user.avatar : default_avatar} alt="" />
                            </div>
                            <div className="content">
                                <p className="name">{`${props.user.firstname} ${props.user.lastname}`}</p>
                                <p className="used">{props.user.uploadsInPeriod} GB {t("media uploaded")}</p> 
                            </div>
                        </Col>
                        <Col xs={2} className="menu-btn">
                            <Form.Check className="checkbox self" type="checkbox" checked={true} />
                        </Col>
                    </Row>
                </div>

                <p className="small-text">{t("To share with others")}, <span className="link-to" onClick={() => history.push("/dashboard/profile/" +  props.user._id + "/2")}>{t("add to your family list")}</span></p>
            </div>
            <ConfirmModal 
                show={confrimModal} 
                action={"delete"} 
                handleClose={() => { setConfirmModal(false);  setSelectedId(''); setSelectedAllId([])} } 
                modalTitle="Confirm" 
                modalBody={() => checkUserHasShare() ? removeBody : addBody}
                reject={() => { setConfirmModal(false);  setSelectedId(''); setSelectedAllId([])}} 
                confirm={() => selectAllId.length ? selectAll() : modifyAccess(selectedId)}
                />
            </> : null} 
        </div>
    )

}