export default function checkUnderAge(birthdate: Date | string) {
    let today = new Date()
    let date: Date = new Date(birthdate)

    if (date instanceof Date) {
        let age = today.getFullYear() - date.getFullYear()
        let m = today.getMonth() - date.getMonth()

        if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
            age--
        }

        return age < 18
    } else {
        return false
    }
}
