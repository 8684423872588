import { IUserDetails } from "./authentication"

export enum ESubscriptionTypes {
    FAMILY="Family",
    FAMILY_PLUS="Family+"
}

export enum ESubscriptionStatus {
    ACTIVE="Active",
    CANCELLED="Cancelled",
    INACTIVE="Inactive"
}

enum EPaymentType {
    CREDIT_TRANSACTION_PAYMENT="1",
    SUBSCRIPTION_PAYMENT="2",
    MEDIA_BOOST_PAYMENT="3"
}

export interface IPayment {
    payment_id:string
    payment_type:EPaymentType
    user:IUserDetails
    date: Date
}

export interface ISubscriptionType {
    fastspring_key: string
    name: string
    seats: number
    upload_limit: 40
    _id: string
    updatedAt: Date
    createdAt: Date
}

export interface ISubscription {
    _id:string
    owner: IUserDetails
    expiration: Date
    auto_renewal: Date
    status: ESubscriptionStatus
    members: IUserDetails[]
    payments: IPayment[]
    subscription_type:ISubscriptionType
    createdAt:Date
    allUploadInPeriod:number
    fastspring_id:string
    trial:boolean
    privateId?: string
    startedAs: string
}